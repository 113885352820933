//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//COMPONENTES
import Rutas from "../../dataDahsboard/routes.json";
import { refresh_token } from "../../main_components/tokens/tokenrefresh.jsx";

//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../dashboar.css";
//IMAGENES/ICONOS
import { get_dashboard1 } from "../../services/reports/dashboard";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import { calculate_total } from "../../main_components/methods_v2/auxiliary_functions";
import SubTable2 from "./Get_datail_Sales_v2";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NewDashboardDetailSales_v2 = () => {
  var date = new Date();

  var dia = `${date.getDate()}`.padStart(2, "0");
  var mes = `${date.getMonth() + 1}`.padStart(2, "0");
  var año = date.getFullYear();

  var mesantes = Number(mes) - 1;

  if (mesantes == 0) {
    mesantes = `${12}`.padStart(2, "0");
  } else {
    mesantes = `${mesantes}`.padStart(2, "0");
  }

  const recuperarPPD = `${año}-${mes}-${dia}`;
  const recuperarPPDantes = `${año}-${mesantes}-${dia}`;

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [menssage, setMenssage] = useState("");

  const [ff, setff] = useState(recuperarPPD);
  const [fi, setfi] = useState(recuperarPPDantes);
  const [origen, setOrigen] = useState("");

  const [rfc, setRFC] = useState("");
  const [bp, setBP] = useState("");
  const [selectRutas, setselectRutas] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);

  const update_identifiers =
  [
    { customer: 'string' },
    { sales: 'number' },
    { participation: 'number' },
    { customers: 'number'},
    { withsale: 'number'},
    { withoutsale: 'number'},
    { freezers: 'number'},
    { cost: 'number'},
    { profit: 'number'},
  ];

  const [sales, setsales]=useState([]);
  const [participation, setparticipation] = useState([]);
  const [customers, setcustomers] = useState([]);
  const [withsale, setwithsale] = useState([]);
  const [withoutsale, setwithoutsale] = useState([]);
  const [freezers, setfreezers] = useState([]);
  const [cost, setcost] = useState([]);
  const [profit, setprofit] = useState([]);

  useEffect(() => {
    columnshide();
  }, [])

  const handleSubmit = async(event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Consultando...");
    setData([]);
    
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_dashboard1(fi, ff, selectRutas);
            const data = d === null ? [] : d.data;
            const data2 = (data.filter(dash=>dash.customer!=="Grand Total"));
            
            setsales(data2?.map(obj=>obj.sales).filter(d=>d!==null));
            setparticipation(data2?.map(obj => obj.participation).filter(d => d !== null));
            setcustomers(data2?.map(obj => obj.customers).filter(d => d !== null));
            setwithsale(data2?.map(obj => obj.withsale).filter(d => d !== null));
            setwithoutsale(data2?.map(obj => obj.withoutsale).filter(d => d !== null));
            setfreezers(data2?.map(obj => obj.freezers).filter(d => d !== null));
            setcost(data2?.map(obj => obj.cost).filter(d => d !== null));
            setprofit(data2?.map(obj => obj.profit).filter(d => d !== null));
            
            setData(data2);
            setOpen(false);
            setMenssage("");
          }catch(err){
            console.log("error", err);
            setData([]);
            setOpen(false);
            setMenssage("");
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  const handleRutas = (event) => {
    setselectRutas(event.target.value);
  };


  const [columnsx, setcolumnsx] = useState([]);
  const columnshide = ()=>{
    if(sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT")==="ROLE_REPORT_SALES_MANAGEMENT"){  
      const d = [
        {
          id:"Costo",
          // enableHiding: sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT")==="ROLE_REPORT_SALES_MANAGEMENT"?true:false,
          header: "Costo",
          accessorKey: "cost",
          Cell: ({ cell }) => {
            return(
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor: theme.palette.success.dark,
                borderRadius: '0.25rem',
                color: '#fff',
                maxWidth: '12ch',
                p: '0.25rem',
              })}
            >
              {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          )},
          muiTableBodyCellProps: {
            align: 'right',
          },
          muiTableFooterCellProps: {
            align: 'right',
          },
          Footer: (d) => {
            const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.cost)
            return (
              <div style={{ textAlign: "right" }}>
                <div>{Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                }).format(calculate_total(subtotal))}</div>
                <div>{Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                }).format(calculate_total(cost))
                }</div> 
              </div>
            )
          },  
        },
        {
          id:"Ganancia",
          // enableHiding: sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT")==="ROLE_REPORT_SALES_MANAGEMENT"?true:false,
          header: "Ganancia",
          accessorKey: "profit",
          Cell: ({ cell }) => {
            return(
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor: theme.palette.success.dark,
                borderRadius: '0.25rem',
                color: '#fff',
                maxWidth: '12ch',
                p: '0.25rem',
              })}
            >
              {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          )},
          muiTableBodyCellProps: {
            align: 'right',
          },
          muiTableFooterCellProps: {
            align: 'right',
          },
          Footer: (d) => {
            const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.profit)
            return (
              <div style={{ textAlign: "right" }}>
                <div>{Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                }).format(calculate_total(subtotal))}</div>
                <div>{Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                }).format(calculate_total(profit))
                }</div>
              </div>
            )
          }, 
        }
      ]
      setcolumnsx([...d])
    }else{
      setcolumnsx([]);
    }
}


  const columns = useMemo(
    () => [
      { 
        id: "customer",
        header: "Clasificación",
        accessorKey: "customer",
        Footer: () => (
          <div>
            <div>Subtotal: </div>
            <div>Gran Total: </div>
          </div>
        ),
      },
      {
        id: "sales",
        header: "Ventas",
        accessorKey: "sales",
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.sales)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(sales))
              }</div>
            </div>
          )
        },  
      },
      {
        id: "participation",
        header: "Participación",
        accessorKey: "participation",
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
          >
            {cell.getValue() === undefined ?
            <div className="">
              {0}%
            </div>:
            <div className="">
              {cell.getValue()}%
            </div>}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.participation).map(value => Number(value));
          return (
            <div style={{ textAlign: "right" }}>
              <div>{(calculate_total(subtotal)).toFixed(2)}%</div>
              <div>{calculate_total(participation).toFixed(1)}%</div>
            </div>
          )
        },
      },
      {
        header: "Puntos de venta",
        accessorKey: "customers",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.customers);
          return (
            <div style={{ textAlign: "right" }}>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(customers)}</div>
            </div>
          )
        },
      },
      {
        header: "Con venta",
        accessorKey: "withsale",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.withsale);
          return (
            <div style={{ textAlign: "right" }}>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(withsale)}</div>
            </div>
          )
        },
      },
      {
        header: "Sin venta",
        accessorKey: "withoutsale",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.withoutsale);
          return (
            <div style={{ textAlign: "right" }}>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(withoutsale)}</div>
            </div>
          )
        },
      },
      {
        header: "Congeladores",
        accessorKey: "freezers",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.freezers);
          return (
            <div style={{ textAlign: "right" }}>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(freezers)}</div> 
            </div>
          )
        },
      },
      ...columnsx
    ],
    [ 
      sales,
      participation,
      customers, 
      withsale, 
      withoutsale, 
      freezers, 
      cost, 
      profit
    ]
  );

  return (
    <div >
      <div>
      <Modal
          open={open}
          className="nModalNew"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga nModalNew">
              <Typography
                id="modal-modal-title"
                
                variant="h6"
                component="h2"
              ></Typography>
              <Typography
                id="modal-modal-description"
                
                sx={{ mt: 2 }}
              ></Typography>
            </div>
          </Box>
        </Modal>
      </div>

      
      {/* <div className="containerExpenseReport2">
        <div className="expen-1 color-1">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha inicial"
            onChange={(event) => setfi(event.target.value)}
          ></input>
        </div>

        <div className="expen-2 color-2">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha Final"
            onChange={(event) => setff(event.target.value)}
          ></input>
        </div>

        <div className="expen-3 color-5 topSelect">
          <TextField
            select
            label="Rutas"
            value={selectRutas}
            sx={{ width: "100%" }}
            onChange={handleRutas}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              native: true,
            }}
          >
            {Rutas.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </TextField>
        </div>

        <div className="expen-4 color-5">
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            onClick={handleSubmit}
          >
            Buscar
          </Button>
        </div>
      </div> */}
      <div className="new_table_v2">
        <div style={{ overflowX: "auto" }}>
        
    <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={
          { 
            showGlobalFilter: true, 
            columnVisibility:{
              cost: sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT") === "ROLE_REPORT_SALES_MANAGEMENT" ? true : false,
              profit: sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT") === "ROLE_REPORT_SALES_MANAGEMENT" ? true : false,
            }
          }
        }
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
            size: 80
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderTopToolbarCustomActions={({ }) => (
          <div className="dashboard-acctions">
                  <div className="dashacc1">
                    <input
                      type="text"
                      className="fExpense"
                      onFocus={(e) => {
                        e.currentTarget.type = "date";
                        e.currentTarget.focus();
                      }}
                      placeholder="Fecha inicial"
                      onChange={(event) => setfi(event.target.value)}
                    ></input>
                  </div>

                  <div className="dashacc2">
                    <input
                      type="text"
                      className="fExpense"
                      onFocus={(e) => {
                        e.currentTarget.type = "date";
                        e.currentTarget.focus();
                      }}
                      placeholder="Fecha Final"
                      onChange={(event) => setff(event.target.value)}
                    ></input>
                  </div>

                  <div className="dashacc3">
                    <TextField
                      select
                      label="Ruta"
                      value={selectRutas}
                      sx={{ width: "50%" }}
                      onChange={handleRutas}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      {Rutas.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </TextField>
                  </div>

                  <div className="">
                    <Button
                      sx={{ width: "100%" }}
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      Buscar
                    </Button>
                  </div>
                </div>


        )}
        renderRowActionMenuItems={({ row, closeMenu }) => [
          <RENDER_ROW_ACTION_MENU_ITEMS
            closeMenu={closeMenu}
            row={row}
            handleSubmit={handleSubmit}
            setreconsult={setreconsult}
            refresh_token={refresh_token}
            verData={true}
            datei={fi}
            datef={ff}
            route={selectRutas}
            nosale={false}
          />
        ]}               
         renderToolbarInternalActions={({ table }) => (
           <RENDER_TOOLBAR_INTERNAL_ACTIONS
             table={table} 
             file_name={"Detalles de Ventas"}
             update_identifiers={update_identifiers}
           />
         )
         }
      />

        </div>
      </div>
    </div>
  );
};

export default NewDashboardDetailSales_v2;
