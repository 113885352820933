//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { get_invoices } from "../../../services/invoices/invoice";
import { download_invoice } from "../../../services/invoices/invoice";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { get_status } from "../../../services/invoices/invoice";
import SEARCH_FILTERS from './tools/shearch_filter';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//ESTILOS
import "../../estilo.css";
import "../../modal.css";
import "../../table.css";
//IMAGENES
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import {
  formattedCurrentDate,
  formattedPreviousDate,
} from "../../../main_components/date/day";
import "./invoice.css";
import NotificationSystem from "react-notification-system";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import dayjs from "dayjs";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import AlertResponse from "../../../main_components/alerts/alertResponse";
import DETAIL_INVOICE_V2 from "./detail/detail_invoce_v2";
import { Box } from "@mui/material";
import { calculate_total } from "../../../main_components/methods_v2/auxiliary_functions";
import { SEARCH_FILTER_WEB_MOVIL } from "../../../main_components/methods_v2/search_filter_web_movil";



//COMPONENTES

const Vppd_v2 = () => {
  const [data, setData] = useState([]);

  const [ff, setff] = useState(dayjs(formattedCurrentDate));
  const [fi, setfi] = useState(dayjs(formattedPreviousDate));
  const [rfc, setRFC] = useState("");
  const [sdn, setSDN] = useState("");
  const [cancel, setCancel] = useState(null);
  const [tipodoc, setTipoDoc] = useState("");
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [modalT, setmodalT] = useState(false);
  const [open, setOpen] = useState(false);
  const [reconsult, setreconsult] = useState(false);
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [menssage, setmenssage] = useState("");
  const notificationSystemRef = useRef();
  const [query_filters, setquery_filters] = useState({});
  const [total_rows, settotal_rows] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});

  const [g_total, setg_total] = useState([]);
  const [g_montopago, setg_montopago] = useState([]);
  const [g_montodeuda, setg_montodeuda] = useState([]);

  const [usStateList, setusStateList] = useState([]);

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setmodalT(true);
    setmodalGeneral(true);
    setData([]);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var dt = await get_invoices(fi.format('YYYY-MM-DD'), ff.format('YYYY-MM-DD'), rfc, sdn, tipodoc, cancel, "", true);
            setg_total(dt.data?.map(obj => obj.total).filter(d => d !== null));
            setg_montopago(dt.data?.map(obj => obj.amount_paid).filter(d => d !== null));
            setg_montodeuda(dt.data?.map(obj => obj.amount_of_debt).filter(d => d !== null));
            setData(dt.data.slice().sort((a, b) => a.invoice_date < b.invoice_date));
            setmodalT(false);
            setmodalGeneral(false);

          } catch (err) {
            setData([]);
            setmodalT(false);
            setmodalGeneral(false);

          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [

      {
        header: "Fecha",
        accessorKey: "invoice_date",
        id: "invoice_date",
        Footer: () => (
          <div>
            <div>Sub Total: </div>
            <div>Gran Total: </div>
          </div>
        ),
      },
      {
        header: "Serie",
        accessorKey: "serie",
        id: "serie",
      },
      {
        id: "folio",
        header: "Folio",
        accessorKey: "folio",
      },
      {
        id: "total",
        header: "Total",
        accessorKey: "total",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.total).filter(d => d !== "")
          console.log(subtotal)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_total))}</div>
            </div>
          )
        },
      },
      {
        header: "Cliente",
        accessorKey: "name_business_partner",
        id: "name_business_partner"
      },
      {
        id: "rfc_business_partner",
        header: "RFC",
        accessorKey: "rfc_business_partner",
      },

      {
        id: "forma_pago",
        header: "Forma de pago",
        accessorKey: "forma_pago",
      },
      {
        id: "amount_paid",
        accessorKey: "amount_paid",
        header: "Monto de pago",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.amount_paid).filter(d => d !== "")
          console.log(subtotal)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_montopago))}</div>
            </div>
          )
        },
      },
      {
        id: "paid",
        header: "Pagado",
        accessorKey: "paid",
        accessorFn: (d) => {
          return d.paid === true ? "SI" : "NO";
        },
      },

      {
        id: "amount_of_debt",
        header: "Monto de deuda",
        accessorKey: "amount_of_debt",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.amount_of_debt).filter(d => d !== "")
          console.log(subtotal)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_montodeuda))}</div>
            </div>
          )
        },
      },

      {
        id: "installment",
        header: "Parcialidad",
        accessorKey: "installment",
      },
      {
        header: "Estado",
        accessorKey: "cancel",
        accessorFn: (d) => {
          return d.cancel === true ? "CANCELADO" : "VIGENTE";
        },
        id: "cancel"
      },
      {
        header: "Usuario",
        accessorKey: "username",
        id: "username",
      },
    ],
    [g_total, g_montodeuda, g_montopago]
  );


  const update_identifiers =
    [
      { invoice_data: 'string' },
      { serie: 'string' },
      { folio: 'string' },
      { total: 'number' },
      { name_business_partner: 'string' },
      { rfc_business_partner: 'string' },
      { forma_pago: 'string' },
      { amount_paid: 'number' },
      { paid: 'string' },
      { amount_of_debt: 'number' },
      { installment: 'string' },
      { cancel: 'string' },
      { username: 'string' }

    ];

  const handle_success_filter = (a, b) => {
    handle_get_invoice(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });
  }

  const update_null_identifiers = (original_obj, identifiers_to_update) => {
    return original_obj.map((item) => {
      for (const identifier in item) {
        if (item.hasOwnProperty(identifier)) {
          const isToUpdate = identifiers_to_update.some((update) => update[identifier] !== undefined);
          if (isToUpdate && item[identifier] === null) {
            item[identifier] = typeof identifiers_to_update.find((update) => update[identifier]) === 'number' ? 0 : '';
          }
        }
      }
      return item;
    });
  };


  const handle_get_invoice = async (query_filters_flag, query_filters_data) => {
    setIsLoading(true);
    setData([]);
    setopenmini(false);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var dt;
            console.log(query_filters_flag)
            if (query_filters_flag) {
              console.log(query_filters_data)
              setfi(dayjs(query_filters_data.di))
              setff(dayjs(query_filters_data.df))
              dt = await get_invoices(query_filters_data.di, query_filters_data.df, query_filters_data.rfc === "null" ? null : query_filters_data.rfc, query_filters_data.b_partner === "null" ? null : query_filters_data.b_partner, query_filters_data.type === "null" ? null : query_filters_data.type, cancel === "all" ? null : cancel);
            } else {
              setquery_filters({
                di: fi.format('YYYY-MM-DD'),
                df: ff.format('YYYY-MM-DD'),
                rfc: rfc || null,
                b_partner: sdn || null,
                type: tipodoc || null,
                cancel: cancel || null,
              })
              dt = await get_invoices(fi.format('YYYY-MM-DD'), ff.format('YYYY-MM-DD'), rfc, sdn, tipodoc, cancel === "all" ? null : cancel);
            }
            setg_total(dt.data?.map(obj => obj.total).filter(d => d !== null));
            setg_montopago(dt.data?.map(obj => obj.amount_paid).filter(d => d !== null));
            setg_montodeuda(dt.data?.map(obj => obj.amount_of_debt).filter(d => d !== null));

            const list = Array.from(new Set(dt.data?.map(obj => obj.serie)));
            dt = dt.data.slice().sort((a, b) => a.invoice_date < b.invoice_date);
            var ndt = update_null_identifiers(dt, update_identifiers);
            setusStateList(list);
            setData(ndt);
            console.log(ndt);
          } catch (err) {
            setData([]);
          } finally {
            setIsLoading(false);

          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  
  
  const [minimenu, setminimenu]=useState(false);
  const [openmini, setopenmini]=useState(false);

   useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 480) {
          console.log('Screen width is less than 480px');
          setminimenu(true);
        } else {
          setminimenu(false);
        }
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    const filters_custom = (
      <SEARCH_FILTERS
      fi={fi}
      setfi={setfi}
      ff={ff}
      setff={setff}
      tipodoc={tipodoc}
      setTipoDoc={setTipoDoc}
      cancel={cancel}
      setCancel={setCancel}
      rfc={rfc}
      setRFC={setRFC}
      sdn={sdn}
      setSDN={setSDN}
      handle_get_invoice={handle_get_invoice}
    ></SEARCH_FILTERS>
    );

  return (
    <>
      <BREADCRUMBS
        niveles={[{ label: "CONSULTAR FACTURAS PPD", path: null }]}
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
        
      ></BREADCRUMBS>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <MODAL_TABLE
        open={true}
        message={"Consultar Facturas PPD"}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <SEARCH_FILTER_WEB_MOVIL
          minimenu={minimenu} 
          setminimenu={setminimenu}
          openmini={openmini} 
          setopenmini={setopenmini}
          filterMovComponent={filters_custom}
          renderTopToolbarCustomActionsFilters={true}
        ></SEARCH_FILTER_WEB_MOVIL> 
      

      <div>
        <div className="new_table_v2">
          <MaterialReactTable
            columns={columns}
            data={data}
            enableGrouping
            enablePinning
            enableFacetedValues
            enableStickyHeader
            enableStickyFooter
            enableRowPinning
            enableColumnFilterModes
            enableRowActions
            enableRowSelection
            getRowId={(row) => row?.id}
            onRowSelectionChange={setRowSelection}
            enableTooltips={false}
            localization={MRT_Localization_ES}
            state={{ rowSelection, isLoading: isLoading }}
            enableColumnResizing
            enableColumnPinning
            enableColumnOrdering
            onStateChange={handleStateChange}
            enableExpandAll={false}
            enableColumnDragging={false}
            initialState={{ showGlobalFilter: true, showColumnFilters: true }}
            muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
            muiSkeletonProps={{ animation: 'pulse', height: 28 }}
            rowVirtualizerInstanceRef
            rowVirtualizerOptions={{ overscan: 5 }}
            columnVirtualizerOptions={{ overscan: 2 }}
            muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
            positionToolbarAlertBanner="bottom"
            paginationDisplayMode='pages'
            rowPinningDisplayMode='sticky'
            layoutMode="grid"
            muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
              'mrt-row-pin': {
                enableHiding: true,
              },
              'mrt-row-actions': {
                enableHiding: true,
              },
              'mrt-row-expand': {
                enableHiding: true,
              },
              'mrt-row-select': {
                enableHiding: true,
              }
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  data.splice(
                    hoveredRow.index,
                    0,
                    data.splice(draggingRow.index, 1)[0],
                  );
                  setData([...data]);
                }
              },
            })}
            muiTableBodyRowProps={
              ({ row, table }) => {
                settotal_rows(table);
                const { density } = table.getState();
                return {
                  sx: {
                    height: row.getIsPinned()
                      ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                      }px`
                      : undefined,
                  },
                };
              }
            }
            renderDetailPanel={({ row }) => {
              if (row.getIsExpanded() === true) {
                sessionStorage.setItem("dataSuntablefiltro", row.original.uuid_sat);
                sessionStorage.setItem("dataSuntablefiltroID", row.original.id);
              }
              return <DETAIL_INVOICE_V2
                reconsult={reconsult}
                data={data}
                tipo={row}
                columns={columns}
                defaultPageSize={3}
                showPagination={false}
              />
            }}
            renderRowActionMenuItems={({ row, closeMenu }) => [
              <RENDER_ROW_ACTION_MENU_ITEMS
                key={row.original.id}
                closeMenu={closeMenu}
                setmodalT={setmodalT}
                row={row}
                handle_get_invoice={handle_get_invoice}
                setreconsult={setreconsult}
                refresh_token={refresh_token}
              />
            ]}
            renderTopToolbarCustomActions={({ }) => {
              if(minimenu===false){
                return(
                <>
                  {filters_custom}
                </>
                )
            }}  
            }

            renderToolbarInternalActions={({ table, idenx }) => (
              <RENDER_TOOLBAR_INTERNAL_ACTIONS
                key={idenx}
                share_filter={true}
                query_filters={query_filters}
                settotal_rows={settotal_rows}
                handle_success_filter={handle_success_filter}
                total_rows={total_rows !== "" ? total_rows : 0}
                table={table} file_name={"FACTURAS PPD DEL " + fi.format('YYYY-MM-DD') + " AL " + ff.format('YYYY-MM-DD')}
                update_identifiers={update_identifiers}
                general_public_report={false}
                zip={true}
              />
            )
            }
          />
        </div>
      </div>
    </>

  );
};

export default Vppd_v2;
