import React, { useEffect, useState, Fragment } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Typography, Box, IconButton, Collapse, Alert,
  styled, useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import Skeleton from '@mui/material/Skeleton';

import { get_businesspartners_pointofsale, get_pointsale_sectors, get_pointsale_cabinets } from '../../../../services/businesspartners/businesspartners';

// Interfaces
interface PointOfSale {
  id: number;
  code: string;
  name: string;
  rfc: string;
  route: string;
  date_register: string;
}

interface Sector {
  id: number;
  number_sector: number;
  name_sector: string;
  orderSector: number;
  route: string;
  date_register: string;
}

interface Cabinet {
  id: number;
  brand: string;
  model: string;
  economic_number: string;
  fixed_asset: string;
  serial_number: string;
}

interface CustomTableProps {
  data: string;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: "450px",

}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: '#0c4683', // Asegúrate de que este color es el que deseas
  '& .MuiTableCell-head': {
    color: "white",
    fontWeight: 'bold',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.common.white,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledCollapse = styled(Collapse)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
}));

const StyledSubTableContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  boxShadow: '0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.06)',
}));

const StyledSubTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: "#0c4683",
  '& .MuiTableCell-head': {
    color: "white",
    fontWeight: 'bold',
  },
}));

const CustomTable: React.FC<CustomTableProps> = ({ data }) => {
  const theme = useTheme();
  const [openRows, setOpenRows] = useState<{ [key: number]: boolean }>({});
  const [pointOfSaleList, setPointOfSaleList] = useState<PointOfSale[]>([]);
  const [cabinets, setCabinets] = useState<Cabinet[]>([]);
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let isMounted = true; // Flag para verificar si el componente está montado

    const getData = async () => {
      try {
        const dataPoint = await fetchPointOfSales(data);
        if (isMounted) {
          setPointOfSaleList(dataPoint);
        }
      } catch (err) {
        console.error("Error al obtener los puntos de venta:", err);
        if (isMounted) {
          setError("Error al obtener los datos de los puntos de venta. Por favor, intenta más tarde.");
        }
      }
    };

    if (data !== undefined) {
      getData();
    }

    return () => {
      isMounted = false; // Se actualiza el flag cuando el componente se desmonta
    };
  }, [data]); // Asegúrate de que la lista de dependencias esté correcta


  // fetchPointOfSales, fetchCabinets, fetchSectors functions (unchanged)
  const fetchPointOfSales = async (id: any): Promise<PointOfSale[]> => {
    try {
      const response = await get_businesspartners_pointofsale(id);
      if (Array.isArray(response.data.pointOfSaleList)) {
        return response.data.pointOfSaleList;
      } else {
        console.warn("Respuesta inesperada: no es un array", response.data);
        return [];
      }
    } catch (error) {
      console.error("Error al obtener los datos de dirección:", error);
      return [];
    }
  };

  const fetchCabinets = async (id: any): Promise<Cabinet[]> => {
    try {
      const response = await get_pointsale_cabinets(id);
      return response.data.cabinets;
    } catch (error) {
      console.error("Error al obtener los datos de los congeladores:", error);
      return [];
    }
  };

  const fetchSectors = async (id: any): Promise<Sector[]> => {
    try {
      const response = await get_pointsale_sectors(id);
      return response.data.sectors;
    } catch (error) {
      console.error("Error al obtener los datos de los sectores:", error);
      return [];
    }
  };

  const toggleRow = async (id: number) => {
    setOpenRows((prev) => ({ ...prev, [id]: !prev[id] }));
    if (!openRows[id]) {
      try {
        const fetchedCabinets = await fetchCabinets(id);
        const fetchedSectors = await fetchSectors(id);
        setCabinets(fetchedCabinets);
        setSectors(fetchedSectors);
      } catch (err) {
        console.error("Error al obtener detalles del punto de venta:", err);
        setError("Error al obtener los detalles del punto de venta. Por favor, intenta más tarde.");
      }
    }
  };

  return (
    <StyledPaper >
      {error && (
        <Box marginBottom={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <StyledTableContainer>
        <Table stickyHeader key={"table"}>
          <StyledTableHead className='table_point_of_sale_details'>
            <TableRow>
              <TableCell>Acciones</TableCell>
              <TableCell>Código</TableCell>
              <TableCell>Punto de venta</TableCell>
              <TableCell>RFC</TableCell>
              <TableCell>Ruta</TableCell>
              <TableCell>Fecha de registro</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {Array.isArray(pointOfSaleList) && pointOfSaleList.map((pos) => (
              <Fragment key={`fragment-${pos.id}`}>
                <StyledTableRow key={pos.id}>
                  <TableCell>
                    <StyledIconButton onClick={() => toggleRow(pos.id)} size="small">
                      {openRows[pos.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </StyledIconButton>
                  </TableCell>
                  <TableCell>{pos.code}</TableCell>
                  <TableCell>{pos.name}</TableCell>
                  <TableCell>{pos.rfc}</TableCell>
                  <TableCell>{pos.route}</TableCell>
                  <TableCell>{pos.date_register}</TableCell>
                </StyledTableRow>
                <TableRow key={`collapse-${pos.id}`}>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <StyledCollapse in={openRows[pos.id]} timeout="auto" unmountOnExit>
                      <StyledSubTableContainer>
                        <Typography variant="h6" gutterBottom component="div" sx={{ padding: theme.spacing(1), backgroundColor: "#e7e7e7" }}>
                          Congeladores Asignados
                        </Typography>
                        <Table size="small">
                          <StyledSubTableHead>
                            <TableRow>
                              <TableCell>Marca</TableCell>
                              <TableCell>Modelo</TableCell>
                              <TableCell>Número Económico</TableCell>
                              <TableCell>Activo</TableCell>
                              <TableCell>Serie</TableCell>
                            </TableRow>
                          </StyledSubTableHead>
                          <TableBody>
                            {cabinets.map((cabinet) => (
                              <StyledTableRow key={`cabinets-${pos.id}-${cabinet.id}`}>
                                <TableCell>{cabinet.brand}</TableCell>
                                <TableCell>{cabinet.model}</TableCell>
                                <TableCell>{cabinet.economic_number}</TableCell>
                                <TableCell>{cabinet.fixed_asset}</TableCell>
                                <TableCell>{cabinet.serial_number}</TableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </StyledSubTableContainer>
                      <StyledSubTableContainer>
                        <Typography variant="h6" gutterBottom component="div" sx={{ padding: theme.spacing(1), backgroundColor: "#e7e7e7" }}>
                          Sectores Relacionados
                        </Typography>
                        <Table size="small">
                          <StyledSubTableHead>
                            <TableRow>
                              <TableCell>No.</TableCell>
                              <TableCell>Sector</TableCell>
                              <TableCell>Orden del Sector</TableCell>
                              <TableCell>Ruta</TableCell>
                              <TableCell>Fecha de Registro</TableCell>
                            </TableRow>
                          </StyledSubTableHead>
                          <TableBody>
                            {sectors.map((sector) => (
                              <StyledTableRow key={`sector-${pos.id}-${sector.id}`}>
                                <TableCell>{sector.number_sector}</TableCell>
                                <TableCell>{sector.name_sector}</TableCell>
                                <TableCell>{sector.orderSector}</TableCell>
                                <TableCell>{sector.route}</TableCell>
                                <TableCell>{sector.date_register}</TableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </StyledSubTableContainer>
                    </StyledCollapse>
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}

          </TableBody>
        </Table>
      </StyledTableContainer>
    </StyledPaper>
  );
};

export default CustomTable;