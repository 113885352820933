import React, { useState } from 'react';
import axios from "axios";

export const route_opt = async(data, p, type_dc) => {
    const max = data.length;
    var data_return = []
    
    const ordendata = data.sort((a, b) => a.orderSector - b.orderSector);
    if(p==="fc"){
        const new_d = {
            lat: 18.8503986,
            lng: -97.0765209,
            name: "FRICONGELADOS",
            code: 0,
            orderSector: 1
        };
        ordendata.unshift(new_d);
        ordendata.map((item, index)=>{
            console.log(index)
            item.orderSector = index+1
        })
    }
    const initial = ordendata[0].orderSector;
    const new_data = data.filter((d)=>d.orderSector != initial);
    const data_init = data.filter((d)=>d.orderSector == initial);    
    if (initial){
        await lotes(new_data, data_init, data_return, 0, max, type_dc);
    }else{
        console.log("Error valor inicial");
    }
    console.log(data_return);
    return data_return
}
const lotes = async(data, initial, data_return, n, max, type_dc) =>{
    if(n >= max){
        console.log("se detiene xd");
        data_return.push(initial[0])
        return;
    }else{
        if(data.length <= 24){
            const d = await matriz_extend(data, initial, type_dc);
            data_return.push(...d)
            return;
        }
        if(data.length === 0) {
            console.log("se detiene");
            data_return.push(initial[0])
            return;
        }else{
            data_return.push(initial[0])
            const grupos = [];
            for (let i = 0; i < data.length; i += 24) {
                const subgrupo = data.slice(i, i + 24);
                subgrupo.unshift(initial[0]);
                grupos.push(subgrupo);
            }
            var k = [];
            for (const grupo of grupos) {
                const d =  await petitions_lotes(grupo, type_dc, n);
                k.push(...d);
            }  
            const objetoMinDuracion = k.reduce((min, current) => {
                return current.duration < min.duration ? current : min;
            }, k[0]);
            const d_initial = k.filter(item => item.lng === objetoMinDuracion.lng);
            const d_dt = k.filter(item => item.lng !== objetoMinDuracion.lng);
            await lotes(d_dt, d_initial, data_return, n+1, max, type_dc);
        }
    }
}

const petitions_lotes = async(grupo, type_dc, n) =>{
    return new Promise(async(resolve, reject) => {
        // setTimeout(async () => {
            try {
                const resultados = await get_matriz(grupo, type_dc, n);
                const resultadosFiltrados = resultados.filter(item => item.duration.length !== 0);
                resolve(resultadosFiltrados);
            } catch (error) {
                reject(error);
            }
        // }, 100);
    });
}


const matriz_extend = async(d, i, type_dc) =>{
    d.unshift(...i);
    const coordinates = d.map(item => `${item.lng},${item.lat}`).join(';');
    var token = "pk.eyJ1IjoiaXNyYWVscm9zZXRlIiwiYSI6ImNsdG9tbGJ3czBoc28yanBjd2NvdW85YXoifQ.phAT3KuP127OB3BvMrGjVA";
    const url = `https://api.mapbox.com/directions-matrix/v1/mapbox/${type_dc}/${coordinates}?annotations=distance,duration&access_token=${token}`;
    try {
        const response = await axios.get(url);
        const dtt = response?.data?.durations;
        let route = []; 
        let current = 0;
        let durations = [];
        let visited = new Set();
        visited.add(current);
        while (visited.size < dtt.length) {
            let nextIndex = -1;
            let nextValue = Infinity;
            let duration = 0;
            dtt[current].forEach((value, index) => {
                if (!visited.has(index) && value > 0 && value < nextValue) {
                    nextValue = value;
                    nextIndex = index;
                    duration = value;
                }
            });
            if (nextIndex === -1) {
                throw new Error("No se puede continuar la ruta, todos los puntos restantes han sido visitados o no hay rutas disponibles.");
            }
            route.push(nextIndex);
            visited.add(nextIndex);
            current = nextIndex;
            durations.push(duration);
        }
        const orderedPointsOfSale = reorderData(d, route);
        orderedPointsOfSale.unshift(...i);
        return orderedPointsOfSale

    } catch (error) {
        console.log(error);
        return []
    }
}

const reorderData = (d, route) =>{
    const orderedData = route.map(index => d[index]);  
    return orderedData;
}

const get_matriz = async(lote, type_dc, n) =>{
 
    const coordinates = lote.map(item => `${item.lng},${item.lat}`).join(';');
    var token = "pk.eyJ1IjoiaXNyYWVscm9zZXRlIiwiYSI6ImNsdG9tbGJ3czBoc28yanBjd2NvdW85YXoifQ.phAT3KuP127OB3BvMrGjVA";
    const url = `https://api.mapbox.com/directions-matrix/v1/mapbox/${type_dc}/${coordinates}?sources=0&annotations=distance,duration&access_token=${token}`;
    try {
        const response = await axios.get(url);
        const dtt = response?.data;
        const destinations = dtt?.destinations || [];
        const durations = dtt?.durations[0] || [];
        const result = destinations.map((item, index) => {
            const duration = durations[index] || [];
            const lat = lote[index]?.lat || null
            const lng = lote[index]?.lng || null
            const code = lote[index]?.code || null
            const name = lote[index]?.name || null
            
             return {      
                duration: duration ? duration : null,
                lat: lat,
                lng: lng,
                code: code,
                name: name
            };
        });   
        return result;
    } catch (error) {
        console.log(error);
        return []
    }
}