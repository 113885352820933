// SliderComponent.jsx
import React, { useState } from 'react';
import './slider.css';

const SliderComponent = ({ imageUrls }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length);
  };

  return (
    <div>
      <div className="slider-container">
        <img className="slider-image" src={imageUrls[currentIndex]} alt={`Slide ${currentIndex}`} />
      </div>
      <button className="slider-button left" onClick={prevSlide}>&lt;</button>

      <button className="slider-button right" onClick={nextSlide}>&gt;</button>

    </div>
  );
};

export default SliderComponent;