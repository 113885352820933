//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from 'react-notification-system';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import "./employees.css";
import { get_employess } from "../../services/humanResources/humanResources";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./utilities/render_row_action_menuItems";
import CreateEmployees from "./utilities/CreateEmployees";
import CREATE from "../../imgComponents/create.png";
import { CustomDialog } from "react-st-modal";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";

const EMPLOYEES_V2 = () => {
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const [query_filters, setquery_filters] = useState({});
  const [query_filters_dt, setquery_filters_data] = useState("");


  const update_identifiers =
    [
      { code: 'string' },
      { name: 'string' },
      { rfc: 'string' },
      { job_title: 'string' },
      { company_cell_phone_1: 'string' },
      { driver_license: 'string' },
      { driver_license_type: 'string' },
      { num_reg_id_trib: 'string' },
      { fiscal_residence: 'string' },
      { company_email: 'string'}

    ];
  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }

  const handleSubmit = async (query_filters_flag, query_filters_data) => {

    setmodalT(true);
    setopen(true);
    setData([]);
    setIsLoading(true);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            if (query_filters_flag) {
              const d = await get_employess();
              setquery_filters(query_filters_data);
            } else {
              var d = await get_employess();
              var ndt = update_null_identifiers(d?.data, update_identifiers);
              setData(ndt);
              setopen(false);
              setmodalT(false);
              setIsLoading(false);
            }
          } catch (err) {
            setData([]);
            setopen(false);
            setmodalT(false);
            setIsLoading(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const columns = useMemo(
    () => [
      {
        id: "code",
        header: "Código",
        accessorKey: "code",
        size:160

      },
      {
        id: "name",
        header: "Nombre",
        accessorKey: "name"
      },
      {
        id: "rfc",
        header: "RFC Empleado",
        accessorKey: "rfc"
      },
      {
        id: "job_title",
        header: "Puesto",
        accessorKey: "job_title"
      },
      {
        id: "company_cell_phone_1",
        header: "Telefono",
        accessorKey: "company_cell_phone_1"
      },
      {
        id: "driver_license",
        header: "Licencia de Conducir",
        accessorKey: "driver_license"
      },
      {
        id: "driver_license_type",
        header: "Tipo de Licencia",
        accessorKey: "driver_license_type"
      },
      {
        id: "num_reg_id_trib",
        header: "Número de Registro Id Tributario",
        accessorKey: "num_reg_id_trib",
        size:250
      },
      {
        id: "fiscal_residence",
        header: "Residencia Fiscal",
        accessorKey: "fiscal_residence"
      },
      {
        id: "company_email",
        header: "Correo Electrónico",
        accessorKey: "company_email",
        size:300
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );


  const handle_success_filter = (a, b) => {
    handleSubmit(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });
  }

  useEffect(() => {
    if (total_rows === "") {
    } else {
      const getCurrentPath = () => {
        const search = window.location.search;
        const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
        return pathWithoutQuestionMark;
      };

      const pathWithoutQuestionMark = getCurrentPath();
      setquery_filters_data(pathWithoutQuestionMark)
    }
  }, [total_rows])



  return (
    <>
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR EMPLEADOS", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>

      <div className="new_table_v2">
        <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          enableRowActions
          onStateChange={handleStateChange}
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          enableExpandAll={false}
          enableColumnDragging={false}
          initialState={{ showGlobalFilter: true, showColumnFilters: true }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
          muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              Header: () => (
                <div>
                  <img
                    title="Registrar Empleado"
                    src={CREATE}
                    style={{ "cursor": "pointer" }}
                    className=""
                    onClick={async () => {
                      const result = await CustomDialog(
                        <CreateEmployees
                          handleSubmit={handleSubmit}
                          alertas={alertas}
                          setmodalT={setmodalT}
                        />,
                        {
                          className: "custom-modal-3",
                          title: "Registrar Empleado",
                          showCloseIcon: true,
                          isCanClose: false,
                        }
                      );
                    }}
                  ></img>
                </div>
              ),
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              settotal_rows(table);
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
              closeMenu={closeMenu}
              setmodalT={setmodalT}
              setmenssage={setmenssage}
              setmodalGeneral={setmodalGeneral}
              row={row}
              handleSubmit={handleSubmit}
              setreconsult={setreconsult}
              refresh_token={refresh_token}
              alertas={alertas}
            />
          ]}
          renderToolbarInternalActions={({ table, index }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table}
              file_name={"Consultar Empleados"}
              update_identifiers={update_identifiers}
              settotal_rows={settotal_rows}
              query_filters={query_filters}
              query_filters_dt={query_filters_dt}
              handle_get_consult={handleSubmit}
              key={index}
              handle_success_filter={handle_success_filter}
              share_filter={true}
              total_rows={total_rows !== "" ? total_rows : 0}
            />
          )
          }
        />
      </div>
    </>
  );
}

export default EMPLOYEES_V2;
