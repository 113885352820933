import React, { useMemo, useState } from 'react'
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../../main_components/methods_v2/export';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { BREADCRUMBS } from '../../../main_components/pagination/breadcrumbs';

export const TABLE = (props) => {    

    const [total_rows, settotal_rows] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    
  const columns = useMemo(
    () => [
      {
        id: "code",
        header: "Código",
        accessorKey: "code",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "name",
        header: "Punto de Venta",
        accessorKey: "name",
        size: 300,
        muiTableBodyCellProps: {
          align: "left"
        }
      },
      {
        id: "group",
        header: "Grupo",
        accessorKey: "group",
        muiTableBodyCellProps:{
          align: "center"
        },
      },  
      {
        id: "sector",
        header: "Sector",
        accessorKey: "sector",
        accessorFn: (item)=>{
          return item?.sector ? item?.sector : "N/A"
        },
        muiTableBodyCellProps:{
          align: "center"
        }
      },
      {
        id: "lat",
        header: "Latitud",
        accessorKey: "lat",

      },
      {
        id: "lon",
        accessorKey: "lon",
        header: "Longitud"
      }
    ],
    []
  );

  
  const update_identifiers =
    [
      { code: 'string' },
      { name: 'string' },
      { group: 'String' },
      { color: 'string' },
      { lat: 'string' },
      { lon: 'string' }
    ];


  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  return (
    <div className='new_table_v2'>
       <BREADCRUMBS niveles={
        [
          { label: "PUNTOS DE VENTA", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={props?.data?.length}
      ></BREADCRUMBS>
    <MaterialReactTable
            columns={columns}
            data={props?.data}
            enableGrouping
            enablePinning
            enableFacetedValues
            enableStickyHeader
            onStateChange={handleStateChange}
            enableStickyFooter
            enableRowPinning
            enableColumnFilterModes
            // enableRowActions
            enableRowSelection
            getRowId={(row) => row?.id}
            onRowSelectionChange={setRowSelection}
            enableTooltips={false}
            localization={MRT_Localization_ES}
            state={{ rowSelection, isLoading: isLoading }}
            enableColumnResizing
            enableColumnPinning
            enableColumnOrdering
            enableExpandAll={false}
            enableColumnDragging={false}
            initialState={{ showGlobalFilter: true, showColumnFilters: true }}
            muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
            muiSkeletonProps={{ animation: 'pulse', height: 28 }}
            rowVirtualizerInstanceRef
            rowVirtualizerOptions={{ overscan: 5 }}
            columnVirtualizerOptions={{ overscan: 2 }}
            muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
            positionToolbarAlertBanner="bottom"
            paginationDisplayMode='pages'
            rowPinningDisplayMode='sticky'
            layoutMode="grid"
            muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

            muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
              'mrt-row-pin': {
                enableHiding: true,
              },
              'mrt-row-actions': {
                enableHiding: true,
                size: 80
              },
              'mrt-row-expand': {
                enableHiding: true,
              },
              'mrt-row-select': {
                enableHiding: true,
              }
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                    props?.data.splice(
                    hoveredRow.index,
                    0,
                    props?.data.splice(draggingRow.index, 1)[0],
                  );
                  props?.setdata([...props?.data]);
                }
              },
            })}
            muiTableBodyRowProps={
              ({ row, table }) => {
                settotal_rows(table);
                const { density } = table.getState();
                return {
                  sx: {
                    height: row.getIsPinned()
                      ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                      }px`
                      : undefined,
                  },
                };
              }
            }
            renderToolbarInternalActions={({ table, index }) => (
              <RENDER_TOOLBAR_INTERNAL_ACTIONS
                table={table}
                file_name={"Configuracion Ruta"}
                update_identifiers={update_identifiers}
              />
            )}
          />

    </div>
  )
}
