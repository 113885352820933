import { useEffect, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { get_route_list } from "../../../services/businesspartners/businesspartners";
import { put_goals } from "../../../services/reports/reports";


function EditGoal(props) {

  useEffect(() => {
    ConsutarRutas();
  }, [])
   
const dialog = useDialog();
const[idroute, setidroute]=useState(props.row.original.id_route_fk);
const[meta, setmeta]=useState(props.row.original.goal);
const[vid, setvid]=useState(false);
const[vmeta, setvmeta]=useState(false);
const[bandera, setbandera]=useState(true);
const[dataroute, setdataroute]=useState([]);
const[my, setmy]=useState(props.row.original.year+"-"+(props.row.original.month<10?"0"+props.row.original.month:props.row.original.month));

//consultar data de rutas
const ConsutarRutas = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_route_list();
          setdataroute(d === null ? [] : d);
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
};


const createnewdiscount = async () => {

  var year = null;
  var month = null;

  if(my===null){
    
  }else{
    const partes = my.split("-"); // Divide la cadena en dos partes: año y mes
    year = parseInt(partes[0]); // Convierte el año en un número entero
    month = parseInt(partes[1]); // Convierte el mes en un número entero
  }

if(idroute===null || idroute==="" || idroute==="vacio" || meta===null ||meta===""||year===null || my===null || month===null){
  props.alertas("Parámetros faltantes", false);
  idroute===null||idroute===""||idroute==="vacio"?setvid(true):setvid(false);
  meta===null||meta===""?setvmeta(true):setvmeta(false);
}else{

props.reconsultar(true);
setbandera(false);
  var data = {
    "goal": meta,//Float = Indica la meta que tendrá la ruta
    "id_route": idroute,//Int = Indica el ID de la ruta a la que hará referencia  
    "year": year,//Int = Año 2023
  	"month": month,//Int = Mes 1 - 12
 }
    
  try {
    var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const dt = await put_goals(props.row.original.id, data);
            console.log(dt);
            props.alertas("Meta actualizada con éxito", true);
            props.setactualizacionmeta(true);
            props.setfiltrometas(false);
            props.reconsultar(false);
            dialog.close();
            props.handleSubmit();
          }catch(err){
            console.log(err);
            catchErrorModul("Metas", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
            setbandera(true);
            props.reconsultar(false);
            if(err.response.status===500){
              props.alertas("Error 500: No se pudo actualizar la meta, vuelva a intertarlo", false);
            }else if(err?.response?.status===409){
              props.alertas("Error 409: La ruta ya tiene una meta asignada");
            }
            else{
              props.alertas("No se pudo actualizar la meta, vuelva a initentarlo", false);
            }
          }
        }, 1000);
      }else{}    
    } catch (err) {  
      console.log(err);
    };
  }
} 

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

  return (
    <div style={{textAlign:"center"}}>
      <div className="addpointsalecss">
        <div className="grid-goal">
          <div className="goal1">
          <TextField
            select
            label="Ruta"
            value={idroute}
            defaultValue={""}
            InputLabelProps={{shrink: true}}
            SelectProps={{native: true,}}
            sx={{ m: 1.5, width: "100%"}}
            onChange={(event) => setidroute(event.target.value)}
            style={
              vid===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
            }
            onSelect={()=>setvid(false)}
            onBlur={
              idroute===null||idroute===""?()=>setvid(true):()=>setvid(false)
            }
        >
        <option key={0} value={"vacio"} selected>
          {"Seleccione ruta"}
        </option>
        {
          dataroute?.data?.parametersList?.map((item)=>(
            <option
              key={item.idRoute}
              value={item.idRoute}
            >
              {item.route}
            </option>
          ))
        }
      </TextField>
          </div>
          <div className="goal2">
            
          <TextField
              label="Meta"
              type={"number"}
              required
              value={meta}
              defaultValue={""}
              sx={{ m: 1.5, width: "100%" }}
              style={
                vmeta===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onSelect={()=>setvmeta(false)}
              onBlur={
                meta==null?()=>setvmeta(true):()=>setvmeta(false)
              }
              onChange={(event) => setmeta(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>    
          <div className="goal3">
            <label>Fecha</label>
          < input 
              type="month" 
              value={my} 
              onChange={(e)=>setmy(e.target.value)} 
          />  
          </div>      
        </div> 
        <br></br>
        <br></br>
          <Box>
            {
              bandera===true?
            <Button
            variant="contained"
            style={{"backgroundColor":"green","height":"35px","color":"white", "marginRight":"10px"}}
            sx={{ m: 1.5, width: "25ch" }}
            onClick={createnewdiscount}
          >
            Actualizar Meta
          </Button>:
          <Button
          variant="contained"
          disabled
          style={{"backgroundColor":"green", "height":"35px"}}
          sx={{ m: 1.5, width: "25ch" }}
        >
          <Spinner></Spinner>
        </Button>
            }
            <Button
              style={{"backgroundColor":"red","height":"35px","color":"white" }}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default EditGoal;
