import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { useState } from 'react'
import "./buscarcasos.css";
import { VIEW_FILES_DESCRIPTION } from '../createCase/view_files_description';

export const DetallesAcordion = ({title = "", content = "", tipo}) => {

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  return (
    <>
     {
      tipo==="text"?
      <div style={{border: "none", margin: "0px", width: "100%"}} className='seguimiento-ticket-details-files'>
         <Accordion style={{border: "none"}} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
            <Typography sx={{ width: '33%', flexShrink: 0}} style={{fontSize: "14px", fontWeight: "bold", paddingLeft: "10px", color: "#464C4A"}}>
            {title}
          </Typography>
          <Typography sx={{ color: 'text.secondary', }} style={{ display: expanded !== 'panel1' ? " " : "none", marginLeft: "30px", fontSize: "12px"}}>
          {`${content?.substring(0,70)}...`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className='descrip-tick'>
          <Typography>
           {content}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>:
    content.length===0?<></>:
    <div style={{border: "none", margin: "0px", width: "100%"}}>
    <Accordion style={{border: "none"}} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
          <Typography sx={{ width: '33%', flexShrink: 0}} style={{fontSize: "14px", fontWeight: "bold", paddingLeft: "10px", color: "#464C4A"}}>
          Archivos
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ width: "100%", overflowX: "auto"}}>
        <Typography>
        <div className='content-view-files-description'>
            <VIEW_FILES_DESCRIPTION data={content}></VIEW_FILES_DESCRIPTION>  
        </div>
        </Typography>
      </AccordionDetails>
    </Accordion>
    </div>
    }
    
    
    </>
   
  )
}
