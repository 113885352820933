import { useEffect, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { get_cabinets_models, get_cabinets_warehouse, get_status_cabinets, put_cabinets,get_cabinets_brands, post_cabinets } from "../../../services/assets/assets";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";


function SHOW_EDIT_FREZZER(props) {

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  const dialog = useDialog();
  const [bandera, setbandera]=useState(true);
  const [economic_number, seteconomic_number]=useState(props?.row?.original['Numero economico']);
  const [fixed_asset, setfixed_asset]=useState(props?.row?.original['Numero activo fijo']);
  const [serial_number, setserial_number]=useState(props?.row?.original['Numero serie']);
  const [capitalization_date, setcapitalization_date]=useState(formattedDate);
  const [note, setnote]=useState(props?.row?.original['Nota']);
  const [id_warehouse, setid_warehouse]=useState(8);
  const [id_cabinets_state, setid_cabinets_state]=useState(3);
  const [id_cabinets_model, setid_cabinets_model]=useState(props?.row?.original['id_model']);
  const [lending, setlending]=useState(props?.row?.original['Comodato']);
  const [production_year, setproduction_year]=useState(props?.row?.original['Año']);

  const [datawarehouse, setdatawarehouse]=useState([]);
  const [datastatus, setdatastatus]=useState([]);
  const [id_cabinets_brand, setid_cabinets_brand]=useState(props?.row?.original['Marca']);

  const [datanamebrandmodels, setdatanamebrandmodels]=useState(props?.databrand);
  const [databrand, setdatabrand]=useState(props?.datamodels?.filter((d)=>{return d.name_brand === id_cabinets_brand}));
  const [datamodels, setdatamodels]=useState(props?.datamodels);
  

useEffect(() => {
  hablealmacen();
  handlestatus();
}, [])

const hablealmacen = async () => {
  try {  
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_cabinets_warehouse();
          setdatawarehouse(d === null ? [] : d.data.warehouses);
        }catch(err){
          setdatawarehouse([]);
        }
        
      }, 1000);
    }else{}
  } catch (error) {
    console.error(error);
   
  } 
}

const handlestatus = async () => {
  try {  
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_status_cabinets();
          setdatastatus( d === null ? [] : d.data.cabinet_status);
        }catch(err){
          setdatastatus([]);
        }
        
      }, 1000);
    }else{}
  } catch (error) {
    console.error(error);
    
  }  
}

const UpdateFrezz = async () => {

  props?.setmodalT(true);
  const dataoriginal = [...props?.file_cabinets_error];
  const item = { ...dataoriginal[props?.id] };
  const model_new = props?.datamodels.filter(i => i.id === parseInt(id_cabinets_model))[0]?.name_model;
  
  var data = {
    "economic_number": economic_number,
    "fixed_asset": fixed_asset, 
    "serial_number": serial_number,
    "capitalization_date": capitalization_date, 
    "note": note,
    "id_warehouse": 8,
    "id_cabinets_state": 3,   
    "id_cabinets_model": parseInt(id_cabinets_model), 
    "lending": lending==="true" || lending===true ?true : false,
    "production_year": production_year,
  }

  item["Numero economico"] = economic_number;
  item['Numero activo fijo'] = fixed_asset;
  item['Numero serie'] = serial_number;
  item['Comodato'] = lending;
  item['Año'] = production_year;
  item['Marca'] = id_cabinets_brand;
  item['id_model'] = parseInt(id_cabinets_model);
  item['Modelo'] = model_new;
  item['Nota'] = note;

  try { 
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try { 
          var d = await post_cabinets(data);
          console.log(d);
          
          item['status_register'] = true;
          item['response_error_register'] = "";
          dataoriginal[props?.id] = item;
          props?.update_data_error(dataoriginal);
          
          dialog.close();
          props?.setmodalT(false);
          props.alertas("Congelador actualizado con éxito", true);

        }catch (err) {          
          props?.setmodalT(false);
          props?.alertas("Error al registrar el congelador", false);
          if(err?.response?.data?.errorMessage){
            item['response_error_register'] = 
              err?.response?.data?.errorMessage == "A cabinet already exists with the same serial number." ? 
                "Ya existe un congelador con el mismo número de serie.":
                "Verifica los datos del congelador.";
          }else{
            item['response_error_register'] = "Error desconocido comuníquese con soporte.";
          }
          item['status_register'] = false;
          dataoriginal[props?.id] = item;
          props?.update_data_error(dataoriginal);
         
        } 
      }, 1000);
    }else{}
  }catch (err) {
    console.log("error post", err);
  } 
} 

const Update_Register = () =>{

  const model_new = props?.datamodels.filter(i => i.id === parseInt(id_cabinets_model))[0]?.name_model;
  
  // const dataoriginal = props?.file_cabinets;
  const dataoriginal = [...props?.file_cabinets]; // Copia superficial del array
  const item = { ...dataoriginal[props?.id] }; // Copia del objeto que vas a modificar
  // var item = props?.file_cabinets[props?.id];

  item["Numero economico"] = economic_number;
  item['Numero activo fijo'] = fixed_asset;
  item['Numero serie'] = serial_number;
  item['Comodato'] = lending;
  item['Año'] = production_year;
  item['Marca'] = id_cabinets_brand;
  item['id_model'] = parseInt(id_cabinets_model);
  item['Modelo'] = model_new;
  item['Nota'] = note;

  if(item.brand_validate || item.brand_validate == true){
    const modeld = props?.datamodels;
    const modeldataaux = modeld.filter(i => i.id_brand_fk === item.id_brand);
    modeldataaux?.map(model=>{
      if(model.name_model === item['Modelo']){
        item['model_validate'] = true;
        item['id_model'] = model.id; 
      }
      if(item.model_validate){
        console.log("data sucess");
      }else{
        item.model_validate = false;
      }
    })
  }
  dataoriginal[props?.id] = item;
  // props?.setfile_cabinets(dataoriginal);
  props?.update_data(dataoriginal);
  dialog.close();
}

  return (
    <div style={{"textAlign":"center"}}>
      <div className="form-frezz">
          <div className="gridfrez1">
            <div className="frez1">
            <TextField
              label="Número Económico"
              required
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              value={economic_number}
              onChange={(event) => seteconomic_number(event.target.value)}  
             InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            <div className="frez2">
            <TextField
              label="Número Activo Fijo"
              required
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              value={fixed_asset}
              onChange={(event) => setfixed_asset(event.target.value)}  
             InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            <div className="frez3">
            <TextField
              label="Número Serie"
              required
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              value={serial_number}
              onChange={(event) => setserial_number(event.target.value)}  
             InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
          </div>
          <div className="gridfrez2">
            <div className="frez4">
            <label className="label-date">Fecha Capitalización</label>
            <input
                  type="text"
                  className="fi" 
                  required
                  disabled={!bandera}
                  style={{"width":"100%"}}
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  value={capitalization_date}
                placeholder="Fecha"
                onChange={(event) => setcapitalization_date(event.target.value)}
            ></input>
            </div>
            <div className="frez5">
            <TextField
                  select
                  label="En comodato"
                  disabled={!bandera}
                  value={lending}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setlending(event.target.value)}  
                 >
                  <option key={1} value={false}>
                      {"No"}
                  </option>                 
                  <option key={2} value={true}>
                      {"SI"}
                  </option>
             </TextField>
            </div>
            <div className="frez6">
            <TextField
              label="Año de producción"
              required
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              value={production_year}
              onChange={(event) => setproduction_year(event.target.value)}  
             InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
          </div>
          <div className="gridfrez3">
              <div className="frez7">
                  <TextField
                  select
                  label="Almacén"
                  disabled={true}
                  value={id_warehouse}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setid_warehouse(event.target.value)}  
                 >
                  <option key={0} value={0}>
                      {"Selecciona Almacén"}
                  </option>
                  {
                    datawarehouse?.map(item=>(
                      <option key={item.id} value={item.id}>
                      {item.warehouse_name}
                  </option>
                    ))
                  }
             </TextField>
              </div>
              <div className="frez8">
                  <TextField
                  select
                  label="Estado Congelador"
                  disabled={true}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  value={id_cabinets_state}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setid_cabinets_state(event.target.value)}  
                 >
                  <option key={0} value={0}>
                      {"Selecciona Estado Congelador"}
                  </option>
                  {
                     datastatus?.map(item=>(
                      <option key={item.id} value={item.id}>
                      {item.description}
                       </option>
                    ))
                  }
             </TextField>
              </div>
              <div className="frez9">
                  <TextField
                  select
                  label="Marca Congelador"
                  disabled={!bandera}
                  value={id_cabinets_brand}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  style={props?.row?.original?.brand_validate === false ? {
                      "border": "2px solid red", "borderRadius": "5px"
                    } : {}
                  }
                  onChange={(event) => {setid_cabinets_brand(event.target.value);
                    setdatabrand(datamodels.filter((d)=>{
                    return d.name_brand===event.target.value}))
                  }}  
                 >
                  <option key={0} value={0}>
                      {"Selecciona una marca"}
                  </option>
                  {
                    datanamebrandmodels?.map(item=>(
                      <option key={item.name} value={item.name}>
                        {item.name}
                      </option>
                    ))
                  }
             </TextField>
              </div>
          </div>
          <div className="gridfrez4">
          <div className="frezn0">

          <TextField

                  select
                  label="Modelo Congelador"
                  disabled={!bandera}
                  value={id_cabinets_model}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  style={props?.row?.original?.model_validate === false ? {
                      "border": "2px solid red", "borderRadius": "5px"
                    } : {}
                  }
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setid_cabinets_model(event.target.value)}  
                 >
                  <option key={0} value={0}>
                      {"Selecciona Congelador"}
                  </option>
                  {
                    databrand?.map(item=>(
                      <option key={item.id} value={item.id}>
                      {item.name_model}
                       </option>
                    ))
                  }
             </TextField>
             </div>

            <div className="frez10">
            <TextField
              label="Nota"
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              value={note}
              onChange={(event) => setnote(event.target.value)}  
             InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
          </div>
          <Box>
            {
              props?.process === 1 ?
              <Button
                variant="contained"
                style={{"background-color":"green"}}
                sx={{ m: 1.5, width: "40ch" }}
                onClick={()=>{Update_Register()}}
              >{"ACTUALIZAR"}
              </Button>:
              props?.process === 2 ?
                props?.row?.original?.status_register === false?
                  <Button
                    variant="contained"
                    style={{"background-color":"green"}}
                    sx={{ m: 1.5, width: "40ch" }}
                    onClick={()=>{UpdateFrezz()}}
                  >
                    {"REINTENTAR"}
                  </Button>:<></>:<></>
            }
          <Button
              style={{"background-color":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default SHOW_EDIT_FREZZER;
