import { useEffect, useState, useMemo, useRef } from "react";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { Box } from '@mui/material';
import { calculate_total } from "../../../main_components/methods_v2/auxiliary_functions";
import POINT_OF_SALE from "../../../imgComponents/iconos/puntoventa.png"

const DETAIL_SALE = (props) => {
  const [lat, setlat] = useState(props.lat);
  const [long, setlong] = useState(props.lng);
  const [lat_pos, setlat_pos] = useState(props.lat_pos);
  const [long_pos, setlong_pos] = useState(props.lng_pos)
  const [map, setmap] = useState(false);


  const columns = useMemo(
    () => [
      {
        header: "Partida",
        accessorKey: "row",
      },
      {
        header: "Código",
        accessorKey: "code",
      },
      {
        header: "Producto",
        accessorKey: "product_name",
      },
      {
        header: "Precio",
        accessorKey: "price",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.price) ? 0 : obj.price)
          const total = props?.details?.map(obj => isNaN(obj.price) ? 0 : obj.price)
          return (
            <div >
              <div>{calculate_total(subtotal).toLocaleString('es-Mx', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</div>
              <div>{calculate_total(total).toLocaleString('es-Mx', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</div>
            </div>
          )
        },
      },
      {
        header: "Cantidad",
        accessorKey: "quantity",
  
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.quantity) ? 0 : obj.quantity)
          const total = props?.details?.map(obj => isNaN(obj.quantity) ? 0 : obj.quantity)
          return (
            <div >
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(total)}</div>
            </div>
          )
        },
      },
      {
        header: "Subtotal",
        accessorKey: "subtotal",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.subtotal) ? 0 : obj.subtotal)
          const total = props?.details?.map(obj => isNaN(obj.subtotal) ? 0 : obj.subtotal)
          return (
            <div >
              <div>{calculate_total(subtotal).toLocaleString('es-Mx', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</div>
              <div>{calculate_total(total).toLocaleString('es-Mx', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</div>
            </div>
          )
        },
      },
      {
        header: "Descuento",
        accessorKey: "discount",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.discount) ? 0 : obj.discount)
          const total = props?.details?.map(obj => isNaN(obj.discount) ? 0 : obj.discount)
          return (
            <div >
              <div>{calculate_total(subtotal).toLocaleString('es-Mx', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</div>
              <div>{calculate_total(total).toLocaleString('es-Mx', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</div>
            </div>
          )
        },
      },
      {
        header: "Impuestos",
        accessorKey: "tax",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.tax) ? 0 : obj.tax)
          const total = props?.details?.map(obj => isNaN(obj.tax) ? 0 : obj.tax)
          return (
            <div >
              <div>{calculate_total(subtotal).toLocaleString('es-Mx', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</div>
              <div>{calculate_total(total).toLocaleString('es-Mx', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</div>
            </div>
          )
        },
      },
      {
        header: "Total",
        accessorKey: "total",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.total) ? 0 : obj.total)
          const total = props?.details?.map(obj => isNaN(obj.total) ? 0 : obj.total)
          return (
            <div >
              <div>{calculate_total(subtotal).toLocaleString('es-Mx', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</div>
              <div>{calculate_total(total).toLocaleString('es-Mx', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</div>
            </div>
          )
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  console.log(props.lat);
  console.log(props.lng);
  console.log("lat:" + props.lat + ", log:" + props.lng);
  console.log("lat_pos:" + props.lat_pos + ", log_pos:" + props.long_pos);

  const MapWithAMarker = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{ lat: Number(lat), lng: Number(long) }}
      >
        <Marker
          position={{ lat: Number(lat), lng: Number(long) }}
          icon={{
            fillOpacity: 1,
            rotation: 0,
            strokeWeight: 1,
            strokeColor: "#ffffff",
            scale: 2,
            animation: window.google.maps.Animation.DROP,
            draggable: true,
          }}
        />

        <Marker
          position={{ lat: Number(lat_pos), lng: Number(long_pos) }}
          icon={{
            fillOpacity: 1,
            rotation: 0,
            strokeWeight: 1,
            strokeColor: "#ffffff",
            scale: 2,
            animation: window.google.maps.Animation.DROP,
            draggable: true,
            url: POINT_OF_SALE
          }}
        />


      </GoogleMap>
    ))
  );

  return (
    <div>
      <div className="price-load-dta-detail">
        <div className="expen-5n">
          <div class="ticket">
            <h2>
              {props.sale_retun}:{props.ticket}
              <br />
              {props.name_pos}
            </h2>
            <p
              style={{ display: props.sale_retun === "Venta" ? "" : "none" }}
              className="centrado detalles"
            >
              <span>Subtotal:</span> ${props.subtotal} <br />
              <span>Descuento:</span> ${props.discount} <br />
              <span>Impuestos:</span> ${props.tax} <br />
              <span>Total:</span> ${props.total} <br />
              <span>Deuda Pendiente de pago:</span> ${props.debt} <br />
              <span>Deuda pagada:</span> ${props.payed} <br />
              <span>Método de pago:</span> {props.payment_method} <br />
              <span>Congelador:</span> {props.scan}
            </p>
            <p
              style={{
                display: props.sale_retun === "Devolución" ? "" : "none",
              }}
              className="centrado detalles"
            >
              <span>Subtotal:</span> ${props.subtotal} <br />
              <span>Descuento:</span> ${props.discount} <br />
              <span>Impuestos:</span> ${props.tax} <br />
              <span>Total:</span> ${props.total} <br />
            </p>

            <h2>Notas</h2>
            <p class="centrado detalles">
              <span>Nota vendedor:</span> {props.note} <br />
            </p>
            <h2>Datos para facturar</h2>
            <p class="centrado detalles">
              <span>Razón social:</span> {props.name_business_partner} <br />
              <span>RFC:</span> {props.rfc_business_partner} <br />
              <span>Código del socio de Negocios:</span> {props.code_pos} <br />
            </p>
          </div>
        </div>

        <div className="expen-6 map-product-load">
          <MapWithAMarker
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBOh51zw12N4kb7yDY4pp-6gM7xVaFterc&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div style={{ height: `300px`, width: "95%" }} />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      </div>

      <div style={{ margin: "0 auto", width: "95%" }}>
        <MaterialReactTable
          columns={columns}
          localization={MRT_Localization_ES}
          data={props?.details === undefined ? [] : props?.details}
          enableColumnActions={false}
          enableColumnFilters
          enableSorting
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          paginationDisplayMode={'pages'}
          enableTopToolbar={false}
          muiTableBodyRowProps={{ hover: false }}
        />;
      </div>

    </div>
  );
};

export default DETAIL_SALE;
