//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from 'react-notification-system';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//COMPONENTES

import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { get_sales_deliveries } from "../../services/reports/reports";
import { SEARCH_FILTER_WEB_MOVIL } from "../../main_components/methods_v2/search_filter_web_movil";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx'; import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
} from '@mui/material';
import { formattedCurrentDate } from "../../main_components/date/day";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import AlertResponse from "../../main_components/alerts/alertResponse";
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { get_route_list, get_sectors } from "../../services/businesspartners/businesspartners";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { PREVENT } from "./preventa";
import { REPART } from "./Reparto";
import { MAP_DELIVERIES } from "./map";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import TableChartIcon from '@mui/icons-material/TableChart';
import RENDER_ROW_ACTION_MENU_ITEMS_NOT_VISIT from "./tools/render_row_action_menuItems_notvisit";
import "./map.css";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";

export const SALES_DELIVERIES = () => {
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const [date, setdate] = useState(dayjs(formattedCurrentDate));
  const [id_route, setid_route] = useState(0);
  const [data_route, setdata_route] = useState([]);
  const [id_sector, setid_sector] = useState(0);
  const [data_sector, setdata_sector] = useState([]);
  const [data_aux_sector, setdata_aux_sector] = useState([]);
  const [close, setclose] = useState(false);
  const [cancelled, setcancelled] = useState(false);
  const [status, setstatus] = useState("None");
  const [showmap, setshowmap] = useState(false);
  const [multimapa, setmultimapa] = useState(false);


  useEffect(() => {
    handleroutes();
    handlesectors();
  }, [])

  const handlesectors = async () => {
    try {
      setmodalT(true);
      const d = await get_sectors();
      console.log(d);
      setmodalT(false);
      setdata_sector(d?.data?.sectors);
    } catch (err) {
      setmodalT(false);
      console.log(err);
    }
  }

  const handleroutes = async () => {
    try {
      const d = await get_route_list();
      setdata_route(d?.data?.parametersList ? d?.data?.parametersList : []);
    } catch (err) {
      console.log(err);
    }
  }

  const handlesubmit = async () => {
    try {
      setData([]);
      setmodalT(true);
      setIsLoading(true);
      var refresh = await refresh_token();
      if (refresh) {
        setTimeout(async () => {
          try {
            const d = await get_sales_deliveries(date.format('YYYY-MM-DD'), id_route, id_sector, close === false ? "" : close, cancelled === false ? "" : cancelled);
            console.log(d);
            setIsLoading(false);
            setmodalT(false);
            setData([d?.data]);
          } catch (err) {
            console.log(err);
            setmodalT(false);
            setIsLoading(false);
            alertas("La consulta no se pudo realizar; es posible que no existan registros en la fecha especificada.", false);
          }
        }, 1000);
      }
    } catch (err) {
      console.log(err);
    }
  }


  const update_identifiers =
    [
      { route: 'string' },
      { quantity_in_sector: 'string' },
      { quantity_visit_in_sector: 'string' },
      { quantity_no_visit_in_sector: 'string' },
      { p_visit_amount: 'string' },
      { p_sales_amount: 'string' },
      { p_not_sales_amount: 'string' },
      { r_sales_amount: 'string' },
      { real_sales: 'string' },
      { p_scan_amount: 'string' },
      { p_not_scan_amount: 'string' },
      { p_pieces_amount: 'string' },
      { r_pieces_amount: 'string' },
      { pieces_real_amount: 'string' },
      { gen_bonification_p: 'string' },
      { gen_devolution_p: 'string' },
      { gen_bonification_r: 'string' },
      { gen_devolution_r: 'string' },
      { p_cash_amount: 'string' },
      { r_cash_amount: 'string' },
      { cash_real_amount: 'string' }
    ];


  const columns = useMemo(
    () => [
      {
        id: "route",
        header: "Ruta",
        accessorKey: "route",
        muiTableBodyCellProps: {
          align: 'center',
        },
        size: 100
      },
      {
        id: "quantity_in_sector",
        header: "Puntos de Venta del Sector",
        accessorKey: "quantity_in_sector",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "quantity_visit_in_sector",
        header: "Puntos de Venta visitados",
        accessorKey: "quantity_visit_in_sector",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "quantity_no_visit_in_sector",
        header: "Puntos de Venta no visitados",
        accessorKey: "quantity_no_visit_in_sector",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "p_visit_amount",
        header: "Puntos de Venta Visitados por Preventa",
        accessorKey: "p_visit_amount",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "p_sales_amount",
        header: "Ventas Preventa",
        accessorKey: "p_sales_amount",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "p_not_sales_amount",
        header: "No Ventas del Preventa",
        accessorKey: "p_not_sales_amount",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "r_sales_amount",
        header: "Ventas del Reparto",
        accessorKey: "r_sales_amount",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "real_sales",
        accessorKey: "real_sales",
        header: "Ventas Reales",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "p_scan_amount",
        header: "Congeladores Escaneados del Preventa",
        accessorKey: "p_scan_amount",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "p_not_scan_amount",
        accessorKey: "p_not_scan_amount",
        header: "Congeladores No Escaneados del Preventa",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "p_pieces_amount",
        accessorKey: "p_pieces_amount",
        header: "Piezas Preventa",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "r_pieces_amount",
        accessorKey: "r_pieces_amount",
        header: "Piezas Reparto",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "pieces_real_amount",
        header: "Piezas Reales",
        accessorKey: "pieces_real_amount",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "gen_bonification_p",
        header: "Bonificaciones - Preventa",
        accessorKey: "gen_bonification_p",
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        id: "gen_devolution_p",
        header: "Devoluciones - Preventa",
        accessorKey: "gen_devolution_p",
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        id: "gen_bonification_r",
        accessorKey: "gen_bonification_r",
        header: "Bonificaciones - Reparto",
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        id: "gen_devolution_r",
        accessorKey: "gen_devolution_r",
        header: "Devoluciones - Reparto",
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        id: "p_cash_amount",
        accessorKey: "p_cash_amount",
        header: "Total Ventas Preventa",
        muiTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => {
          return (
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor: theme.palette.success.dark,
                borderRadius: '0.25rem',
                color: '#fff',
                maxWidth: '12ch',
                p: '0.25rem',
              })}
            >
              {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          )
        },
      },
      {
        id: "r_cash_amount",
        accessorKey: "r_cash_amount",
        header: "Total Ventas Reparto",
        muiTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => {
          return (
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor: theme.palette.success.dark,
                borderRadius: '0.25rem',
                color: '#fff',
                maxWidth: '12ch',
                p: '0.25rem',
              })}
            >
              {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          )
        },
      },
      {
        id: "cash_real_amount",
        header: "Total Ventas Reales",
        accessorKey: "cash_real_amount",
        muiTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => {
          return (
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor: theme.palette.success.dark,
                borderRadius: '0.25rem',
                color: '#fff',
                maxWidth: '12ch',
                p: '0.25rem',
              })}
            >
              {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          )
        },
      },

    ], []);

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  const filters_custom = (
    <div className="custom-year-search">
      <LocalizationProvider dateAdapter={AdapterDayjs} locale="es-mx">
        <DatePicker
          sx={{ width: 150, m: 1.5 }}
          label="Fecha"
          value={date}
          onChange={(newValue) => setdate(newValue)}
          locale="es"
          format="DD/MM/YYYY"
        />
      </LocalizationProvider>
      <FormControl required sx={{ width: 100 }}>
        <InputLabel id="demo-simple-select-required-label" style={{ marginLeft: "12px", marginTop: "10px" }}>Ruta</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={id_route}
          sx={{ height: '35px', m: 1.5 }}
          label="Ruta*"
          onChange={(e) => {
            setid_route(e.target.value);
            const d = data_sector.filter(item => item?.id_route_fk == e.target.value);
            setdata_aux_sector(d);
          }
          }
        >
          {
            data_route?.map((item, index) => (
              <MenuItem key={index} value={item?.idRoute}>{item?.route}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <FormControl required sx={{ width: 150 }}>
        <InputLabel id="demo-simple-select-required-label" style={{ marginLeft: "12px", marginTop: "10px" }}>Sector</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={id_sector}
          sx={{ height: '35px', m: 1.5 }}
          label="Ruta*"
          onChange={(e) => setid_sector(e.target.value)}
        >
          {
            data_aux_sector?.map((item, index) => (
              <MenuItem key={index} value={item?.id}>
                {item?.number_sector}-{item?.name_sector}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <FormControl required sx={{ width: 150 }}>
        <InputLabel id="demo-simple-select-required-label" style={{ marginLeft: "12px", marginTop: "10px" }}>Estado de la Venta</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={status}
          sx={{ height: '35px', m: 1.5 }}
          label="Estado de la Venta*"
          onChange={(e) => {
            const stateMap = {
              Close: { cancelled: false, close: true },
              Cancel: { cancelled: true, close: false },
              None: { cancelled: false, close: false },
            };
            const { cancelled, close } = stateMap[e.target.value] || { cancelled: false, close: false };
            setcancelled(cancelled);
            setclose(close);
            setstatus(e.target.value);
          }}
        >
          <MenuItem key={0} value={"None"}>Ninguna</MenuItem>
          <MenuItem key={1} value={"Close"}>Ventas Cerradas</MenuItem>
          <MenuItem key={2} value={"Cancel"}>Ventas Canceladas</MenuItem>
        </Select>
      </FormControl>
      <FormControl required sx={{ width: "auto" }}>
        <Button variant="contained"
          sx={{ height: '35px' }}
          onClick={(e) => handlesubmit()}
        >
          <SearchIcon titleAccess="Buscar"></SearchIcon>
        </Button>
      </FormControl>
      <FormControl required sx={{ width: "auto" }}>
        <Button variant="contained" onClick={() => setshowmap(!showmap)}
          sx={{ height: '35px', backgroundColor: "purple", marginLeft: "15px" }}
        >
          {
            showmap === true ?
              <TableChartIcon titleAccess="Tabla"></TableChartIcon> :
              <FmdGoodIcon titleAccess="Mapa"></FmdGoodIcon>
          }
        </Button>
      </FormControl>
    </div>
  );


  const [minimenu, setminimenu] = useState(false);
  const [openmini, setopenmini] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        console.log('Screen width is less than 480px');
        setminimenu(true);
      } else {
        setminimenu(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }

  return (
    <div className="new_table_v2" style={{ "margin": "0 auto", width: "95%" }}>
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
      <BREADCRUMBS niveles={
        [
          { label: "COMPARACIÓN HORARIOS", path: null },
        ]
      }
      ></BREADCRUMBS>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <SEARCH_FILTER_WEB_MOVIL
        minimenu={minimenu}
        setminimenu={setminimenu}
        openmini={openmini}
        setopenmini={setopenmini}
        filterMovComponent={filters_custom}
        renderTopToolbarCustomActionsFilters={true}
      ></SEARCH_FILTER_WEB_MOVIL>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        onStateChange={handleStateChange}
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowSelection
        enableRowActions
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        enableColumnDragging={false}
        initialState={{ showGlobalFilter: true, showColumnFilters: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
            size: 80
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              data.splice(
                hoveredRow.index,
                0,
                data.splice(draggingRow.index, 1)[0],
              );
              setData([...data]);
            }
          },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderTopToolbarCustomActions={({ }) => {
          if (minimenu === false) {
            return (
              <>
                {filters_custom}
              </>
            )
          }
        }}
        renderRowActionMenuItems={({ row, closeMenu }) => [
          <RENDER_ROW_ACTION_MENU_ITEMS_NOT_VISIT
            closeMenu={closeMenu}
            setmodalT={setmodalT}
            setmenssage={setmenssage}
            setmodalGeneral={setmodalGeneral}
            row={row}
            handleSubmit={handlesubmit}
            refresh_token={refresh_token}
            alertas={alertas}
          />
        ]}
        renderToolbarInternalActions={({ table }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            table={table}
            file_name={"Comparación de Horarios"}
            update_identifiers={update_identifiers}
          />
        )
        }
      />
      {
        showmap === false ?
          <>
            <br /><br />
            <div className="content-deliveries-prevent-table">
              <PREVENT data={data}></PREVENT>
            </div>
            <br /><br />
            <div className="content-deliveries-prevent-table">
              <REPART data={data}></REPART>
            </div>
          </> :
          <div className={multimapa === true ? "mapa-grid-show-vist" : "mapas-show-vist"}>
            <MAP_DELIVERIES data={data} alertas={alertas} multimapa={multimapa} setmultimapa={setmultimapa} multiple={true}></MAP_DELIVERIES>
            {
              multimapa === true ? <MAP_DELIVERIES data={data} alertas={alertas} multimapa={multimapa} setmultimapa={setmultimapa}></MAP_DELIVERIES> : <></>
            }
          </div>
      }
    </div>
  )
}
