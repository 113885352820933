//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from 'react-notification-system';
import MODAL_TABLE from "../main_components/modal/modal_Table";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//COMPONENTES
import AlertResponse from "../main_components/alerts/alertResponse";
//ESTILOS
import "../components_cfdi/estilo.css";
import '../componentSociosNegocios/componentSectors/bonuses.css';
import "../components_cfdi/modal.css";
//IMAGENES/ICONOS
import { refresh_token } from "../main_components/tokens/tokenrefresh";
import { BREADCRUMBS } from "../main_components/pagination/breadcrumbs";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import { get_accountingA } from "../services/finances/finances";
import CREATE from "../imgComponents/create.png";
import OPERATION_ACCOUNTING_ACCOUNT from "./tools/op_accounting_account";
import { CustomDialog, Confirm, useDialog } from "react-st-modal";
import { update_null_identifiers } from "../main_components/methods_v2/auxiliar_funtion";

const ACCOUNTING_ACCOUNT_V2 = () => {
  const notificationSystemRef = useRef();
  const [open, setopen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  var consulta = 0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [total_rows, settotal_rows] = useState("");

  const [query_filters, setquery_filters] = useState({});
  const [query_filters_dt, setquery_filters_data] = useState("");

  


  const update_identifiers =
    [
      { code: 'string' },
      { name: 'string' },
      { expense_concept: 'String' },
      { efect: 'string' },
      { type: 'string' }
    ];

  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }

  const handleSubmit = async (query_filters_flag, query_filters_data) => {
    if (consulta === 0) {
      consulta = consulta + 1;
      setmenssage("Consultando Cuentas Contables...");
      setmodalGeneral(true);
      setmodalT(true);
    } else {
      setmodalT(true);
    }
    setopen(true);
    setData([]);
    setIsLoading(true);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            if (query_filters_flag) {
              const d = await get_accountingA();
              setquery_filters(query_filters_data);
            } else {
              const d = await get_accountingA();
              const data = (d === null ? [] : d.data.acc_accounts);
              var ndt = update_null_identifiers(data, update_identifiers);
              setData(ndt);
              setopen(false);
              setmodalGeneral(false);
              setmodalT(false);
              setIsLoading(false);
            }
          } catch (err) {
            console.log("error", err);
            setData([]);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
            setIsLoading(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const columns = useMemo(
    () => [
      {
        id: "code",
        header: "Código",
        accessorKey: "code",
        muiTableBodyCellProps: {
          align: 'center',
        },
        size: 200
      },
      {
        id: "name",
        header: "Nombre",
        accessorKey: "name",
        muiTableBodyCellProps: {
          align: 'justify'
        },
        size: 400
      },
      {
        id: 'expense_concept',
        header: "Concepto",
        accessorKey: "expense_concept",
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        id: 'efect',
        header: "Efecto",
        accessorKey: "efect",
        filterVariant: 'select',
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        id: 'type',
        header: "Tipo",
        accessorKey: "type",
        filterVariant: 'select',
        muiTableBodyCellProps: {
          align: 'center'
        }
      }
    ],
    []
  );

  const handle_success_filter = (a, b) => {
    handleSubmit(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });
  }

  useEffect(() => {
    if (total_rows === "") {
    } else {
      const getCurrentPath = () => {
        const search = window.location.search;
        const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
        return pathWithoutQuestionMark;
      };

      const pathWithoutQuestionMark = getCurrentPath();
      setquery_filters_data(pathWithoutQuestionMark)
    }
  }, [total_rows])



  return (
    <>
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR CUENTAS CONTABLES", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <br />
      <div className='new_table_v2'>
        <div >
          <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
          <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
          ></MODAL_TABLE>
          <>
            <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowActions
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  Header: () => (
                    <div>
                      <img
                        title="Agregar Cuenta Contable"
                        src={CREATE}
                        className=""
                        onClick={async () => {
                          const result = await CustomDialog(
                            <OPERATION_ACCOUNTING_ACCOUNT
                              operation={"CREATE"}
                              setmodalT={setmodalT}
                              handleSubmit={handleSubmit}
                              alertas={alertas}
                              data={data}
                            />,
                            {
                              className: "custom-modal",
                              title: "Agregar Cuenta Contable",
                              showCloseIcon: true,
                              isCanClose: false,
                            }
                          );
                        }}
                      ></img>
                    </div>
                  ),
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                  size: 80
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderRowActionMenuItems={({ row, closeMenu }) => [
                <RENDER_ROW_ACTION_MENU_ITEMS
                  closeMenu={closeMenu}
                  setmodalT={setmodalT}
                  setmenssage={setmenssage}
                  setmodalGeneral={setmodalGeneral}
                  row={row}
                  handleSubmit={handleSubmit}
                  setreconsult={setreconsult}
                  refresh_token={refresh_token}
                  alertas={alertas}
                />
              ]}
              renderToolbarInternalActions={({ table, index }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"Cuentas Contables"}
                  update_identifiers={update_identifiers}
                  query_filters={query_filters}
                  query_filters_dt={query_filters_dt}
                  handle_get_consult={handleSubmit}
                  key={index}
                  settotal_rows={settotal_rows}
                  handle_success_filter={handle_success_filter}
                  share_filter={true}
                  total_rows={total_rows !== "" ? total_rows : 0}

                />
              )
              }
            />

          </>
        </div>
      </div>
    </>
  );
}

export default ACCOUNTING_ACCOUNT_V2;
