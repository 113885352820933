//Paqueterias
import React, { useState } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { useDialog } from "react-st-modal";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
//Componentes
import { catchErrorModul } from "../../../../../main_components/catchErr/catchError";
import { refresh_token } from "../../../../../main_components/tokens/tokenrefresh";
import boleanDate from "../../../../../dataComponets/true-false.json";
import { put_sale_cashclosing } from "../../../../../services/cashclosing/cashclosing";
import MODAL_TABLE from "../../../../../main_components/modal/modal_Table";
//Estilos
import "../../../cashclosing.css";


const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "green",
    borderWidth: 2,
    height: 42,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const PUT_CASHCLOSING = ({ downloadable, special_folio_number, no_invoice, never_invoice, paper, id_sale, date, handleSubmit_reconsul }) => {
  const dialog = useDialog();
  const [ddownloadable, setdownloadable] = useState(downloadable);
  const [dspecial_folio_number, setspecial_folio_number] = useState(special_folio_number);
  const [dno_invoice, setno_invoice] = useState(no_invoice);
  const [dnever_invoice, setnever_invoice] = useState(never_invoice);
  const [dpaper, setpaper] = useState(paper);
  const [ddate, setddate] = useState(date);
  const [modalT, setmodalT] = useState(false);




  const handleDownloadable = (event) => {
    setdownloadable(event?.target?.value === "true" ? true : false);
    if (event?.target?.value === true) {
      setnever_invoice(false);
      setno_invoice(false);
    }
  };
  const handlePeper = (event) => { setpaper(event?.target?.value); };
  const handleSpecial_fol = (event) => { setspecial_folio_number(event?.target?.value); };
  const handleNo_inv = (event) => {
    setno_invoice(event?.target?.value === "true" ? true : false);
    if (event?.target?.value === "true") {
      setdownloadable(false);
      setnever_invoice(false);
    }
  };
  const handleNever_inv = (event) => {
    setnever_invoice(event?.target?.value === "true" ? true : false);
    if (event?.target?.value === "true") {
      setdownloadable(false);
      setno_invoice(false);
    }
  };


  var data = {
    special_folio_number: dspecial_folio_number,
    no_invoice: dno_invoice,
    never_invoice: dnever_invoice,
    downloadable: ddownloadable,
    paper: dpaper,
    date: ddate?.format("YYYY-MM-DD HH:mm:ss"),
  };
  console.log(data)

  const handleSubmit = async () => {
    var data = {
      special_folio_number: dspecial_folio_number,
      no_invoice: dno_invoice,
      never_invoice: dnever_invoice,
      downloadable: ddownloadable,
      paper: dpaper,
      date: ddate?.format("YYYY-MM-DD HH:mm:ss"),
    };
    console.log(data)

    try {
      setmodalT(true)
      await put_sale_cashclosing(id_sale, data);
      dialog.close(true);
      handleSubmit_reconsul();
    } catch (err) {
      catchErrorModul("Corte de Caja => Actualizar Venta", JSON.stringify(data), JSON.stringify(err?.response));
      alert("Error.," + JSON.stringify(err?.response?.data));
    } finally {
      setmodalT(false)
    }
  };

  return (
    <div>
      <MODAL_TABLE modalGeneral={modalT} modalT={modalT} message="Actualizando Venta..."></MODAL_TABLE>

      <div style={{ width: "97%", margin: "0 auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <ValidationTextField
              label="Folio Especial"
              variant="outlined"
              placeholder="Descargable"
              sx={{ m: 1, width: "100%" }}
              value={dspecial_folio_number}
              InputLabelProps={{ shrink: true }}
              onChange={handleSpecial_fol}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ValidationTextField
              label="Paper"
              variant="outlined"
              placeholder="Paper"
              sx={{ m: 1, width: "100%" }}
              value={dpaper}
              InputLabelProps={{ shrink: true }}
              onChange={handlePeper}
            />
          </Grid>
          <Grid item xs={12} sm={4}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Fecha"
                sx={{ m: 1.3, width: "100%" }}
                onChange={(e) => setddate(e)}
                value={ddate}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>


          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              child
              label="No facturar"
              value={dno_invoice}
              sx={{ m: 1, width: "100%" }}
              onChange={handleNo_inv}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {boleanDate?.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              child
              label="Nunca facturar"
              value={dnever_invoice}
              sx={{ m: 1, width: "100%" }}
              onChange={handleNever_inv}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {boleanDate?.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>

            <Button
              variant="contained"
              sx={{ m: 1, width: "100%" }}
              className="ButtonModal"
              onClick={() => handleSubmit()}
            >
              ACTUALIZAR
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>

            <Button
              variant="contained"
              sx={{ m: 1, width: "100%" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close(false);
              }}
            >
              cancelar
            </Button>
          </Grid>

        </Grid>

      </div>
    </div>

  );
};

export default PUT_CASHCLOSING;
