/* eslint-disable jsx-a11y/alt-text */
//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable} from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import TableContainer from "../../../main_components/Table/TableContainer";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
//ESTILOS
import "../../../components_cfdi/estilo.css";
import '../../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../../components_cfdi/modal.css";
import "./commissions.css";
//IMAGENES/ICONOS
import Delete from "../../../imgComponents/borrar.png";
import CREATE from "../../../imgComponents/create.png";
import Edit from "../../../imgComponents/save.png";
import { delete_commissions, get_commissions } from "../../../services/reports/commissions";
import { OPERATIONS_COMMISSIONS } from "./tools/op_commss";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import { update_null_identifiers } from "../../../main_components/methods_v2/auxiliar_funtion";

const COMMISSIONS_V2= () => {

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [total_rows, settotal_rows] = useState("");

useEffect(()=>{
  handleSubmit();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}

const update_identifiers =
[
  { date_start: 'string' },
  { date_end: 'string' },
  { sale_type: 'String' },
  { seller_own_sales_com: 'string'},
  { dealer_own_sales_com: 'string'},
  { assistant_own_sales_com: 'string'},
  { seller_sec_sales_com: 'string'},
  { dealer_sec_sales_com: 'stirng'},
  { assistant_sec_sales_com: 'string'},
  { route: 'string'}
];



const handleSubmit = async () => {
    setIsLoading(true);
    setopen(true);
    setData([]);

    try {
      var rf_token = await refresh_token();
      if(rf_token ===true){
        setTimeout(async() => {
          try{
            var d = await get_commissions();
            var ndt = update_null_identifiers(d?.data, update_identifiers);          
            setData(ndt === null ? [] : ndt); 
            setopen(false);
            setIsLoading(false);
            setmodalT(false);  
          }catch(err){
            setData([]);
            setopen(false)
            setIsLoading(false);
            setmodalT(false);
          }     
        }, 1000);
      }else{}
    } catch (err) {
     console.log(err);
    }
};

const handleStateChange = (newState) => {
  const filteredRowsCount = newState.filteredRowModel
  settotal_rows(filteredRowsCount);
};

const columns = useMemo(
  () => [
    // {
    //   Header:()=>(
    //     <div>
    //       <img
    //         id="crearRol"
    //         src={CREATE}
    //         onClick={async () => {
    //           CustomDialog(               
    //          <div>
    //             <OPERATIONS_COMMISSIONS operation="CREATE" handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></OPERATIONS_COMMISSIONS>
    //          </div>,
    //        {
    //          className: "modalTItle",
    //          title:"Crear Nueva Comisión",
    //          showCloseIcon: true,
    //          isCanClose:false,
    //        }
    //    );
    //  }}
    //         title="Crear Comisión"
    //         className=""
    //       ></img>
    //     </div>
    //   ),
      
    //   id: "Acciones",
    //   Cell: ({ row }) => {
    //   const DeleteSector = async () => {  
      
    //   setmodalT(true);
    //   setData([]);
    //   setopen(true);
    //     try {
    //       var rf_token = await refresh_token();
    //       if(rf_token ===true){
    //         setTimeout(async() => {
    //           try{
    //             const dt = await delete_commissions(row.original.id);
    //             console.log(dt);
    //             alertas("Comisión Eliminada", true);
    //             handleSubmit( );
    //           }catch(err){
    //             console.log("error", err);
    //             setopen(false);
    //             alertas("Error, no se pudo eliminar la comisión", false);
    //             setmodalT(false);
    //             handleSubmit();
    //           }
    //         }, 1000);
    //       }else{}
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   }
    //     return (
    //       <div>
    //         <img
    //           src={Delete}
    //           onClick={async() =>{
    //               const result = await Confirm('¿Esta usted seguro de eliminar la comisión?','Eliminar Comisión','Si','No');
    //               if(result){
    //                 DeleteSector()
    //                 }
    //               }            
    //             }
              
    //           title="Eliminar Comisión"
    //         ></img>
    //           &nbsp;
    //         <img
    //           src={Edit}
    //           onClick={
    //             async () => {
    //               CustomDialog(               
    //              <div>
    //                 <OPERATIONS_COMMISSIONS operation="EDIT" row={row} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></OPERATIONS_COMMISSIONS>
    //               </div>,
    //            {
    //              className: "modalTItle",
    //              title:"Editar Comisión",
    //              showCloseIcon: true,
    //              isCanClose:false,
    //            }
    //        ); 
    //      }
    //     }
              
    //           title="Editar Comisión"
    //         ></img>
    //       </div>
    //     );
    //   },
    // },
    {
      id: "date_start",
      header: "Fecha de Inicio",
      accessorKey:"date_start"
    },
    {
      id: "date_end",
      header:"Fecha Final",
      accessorKey:"date_end"
    },
    {
      id: "sale_type",
      header: "Tipo de Venta",
      accessorKey:"sale_type"
    },
    {
      id: "seller_own_sales_com",
      header: "Comisión Preventa Ventas Propias",
      accessorKey: "seller_own_sales_com",
      accessorFn: (d) => {
        return d.seller_own_sales_com+"%"
      },    
    },
    {
      id: "dealer_own_sales_com",
      header: "Comisión Reparto Ventas Propias",
      accessorKey: "dealer_own_sales_com",
      accessorFn: (d) => {
        return d.dealer_own_sales_com+"%"
      },    
    },
    {
      id: "assistant_own_sales_com",
      header: "Comisión Ayudante Ventas Propias",
      accessorKey: "assistant_own_sales_com",
      accessorFn: (d) => {
        return d.assistant_own_sales_com+"%"
      },   
    },
    {
      id: "seller_sec_sales_com",
      header: "Comisión Preventa Ventas Secundarias",
      accessorKey: "seller_sec_sales_com",
      accessorFn: (d) => {
        return d.seller_sec_sales_com+"%"
      }
    },
    {
      id: "dealer_sec_sales_com",
      header:"Comisión Reparto Ventas Secundarias",
      accessorKey: "dealer_sec_sales_com",
      accessorFn: (d) => {
        return d.dealer_sec_sales_com+"%"
      },
    },
    {
      id: "assistant_sec_sales_com",
      header:"Comisión Ayudante Ventas Secundarias",
      accessorKey: "assistant_sec_sales_com",
      accessorFn: (d) => {
        return d.assistant_sec_sales_com+"%"
      },   
    },
    {
      id: "route",
      header: "Ruta Asignada",
      accessorKey: "route",
      accessorFn: (d)=>{return(d.route===null?"Todas":d.route)}
    },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
);
  return (
    <>
    <BREADCRUMBS niveles={
                [
                  {label: "ASIGNACIÓN DE PERSONAL", path: "/daily_staff_v2"},
                  {label: "REGISTRO DE COMISIONES", path: null},
                ]
              }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}  
      ></BREADCRUMBS>

<div className="new_table_v2">        
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
          
          <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        onStateChange={handleStateChange}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            Header: () => (
              <div>
                <img
                title="Registrar Comisión"
                src={CREATE}
                className=""
                onClick={async () => {
                  const result = await CustomDialog(
                    <OPERATIONS_COMMISSIONS
                        operation={"CREATE"}
                        handleSubmit={handleSubmit} 
                        setmodalT={setmodalT} 
                        alertas={alertas}
                    />,
                    {
                      className: "custom-modal-3",
                      title: "Registrar Comisión",
                      showCloseIcon: true,
                      isCanClose:false,
                    }
                  );
                }}
              ></img>
              </div>
            ),
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderRowActionMenuItems={({ row, closeMenu }) => [
             <RENDER_ROW_ACTION_MENU_ITEMS
               closeMenu={closeMenu}
               setmodalT={setmodalT}
               setmenssage={setmenssage}
               setmodalGeneral={setmodalGeneral}
               row={row}
               handleSubmit={handleSubmit}
               setreconsult={setreconsult}
               refresh_token={refresh_token}
               alertas={alertas}
             />
           ]}
        
         renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table} 
              file_name={"Consultar Comisiones"}
              update_identifiers={update_identifiers}
              settotal_rows={settotal_rows}
            />
          )
          }
      />
        </div>
    </>
  );
}

export default COMMISSIONS_V2;
