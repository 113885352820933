//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import { CustomDialog } from "react-st-modal"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, } from "react-google-maps";
import NotificationSystem from 'react-notification-system';
//COMPONENTES

import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
import "./tablepoitofsalews.css";
//IMAGENES/ICONOS
import Spinner from "../../imgComponents/S.gif";
import TableContainer from "../../main_components/Table/TableContainer";
import Modal_Table from "../../main_components/modal/modal_Table";
import ADD from "../../imgComponents/agregar.png";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import { get_pos_without_sector } from "../../services/businesspartners/businesspartners";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import ADD_POINT_SALE_SECTOR from "./tools/add_point_sale_sector";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import AlertResponse from "../../main_components/alerts/alertResponse";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";


const POINT_OF_SALE_WITHOUT_SECTOR_V2 = () => {

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [MensajeBusqueda, setMensajeBusqueda] = useState("");
  const [open, setopen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [total_rows, settotal_rows] = useState("");

  var entrada = 0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

  useEffect(() => {
    handleSubmit();
  }, []);

  const update_identifiers =
    [
      { code: 'string' },
      { route: 'string' },
      { name: 'string' },
      { lat: 'string' },
      { lng: 'string' },
      { frequency: 'string' },
      { visit_days: 'string' }

    ];


  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }

  const MapWithAMarker = withScriptjs(withGoogleMap(props =>

    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: data[0]?.lat, lng: data[0]?.lng }}
    >
      {
        data.map((item) => (
          <Marker
            //label={item.code}
            position={{ lat: item.lat, lng: item.lng }}
            title={item.code + "-" + item.name}
            icon={{
              fillOpacity: 1,
              rotation: 0,
              strokeWeight: 1,
              strokeColor: "#ffffff",
              scale: 2,
              animation: window.google.maps.Animation.DROP,
              draggable: true
            }}
          />
        ))
      }
    </GoogleMap>
  ));
  const columns = useMemo(
    () => [
      // {
      //   Header:"Acciones",
      //   Cell: ({ valor, row }) => {

      //    return (
      //         <div>
      //           <img
      //             src={ADD}
      //             onClick={() =>{
      //               CustomDialog(               
      //               <div>
      //                <ADD_POINT_SALE_SECTOR handleSubmit={handleSubmit} row={row}/>
      //               </div>,
      //             {
      //               className: "modalTItle",
      //               title:"Agregar Punto de Venta a Sector",
      //               showCloseIcon: true,
      //               isCanClose:false,
      //             }
      //         );
      //       }
      //     }

      //           title="Agregar a Sector"
      //           alt=""
      //         ></img>
      //       </div>
      //     );
      //   },
      // },
      {
        id: 'code',
        header: "Código",
        accessorKey: "code",
        muiTableBodyCellProps: {
          align: 'center'
        },
      },
      {
        id: "route",
        header: "Ruta",
        accessorKey: "route",
        muiTableBodyCellProps: {
          align: 'center'
        },
        size: 100,
        filterVariant: 'select'
      },
      {
        id: "name",
        header: "Nombre",
        accessorKey: "name",
        muiTableBodyCellProps: {
          align: 'justify'
        },
      },
      {
        id: "lat",
        header: "Latitud",
        accessorKey: "lat",
        muiTableBodyCellProps: {
          align: 'center'
        },
      },
      {
        id: "lng",
        header: "Longitud",
        accessorKey: "lng",
        muiTableBodyCellProps: {
          align: 'center'
        },
      },

      {
        id: "frequency",
        header: "Frecuencia",
        accessorKey: "frequency",
        muiTableBodyCellProps: {
          align: 'center'
        },
        filterVariant: 'select'
      },
      {
        id: "visit_days",
        header: "Días de visita",
        accessorKey: "visit_days",
        muiTableBodyCellProps: {
          align: 'center'
        },
        filterVariant: 'select'
      }
    ], []
  );

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  const handleSubmit = async () => {

    setmodalT(true);
    setData([]);
    setopen(true);
    setIsLoading(false);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var d = await get_pos_without_sector();
            var ndt = update_null_identifiers(d?.data?.pointSaleList, update_identifiers);
            setData(ndt);
            setopen(false);
            setmodalT(false);
          } catch (err) {
            setData([]);
            setopen(false)
            setmodalT(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>

      <div className="containeritems py-2">

        <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>

        <Modal_Table
          open={true}
          message={menssage}
          modalGeneral={false}
          modalT={modalT}
        ></Modal_Table>

        <BREADCRUMBS niveles={
          [
            { label: "CONSULTAR PUNTOS DE VENTA", path: "/consult_point_of_sale_v2" },
            { label: "PUNTOS DE VENTA SIN SECTOR", path: null },
          ]
        }
          cards_dashboard={true}
          total_rows={total_rows !== "" ? total_rows : 0}
          filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
          visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
          total={data?.length}

        ></BREADCRUMBS>
        <br /><br />
      </div>

      <div className="new_table_v2">
        <div >
          <div style={{ width: "98%", height: "100%", paddingLeft: "20px" }}>

            <div className="tableposwiths">
              <MaterialReactTable
                columns={columns}
                data={data}
                enableGrouping
                enablePinning
                enableFacetedValues
                enableStickyHeader
                enableStickyFooter
                enableRowPinning
                enableColumnFilterModes
                enableRowActions
                onStateChange={handleStateChange}
                enableRowSelection
                getRowId={(row) => row?.id}
                onRowSelectionChange={setRowSelection}
                enableTooltips={false}
                localization={MRT_Localization_ES}
                state={{ rowSelection, isLoading: isLoading }}
                enableColumnResizing
                enableColumnPinning
                enableColumnOrdering
                enableExpandAll={false}
                enableColumnDragging={false}
                initialState={{ showGlobalFilter: true, showColumnFilters: true }}
                muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
                muiSkeletonProps={{ animation: 'pulse', height: 28 }}
                rowVirtualizerInstanceRef
                rowVirtualizerOptions={{ overscan: 5 }}
                columnVirtualizerOptions={{ overscan: 2 }}
                muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
                positionToolbarAlertBanner="bottom"
                paginationDisplayMode='pages'
                rowPinningDisplayMode='sticky'
                layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
                muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
                muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
                displayColumnDefOptions={{
                  'mrt-row-pin': {
                    enableHiding: true,
                  },
                  'mrt-row-actions': {
                    enableHiding: true,
                  },
                  'mrt-row-expand': {
                    enableHiding: true,
                  },
                  'mrt-row-select': {
                    enableHiding: true,
                  }
                }}
                muiTableBodyRowDragHandleProps={({ table }) => ({
                  onDragEnd: () => {
                    const { draggingRow, hoveredRow } = table.getState();
                    if (hoveredRow && draggingRow) {
                      data.splice(
                        hoveredRow.index,
                        0,
                        data.splice(draggingRow.index, 1)[0],
                      );
                      setData([...data]);
                    }
                  },
                })}
                muiTableBodyRowProps={
                  ({ row, table }) => {
                    settotal_rows(table);
                    const { density } = table.getState();
                    return {
                      sx: {
                        height: row.getIsPinned()
                          ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                          }px`
                          : undefined,
                      },
                    };
                  }
                }
                renderRowActionMenuItems={({ row, closeMenu }) => [
                  <RENDER_ROW_ACTION_MENU_ITEMS
                    closeMenu={closeMenu}
                    setmodalT={setmodalT}
                    setmenssage={setmenssage}
                    setmodalGeneral={setmodalGeneral}
                    row={row}
                    handleSubmit={handleSubmit}
                    setreconsult={setreconsult}
                    refresh_token={refresh_token}
                    alertas={alertas}
                  />
                ]}
                renderToolbarInternalActions={({ table }) => (
                  <RENDER_TOOLBAR_INTERNAL_ACTIONS
                    table={table}
                    file_name={"Consultar Puntos de Venta sin Sector"}
                    update_identifiers={update_identifiers}
                    settotal_rows={settotal_rows}
                  />
                )
                }
              />
            </div>
            <br></br>
            <br /> <br />
            <MapWithAMarker
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBOh51zw12N4kb7yDY4pp-6gM7xVaFterc&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default POINT_OF_SALE_WITHOUT_SECTOR_V2;
