import React from "react";
import { AuthState } from "@aws-amplify/ui-components";
import fondo from "../../img-login/fondo-OD2-Act.webp";
import fc from "../../img-login/fondo.webp";
function LoginPage({ SetAuthState }) {
  return (
    <div className="container_login">
      <div className="container_login">
        <div className="grid-login">
          <div className="formsesion">
            <div className="logoempresa-ingresar">
              <img src={fc} alt=""></img>
            </div>

            <div className="camplogin">
              <br></br>
              <div className="div-lg1">
                <p className="bienvenido">Bienvenido de nuevo</p>
                <p className="text-login">Operación Dinámica V.2</p>
              </div>

              <br></br>
              <br></br>

              <div
                className="boton-ingresar"
                onClick={(e) => SetAuthState(AuthState.SignIn)}
              >
                <a>Ingresar</a>
              </div>
            </div>
          </div>
          <div className="imglogin">
            <img src={fondo} alt=""></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
