//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//COMPONENTES
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import { Box } from "@mui/material";
import { calculate_total } from "../../main_components/methods_v2/auxiliary_functions";

export const PREVENT = (props) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [total_rows, settotal_rows] = useState("");

    const handleStateChange = (newState) => {
        const filteredRowsCount = newState.filteredRowModel
        settotal_rows(filteredRowsCount);
    };
    
    useEffect(() => {
        props?.data[0]?.p_details ? setData(props?.data[0]?.p_details): setData([]);
    }, [props?.data])

    
  const update_identifiers=
  [
    { sector: 'string' },
    { code_pos: 'string' },
    { name_pos: 'string' },
    { ticket: 'string' },
    { date: 'string' },
    { hour: 'string' },
    { scan: 'string'},
    { pieces_sale: 'string'},
    { pieces_return: 'string'},
    { pieces_real: 'string'},
    { total_sale: 'string'},
    { total_return: 'string'},
    { total_real: 'string'}
  ];

    const columns = useMemo(
        () => [
          {
            id: "sector",
            header: "Sector",
            accessorKey: "sector",
            muiTableBodyCellProps: {
              align: 'left',
            },
            // Footer: () => (
            //   <div>
            //     <div>Sub Total: </div>
            //     <div>Gran Total: </div>
            //   </div>
            // ),
          },
          {
            id: "code_pos",
            accessorKey: "code_pos",
            header: "Código Punto de Venta",
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            id: "name_pos",
            accessorKey: "name_pos",
            header: "Nombre Punto de Venta",
            muiTableBodyCellProps: {
              align: 'left',
            }
          },
          {
            id: "ticket",
            accessorKey: "ticket",
            header: "Ticket",
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            id: "date",
            accessorKey: "date",
            header: "Fecha",
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            id: "hour",
            accessorKey: "hour",
            header: "Hora",
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            id: "scan",
            accessorKey: "scan",
            header: "Congelador Escaneado",
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            id: "pieces_sale",
            accessorKey: "pieces_sale",
            header: "Piezas Vendidas",
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            id: "pieces_return",
            header: "Piezas Devolución",
            accessorKey: "pieces_return",
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            id: "pieces_real",
            accessorKey: "pieces_real",
            header: "Piezas Entregadas",
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            id: "total_sale",
            accessorKey: "total_sale",
            header: "Total Ventas",
            muiTableBodyCellProps: {
              align: 'right',
            },
            Cell: ({ cell }) => {
              return (
              <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor: theme.palette.success.dark,
                  borderRadius: '0.25rem',
                  color: '#fff',
                  maxWidth: '12ch',
                  p: '0.25rem',
                })}
              >
                {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                  style: 'currency',
                  currency: 'MXN',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) : cell.getValue()?.toLocaleString?.('es-MX', {
                  style: 'currency',
                  currency: 'MXN',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Box>
              )
            },
          },
          {
            id: "total_return",
            accessorKey: "total_return",
            header: "Total Devoluciones",
            muiTableBodyCellProps: {
              align: 'right',
            },
            Cell: ({ cell }) => {
              return (
              <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor: theme.palette.success.dark,
                  borderRadius: '0.25rem',
                  color: '#fff',
                  maxWidth: '12ch',
                  p: '0.25rem',
                })}
              >
                {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                  style: 'currency',
                  currency: 'MXN',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) : cell.getValue()?.toLocaleString?.('es-MX', {
                  style: 'currency',
                  currency: 'MXN',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Box>
              )
            },
          },
          {
            header: "Total Real",
            accessorKey: "total_real",
            id: "total_real",
            muiTableBodyCellProps: {
              align: 'right',
            },
            Cell: ({ cell }) => {
              return (
              <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor: theme.palette.success.dark,
                  borderRadius: '0.25rem',
                  color: '#fff',
                  maxWidth: '12ch',
                  p: '0.25rem',
                })}
              >
                {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                  style: 'currency',
                  currency: 'MXN',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) : cell.getValue()?.toLocaleString?.('es-MX', {
                  style: 'currency',
                  currency: 'MXN',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Box>
              )
            },
            muiTableFooterCellProps: {
              align: 'right',
            },
            // Footer: (d) => {
            //   const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.total_real)
            //   return (
            //     <div style={{ textAlign: "right" }}>
            //       <div>{Intl.NumberFormat("es-MX", {
            //         style: "currency",
            //         currency: "MXN",
            //       }).format(calculate_total(subtotal))}</div>
            //       {/* <div>{Intl.NumberFormat("es-MX", {
            //         style: "currency",
            //         currency: "MXN",
            //       }).format(calculate_total(gtotal))}</div> */}
            //     </div>
            //   )
            // },  
          }
        ], []);
  
  return (
    <div>
      <div className="new_table_v2" style={{"margin":"0 auto", width: "100%"}}>
        <BREADCRUMBS
            niveles={
                [
                    { label: "PUNTOS DE VENTA VISITADOS POR PREVENTA", path: null },
                ]
            }
            cards_dashboard={true}
            total_rows={total_rows !== "" ? total_rows : 0}
            filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
            visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
            total={data?.length}
        ></BREADCRUMBS>

        <MaterialReactTable
            columns={columns}
            data={data}
            enableGrouping
            enablePinning
            enableFacetedValues
            enableStickyHeader
            onStateChange={handleStateChange}
            enableStickyFooter
            enableRowPinning
            enableColumnFilterModes
            enableRowSelection
            enableRowActions
            getRowId={(row) => row?.id}
            onRowSelectionChange={setRowSelection}
            enableTooltips={false}
            localization={MRT_Localization_ES}
            state={{ rowSelection, isLoading: isLoading }}
            enableColumnResizing
            enableColumnPinning
            enableColumnOrdering
            enableExpandAll={false}
            enableColumnDragging={false}
            initialState={{ showGlobalFilter: true, showColumnFilters: true }}
            muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
            muiSkeletonProps={{ animation: 'pulse', height: 28 }}
            rowVirtualizerInstanceRef
            rowVirtualizerOptions={{ overscan: 5 }}
            columnVirtualizerOptions={{ overscan: 2 }}
            muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
            positionToolbarAlertBanner="bottom"
            paginationDisplayMode='pages'
            rowPinningDisplayMode='sticky'
            layoutMode="grid"
            muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

            muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
            'mrt-row-pin': {
                enableHiding: true,
            },
            'mrt-row-actions': {
                enableHiding: true,
                size: 80
            },
            'mrt-row-expand': {
                enableHiding: true,
            },
            'mrt-row-select': {
                enableHiding: true,
            }
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                data.splice(
                    hoveredRow.index,
                    0,
                    data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
                }
            },
            })}
            muiTableBodyRowProps={
            ({ row, table }) => {
                settotal_rows(table);
                const { density } = table.getState();
                return {
                sx: {
                    height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
                };
            }
            }
            renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
                closeMenu={closeMenu}
                row={row}
            />
            ]}
            renderToolbarInternalActions={({ table }) => (
              <RENDER_TOOLBAR_INTERNAL_ACTIONS
                table={table} 
                file_name={"Puntos de Visitados por Preventa"}
                update_identifiers={update_identifiers}
              />
            )}
        />
    </div>
    </div>
  )
}
