//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, Confirm } from "react-st-modal";
import NotificationSystem from "react-notification-system";
import Button from "@mui/material/Button";

//COMPONENTES
import TableContainer from "../../main_components/Table/TableContainer";
import { get_daily_staff } from "../../services/reports/reports";
import { delete_daily_staff } from "../../services/reports/reports";
import CREATE_UPDATE from "./tools/create_update";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import SubTableContainer from "../../main_components/Table/SubTableContainer";
import { delete_products } from "../../services/inventory/inventory";
import { recovery_products } from "../../services/inventory/inventory";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import { date_current } from "../../main_components/date/date";
import { previous_date } from "../../main_components/date/date";
import ReenvioCorreo from "../../components_cfdi/invoice/consult_invoice/report_email/send_invoice";
import { get_routes } from "../../services/cashclosing/cashclosing";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import VER from "../../imgComponents/reconsultaTable.png";

import TAX from "./tools/tax";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import REPORT from "../../imgComponents/iconos/icono-1.png";

import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { get_daily_staff_user } from "../../services/reports/reports";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";
import { SEARCH_FILTER_WEB_MOVIL } from "../../main_components/methods_v2/search_filter_web_movil";

const DAILY_STAFF_V2 = (props) => {
  const [data, setData] = useState([]);
  const [modalT, setmodalT] = useState(true);
  const [menssage, setMenssage] = useState(true);
  const [ff, setff] = useState(date_current);
  const [fi, setfi] = useState(previous_date);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [total_rows, settotal_rows] = useState("");


  const update_identifiers =
    [
      { date: 'string' },
      { route: 'string' },
      { name_seller_employee: 'string' },
      { name_seller_asst_employee: 'string' },
      { name_dealer_employee: 'string' },
      { name_dealer_asst_employee: 'string' }
    ];

  var permisosUsuario = sessionStorage.getItem("roles_user");
  var permisoUsuarioReplace = permisosUsuario?.replace(/ /g, "");
  var permisos = permisoUsuarioReplace?.split(",");
  var permisoconf = permisos?.filter((x) => x === "ROLE_CONFIGURATION");

  console.log(props.subtabla);
  const notificationSystemRef = useRef();

  const handleSubmit = async (a, b) => {
    setmodalT(true);
    setMenssage("Productos...");
    setIsLoading(true);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            console.log("entra dato");
            const dt = await get_daily_staff(fi, ff);
            var ndt = update_null_identifiers(dt?.data, update_identifiers);
            setData(ndt);
            setmodalT(false);
            setIsLoading(false);
          } catch (err) {
            setData([]);
            setMenssage("");
            setmodalT(false);
            setIsLoading(false);
          }
        }, 1000);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "date",
        header: "Fecha",
        accessorKey: "date",
      },
      {
        id: "route",
        header: "Ruta",
        accessorKey: "route",
        // Filter: SelectColumnFilter,
      },
      {
        id: "name_seller_employee",
        header: "Vendedor",
        accessorKey: "name_seller_employee",
        size: 200
      },
      {
        id: "name_seller_asst_employee",
        header: "Ayudante de vendedor",
        accessorKey: "name_seller_asst_employee",
        accessorFn: (d) => {
          return d?.name_seller_asst_employee === null ? "" : d?.name_seller_asst_employee
        },
        size: 200
      },
      {
        id: "name_dealer_employee",
        header: "Repartidor",
        accessorKey: "name_dealer_employee",
        size: 200
      },
      {
        id: "name_dealer_asst_employee",
        header: "Ayudante de repartidor",
        accessorKey: "name_dealer_asst_employee",
        accessorFn: (d) => {
          return d?.name_dealer_asst_employee === null ? "" : d?.name_dealer_asst_employee
        },
        size: 200
      },
    ],
    []
  );

  function alertas(msj, status) {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  }


  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };
  const [minimenu, setminimenu]=useState(false);
  const [openmini, setopenmini]=useState(false);
   useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 480) {
          console.log('Screen width is less than 480px');
          setminimenu(true);
        } else {
          setminimenu(false);
        }
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    const filters_custom = (
      <div className="containerExpenseReport2">
      <div className="expen-1 color-1">
        <input
          type="text"
          className="fExpense"
          onFocus={(e) => {
            e.currentTarget.type = "date";
            e.currentTarget.focus();
          }}
          placeholder="Fecha Inicial"
          onChange={(event) => setfi(event.target.value)}
        ></input>
      </div>
      <div className="expen-2 color-2">
        <input
          type="text"
          className="fExpense"
          onFocus={(e) => {
            e.currentTarget.type = "date";
            e.currentTarget.focus();
          }}
          placeholder="Fecha Final"
          onChange={(event) => setff(event.target.value)}
        ></input>
      </div>
      <div className="expen-3 color-5">
        <Button
          sx={{ width: "100%" }}
          variant="contained"
          onClick={handleSubmit}
        >
          Buscar
        </Button>
      </div>
    </div>
    )

  return (
    <div>
      <BREADCRUMBS
        niveles={[
          { label: "ASIGNACIÓN DE PERSONAL", path: null },
          permisoconf.length === 0
            ? ""
            : { label: "REGISTRO DE COMISIONES", path: "/commissions_v2" },
        ]}
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>

      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <SEARCH_FILTER_WEB_MOVIL
          minimenu={minimenu} 
          setminimenu={setminimenu}
          openmini={openmini} 
          setopenmini={setopenmini}
          filterMovComponent={filters_custom}
          renderTopToolbarCustomActionsFilters={true}
        ></SEARCH_FILTER_WEB_MOVIL>
      <div className="new_table_v2">
        <div>
          <MaterialReactTable
            columns={columns}
            data={data}
            enableGrouping
            enablePinning
            enableFacetedValues
            enableStickyHeader
            enableStickyFooter
            enableRowPinning
            enableColumnFilterModes
            enableRowActions
            enableRowSelection
            onStateChange={handleStateChange}
            getRowId={(row) => row?.id}
            onRowSelectionChange={setRowSelection}
            enableTooltips={false}
            localization={MRT_Localization_ES}
            state={{ rowSelection, isLoading: isLoading }}
            enableColumnResizing
            enableColumnPinning
            enableColumnOrdering
            enableExpandAll={false}
            enableColumnDragging={false}
            initialState={{ showGlobalFilter: true, showColumnFilters: true }}
            muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
            muiSkeletonProps={{ animation: 'pulse', height: 28 }}
            rowVirtualizerInstanceRef
            rowVirtualizerOptions={{ overscan: 5 }}
            columnVirtualizerOptions={{ overscan: 2 }}
            muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
            positionToolbarAlertBanner="bottom"
            paginationDisplayMode='pages'
            rowPinningDisplayMode='sticky'
            layoutMode="grid"
            muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

            muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
              'mrt-row-pin': {
                Header: () => (
                  <div>
                    <img
                      title="Registrar Asignación"
                      src={CREATE}
                      style={{ "cursor": "pointer" }}
                      className=""
                      onClick={async () => {
                        const result = await CustomDialog(
                          <CREATE_UPDATE
                            id_route_fk={"SELECCIONA UNA RUTA"}
                            date={"Selecciona una fecha"}
                            route={"SELECCIONA UNA RUTA"}
                            id_seller_employee_fk={"SELECCIONA UN USUARIO"}
                            id_seller_asst_employee_fk={"SIN USUARIO"}
                            id_dealer_employee_fk={"SELECCIONA UN USUARIO"}
                            id_dealer_asst_employee_fk={"SIN USUARIO"}
                            method={"CREATE"}
                            alertas={alertas}
                            handleSubmit={handleSubmit}
                            setmodalT={setmodalT}
                          ></CREATE_UPDATE>,
                          {
                            className: "custom-modal-3",
                            title: "Registrar Asignación",
                            showCloseIcon: true,
                            isCanClose: false,
                          }
                        );
                      }}
                    ></img>
                  </div>
                ),
                enableHiding: true,
              },
              'mrt-row-actions': {
                enableHiding: true,
                size: 80
              },
              'mrt-row-expand': {
                enableHiding: true,
              },
              'mrt-row-select': {
                enableHiding: true,
              }
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  data.splice(
                    hoveredRow.index,
                    0,
                    data.splice(draggingRow.index, 1)[0],
                  );
                  setData([...data]);
                }
              },
            })}
            muiTableBodyRowProps={
              ({ row, table }) => {
                settotal_rows(table);
                const { density } = table.getState();
                return {
                  sx: {
                    height: row.getIsPinned()
                      ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                      }px`
                      : undefined,
                  },
                };
              }
            }
            renderTopToolbarCustomActions={({ }) => {
              if(minimenu===false){
                return(
                  <>
                  {filters_custom}
                  </>
                )
              }}
            }
            renderRowActionMenuItems={({ row, closeMenu }) => [
              <RENDER_ROW_ACTION_MENU_ITEMS
                closeMenu={closeMenu}
                setmodalT={setmodalT}
                setmenssage={""}
                setmodalGeneral={false}
                row={row}
                handleSubmit={handleSubmit}
                refresh_token={refresh_token}
                alertas={alertas}
              />
            ]}
            renderToolbarInternalActions={({ table }) => (
              <RENDER_TOOLBAR_INTERNAL_ACTIONS
                table={table}
                file_name={"Asignación del personal"}
                update_identifiers={update_identifiers}
                report_daily={true}
                settotal_rows={settotal_rows}
              />
            )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default DAILY_STAFF_V2;
