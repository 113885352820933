import axios from "axios";
import { number_data } from "./number_data";

export async function reports_pag(divnum, origen, di, df, route, saletype, scan, pos, bp, sale, returns) {
  var promises = [];
  const url_report = `https://cs82e77ym1.execute-api.us-east-1.amazonaws.com`;
  var url = "";
  var urlnumber = "";
  var parameters = {};

  switch (origen) {
    case "sales_report":
      url = `${url_report}/development/sales?`;
      urlnumber = `${url_report}/development/sales`;
      break;

    default:
      url = url_report;
      break;
  }

  var totalData = await number_data(
    urlnumber,
    origen,
    di === "" ? null : di,
    df === "" ? null : df,
    route === "TODAS" ? null : route,
    saletype === "TODAS" ? null : saletype,
    scan === "" ? null : scan,
    pos === "" ? null : pos,
    bp === "" ? null : bp,
    sale === "" ? null : sale,
    returns === "" ? null : returns
  );
  console.log(totalData);

  if (origen === "sales_report") {
    parameters = {
      di: di === "" ? null : di,
      df: df === "" ? null : df,
      route: route === "TODAS" ? null : route,
      saletype: saletype === "TODAS" ? null : saletype,
      scan: scan === "" ? null : scan,
      pos: pos === "" ? null : pos,
      bp: bp === "" ? null : bp,
      sale: sale === "" ? null : sale,
      returns: returns === "" ? null : returns
    }
  }

  try {
    const resultado = totalData;
    const miVariable = resultado;
    if (miVariable === 0) {
      return [];
    }

    if (miVariable >= 0 && miVariable <= 500) {
      promises.push(
        axios.get(`${url}offset=${0}&limit=${miVariable}`, {
          // axios.get(`${url}`, {
          params: parameters,
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        })
      );
      if (sale !== "") {
        console.log("con venta ")
        const results = await Promise.all(promises);
        const ndata = results?.flatMap((result) => result?.data?.sale || []);
        return ndata;
      } else {
        const results = await Promise.all(promises);
        const ndata = results?.flatMap((result) => result?.data?.sales || []);
        return ndata;
      }

    }

    const offsetInt = Math.floor(miVariable === null ? 0 : miVariable / divnum);
    const lim = offsetInt * divnum;
    const limitF = null ? 0 : miVariable - lim;
    const limitFinal = limitF < 0 ? lim : limitF;

    var cont = divnum - 1;

    for (let i = 0; i <= cont; i++) {
      promises.push(
        axios.get(
          `${url}offset=${i === 0 ? 0 : offsetInt * i}&limit=${i === cont ? offsetInt * (i + 1) + limitFinal : offsetInt * (i + 1)
          }`,
          {
            params: parameters,
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          }
        )
      );
    }

    console.log(sale)


    if (sale !== "") {
      console.log("con venta ")
      const results = await Promise.all(promises);
      const ndata = results?.flatMap((result) => result?.data?.sale || []);
      return ndata;
    } else {
      const results = await Promise.all(promises);
      const ndata = results?.flatMap((result) => result?.data?.sales || []);
      return ndata;
    }




  } catch (error) {
    return [];
  }
}
