import { useEffect, useState, Fragment } from 'react';
import {
  Box,
  Button,
  MenuItem,
} from '@mui/material';

//Date Picker Imports
import FormControl from '@mui/material/FormControl';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SaveIcon from '@mui/icons-material/Save';

const SEARCH_FILTERS = (props) => {
  return (
    <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
      <FormControl required sx={{ width: 200 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='CONSULTAR'
            sx={{ height: '35px', width: "100%" }}
            onClick={(e) => {
              props.analisisedit()
            }}
          >
            <SaveIcon></SaveIcon>&nbsp;Guardar Cambios
          </Button>
        </Box>
      </FormControl>
    </Box>
  );
};

export default SEARCH_FILTERS;
