import React, { useEffect, useState } from "react";
import { Search } from "@material-ui/icons";
import { DoDisturbAlt } from "@mui/icons-material";
import { ListItemButton } from "@mui/material";
import "./SearchBar.css";

function SearchBar({ placeholder = "Search ...", data = [], handleClickIcon }) {
  const [color, setcolor] = useState("black");
  const [searchText, setSearchText] = useState("");
  const [filteredArray, setFilteredArray] = useState([]);

  const arrLocalStorage = data;

  const handleOnBlur = () => {
    setcolor("black");
  };

  const handleOnFocus = () => {
    setcolor("#1853A7");
  };

  useEffect(() => {
    const filtered = arrLocalStorage.filter((item) => {
      return (
        item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.parent.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    const grouped = filtered.reduce((acc, curr) => {
      const parent = curr.parent;
      if (!acc[parent]) {
        acc[parent] = [];
      }
      acc[parent].push(curr);
      return acc;
    }, {});
    const result = Object.keys(grouped).map((key) => {
      return {
        parent: key,
        childs: grouped[key],
      };
    });

    // Filter sections without visible children
    const filteredResult = result.filter((item) => {
      return item.childs.some((child) => {
        return (
          child.module_restriccion.filter((valor) => valor !== "none").length >
            0 && child.restriccion !== "none"
        );
      });
    });

    setFilteredArray(filteredResult);
  }, [searchText]);

  return (
    <div className="search-container">
      <div onBlur={handleOnBlur} onFocus={handleOnFocus} className="search-bar">
        <Search className="icon-search" />
        <input
          type="search"
          placeholder={placeholder}
          onChange={(event) => {
            setSearchText(event.target.value);
            if (event.target.value.toUpperCase() === "SOPORTE") {
              handleClickIcon();
            }
          }}
        />
      </div>
      <div
        style={{
          display: searchText !== "" ? "block" : "none",
        }}
        className="search-results"
      >
        <p className="search-results-header">
          Mostrando resultados para "{searchText}"
        </p>
        {filteredArray.length > 0 ? (
          filteredArray.map((item, index) => {
            return (
              <div key={index} className="category-section">
                <ListItemButton className="category-header">
                  {item.parent}
                </ListItemButton>
                <ul className="category-list">
                  {item.childs.map((valor) => {
                    return (
                      <li
                        key={valor.name}
                        className="category-item"
                        style={{
                          display: valor.restriccion,
                        }}
                      >
                        <a href={`/${valor.ruta}`} className="category-link">
                          {valor.icono}
                          <span>{valor.name}</span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })
        ) : (
          <div className="no-results">
            <DoDisturbAlt />
            No hay resultados ...
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchBar;
