//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Button from "@mui/material/Button";
import { StickyTable, Row, Cell } from "react-sticky-table";
import NotificationSystem from "react-notification-system";
import dayjs from 'dayjs';

//COMPONENTES
import TableContainer from "../../Table_Container_Tax";
import SubTable from "../../expenses/detail_expense/datail";
import { date_current } from "../../../main_components/date/date";
import { previous_date } from "../../../main_components/date/date";
import {
  get_expense,
  download_expense,
} from "../../../services/expenses/expenses";
import UPDATE_EXPENSE from "../tools/update_expense";
import GENERATE_EXPENSE_GROUP from "./tools/generate_expense_group";
import { base64toBlob } from "../../../main_components/donwloadfuncion/download";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
//ESTILOS
import "../../expense.css";
//IMAGENES/ICONOS
import SAVE from "../../../imgComponents/save.png";
import PDF from "../../../imgComponents/pdf.png";
import Agrupar from "../../../imgComponents/agrupar.png";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import SPECIAL_ACTIONS from "./tools/special_actions";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import SubTable_v2 from "../detail_expense/datail_v2";
import { Box } from '@mui/material';
import { calculate_total } from "../../../main_components/methods_v2/auxiliary_functions";

import SEARCH_FILTERS from "./tools/shearch_filter";

const REPORT_EXPENSE_V2 = () => {
  const [ff, setff] = useState(dayjs(date_current));
  const [fi, setfi] = useState(dayjs(previous_date));
  const [sumCantidad, setSumCantidad] = useState(0);
  const [data, setData] = useState([]);
  const [menssage, setMenssage] = useState("");
  const [rfc, setRFC] = useState(null);
  const [bp, setBP] = useState(null);
  const notificationSystemRef = useRef();
  const [modalT, setmodalT] = useState(false);
  const [modalGeneral, setmodalGeneral] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const [restriction_dt, setrestriction_dt] = useState("SC");


  const handleSubmit = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        if (totalG === 0) {
          try {
            setmodalT(true);
            setMenssage("Consultando gastos...");
            var dt = await get_expense(fi.format('YYYY-MM-DD'), ff.format('YYYY-MM-DD'), null, rfc, bp);
            setData(
              dt?.data?.sort((a, b) => (a.id < b.id ? 1 : a.id > b.id ? -1 : 0))
            );
            setmodalT(false);
            setmodalGeneral(false);
            setMenssage("");
          } catch (err) {
            console.log("error", err);
            setData([]);
            setmodalT(false);
            setMenssage("");
          }
        } else {
          alert("Verifica que no tengas ningun gasto seleccionado");
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };
  useEffect(() => {
    handleSubmit();
  }, []);

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            title="Seleccionar gasto"
            type="checkbox"
            ref={resolvedRef}
            {...rest}
          />
        </>
      );
    }
  );

  const [difT, setDifT] = useState([]);
  const [totalG, setTotalG] = useState(0);

  const result = difT?.filter((idExpense) => idExpense?.id != "elminarEsto");

  console.log(result);

  const handle_download = async (id, file_name) => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setmodalT(true);
        try {
          var dta = await download_expense(id);
          const data = dta.data;
          var blob = base64toBlob(data.file);
          const blobUrl = URL.createObjectURL(blob);
          const downloadLink = document.createElement("a");
          downloadLink.href = blobUrl;
          var arr = file_name.split("/");
          downloadLink.download = arr[4] + ".pdf";
          downloadLink.click();
          setmodalT(false);
        } catch (err) {
          setmodalT(true);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const handleExpense = () => {
      setDifT(difT);
      setTotalG(totalG);
      setSumCantidad(sumCantidad);
    };
    handleExpense();
  }, []);

  const columns = useMemo(
    () => [
      // {
      //   id: "expander",
      //   header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
      //     const resultDif = difT?.filter((idExpense) => idExpense !== null);

      //     return (
      //       <img
      //         title="Agrupar Gasto"
      //         src={Agrupar}
      //         alt=""
      //         onClick={async () => {
      //           const result = await CustomDialog(
      //             <div>
      //               <div className="loader"></div>
      //               <GENERATE_EXPENSE_GROUP
      //                 grupExpense={resultDif}
      //               ></GENERATE_EXPENSE_GROUP>
      //             </div>,
      //             {
      //               className: "modalTItle",
      //               title: "Agrupando gastos...",
      //               showCloseIcon: true,
      //               isCanClose: false,
      //             }
      //           );
      //           // eslint-disable-next-line no-lone-blocks
      //           {
      //             handleReconsulta(result);
      //           }
      //         }}
      //       ></img>
      //     );
      //   },
      //   Cell: ({ row }) => {
      //     function guardardato() {
      //       sessionStorage.setItem("id_expense_subtable", row.original.id);
      //     }

      //     function Denegado() {
      //       alert("No tienes el rol necesario para ver los detalles del gasto");
      //     }

      //     if (
      //       sessionStorage.getItem("PERMISOVERDETALLESGASTO") ===
      //       "ROLE_EXPENSE_ADMINISTRATIVOS"
      //     ) {
      //       return (
      //         <div>
      //           <a
      //             title="Consultar impuestos del concepto"
      //             {...row.getToggleRowExpandedProps()}
      //           >
      //             {row?.isExpanded ? (
      //               <span title="Consultar detalles del gasto">🔼</span>
      //             ) : (
      //               <span
      //                 title="Consultar detalles del gasto"
      //                 onClick={guardardato}
      //               >
      //                 🔽
      //               </span>
      //             )}
      //           </a>

      //           <img
      //             title="Descargar pdf"
      //             alt=""
      //             src={PDF}
      //             onClick={() =>
      //               handle_download(row.original.id, row.original.file_name)
      //             }

      //           ></img>

      //           <img
      //             title="Editar Gasto"
      //             src={SAVE}
      //             alt=""
      //             onClick={async () => {
      //               if (row.original.modifiable === true) {
      //                 const result = await CustomDialog(
      //                   <UPDATE_EXPENSE
      //                     codCocept={row.original.concept}
      //                     codDescripcion={row.original.description}
      //                     codDate={row.original.date}
      //                     codImporte={row.original.ammount}
      //                     codAccount={row.original.account}
      //                     codIdPayment={row.original.paiement_account}
      //                     id_expense={row.original.id}
      //                     codidConcept={row.original.id_account}
      //                     foreign={row.original.origin === "EXTRANJERO" ? 1 : 0}
      //                     origin={row.original.origin}
      //                     date_data={row.original.payment_date}
      //                     concept={row.original.concept}
      //                   ></UPDATE_EXPENSE>,
      //                   {
      //                     className: "modalTItle",
      //                     title: "Editar Gasto",
      //                     showCloseIcon: true,
      //                     isCanClose: false,
      //                   }
      //                 );
      //                 // eslint-disable-next-line no-lone-blocks
      //                 {
      //                   handleReconsulta(result);
      //                 }
      //               } else {
      //                 alert("Este gasto ya no se puede modificar.");
      //               }
      //             }}
      //             className="centerText, taxes"
      //           ></img>
      //         </div>
      //       );
      //     } else {
      //       return (
      //         <span title="Consultar detalles del gasto" onClick={Denegado}>
      //           🔽
      //         </span>
      //       );
      //     }
      //   },
      // },

      {
        id: "spend_folio_number",
        header: "No.",
        accessorKey: "spend_folio_number",
      },
      {
        id: "date",
        header: "F. Recepción",
        accessorKey: "date",
      },
      {
        id: "date_document",
        header: "F. Documento",
        accessorKey: "date_document",
      },
      {
        id: "concept",
        header: "Concepto",
        accessorKey: "concept",
      },
      {
        id: "description",
        header: "Descripción",
        accessorKey: "description",
      },
      {
        id: "ammount",
        header: "Importe",
        accessorKey: "ammount",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        id: "user_name",
        header: "Usuario",
        accessorKey: "user_name",
      },
      {
        id: "origin",
        header: "Origen",
        accessorKey: "origin",
      },
      {
        id: "modifiable",
        header: "Pagado",
        accessoKey: "modifiable",
        accessorFn: (d) => {
          return d.modifiable === false ? "✅" : "❌";
        },
      },
      {
        id: "payment",
        header: "Cuenta de pago",
        accessorKey: "payment",
      },
      {
        id: "scheduled_payment_date",
        header: "F. Vencimiento",
        accessorKey: "scheduled_payment_date",
      },
      {
        id: "payment_date",
        header: "F. Pago",
        accessorKey: "payment_date",
      },

      {
        id: "business_partner_name",
        header: "Proveedor",
        accessorKey: "business_partner_name"
      },
      {
        id: "rfc",
        header: "RFC Proveedor",
        accessorKey: "rfc"
      },
      // {
      //   id: "selection2",

      //   header: ({ getToggleAllRowsSelectedProps }) => <div></div>,

      //   Cell: ({ row, value, onChange }) => {
      //     function idExpense() {
      //       if (row.isSelected === false) {
      //         if (difT[row.index]?.length != 0) {
      //           difT[row.index] = {
      //             id: row.original.id,
      //             importe: row.original.ammount,
      //           };
      //         } else {
      //           setDifT([
      //             ...difT,
      //             {
      //               id: row.original.id,
      //               importe: row.original.ammount,
      //             },
      //           ]);
      //         }
      //       } else {
      //         difT[row.index] = {
      //           id: "elminarEsto",
      //         };
      //         setDifT(difT);
      //       }
      //     }

      //     useEffect(() => {
      //       const handleExpense = () => {
      //         setDifT(difT);
      //         console.log(difT);
      //         setTotalG(totalG);
      //         setSumCantidad(sumCantidad);
      //       };
      //       handleExpense();
      //     }, []);

      //     useEffect(() => {
      //       const handleSumar = () => {
      //         const result = difT?.filter(
      //           (idExpense) => idExpense?.id !== "elminarEsto"
      //         );

      //         const sumar = result
      //           ?.map((saldo) =>
      //             parseFloat(isNaN(saldo?.importe) === true ? 0 : saldo.importe)
      //           )
      //           .reduce((previous, current) => {
      //             return previous + current;
      //           }, 0);
      //         setSumCantidad(sumar.toFixed(2));
      //         setTotalG(result?.length);
      //       };
      //       handleSumar();
      //     });

      //     if (row.original.modifiable === true) {
      //       return (
      //         <div className="campoPagoSelect">
      //           <IndeterminateCheckbox
      //             className="NoInput"
      //             onClick={idExpense}
      //             onChange={idExpense}
      //             {...row.getToggleRowSelectedProps()}
      //           />
      //         </div>
      //       );
      //     } else {
      //       return <div></div>;
      //     }
      //   },
      // },
    ],
    []
  );

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const update_identifiers =
    [
      { spend_folio_number: 'string' },
      { date: 'string' },
      { date_document: 'string' },
      { concept: 'string' },
      { description: 'string' },
      { ammount: 'string' },
      { user_name: 'string' },
      { origin: 'string' },
      { modifiable: 'string' },
      { payment: 'string' },
      { scheduled_payment_date: 'string' },
      { payment_date: 'string' },
      { business_partner_name: 'string' }
    ];


  return (
    <div >
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>

      <BREADCRUMBS
        niveles={[
          { label: "REPORTE DE GASTOS", path: null },
          { label: "REPORTE DE GASTOS AGRUPADOS", path: "/expensesGrup_V2" },
        ]}

       cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <div >

        <div className="new_table_v2">
          <MaterialReactTable
            columns={columns}
            data={data}
            enableGrouping
            enablePinning
            enableFacetedValues
            enableStickyHeader
            enableStickyFooter
            enableRowPinning
            enableColumnFilterModes
            enableRowActions
            enableRowSelection
            getRowId={(row) => row?.id}
            onRowSelectionChange={setRowSelection}
            enableTooltips={false}
            localization={MRT_Localization_ES}
            state={{ rowSelection, isLoading: isLoading }}
            enableColumnResizing
            enableColumnPinning
            enableColumnOrdering
            onStateChange={handleStateChange}
            enableExpandAll={false}
            enableColumnDragging={false}
            initialState={{ showGlobalFilter: true, showColumnFilters: true }}
            muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
            muiSkeletonProps={{ animation: 'pulse', height: 28 }}
            rowVirtualizerInstanceRef
            rowVirtualizerOptions={{ overscan: 5 }}
            columnVirtualizerOptions={{ overscan: 2 }}
            muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
            positionToolbarAlertBanner="bottom"
            paginationDisplayMode='pages'
            rowPinningDisplayMode='sticky'
            layoutMode="grid"
            muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
              'mrt-row-pin': {
                Header: () => (
                  <div>
                    <SPECIAL_ACTIONS setIsLoading={setIsLoading} rowSelection={rowSelection} total_rows={total_rows !== "" ? total_rows : []}></SPECIAL_ACTIONS>
                  </div>
                ),
                enableHiding: true,
              },
              'mrt-row-actions': {
                enableHiding: true,
              },
              'mrt-row-expand': {
                enableHiding: true,
              },
              'mrt-row-select': {
                enableHiding: true,
              }
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  data.splice(
                    hoveredRow.index,
                    0,
                    data.splice(draggingRow.index, 1)[0],
                  );
                  setData([...data]);
                }
              },
            })}
            muiTableBodyRowProps={
              ({ row, table }) => {
                settotal_rows(table);
                const { density } = table.getState();
                return {
                  sx: {
                    height: row.getIsPinned()
                      ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                      }px`
                      : undefined,
                  },
                };
              }
            }
            renderDetailPanel={({ row }) => {

              if (
                sessionStorage.getItem("PERMISOVERDETALLESGASTO") === "ROLE_EXPENSE_ADMINISTRATIVOS"
              ) {
                sessionStorage.setItem("id_expense_subtable", row.original.id);
                return (
                  <SubTable_v2
                    row={row}
                    setmodalT={setmodalT}
                    //alertas={alertas}
                    data={data}
                    columns={columns}
                    defaultPageSize={3}
                    showPagination={false}
                    verData={true}
                    foreign={null}
                  />)
              } else {
              }
            }}

            renderTopToolbarCustomActions={({ }) => (
              <SEARCH_FILTERS
                setfi={setfi}
                fi={fi}
                setff={setff}
                ff={ff}
                setRFC={setRFC}
                rfc={rfc}
                setBP={setBP}
                bp={bp}
                restriction_dt={restriction_dt}
                total_rows={total_rows !== "" ? calculate_total((total_rows?.getSelectedRowModel()?.rows.map(d => d?.original?.ammount))) : 0}
                setrestriction_dt={setrestriction_dt}
                handle_get_sales={handleSubmit}
              ></SEARCH_FILTERS>
              //       <div class="containerExpenseReport grid-tools-report-expenses">
              //         <div class="expen-1 color-1">
              //           <input
              //             type="text"
              //             className="fExpense"
              //             onFocus={(e) => {
              //               e.currentTarget.type = "date";
              //               e.currentTarget.focus();
              //             }}
              //             placeholder="Fecha inicial"
              //             onChange={(event) => setfi(event.target.value)}
              //           ></input>
              //         </div>

              //         <div class="expen-2 color-2">
              //           <input
              //             type="text"
              //             className="fExpense"
              //             onFocus={(e) => {
              //               e.currentTarget.type = "date";
              //               e.currentTarget.focus();
              //             }}
              //             placeholder="Fecha Final"
              //             onChange={(event) => setff(event.target.value)}
              //           ></input>
              //         </div>

              //         <div class="expen-3 color-4">
              //           <input
              //             type="text"
              //             placeholder="RFC"
              //             className="fExpense"
              //             onChange={(event) => setRFC(event.target.value)}
              //           ></input>
              //         </div>

              //         <div class="expen-4 color-5">
              //           <input
              //             type="text"
              //             className="fExpense"
              //             placeholder="Socio de negocios"
              //             onChange={(event) => setBP(event.target.value)}
              //           ></input>
              //         </div>

              //         <div class="expen-5  color-5">
              //           <Button
              //             className="botonExpense"
              //             sx={{ width: "100%" }}
              //             variant="contained"
              //             onClick={handleSubmit}
              //           >
              //             Buscar
              //           </Button>
              //         </div>
              //         {/* <div class="expen-8  color-5">
              //   <p>Seleccionados: {totalG}</p>
              // </div> */}
              //         <div class="expen-7  color-5">
              //           <p>Total de Gastos: {total_rows !== "" ? calculate_total((total_rows?.getSelectedRowModel()?.rows.map(d => d?.original?.ammount))) : 0}</p>
              //         </div>
              //       </div>

            )}

            renderRowActionMenuItems={({ row, closeMenu }) => [
              <RENDER_ROW_ACTION_MENU_ITEMS
                closeMenu={closeMenu}
                setmodalT={setmodalT}
                row={row}
                handleSubmit={handleSubmit}
                setreconsult={setreconsult}
                refresh_token={refresh_token}
              //  alertas={alertas}
              />
            ]}
            renderToolbarInternalActions={({ table }) => (
              <RENDER_TOOLBAR_INTERNAL_ACTIONS
                table={table}
                file_name={"Polizas"}
                update_identifiers={update_identifiers}
                settotal_rows={settotal_rows}
              />
            )
            }
          />
        </div>
        {/* <StickyTable>
            <TableContainer
              columns={columns}
              exportEx={false}
              data={data}
              style={{ overflowx: 10 }}
              renderRowSubComponent={(row) => {
                return (
                  <div style={{ padding: "20px" }}>
                    <SubTable verData={true} row={row} foreign={null} />
                  </div>
                );
              }}
            />
          </StickyTable> */}
      </div>
    </div>
  );
};

export default REPORT_EXPENSE_V2;
