import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../../imgComponents/save.png";
import Delete from "../../../../imgComponents/borrar.png";
import EditGoal from "../EditGoal";
import { delete_goal } from "../../../../services/reports/reports";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const DeleteSector =async () =>{
        props.setmodalT(true);
        props.setreconsult(true);
        try{
             var rf_token = await refresh_token();
             if(rf_token===true){
               setTimeout(async() => {
                 try{
                    const dt = await delete_goal(props?.row?.original?.id);
                    console.log(dt);
                   props.alertas("Meta eliminada", true);
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.handleSubmit();
                 }catch(err){
                   console.log("error", err);
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.alertas("Error, no se pudo eliminar la Meta", false);
                 }
               }, 1000);
             }else{}
           }catch(err){
            console.log(err);
           }
    }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar la Meta?','Eliminar Meta','Si','No');
                    if(result){
                        DeleteSector()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Meta"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Meta
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <EditGoal 
                            row={props?.row} 
                            handleSubmit={props?.handleSubmit}
                            alertas={props?.alertas} 
                            reconsultar={props?.reconsultar} 
                            setactualizacionmeta={props?.setactualizacionmeta} 
                            setfiltrometas={props?.setfiltrometas}
                        ></EditGoal>,
                    {
                        className: "custom-modal",
                        title: "EDITAR META",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Meta"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Meta
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
