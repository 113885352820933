import { Confirm, CustomDialog, useDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import lupa from "../../../imgComponents/lupa.png";
import CONSULT_DETAIL_WAREHOUSE_v2 from "../detail/consult_detail_sale _v2";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const SPECIAL_ACTIONS = (props) => {
    const dialog = useDialog();

    return (
        <div>
            <MenuItem
                key={1}
                onClick={async () => {
                    props.closeMenu();
                    const result = await CustomDialog(
                        <CONSULT_DETAIL_WAREHOUSE_v2
                            id={props?.row.original.id}
                            date={props?.row.original.movement_date}
                            rows={props?.row.original.rows}
                            note={props?.row.original.note}
                            reference={props?.row.original.reference}
                            warehouse_name={props?.row.original.warehouse_name}
                            sale={props?.row.original.sale}
                            returns={props?.row.original.returns}
                            type={props?.row.original.type}
                            movement_type={props?.row.original.movement_type}
                            fullusername={props?.row.original.fullusername}
                        ></CONSULT_DETAIL_WAREHOUSE_v2>,
                        {
                            className: "custom-modal-3",
                            title: "MOSTRAR DETALLES DEL MOVIMIENTO",
                            showCloseIcon: true,
                        });
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={lupa}
                        title="Mostrar Detalles del Movimiento"
                        className=""
                    ></img>
                </ListItemIcon>
                Mostrar Detalles del Movimiento
            </MenuItem>

            <MenuItem
                key={2}
                onClick={async () => {
                    props.closeMenu();
                    const result = await Confirm(
                        "¿Esta usted seguro de copiar este movimiento?",
                        "Copiar movimiento",
                        "Si",
                        "No"
                    );
                    if (result) {
                        dialog.close(props?.row.original.id);
                    } else {
                    }
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <ContentCopyIcon></ContentCopyIcon>
                </ListItemIcon>
                Copiar Movimiento
            </MenuItem>

        </div >
    );
};

export default SPECIAL_ACTIONS;
