import { useEffect, useMemo, useState } from "react";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { get_deatils_notices } from "../../services/supports/avisos";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//COMPONENTES

export const DETAILS = (props) => {

    const [data, setdata] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [total_rows, settotal_rows] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      details();
    }, [])

    
  const columns = useMemo(
    () => [
      {
        id: "id_user",
        header: "Correo Usuario",
        accessorKey: "id_user",
        muiTableBodyCellProps: {
          align: 'left',
        },
        size: 350
      },
      {
        id: "date_confirmed",
        header: "Fecha",
        accessorKey: "date_confirmed",
        muiTableBodyCellProps: {
          align: 'left'
        },
        size: 300
      }
    ],
    []
  );
    
    const handleStateChange = (newState) => {
        const filteredRowsCount = newState.filteredRowModel
        settotal_rows(filteredRowsCount);
      };

    const details = async() =>{

        try{
            const rf = await refresh_token();
            setIsLoading(true);
            if(rf){
                setTimeout(async() => {
                    try{
                        const d = await get_deatils_notices(props?.row?.original?.id);
                        setdata(d?.data);
                        setIsLoading(false);
                    }catch(err){
                        console.log(err);
                        setIsLoading(false);
                    }
                }, 1000);
            }
        }catch(err){
            console.log(err);
        }

    }




  return (
    <div className="new_table_v2" style={{"width":"90%", margin: "0 auto"}}>
            <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
            //   enableRowActions
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                  size: 80
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                      props?.data.splice(
                      hoveredRow.index,
                      0,
                      props?.data.splice(draggingRow.index, 1)[0],
                    );
                    props.setdata([...props?.data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
            //   renderRowActionMenuItems={({ row, closeMenu }) => [
            //     <RENDER_ROW_ACTION_MENU_ITEMS
            //       closeMenu={closeMenu}
            //       setmodalT={props?.setmodalt}
            //       setmenssage={""}
            //       setmodalGeneral={false}
            //       row={row}
            //       handleSubmit={props?.get_avisos}
            //       alertas={props?.alertas}
            //     />
            //   ]}
            //   renderToolbarInternalActions={({ table, index }) => (
            //     <RENDER_TOOLBAR_INTERNAL_ACTIONS
            //       table={table}
            //       file_name={"Avisos od2"}
            //       update_identifiers={update_identifiers}
            //       key={index}
            //       settotal_rows={settotal_rows}
            //     />
            //   )
            //   }
            />
    </div>
  )
}
