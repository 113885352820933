import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Delete from "../../../../imgComponents/borrar.png";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import {delete_pos_sector } from "../../../../services/businesspartners/businesspartners";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const DeleteSector =async () =>{
        props.setmodalT(true);
        props.setreconsult(true);
        try {
            var rf_token = await refresh_token();
            if(rf_token===true){
                setTimeout(async() => {
                try{
                   const dt = await delete_pos_sector(props?.row.original.idRelation);
                   console.log(dt);
                   props.alertas("Punto de Venta Eliminado", true);
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.handleSubmit();
                }catch(err){
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.alertas("No se pudo eliminar el punto de venta", false);
                }
                }, 1000);
            }else{}    
        } catch (err) {
        console.log(err);
        }
    }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar el punto de venta relacionado al sector?','Eliminar punto de venta relacionado al sector','Si','No');
                    if(result){
                        DeleteSector()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Punto de Venta del Sector"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Punto de Venta del Sector
            </MenuItem>
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
