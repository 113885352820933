//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import Button from "@mui/material/Button";
import NotificationSystem from "react-notification-system";
//COMPONENTES
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
//ESTILOS
import "../expense.css";
//IMAGENES/ICONOS
import { date_current } from "../../main_components/date/date";
import { previous_date } from "../../main_components/date/date";
import { get_expense } from "../../services/expenses/expenses";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import "./expense.css";
import { Box } from "@mui/material";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import SubTable_v2 from "./detail_expense/datail_v2";
import CREATE_UPDATE_EXPENSE from "./tools/create_expense";
import { CustomDialog, Confirm, useDialog } from "react-st-modal";
import CREATE from "../../imgComponents/create.png";


const EXPENSES_V2 = (props) => {
  const [data, setData] = useState([]);
  const [menssage, setMenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(true);
  const [modalT, setmodalT] = useState(false);
  const [ff, setff] = useState(date_current);
  const [fi, setfi] = useState(previous_date);
  const notificationSystemRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});

  const [total_rows, settotal_rows] = useState("");



  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }


  const handleSubmit = async (menssageAlert) => {
    try {
      setIsLoading(true);
      var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          setmodalT(true);
          setMenssage("Consultando gastos...");
          console.log(props.expense);
          var dt = await get_expense(fi, ff, props.expense);
          setData(
            dt?.data?.sort((a, b) => (a.id < b.id ? 1 : a.id > b.id ? -1 : 0))
          );
          setmodalT(false);
          setmodalGeneral(false);
          setMenssage("");
          setIsLoading(false);
          if (menssageAlert === undefined) {
            console.log("sin alaerta");
          } else {
            notificationSystemRef.current.addNotification({
              message: (
                <AlertResponse msj={menssageAlert} view={true}></AlertResponse>
              ),
              level: "success",
              position: "br",
              autoDismiss: 10,
            });
          }
        } catch (err) {
          console.log("error", err);
          setData([]);
          setmodalT(false);
          setMenssage("");
          setIsLoading(false);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        header: "Número Folio",
        accessorKey: "spend_folio_number",
      },
      {
        header: "Fecha",
        accessorKey: "date",
      },
      {
        header: "Concepto",
        accessorKey: "concept",
      },
      {
        header: "Descripción",
        accessorKey: "description",
      },
      {
        header: "Importe",
        accessorKey: "ammount",
        Cell: ({ cell }) => {
          return (
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor: theme.palette.success.dark,
                borderRadius: '0.25rem',
                color: '#fff',
                maxWidth: '9ch',
                p: '0.25rem',
              })}
            >
              {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          )
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        header: "Usuario",
        accessorKey: "user_name",
      },
      {
        header: "Editable",
        accessorKey: "modifiable",
        muiTableBodyCellProps: {
          align: "center"
        },
        accessorFn: (d) => {
          return d.modifiable === true ? "✅" : "❌";
        },
      },
      {
        header: "Cuenta",
        accessorKey: "account",
      },
    ],
    []
  );

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  const update_identifiers =
    [
      { spend_folio_number: 'string' },
      { date: 'string' },
      { concept: 'string' },
      { description: 'string' },
      { ammount: 'string' },
      { user_name: 'string' },
      { modifiable: 'string' },
      { account: 'string' },
    ];

  return (
    <div >
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>

      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>

      <BREADCRUMBS
        niveles={[
          {
            label:
              props.expense === "EXTRANJERO"
                ? "GASTOS DEL " + props.expense
                : "GASTOS " + props.expense,
            path: null,
          },
        ]}
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>



      <div className="new_table_v2">
        <div style={{ overflowX: "auto" }}>

          <MaterialReactTable
            columns={columns}
            data={data}
            enableGrouping
            enablePinning
            enableFacetedValues
            enableStickyHeader
            enableStickyFooter
            enableRowPinning
            enableColumnFilterModes
            enableRowActions
            onStateChange={handleStateChange}
            enableRowSelection
            getRowId={(row) => row?.id}
            onRowSelectionChange={setRowSelection}
            enableTooltips={false}
            localization={MRT_Localization_ES}
            state={{ rowSelection, isLoading: isLoading }}
            enableColumnResizing
            enableColumnPinning
            enableColumnOrdering
            enableExpandAll={false}
            enableColumnDragging={false}
            initialState={{ showGlobalFilter: true, showColumnFilters: true }}
            muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
            muiSkeletonProps={{ animation: 'pulse', height: 28 }}
            rowVirtualizerInstanceRef
            rowVirtualizerOptions={{ overscan: 5 }}
            columnVirtualizerOptions={{ overscan: 2 }}
            muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
            positionToolbarAlertBanner="bottom"
            paginationDisplayMode='pages'
            rowPinningDisplayMode='sticky'
            layoutMode="grid"
            muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

            muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
              'mrt-row-pin': {
                Header: () => (
                  <div>
                    <img
                      title="Registrar Gasto"
                      src={CREATE}
                      className=""
                      onClick={async () => {
                        const result = await CustomDialog(
                          <CREATE_UPDATE_EXPENSE
                            codCocept={""}
                            codDescripcion={""}
                            codDate={""}
                            codImporte={0}
                            codAccount={"9090909090909"}
                            codIdPayment={""}
                            id_expense={""}
                            codidConcept={""}
                            method={"CREATE"}
                            foreing={props?.foreing}
                            origin={props?.origin}
                            handleSubmit={handleSubmit}
                          />,
                          {
                            className: "custom-modal",
                            title: "Registrar Gasto",
                            showCloseIcon: true,
                            isCanClose: false,
                          }
                        );
                      }}
                    ></img>
                  </div>
                ),
                enableHiding: true,
              },
              'mrt-row-actions': {
                enableHiding: true,
              },
              'mrt-row-expand': {
                enableHiding: true,
              },
              'mrt-row-select': {
                enableHiding: true,
              }
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  data.splice(
                    hoveredRow.index,
                    0,
                    data.splice(draggingRow.index, 1)[0],
                  );
                  setData([...data]);
                }
              },
            })}
            muiTableBodyRowProps={
              ({ row, table }) => {
                settotal_rows(table)
                const { density } = table.getState();
                return {
                  sx: {
                    height: row.getIsPinned()
                      ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                      }px`
                      : undefined,
                  },
                };
              }
            }
            renderTopToolbarCustomActions={({ }) => (
              <div class="gastosnodedutable">
                <div class="expen-1 color-1">
                  <input
                    type="text"
                    className="fExpense"
                    onFocus={(e) => {
                      e.currentTarget.type = "date";
                      e.currentTarget.focus();
                    }}
                    placeholder="Fecha inicial"
                    onChange={(event) => setfi(event.target.value)}
                  ></input>
                </div>

                <div class="expen-2 color-2">
                  <input
                    type="text"
                    className="fExpense"
                    onFocus={(e) => {
                      e.currentTarget.type = "date";
                      e.currentTarget.focus();
                    }}
                    placeholder="Fecha Final"
                    onChange={(event) => setff(event.target.value)}
                  ></input>
                </div>

                <div class="expen-3 color-5">
                  <Button
                    sx={{ width: "100%" }}
                    variant="contained"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Buscar
                  </Button>
                </div>
              </div>

            )}
            renderRowActionMenuItems={({ row, closeMenu }) => [
              <RENDER_ROW_ACTION_MENU_ITEMS
                closeMenu={closeMenu}
                setmodalT={setmodalT}
                setmenssage={""}
                setmodalGeneral={setmodalGeneral}
                row={row}
                handleSubmit={handleSubmit}
                refresh_token={refresh_token}
                alertas={alertas}
              />
            ]}
            renderToolbarInternalActions={({ table }) => (
              <RENDER_TOOLBAR_INTERNAL_ACTIONS
                table={table}
                file_name={props.expense === "EXTRANJERO"
                  ? "GASTOS DEL " + props.expense
                  : "GASTOS " + props.expense}
                update_identifiers={update_identifiers}
                settotal_rows={settotal_rows}
              />
            )}
            renderDetailPanel={({ row }) => {
              return (
                <SubTable_v2
                  verData={true}
                  row={row}
                  foreign={props.foreing}
                  alertas={alertas}
                  handleSubmit={handleSubmit}
                  setmodalT={setmodalT}
                />
              )
            }}
          />
          {/*       
          <TableContainer
            exportar={true}
            columns={columns}
            nametable={props.expense === "EXTRANJERO"
            ? "GASTOS DEL " + props.expense
            : "GASTOS " + props.expense}
            data={data} 
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <SubTable verData={true} row={row} foreign={props.foreing} />
                </div>
              );
            }}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default EXPENSES_V2;
