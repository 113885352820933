//PAQUETERIAS
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

//COMPONENTES
import { update_price_list_products } from "../../../../services/inventory/inventory";
import { update_price_list } from "../../../../services/inventory/inventory";
import MODAL_TABLE from "../../../../main_components/modal/modal_Table";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../../../main_components/catchErr/catchError";

//IMAGENES
//ESTILOS

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const CREATE_UPDATE = (props) => {
  const [modalT, setmodalT] = useState(false);
  const [price_list, setprice_list] = useState(props.price);
  const dialog = useDialog();

  const handle_create_products_line = async () => {
    var n_data_price_list = props.data_price_list.map((d) => {
      return { code: d.code, price: d.price };
    });
    const posicion = n_data_price_list?.findIndex(
      (objeto) => objeto.code === props.code
    );
    console.log(n_data_price_list);
    const newArray = [...n_data_price_list];
    newArray[posicion] = {
      code: newArray[posicion].code,
      price: Number(price_list),
    };
    console.log(newArray);
    console.log(posicion);
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            await update_price_list_products(newArray, props.id);
            setmodalT(false);
            dialog.close({
              confirm: true,
              msj: "Linea de productos actualizada correctamente",
            });
            props?.handleSubmit ? props?.handleSubmit(): console.log("");
          } catch (err) {
            catchErrorModul("Linea de productos",JSON.stringify(newArray),JSON.stringify(err?.response));
            setmodalT(false);
            dialog.close(JSON.stringify(err?.response?.data));
            dialog.close({
              confirm: false,
              msj: JSON.stringify(err?.response.data),
            });
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  const handle_delete_products_line = async () => {
    var n_data_price_list = props.data_price_list.map((d) => {
      return { code: d.code, price: d.price };
    });
    const posicion = n_data_price_list?.findIndex(
      (objeto) => objeto.code === props.code
    );

    console.log(n_data_price_list);
    const newArray = [...n_data_price_list];
    newArray[posicion] = { code: newArray[posicion].code, price: price_list };
    console.log(newArray);
    console.log(posicion);
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        try {
          await update_price_list_products(newArray, props.id);
          setmodalT(false);
          dialog.close({
            confirm: true,
            msj: "Linea de productos eliminada correctamente",
          });
          props?.handleSubmit ? props?.handleSubmit(): console.log("");
        } catch (err) {
          catchErrorModul("Linea de productos",JSON.stringify(newArray),JSON.stringify(err?.response));
          setmodalT(false);
          dialog.close(JSON.stringify(err?.response?.data));
          dialog.close({
            confirm: false,
            msj: JSON.stringify(err?.response.data),
          });
        }
      }else{}
    }catch(err){
      console.log(err);
    }
    
  };

  return (
    <div style={{"textAlign":"center"}}>
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>

      <form>
        <Box style={{ display: props.method === "UPDATE" ? "" : "none" }}>
          <ValidationTextField
            label="Precio "
            variant="outlined"
            placeholder="Precio"
            type="number"
            onFocus={(e) => setprice_list(Number(e.target.value).toFixed(2))}
            onBlur={(e) => setprice_list(Number(e.target.value).toFixed(2))}
            sx={{ m: 2, width: "80%" }}
            value={price_list}
            onChange={(e) => setprice_list(e.target.value>=0?e.target.value:0)}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <br></br>

        <Box>
          <Button
            variant="contained"
            style={{ display: props.method === "DELETE" ? "" : "none" }}
            sx={{ m: 1.5, width: "20ch" }}
            onClick={handle_delete_products_line}
            className="ButtonModal"
          >
            ELIMINAR
          </Button>
          <Button
            style={{ display: props.method === "UPDATE" ? "" : "none" }}
            variant="contained"
            sx={{ m: 1.5, width: "20ch" }}
            onClick={handle_create_products_line}
            className="ButtonModal"
          >
            Actualizar
          </Button>

          <Button
            variant="contained"
            sx={{ m: 1.5, width: "20ch" }}
            className="ButtonModal2"
            onClick={() => {
              dialog.close();
            }}
          >
            cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
};
export default CREATE_UPDATE;
