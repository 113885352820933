import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useState } from 'react';
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';
import { post_competition_unilever, put_competition_unilever } from '../../../services/unilever/unilever';
import { Button } from '@mui/material';
import { useDialog } from 'react-st-modal';
import dayjs from 'dayjs';

export const OPERATION_COMPETITION = (props) => {

  const [name, setname] = useState(props?.operation === "CREATE"? "": props?.row?.original?.name_contests);
  const [start_date, setstart_date] = useState(
    props?.operation === "CREATE"? null :
      dayjs(props?.row?.original?.start_date)
  );
  const [end_date, setend_date] = useState(
    props?.operation === "CREATE"? null : 
      dayjs(props?.row?.original?.end_date)
  );
  const [enable, setenable] = useState(props?.operation === "CREATE"? true : props?.row?.original?.enable);
  const [number_tickets, setnumber_tickets] = useState(props?.operation === "CREATE"? 0 : props?.row?.original?.number_tickets);
  const [show, setshow] = useState(props?.operation==="CREATE"?false:true);
  const dialog = useDialog();

  const handlepost = async() =>{
    
    if(name === "" || start_date === null || end_date === null || number_tickets === null){
      props?.alertas("Datos faltantes", false);
      return null;
    }
    props.setmodalT(true);
    var dat = {
      name_contests: name,
      start_date: start_date?.format("YYYY-MM-DD HH:mm:ss"),
      end_date: end_date?.format("YYYY-MM-DD HH:mm:ss"),
      enable: enable === "true" || enable === true ? true : false ,
      number_tickets: Number(number_tickets)
    }
    try{
      const rf = await refresh_token();
      if(rf){
        setTimeout(async() => {
          try{
            const d = await post_competition_unilever(dat);
            props.alertas("Concurso registrado con éxito", true);
            props.setmodalT(false);
            dialog.close();
            props.handlesubmit();
          }catch(err){
            console.log(err);
            props.setmodalT(false);
            props?.alertas("Error al registrar el concurso", false);
          }
        }, 1500);
      }
    }catch(err){
      props.setmodalT(false);
      console.log(err);
    }
  }
  const handleupdate = async() =>{
    
    if(name === "" || start_date === null || end_date === null || number_tickets === null){
      props?.alertas("Datos faltantes", false);
      return null;
    }
    props.setmodalT(true);
    
    try{
      var dat = {
        name_contests: name,
        start_date: start_date?.format("YYYY-MM-DD HH:mm:ss"),
        end_date: end_date?.format("YYYY-MM-DD HH:mm:ss"),
        enable: enable === "true" || enable === true ? true : false ,
        number_tickets: Number(number_tickets)
      }

      const rf = await refresh_token();
      if(rf){
        setTimeout(async() => {
          try{
            const d = await put_competition_unilever(dat, props?.row?.original?.id);
            props.alertas("Concurso actualizado con éxito", true);
            props.setmodalT(false);
            dialog.close();
            props.handlesubmit();
          }catch(err){
            console.log(err);
            props.setmodalT(false);
            props?.alertas("Error al actualizar el concurso", false);
     
          }
        }, 1500);
      }
    }catch(err){
      console.log(err);
      props.setmodalT(false);
     
    }
  }
  const handleSwitchChangeshow = (event) => {
    setshow(!event.target.checked);
};

  return (
    <div>
      <div className='content-forms-contests'>
      {
            props?.operation==="EDIT"?
            <div className='mod-edition-form-edit'>
            <div className=''>
                <FormControlLabel
                        control={
                            <Switch
                                checked={!show}
                                onChange={handleSwitchChangeshow}
                                color="primary"
                                sx={{ flexShrink: 0 }}
                            />
                        }
                        label={!show ? "Desactivar Modo Edición":"Activar Modo Edición"}
                        sx={{
                            marginRight: '8px',
                            flex: '1 1 auto',
                            minWidth: '150px'
                        }}
                    />
            </div>
        </div>
        :<></>
        }
        <div className='grid1-contests'>
          <div className='contests-g1'>
              <TextField
                label="Nombre del concurso"
                value={name}
                disabled={show}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setname(event.target.value.toUpperCase())}  
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>
        </div>
        <div className='grid2-contests'>
          <div className='contests-g2'>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="es-mx">
              <DatePicker
                  sx={{ m: 1.5, width: "100%"}}
                  className='text-field-disabled'                       
                  disabled={show}
                  label="Fecha de Inicio"
                  value={start_date}
                  onChange={(newValue) => setstart_date(newValue)}
                  locale="es"
                  format="DD/MM/YYYY HH:mm:ss"
              />
            </LocalizationProvider>
          </div>
          <div className='contests-g3'>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="es-mx">
                <DatePicker
                    sx={{ m: 1.5, width: "100%"}}
                    className='text-field-disabled'                       
                    disabled={show}
                    label="Fecha de Término"
                    value={end_date}
                    onChange={(newValue) => setend_date(newValue)}
                    locale="es"
                    format="DD/MM/YYYY HH:mm:ss"
                />
              </LocalizationProvider>
          </div>
        </div>
        <div className='grid2-contests'>
          <div className='contests-g4'>
            <TextField
              select
              label="Estado"
              value={enable}
              disabled={show}
              InputLabelProps={{shrink: true}}
              SelectProps={{native: true}}
              sx={{ m: 1.5, width: "100%"}}
              onChange={(event) => setenable(event.target.value)}  
            >
              <option key={1} value={true}>{"Vigente"}</option>
              <option key={2} value={false}>{"Terminado"}</option>
            </TextField>
          </div>
          <div className='contests-g5'>
            <TextField
              label="Número de Boletos"
              type='number'
              disabled={show}
              value={number_tickets}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setnumber_tickets(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>
        </div>
        <Box className="button-action-job-opp">
            <Button
              variant="contained"
              style={{"backgroundColor":"#1976d2"}}
              sx={{ m: 1.5, width: "25ch" }}
              disabled={show}
              onClick={()=>{props?.operation==="CREATE"?handlepost():handleupdate()}}
            >
                {props?.operation==="CREATE"?"REGISTRAR":"ACTUALIZAR"}
            </Button>
            <Button
              style={{"backgroundColor":"white", "color":"#1976d2"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  )
}
