import { Box } from '@mui/material';
import { CustomDialog } from "react-st-modal";
import CREATE from "../../../../imgComponents/create.png";
import SHEARCH_SALES from './shearch_sales';

const SPECIAL_ACTIONS = (props) => {
  return (
    <Box sx={{ display: 'flex', gap: '0.1rem', alignItems: 'flex-end' }}>
      <img
        src={CREATE}
        className="icon_add_qr"
        title='CONSULTAR VENTAS'
        key={"add_qr"}
        onClick={async () => {
          await CustomDialog(
            <SHEARCH_SALES></SHEARCH_SALES>,
            {
              className: "custom-modal",
              title: "Consultar ventas",
              showCloseIcon: true,
              isCanClose: false,
            }
          );
        }}
      >
      </img>
    </Box >

  );
}
export default SPECIAL_ACTIONS;
