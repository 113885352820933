import { useMemo, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { StickyTable} from "react-sticky-table";
import TableContainer from "../../../main_components/Table/TableContainer";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";

import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
function DETAILS_SALES_REAL_V2(props) {

const [data, setdata]=useState(props.row.original);
const dialog = useDialog();
const [isLoading, setIsLoading] = useState(false);
const [rowSelection, setRowSelection] = useState({});

const columns = useMemo(
  () => [
    {
      id: "code",
      header:"Código",
      accessorKey:"code"
    },
    {
      id: "product_name",
      header:"Nombre",
      accessorKey:"product_name"
    },
    {
      id: "quantity",
      header:"Cantidad",
      accessorKey:"quantity"
    },
    {
      id: "total",
      header:"Total",
      accessorKey: "total",
      accessorFn:(d)=>{
        return d.total
        ? Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(d.total)
        : Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(d.total);
      },  
    }
  ]
);


const update_identifiers =
[
  { code: 'string' },
  { product_name: 'string' },
  { quantity: 'String' },
  { total: 'string'}
];

return (
    <div style={{textAlign:"center"}}>
      <div className="detailstitle">
        <h2>PUNTO DE VENTA: {data.name}</h2>
      </div>
      <div className="griddetailsTR">
        <div className="itemdetails1">
        <label className="labeltextarea">Nota:</label> 
          <textarea 
            className="notadetails"
            value={data.sales[0].note === null || "" ? " " : data.sales[0].note.replace(/<br\s*\/?>/gi, '\n')}
          >
          </textarea>
        </div>
        <div className="itemdetails2">
          <TextField
            label="Total: "
            value={"$"+data.sales[0].total}
            sx={{ m: 1.5, width: "100%" }}
          ></TextField>
        </div>
        <div className="itemdetails3">
          <TextField
            label="Tipo de Venta: "
            value={data.sales[0].saletype}
            sx={{ m: 1.5, width: "100%" }}
          ></TextField>
        </div>
      </div>
      <div className="SubTableSalesTimeReal">
        <div > 
        <div className="titletable">
          <h1>Detalles </h1>
         </div>     
          <>

          {
            data.sales[0].details.length===0?
            <><p className="sindetails">Sin Detalles en la Venta</p></>:
            <div className="new_table_v2">
              <br></br>
              <MaterialReactTable
              columns={columns}
              data={data.sales[0].details}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              // enableRowActions
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              initialState={{ showGlobalFilter: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
                      muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setdata([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderToolbarInternalActions={({ table }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table} 
                  file_name={"Productos"}
                  update_identifiers={update_identifiers}
                />
              )
              }
            />
            </div>  
          }
        </>   
        </div>
      </div>
      <br></br>  
          <Box>
          
            <Button
              style={{"backgroundColor":"red", "color":"white", "height":"40px"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
               onClick={() => {
                  dialog.close();
                } 
              }
            >
            cerrar
          </Button>
          </Box>
    </div>
  );
}
export default DETAILS_SALES_REAL_V2;