//PAQUETERIAS
import { useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
//COMPONENTES
import Rutas from "../../dataDahsboard/routes.json";
import FILTER from "../../dataDahsboard/filtros.json";
import NOTAS from "../../dataDahsboard/notas.json";
import { refresh_token } from "../../main_components/tokens/tokenrefresh.jsx";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../dashboar.css";
import "./bonus_dashboard.css";

//IMAGENES/ICONOS
import Spinner from "../../imgComponents/S.gif";
import { get_dashboard1_bonus } from "../../services/reports/dashboard";
import TableContainer from "../../main_components/Table/TableContainer";
import { calculate_total } from "../../main_components/methods_v2/auxiliary_functions";
import ViewDetail_Bonus_v2 from "./ViewDetail_Bonus_v2";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NewDashboardBonus_v2 = () => {
  var date = new Date();

  var dia = `${date.getDate()}`.padStart(2, "0");
  var mes = `${date.getMonth() + 1}`.padStart(2, "0");
  var año = date.getFullYear();

  var mesantes = Number(mes) - 1;

  if (mesantes == 0) {
    mesantes = `${12}`.padStart(2, "0");
  } else {
    mesantes = `${mesantes}`.padStart(2, "0");
  }

  const recuperarPPD = `${año}-${mes}-${dia}`;
  const recuperarPPDantes = `${año}-${mesantes}-${dia}`;

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [menssage, setMenssage] = useState("");

  const [ff, setff] = useState(recuperarPPD);
  const [fi, setfi] = useState(recuperarPPDantes);
  const [selectRutas, setselectRutas] = useState("all");
  const [productSelect, setproductSelect] = useState("");
  const [productBonus, setproductBonus] = useState("all");
  const [poductNote, setpoductNote] = useState("null");
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});


  const[sales, setsales]=useState([]);
  const[dev, setdev]=useState([]);
  const[bon, setbon]=useState([]);
  const[venreal, setvenreal]=useState([]);
  const[part, setpart]=useState([]);
  const[devc, setdevc]=useState([]);
  const[bonc, setbonc]=useState([]);


  const handleSubmit = async (event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Consultando...");
    setData([]);

    try{
      var rf_token = await refresh_token();
      if(rf_token){
        setTimeout(async() => {
          try{
            const d = await get_dashboard1_bonus(fi, ff, selectRutas, productBonus, poductNote);
            const data = d===null?[]: d.data;
            const data2 = (data.filter(dash=>dash.customer!=="Grand Total"));
            setsales(data2?.map(obj=>obj.sales).filter(d => d !== null));
            setdev(data2?.map(obj=>obj?.returns).filter(d => d !== null));
            setbon(data2?.map(obj=>obj?.bonus).filter(d => d !== null));
            setvenreal(data2?.map(obj=> obj?.real_sales).filter(d=> d !== null));
            setpart(data2?.map(obj=>obj?.participation).filter(d=> d !== null));
            setdevc(data2?.map(obj=>obj?.customers_returns).filter(d=> d !== null));
            setbonc(data2?.map(obj=>obj?.customers_bonus).filter(d=> d !== null));
            setData(data2);
            setOpen(false);
            setMenssage("");
          }catch(err){
            console.log("error", err);
            setData([]);
            setOpen(false);
            setMenssage("");
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  const handleRutas = (event) => {
    setselectRutas(event.target.value);
  };
  const handleBonus = (event) => {
    setproductBonus(event.target.value);
  };
  const handleNote = (event) => {
    setpoductNote(event.target.value);
  };


  const update_identifiers =
  [
    { customer: 'string' },
    { sales: 'number' },
    { returns: 'number' },
    { bonus: 'number' },
    { real_sales: 'number' },
    { participation: 'number'},
    { customers_returns: 'number'},
    { customers_bonus: 'number'},
  ];

  const columns = useMemo(
    () => [
      { 
        id: "customer",
        header: "Clasificación",
        accessorKey: "customer",
        Footer: () => (
          <div>
            <div>Subtotal: </div>
            <div>Gran Total: </div>
          </div>
        ),
      },
      {
        id: "sales",
        header: "Ventas",
        accessorKey: "sales",
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.sales)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(sales))
              }</div>
            </div>
          )
        }, 

      },
      {
        id: "returns",
        header: "Devoluciones",
        accessorKey: "returns",
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.returns)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(dev))
              }</div>
            </div>
          )
        },
        
      },
      {
        id: "bonus",
        header: "Bonificaciones",
        accessorKey: "bonus",
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.bonus)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(bon))
              }</div>
            </div>
          )
        },
      },
      {
        id: "real_sales",
        header: "Venta Real",
        accessorKey: "real_sales",
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.real_sales)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(venreal))
              }</div>
            </div>
          )
        },
      },
      {
        id: "participation",
        header: "Participación",
        accessorKey: "participation",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
            sx={(theme) => ({
              borderRadius: '0.25rem',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ?
            <div className="">
              {0}%
            </div>:
            <div className="">
              {cell.getValue()}%
            </div>}
          </Box>
        )},
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.participation)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{calculate_total(subtotal)}%</div>
              <div>{calculate_total(part)}%</div>
            </div>
          )
        },
      },
      {
        id: "customers_returns",
        header: "Devolución al Cliente",
        accessorKey: "customers_returns",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.customers_returns)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(devc))
              }</div>
            </div>
          )
        },
      },
      {
        id: "customers_bonus",
        header: "Bonificación al Cliente",
        accessorKey: "customers_bonus",
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.customers_bonus)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(bonc))
              }</div>
            </div>
          )
        },
      },  
    ],
    [data]
  );

  return (
    <div >
      <div>
      <Modal
          open={open}
          className="nModalNew"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga nModalNew">
              <Typography
                id="modal-modal-title"
                
                variant="h6"
                component="h2"
              ></Typography>
              <Typography
                id="modal-modal-description"
                
                sx={{ mt: 2 }}
              ></Typography>
            </div>
          </Box>
      </Modal>
      </div>
      <div className="new_table_v2">
        <div>
          <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          enableExpandAll={false}
          initialState={{ showGlobalFilter: true }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
                  muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }
          renderTopToolbarCustomActions={({ }) => (
            <div className="grid-detalles-bon">
              <div className="expen-1 color-1">
                <input
                  type="text"
                  className="fExpense"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  style={{height: "40px"}}
                  placeholder="Fecha inicial"
                  onChange={(event) => setfi(event.target.value)}
                ></input>
              </div>

              <div className="expen-2 color-2">
                <input
                  type="text"
                  className="fExpense"
                  style={{height: "40px"}}
                 
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha Final"
                  onChange={(event) => setff(event.target.value)}
                ></input>
              </div>

              <div className="expen-3 color-5 topSelect">
                <TextField
                  select
                  label="Rutas"
                  value={selectRutas}
                  sx={{ width: "100%" }}
                  onChange={handleRutas}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    native: true,
                  }}
                >
                  {Rutas.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </TextField>
              </div>
              <div className="expen-4 color-5 topSelect">
                <TextField
                  select
                  label="Bonificación/Devolución"
                  value={productBonus}
                  sx={{ width: "100%" }}
                  onChange={handleBonus}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    native: true,
                  }}
                >
                  {FILTER.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </TextField>
              </div>
              <div className="expen-5 color-5 topSelect">
                <TextField
                  select
                  label="Tipo de nota"
                  value={poductNote}
                  sx={{ width: "100%" }}
                  onChange={handleNote}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    native: true,
                  }}
                >
                  {NOTAS.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </TextField>
              </div>

              <div className="expen-8 color-5">
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Buscar
                </Button>
              </div>
            </div>
          )}
          renderDetailPanel={({ row }) => {
            return (
              <div style={{"overflowX":"hidden", width:"60%"}}>
                <ViewDetail_Bonus_v2
                  id_detail={row?.original?.customer}
                  dev={row?.original?.customers_returns}
                  bonus={row?.original?.customers_bonus}
                  datei={fi}  
                  datef={ff}
                  route={selectRutas} 
                  filtro={productBonus}
                  note={poductNote}
                />
              </div>
              
            )
          }}
          renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table} 
              file_name={"Detalles de Bonificaciones"}
              update_identifiers={update_identifiers}
            />
          )}
      />
        </div>
      </div>
    </div>
  );
};

export default NewDashboardBonus_v2;
