export const modulos = [
    {value: '',label: 'Seleccione un módulo',},
    {value: 'ACTIVOS',label: 'ACTIVOS',},
    {value: 'BANCOS',label: 'BANCOS',},
    {value: 'COLABORADORES', label: 'COLABORADORES'},
    {value: 'FINANZAS',label: 'FINANZAS',},
    {value: 'INVENTARIOS',label: 'INVENTARIOS',},
    {value: 'REPORTES',label: 'REPORTES',},
    {value: 'COMPRAS',label: 'COMPRAS',},
    {value: 'FACTURAS',label: 'FACTURAS',},
    {value: 'SOCIO DE NEGOCIOS',label: 'SOCIO DE NEGOCIOS',},
    {value: 'OPERACIONES CON UNILEVER',label: 'OPERACIONES CON UNILEVER',},
    {value: 'SOPORTE', label: 'SOPORTE'},
    {value: 'ANDROID MÓVIL',label: 'ANDROID MÓVIL',}
];

export const activos_categories = [
    {value: 'Seleccione un valor',label: 'Seleccione un valor',},
    {value: 'Congeladores',label: 'Congeladores',},
    {value: 'Marcas y modelos',label: 'Marcas y modelos',},
    {value: 'Vehículos',label: 'Vehículos',}
];

export const bancos_categories = [
    {value: 'Seleccione un valor',label: 'Seleccione un valor',},
    {value: 'Corte de caja',label: 'Corte de caja',},
    {value: 'Deudas',label: 'Deudas',},
    {value: 'Pagos', label: 'Pagos'},
    {value: 'Antiguedad de Saldos', label: 'Antiguedad de Saldos'}
];

export const colaboradores_categories = [
    {value: 'Seleccione un valor', label: 'Seleccione un valor'},
    {value: 'Empleados', label: 'Empleados'},
    {value: 'Conductores', label: 'Conductores'}, 
    {value: 'Asignación de Personal', label: 'Asignación de Personal'}
]

export const finanzas_categories = [
    {value: 'Seleccione un valor',label: 'Seleccione un valor',},
    {value: 'Cuentas contables',label: 'Cuentas Contables',}
];

export const inventarios_categories = [
    {value: 'Seleccione un valor',label: 'Seleccione un valor',},
    {value: 'Línea de productos', label: 'Línea de productos',},
    {value: 'Productos', label: 'Productos',},
    {value: 'Lista de precios', label: 'Lista de precios'},
    {value: 'Almacén de productos', label: 'Almacén de productos',},
    {value: 'Movimientos de productos', label: 'Movimientos de productos',},
    {value: 'Carga de Productos', label: 'Carga de Productos'}
];

//falta agregar categorias
export const reportes_categories = [
    {value: 'Seleccione un valor',label: 'Seleccione un valor',},
    {value: 'Detalles de ventas',label: 'Detalles de ventas',},
    {value: 'Mapa de rutas',label: 'Mapa de rutas',},
    {value: 'Reporte de ventas por ruta',label: 'Reporte de ventas por ruta',},
    {value: 'Reporte de ventas en tiempo real', label: 'Reporte de ventas en tiempo real',},
    {value: 'Reporte sumaria anual',label: 'Reporte sumaria anual',},
    {value: 'Reporte sumaria semanal',label: 'Reporte sumaria semanal',},
];


export const compras_categories = [
    {value: 'Seleccione un valor',label: 'Seleccione un valor',},
    {value: 'Consultar documentos envíados',label: 'Consultar documentos envíados',},
    {value: 'Envío de documentos',label: 'Envío de documentos',},
    {value: 'Consultar Proveedores', label: 'Consultar Proveedores'},
    {value: 'Catálogo de gastos',label: 'Catálogo de gastos',},
    {value: 'Reglas de gastos',label: 'Reglas de gastos',},
    {value: 'Gastos no deducibles',label: 'Gastos no deducibles',},
    {value: 'Gastos del extranjero',label: 'Gastos del extranjero',},   
    {value: 'Reporte de gastos',label: 'Reporte de gastos',},
    {value: 'Reporte de gastos agrupados',label: 'Reporte de gastos agrupados',},
    {value: 'Pólizas',label: 'Pólizas',},   
];

export const facturas_categories = [
    {value: 'Seleccione un valor',label: 'Seleccione un valor',},
    {value: 'Consultar conceptos',label: 'Consultar conceptos',},
    {value: 'Consultar facturas',label: 'Consultar facturas',},
    {value: 'Consultar facturas PPD',label: 'Consultar facturas PPD',},
    {value: 'Consultar impuestos',label: 'Consultar impuestos',},
    {value: 'Realizar Carta Porte',label: 'Realizar Carta Porte',},
    {value: 'Realizar complemento de pago', label: 'Realizar complemento de pago',},
    {value: 'Realizar factura',label: 'Realizar factura',},
    {value: 'Reporte de Complementos de Pago', label: 'Reporte de Complementos de Pago'}
];

export const snegocios_categories = [
    {value: 'Seleccione un valor',label: 'Seleccione un valor',},
    {value: 'Consultar descuentos',label: 'Consultar descuentos',},
    {value: 'Consultar promociones',label: 'Consultar promociones',},
    {value: 'Consultar puntos de venta',label: 'Consultar puntos de venta',},
    {value: 'Consultar sectores',label: 'Consultar sectores',},
    {value: 'Consultar socio de negocios',label: 'Consultar socio de negocios',},
    {value: 'Recuperar socio de negocios',label: 'Recuperar socio de negocios',},
    {value: 'Registro general',label: 'Registro general',}
];

export const unilever_categories = [
    {value: 'Seleccione un valor',label: 'Seleccione un valor',},
    {value: 'Reporte de bonificaciones', label: 'Reporte de bonificaciones',},
];

export const soporte = [
    {value: 'Seleccione un valor', label: 'Seleccione un valor'},
    {value: 'Centro de Ayuda', label: "Centro de ayuda"},
    {value: 'Capacitación', label: 'Capacitación'}
]

export const android_categories = [
    {value: 'Seleccione un valor',label: 'Seleccione un valor'},
    {value: 'Carga de información', label: 'Carga de información'},
    {value: 'Subir información', label: 'Subir información'},
    {value: 'Reimpresión de Tickets', label: 'Reimpresión de Tickets'},
    {value: 'Generar venta', label: 'Generar venta'},
    {value: 'Imprimir facturas', label: 'Imprimir facturas'},
    {value: 'Congeladores', label: 'Congeladores'},
    {value: 'Puntos de venta', label: 'Puntos de venta'}
];

export const severidad = [
    {value: 'Baja', label: 'Baja',},
    {value: 'Media', label: 'Media',},
    {value: 'Alta', label: 'Alta',},
];


