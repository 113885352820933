import { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { get_expense_concepts_roles, post_expense_concept, put_expense_concept } from "../../../services/expenses/expenses";

function OPERATIONS_EXPENSE_CONCEPT(props) {

  const notificationSystemRef = useRef();
  const dialog = useDialog();

  const [namecat, setnamecat] = useState(props.operation === "EDIT" ? props.row.original.catalog_name : null);
  const [roleperm, setroleperm] = useState(props.operation === "EDIT" ? props.row.original.role_approved : null);
  const [enable, setenable] = useState(props.operation === "EDIT" ? props.row.original.enable === true ? true : false : true);
  const [bandera, setbandera] = useState(true);
  const [dataroles, setdataroles] = useState([]);

  const [vnamecat, setvnamecat] = useState(false);
  const [vroleperm, setvroleperm] = useState(false);


  useEffect(() => {
    handleroles();
  }, []);

  const handleroles = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          setTimeout(async () => {
            var d = await get_expense_concepts_roles();
            setdataroles(d === null ? [] : d.data.allow_roles);
          }, 1000);
        } catch (err) {
          console.error(err);
          setdataroles([]);
        }
      } else { }
    } catch (err) {
      console.log(err);
    }
  }


  //crear un nuevo catalogo 
  const createnewexpenseconcept = async () => {

    if (namecat == null || roleperm == null) {
      setvnamecat(true);
      setvroleperm(true);
      props.alertas("Parámetros faltantes", false);

    } else {

      setbandera(false);

      var data = {
        "enable": enable,//boolean = Habilitado | Deshabilitado
        "catalog_name": namecat, //string
        "role_approved": roleperm //int
      }
      try {
        props.setmodalT(true);
        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try {
              const d = await post_expense_concept(data);
              console.log(d);
              props.alertas("Catálogo de Gastos creado con éxito", true);
              props.setmodalT(false);
              dialog.close();
              props.handleSubmit();
            } catch (err) {
              console.log("error post", err);
              catchErrorModul("Cátalogo de Conceptos de Gastos", JSON.stringify(data), JSON.stringify(err?.response === undefined ? "Token expirado" : err?.response));
              if (err.response.status === 409) {
                props.alertas("Ya existe un catálogo de conceptos con el mismo nombre", false);
              } else {
                props.alertas("No se pudo crear el catálogo de Gastos", false);
              }
              props.setmodalT(false);
              setbandera(true);
            }
          }, 1000);
        } else { }
      } catch (err) {
        console.log(err);
      }
    }
  }

  //actualizar catalogo
  const updatexpenseconcept = async () => {
    props.setmodalT(true);
    setbandera(false);
    var data = {
      "enable": enable,//boolean = Habilitado | Deshabilitado
      "catalog_name": namecat, //string
      "role_approved": roleperm //int
    }
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await put_expense_concept(props.row.original.id, data);
            console.log(d);
            props.alertas("Catálogo de Gastos actualizado con éxito", true);
            props.setmodalT(false);
            dialog.close();
            props.handleSubmit();
          } catch (err) {
            console.log("error put", err);
            catchErrorModul("Cátalogo de Conceptos de Gastos", JSON.stringify(data), JSON.stringify(err?.response === undefined ? "Token expirado" : err?.response));
            props.setmodalT(false);
            props.alertas("No se pudo actualizar el Catálogo de Gastos", false);
            setbandera(true);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  }

  function Spinner() {
    return (
      <div className="spinner-border spinner-border-sm text-light" role="status">
        <span className="sr-only">Cargando...</span>
      </div>
    );
  }

  return (
    <div style={{ "textAlign": "center" }}>
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>

        <TextField
          label="Nombre del Catálogo"
          required
          sx={{ m: 1.5, width: "100%" }}
          value={namecat}
          disabled={!bandera}
          onChange={(event) => setnamecat(event.target.value.toUpperCase())}
          InputLabelProps={{ shrink: true }}
          style={
            vnamecat === true ? { "border": "2px solid red", "border-radius": "5px" } : {}
          }
          onSelect={() => setvnamecat(false)}
          onBlur={
            namecat == null ? () => setvnamecat(true) : (event) => setvnamecat(false)
          }
        ></TextField>
      </Box>
      <p className="p_alert" style={vnamecat === true ? { "display": "block", "textAlign": "center" } : { "display": "none" }}>Completa este campo </p>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', }}>
        <TextField
          select
          label="Habilitado"
          disabled={!bandera}
          value={enable}
          InputLabelProps={{ shrink: true, }}
          SelectProps={{ native: true, }}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setenable(!enable)}
        >
          <option key={1} value={true} selected >
            {"SI"}
          </option>
          <option key={2} value={false}>
            {"NO"}
          </option>
        </TextField>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', }}>

        <TextField
          select
          required
          label="Roles permitidos"
          disabled={!bandera}
          value={roleperm}
          InputLabelProps={{ shrink: true, }}
          SelectProps={{ native: true, }}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setroleperm(parseInt(event.target.value))}
          style={
            vroleperm === true ? { "border": "2px solid red", "border-radius": "5px" } : {}
          }
          onSelect={() => setvroleperm(false)}
          onBlur={
            roleperm == null ? (event) => setvroleperm(true) : (event) => setvroleperm(false)
          }
        >
          <option key={0} value={0}>
            Selecciona un Role
          </option>
          {
            dataroles?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.codeAndName}
              </option>
            ))
          }
        </TextField>
      </Box>
      <p className="p_alert" style={vroleperm === true ? { "display": "block", "textAlign": "center" } : { "display": "none" }}>Completa este campo </p>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', }}>
        {
          bandera === true ?

            <Button
              variant="contained"
              style={{ "background-color": "green" }}
              sx={{ m: 1.5, width: "100%" }}
              onClick={() => { props.operation === "EDIT" ? updatexpenseconcept() : createnewexpenseconcept() }}
            >
              {props.operation === "EDIT" ? "ACTUALIZAR" : "REGISTRAR"}
            </Button> :
            <Button
              variant="contained"
              disabled
              style={{ "background-color": "green", "height": "35px" }}
              sx={{ m: 1.5, width: "100%" }}
            >
              <Spinner></Spinner>
            </Button>
        }
        <Button
          style={{ "background-color": "red" }}
          variant="contained"
          sx={{ m: 1.5, width: "100%" }}
          disabled={!bandera}
          onClick={() => {
            dialog.close();
          }
          }
        >
          cancelar
        </Button>
      </Box>
    </div>
  );
}

export default OPERATIONS_EXPENSE_CONCEPT;
