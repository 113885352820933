/**
 * Función para extraer y procesar los roles del usuario desde sus atributos.
 * @param {Object} attributes - Atributos del usuario obtenidos de las props.
 * @returns {Object} Objeto con claves representando cada rol y valores indicando la disponibilidad del rol.
 */
function getUserRoles(attributes) {
  if (!attributes) return {};

  // Extraer el string de roles, eliminar espacios y convertirlo en un array
  const roleString = attributes?.replace(/ /g, "");
  const rolesArray = roleString.split(",");
  console.log(roleString)

  console.log(rolesArray)
  // Definir todos los roles posibles como claves y establecer sus valores iniciales a "none"
  const roles = {
    ROLE_INVENTORY: "none",
    ROLE_EXPENSE_RULES: "none",
    ROLE_BUSINESS_PARTNER_PROMOTIONS: "none",
    ROLE_CABINET_DECAPITALIZED: "none",
    ROLE_EXPENSE_EXTRANJERO: "none",
    ROLE_PERFECT_STORE: "none",
    ROLE_COMPLEMENTO_PAGO_SHOW_FOLIO: "none",
    ROLE_BUYS_PAYMENT_ACCOUNTS: "none",
    ROLE_REPORT_NO_SALES: "none",
    ROLE_EXPENSE_VENDEDOR: "none",
    ROLE_EXPENSE_DIRECTIVOS: "none",
    ROLE_BUSINESS_PARTNER_BUSINESS_PARTNER: "none",
    ROLE_BUSINESS_PARTNER_POINT_OF_SALE: "none",
    ROLE_CABINETS: "none",
    ROLE_SALES: "none",
    ROLE_BUSINESS_PARTNER_SECTOR: "none",
    ROLE_BUSINESS_PARTNER_GENERAL_REGISTRATION: "none",
    ROLE_BUSINESS_PARTNER_RECOVER: "none",
    ROLE_BUSINESS_PARTNER_DISCOUNT: "none",
    ROLE_ASSETS: "none",
    ROLE_BUSINESS_PARTNER: "none",
    ROLE_CANCEL_INVOICE: "DENEGADO",
    ROLE_REPORT_SALES_MANAGEMENT: "DENEGADO",
    ROLE_BANK: "none",
    ROLE_FINANCE: "none",
    ROLE_BUYS: "none",
    ROLE_CONFIGURATION: "none",
    ROLE_VEHICLES: "none",
    ROLE_EXPENSE: "none",
    ROLE_EXPENSE_ADMINISTRATIVOS: "none",
    ROLE_POLICY: "DENEGADO",
    ROLE_POLICY_ACCESS: "none",
    ROLE_INVOICING: "none",
    ROLE_INVOICINGACCES: "none",
    ROLE_NOMINA: "none",
    ROLE_CARTA_PORTE: "none",
    ROLE_REPORT_SALES: "none",
    ROLE_COMPLEMENTO_PAGO: "none",
    ROLE_UNILEVER: "none",
    ROLE_ADMIN: "none",
    ROLE_EXPORT_INVOICE: "none",
    ROLE_ADDENDAS: "none",
    ROLE_HUMAN_RESOURCES: "none",
    ROLE_USER: "none",
    ROLE_INVENTORY_PRODUCTS: "none",
    ROLE_INVENTORY_MOVEMENTS: "none",
    ROLE_INVENTORY_PRODUCTS_LINE: "none",
    ROLE_SUPERVISOR: "none",
  };

  // Actualizar los roles basados en la presencia de cada uno en el array de roles del usuario
  rolesArray.forEach(role => {
    if (role in roles) {
      roles[role] = role;  // o cualquier otra lógica específica para activar el rol
    }
  });

  return roles;
}


export default getUserRoles;