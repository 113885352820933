//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { MaterialReactTable } from 'material-react-table';

//COMPONENTES

//ESTILOS

import { get_detail_sale_cashclosing } from "../../../../../services/cashclosing/cashclosing";
import { get_detail_sale_return_cashclosing } from "../../../../../services/cashclosing/cashclosing";

function DETAIL_SALE(props) {
  const [data, setData] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  
  const handle_detail_sale = async () => {
    try {
      const dt= await get_detail_sale_cashclosing(props.id);
      setData(dt.data)
      setisLoading(false)
    } catch (err) {
      setData([])
    }
  };

  const handle_detail_return_sale = async() => {
    try {
      const dt= await get_detail_sale_return_cashclosing(props.id);
      setData(dt.data)
      setisLoading(false)

    } catch (err) {
      setData([])
    }
  };

  useEffect(() => {
    if(props.typeSale==="SALE"){
      handle_detail_sale();
    }else{
      handle_detail_return_sale();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const columns = useMemo(
    () => [
      {
        header: "Linea",
        accessorKey: "row",
        size: 80
      },
      {
        header: "Código",
        accessorKey: "code",
        size: 120

      },
      {
        header: "Nombre del producto",
        accessorKey: "product_name",
        size: 80

      },
      {
        header: "Precio",
        accessorKey: "price",
        size: 80

      },

      {
        header: "Cantidad",
        accessorKey: "quantity",
        size: 80

      },
      {
        header: "Sub Total",
        size: 80,
        accessorKey: "subtotal",
      },
      {
        header: "Descuento",
        accessorKey: "discount",
        size: 80
      },
      {
        header: "Impuesto",
        accessorKey: "tax",
        size: 80
      },
      {
        header: "Total",
        accessorKey: "total",
        size: 80
      },
      {
        header: "Fecha de registro",
        accessorKey: "date_register",
        size: 80
      }
    ],

    []
  );

  return (
    <div>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnFilters
        enablePagination
        enableSorting
        enableBottomToolbar={false}
        enableTopToolbar={false}
        state={{ isLoading: isLoading }}
        muiCircularProgressPropsave_datas={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        muiTableBodyRowProps={{ hover: false }}
      />

    </div>

  );
}

export default DETAIL_SALE;
