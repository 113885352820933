import React, { useState } from 'react'
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Button, FormControlLabel, IconButton, Switch } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';
import { post_job_opportunities, put_job_opportunities } from '../../../services/humanResources/humanResources';
import { useDialog } from 'react-st-modal';
import dayjs from 'dayjs';

export const OPERATIONS_JOB = (props) => {
  const dialog = useDialog();
  const [title, settitle] = useState(props?.operation==="CREATE"?"": props?.row?.original?.title);
  const [department, setdepartment] = useState(props?.operation==="CREATE"?"": props?.row?.original?.department);
  const [employment_type, setemployment_type] = useState(props?.operation==="CREATE"?"": props?.row?.original?.employment_type);
  const [salary_range, setsalary_range] = useState(props?.operation==="CREATE"?null: props?.row?.original?.salary_range);
  const [job_description, setjob_description] = useState(props?.operation==="CREATE"?"": props?.row?.original?.job_description);
  const [requirements, setrequirements] = useState(props?.operation==="CREATE"?"": props?.row?.original?.requirements);
  const [application_deadline, setapplication_deadline] = useState(
    props?.operation==="CREATE"?null: 
        props?.row?.original?.application_deadline === "Ninguna"? null:
            dayjs(props?.row?.original?.application_deadline));
  const [contact_info, setcontact_info] = useState(props?.operation==="CREATE"?"": props?.row?.original?.contact_info);
  const [benefits, setbenefits] = useState(props?.operation==="CREATE"?"": props?.row?.original?.benefits);
  const [enable, setenable] = useState(props?.operation==="CREATE"?true: props?.row?.original?.enable);

  const [error_title, setError_title] = useState(false);
  const [error_dep, seterror_dep] = useState(false);
  const [error_type, seterror_type] = useState(false);
  const [error_contact, seterr_contact] = useState(false);
  const [error_desc, seterror_desc] = useState(false);
  const [error_req, seterror_req] = useState(false);
  const [error_ben, seterror_ben] = useState(false);
  const [show, setshow] = useState(props?.operation==="CREATE"?false:true);

    const handleSwitchChange = (event) => {
        setenable(event.target.checked);
    };
    const validation = ()=>{

        title?.trim()==='' || title===null?setError_title(true):setError_title(false);
        department?.trim()==='' || department===null?seterror_dep(true):seterror_dep(false);
        employment_type?.trim()==='' || employment_type===null?seterror_type(true):seterror_type(false);
        job_description?.trim()==='' || job_description===null?seterror_desc(true):seterror_desc(false);
        requirements?.trim()==='' || requirements===null?seterror_req(true):seterror_req(false);
        contact_info?.trim()==='' || contact_info===null?seterr_contact(true):seterr_contact(false);
        benefits?.trim()==='' || benefits===null?seterror_ben(true):seterror_ben(false);
 
        if(error_title===true || error_dep===true || error_type===true || 
            error_desc===true || error_req===true || error_contact===true || error_ben===true){
                return false;
            }
        else{
                return true;
            }
    }
    const update_job = async()=>{
        
        props?.setmodalT(true);               
        const d = validation();
        if(d===false){
            props?.alertas("Datos Faltantes", false);
            props?.setmodalT(false);
            return null;
        }

        var dat = {
            job_id: props?.row?.original?.job_id,
            title: title,
            department: department,
            employment_type: employment_type,    
            salary_range: salary_range === "" || salary_range === null ? null : salary_range,
            job_description: job_description,     
            requirements: requirements?.replace(/\n/g, '\n'),
            application_deadline: application_deadline === "" || application_deadline === null ? null : application_deadline.format("YYYY-MM-DD HH:mm:ss"),     
            contact_info: contact_info,
            benefits: benefits,
            enable: enable
        }
        try{
            const ref = await refresh_token();
            if(ref){
                setTimeout(async() => {
                    try{
                        const d = await put_job_opportunities(dat);
                        console.log(d);
                        props?.alertas("Vacante Actualizada con Éxito", true);
                        props?.setmodalT(false);
                        dialog.close();
                        props.handlesubmit();                
                    }catch(err){
                        console.log(err);
                        props?.setmodalT(false);
                        props?.alertas("Error al Actualizar la vacante", false);
                    }        
                }, 1500);
            }
        }catch(err){
            props?.setmodalT(false);                       
            console.log(err);
        }
    }

    const create_job = async()=>{
        
        props?.setmodalT(true);               
        const d = validation();
        if(d===false){
            props?.alertas("Datos Faltantes", false);
            props?.setmodalT(false);
            return null;
        }

        var dat = {
            title: title,
            department: department,
            employment_type: employment_type,    
            salary_range: salary_range === "" || salary_range === null ? null : salary_range,
            job_description: job_description,     
            requirements: requirements?.replace(/\n/g, '\n'),
            application_deadline: application_deadline === "" || application_deadline === null ? null : application_deadline.format("YYYY-MM-DD HH:mm:ss"),     
            contact_info: contact_info,
            benefits: benefits,
            enable: enable===true? null : enable
        }
        try{
            const ref = await refresh_token();
            if(ref){
                setTimeout(async() => {
                    try{
                        const d = await post_job_opportunities(dat);
                        console.log(d);
                        props?.alertas("Vacante Registrada con Éxito", true);
                        props?.setmodalT(false);
                        dialog.close();
                        props.handlesubmit();                
                    }catch(err){
                        console.log(err);
                        props?.setmodalT(false);
                        props?.alertas("Error al Registrar la vacante", false);
                    }        
                }, 1500);
            }
        }catch(err){
            props?.setmodalT(false);                       
            console.log(err);
        }
    }

    const handleSwitchChangeshow = (event) => {
        setshow(!event.target.checked);
    };
  return (
    <div>
        {
            props?.operation==="EDIT"?
            <div className='mod-edition-form-edit'>
            <div className=''>
                <FormControlLabel
                        control={
                            <Switch
                                checked={!show}
                                onChange={handleSwitchChangeshow}
                                color="primary"
                                sx={{ flexShrink: 0 }}
                            />
                        }
                        label={!show ? "Desactivar Modo Edición":"Activar Modo Edición"}
                        sx={{
                            marginRight: '8px',
                            flex: '1 1 auto',
                            minWidth: '150px'
                        }}
                    />
            </div>
        </div>
        :<></>
        }
        <div className='form-job-opp'>
            <div className='grid-job-form'>
                <div className='jobformite1'>
                    <TextField
                        label="Título de la Vacante"
                        className='text-field-disabled'
                        value={title}
                        error={error_title}
                        required
                        fullWidth
                        disabled={show}
                        helperText={error_title ? "Este campo es obligatorio" : ""}
                        sx={{ m: 1.5}}
                        onChange={(event)=>{
                            settitle(event.target.value);
                            setError_title(event.target.value.trim() === '');
                        }}
                        onBlur={()=>setError_title(title.trim() === '')}
                        InputLabelProps={{ shrink: true }}
                    ></TextField>
                </div>
                <div className='jobformite2'>
                    <TextField
                        label="Departamento"
                        value={department}
                        className='text-field-disabled'
                        required
                        fullWidth
                        disabled={show}
                        error={error_dep}
                        helperText={error_dep ? "Este campo es obligatorio" : ""}
                        sx={{ m: 1.5}}
                        onBlur={()=>seterror_dep(department.trim() === '')}
                        onChange={(event)=>{
                            setdepartment(event.target.value);
                            seterror_dep(event.target.value.trim() === '')
                        }}
                        InputLabelProps={{ shrink: true }}
                    ></TextField>
                </div>
            </div>
            <div className='grid-job-form-2'>
                <div className='jobformite3'>
                    <TextField
                        label="Tipo de Empleo"
                        value={employment_type}
                        className='text-field-disabled'
                        disabled={show}
                        required
                        error={error_type}
                        helperText={error_type ? "Este campo es obligatorio" : ""}                       
                        fullWidth
                        sx={{ m: 1.5}}
                        onBlur={()=>seterror_type(employment_type.trim() === '')}
                        onChange={(event)=>{
                            setemployment_type(event.target.value);
                            seterror_type(event.target.value.trim() === '')
                        }}
                        InputLabelProps={{ shrink: true }}
                    ></TextField>
                </div>
                <div className='jobformite4'>
                    <TextField
                        label="Rango Salarial"
                        className='text-field-disabled'
                        value={salary_range}
                        disabled={show}
                       
                        fullWidth
                        sx={{ m: 1.5}}
                        onChange={(event)=>{
                            setsalary_range(event.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                    ></TextField>
                </div>
            </div>
            <div className='grid-job-form-3'>
                <div className='jobformite5'>
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="es-mx">
                    <DatePicker
                        sx={{ m: 1.5, width: "100%"}}
                        className='text-field-disabled'                       
                        disabled={show}
                        label="Fecha Límite Postulación"
                        value={application_deadline}
                        onChange={(newValue) => setapplication_deadline(newValue)}
                        locale="es"
                        format="DD/MM/YYYY" 
                    />
                    </LocalizationProvider> 
                </div>
                <div className='jobformite6'>
                    <TextField
                        label="Información de Contacto"
                        value={contact_info}
                        required
                        fullWidth
                        className='text-field-disabled'
                        error={error_contact}
                        disabled={show}
                        helperText={error_contact ? "Este campo es obligatorio" : ""}                       
                        sx={{ m: 1.5}}
                        onBlur={()=> seterr_contact(contact_info.trim() === '')}
                        onChange={(event)=>{
                            setcontact_info(event.target.value);
                            seterr_contact(event.target.value.trim() === '')
                        }}
                        InputLabelProps={{ shrink: true }}
                    ></TextField>
                </div>
                <div className='jobformite7'>
                    <FormControlLabel
                         control={
                            <Switch
                                disabled={show}
                                checked={enable}
                                onChange={handleSwitchChange}
                                color="primary"
                                sx={{ flexShrink: 0 }}
            
                            />
                        }
                        label={enable ? "Habilitado" : "Deshabilitado"}
                        sx={{
                            marginRight: '8px',
                            flex: '1 1 auto',
                            minWidth: '150px'
                        }}
                    />
                </div>


            </div>
            <div className='grid-job-form-4'>
                <div className='jobformite8'>
                    <TextField
                        className='text-field-disabled'
                        label="Descripción del Trabajo"
                        value={job_description}
                        disabled={show}
                        required
                        error={error_desc}
                        fullWidth
                        multiline
                        helperText={error_desc ? "Este campo es obligatorio" : ""}                       
                        rows={2}
                        sx={{ m: 1.5,
                            '& .MuiInputBase-input.Mui-disabled': {
                                        color: 'black', 
                                        WebkitTextFillColor: 'black', 
                                        backgroundColor: 'transparent',
                                    }

                        }}
                        onBlur={()=> seterror_desc(job_description.trim() === '')}
                        onChange={(event) => {
                            setjob_description(event.target.value);
                            seterror_desc(event.target.value.trim() === '')
                        }}
                        InputLabelProps={{ shrink: true }}
                    ></TextField>
                </div>
            </div>
            <div className='grid-job-form-4'>
                <div className='jobformite9'>
                <TextField
                         disabled={show}
                         label="Requisitos"
                        value={requirements}
                        className='text-field-disabled'
                        required
                        fullWidth
                        multiline
                        error={error_req}
                        helperText={error_req ? "Este campo es obligatorio" : ""}                       
                        rows={3}
                        sx={{ m: 1.5}}
                        onBlur={()=>seterror_req(requirements?.trim() === '')}
                        onChange={(event) => {
                            setrequirements(event.target.value);
                            seterror_req(event.target.value.trim() === '')
                        
                        }}
                        InputLabelProps={{ shrink: true }}
                    ></TextField>
                    
                </div>
            </div>
            <div className='grid-job-form-4'>
                {/* <div className=''>
                {requirements.map((benefit, index) => (
                    <div className='jobformite10' key={index} style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            label={`Requisito ${index + 1}`}
                            value={benefit}
                            required
                            fullWidth
                            sx={{ m: 1.5 }}
                            onChange={(event) => handleBenefitChange(index, event.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                        {
                            index===0?
                            <IconButton 
                                onClick={() => addBenefitField()} 
                                sx={{ m: 1.5 }}
                                aria-label="Agregar Requisito"
                                title='Agregar Requisito'
                                disabled={requirements.length>5?true:false}
                            >
                                <AddCircleIcon/>
                            </IconButton>
                            :
                            <IconButton 
                                onClick={() => removeBenefitField(index)} 
                                sx={{ m: 1.5 }}
                                aria-label="Eliminar Requisito"
                            ><DeleteIcon/>
                            </IconButton>
                        }
                    </div>
                  ))}
                </div> */}
                <div className='jobformite10'>
                <TextField
                        label="Beneficios"
                        className='text-field-disabled'
                        value={benefits}
                        required
                        disabled={show}
                        fullWidth
                        error={error_ben}
                        helperText={error_ben ? "Este campo es obligatorio" : ""}                       
                        multiline
                        rows={3}
                        sx={{ m: 1.5}}
                        onBlur={()=> seterror_ben(benefits.trim() === '')}
                        onChange={(event) => {
                            setbenefits(event.target.value);
                            seterror_ben(event.target.value.trim() === '')
                        }}
                        InputLabelProps={{ shrink: true }}
                    ></TextField>
                </div>
            </div>
            <Box className="button-action-job-opp">
            <Button
              variant="contained"
              style={{"backgroundColor":"#1976d2"}}
              sx={{ m: 1.5, width: "25ch" }}
              disabled={show}
              onClick={()=>{props?.operation==="CREATE"?create_job():update_job()}}
            >
                {props?.operation==="CREATE"?"REGISTRAR":"ACTUALIZAR"}
            </Button>
            <Button
              style={{"backgroundColor":"white", "color":"#1976d2"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
        </div>
    </div>
  )
}
