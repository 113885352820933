//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { StickyTable, Row, Cell } from "react-sticky-table";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
//COMPONENTES
import SubTableContainer from "../SubTableContainer";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../../components_cfdi/table.css";
import "../../components_Expenses/expense.css";

//IMAGENES
import Spinner from "../../imgComponents/S.gif";
import reconsultaTable from "../../imgComponents/reconsultaTable.png";

import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { get_dashboard1_bonus3 } from "../../services/reports/dashboard";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ViewDetail_Detail_Bonus_v2({ datei,datef,route,id_detail,id_id_detail,filtro,note,pointSale,address}) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [equality, setEquality] = useState("");
  const [success_msg, setSuccess_msg] = useState("none");
  const [error_msg, setError_msg] = useState("none");
  const [Reconsulta, setReconsulta] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);



  const dialog = useDialog();

  const handleSubmit = async(event) => {
    event?.preventDefault();
    setReconsulta(true);
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_dashboard1_bonus3(id_detail?.replaceAll(' ', '_')?.replaceAll("/","-"),id_id_detail, datei, datef, route, filtro);
            const data = d===null?[]:d.data;
            setData(data);
            const cargos = data.filter((item) => item.efect === "CARGO");
            const abonos = data.filter((item) => item.efect === "ABONO");
    
            const sumCargos = cargos
              .map((cargoSum) => parseFloat(cargoSum.ammount))
              .reduce((previous, current) => {
                return previous + current;
              }, 0);
    
            const sumAbonos = abonos
              .map((abonoSum) => parseFloat(abonoSum.ammount))
              .reduce((previous, current) => {
                return previous + current;
              }, 0);
            console.log("==>cargos<==");
            console.log(sumCargos);
            console.log("==>abonos<==");
            console.log(sumAbonos);
            if (sumCargos - sumAbonos === 0) {
              setEquality("Los datos cuadran");
              console.log("Los datos cuadran");
              setSuccess_msg("");
              setError_msg("none");
            } else {
              setEquality("Los datos no cudran");
              console.log("Los datos no cudran");
              setError_msg("");
              setSuccess_msg("none");
            }
            setReconsulta(false);
          }catch(err){
            console.log(err);
            setData([]);
            setReconsulta(false);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        header: "Ticket",
        accessorKey: "ticket",
      },
      {
        header: "Nota",
        accessorKey: "note",
      },
      {
        header: "Descuento",
        accessorKey: "discount",
        muiTableBodyCellProps:{
          align: "right"
        }
      },
      {
        header: "Impuesto",
        accessorKey: "tax",
        muiTableBodyCellProps:{
          align: "right"
        }
      },
      {
        header: "Sub Total",
        accessorKey: "subtotal",
        muiTableBodyCellProps:{
          align: "right"
        }
      },
      {
        id:"total",
        header: "Total",
        accessorKey: "total",
        muiTableBodyCellProps:{
          align: "right"
        },
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
      },
    ],
    []
  );

  if (Reconsulta === false) {
    return (
      <div>
        <div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style2}>
              <div className="centermodaldescarga">
                <Typography
                  id="modal-modal-title"
                  
                  variant="h6"
                  component="h2"
                >
                  <img alt="" src={Spinner}></img>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  
                  sx={{ mt: 2 }}
                >
                  {"Eliminando detalle..."}
                </Typography>
              </div>
            </Box>
          </Modal>
        </div>
       
        <div className="">
        <div className="success-msg" style={{ display: success_msg }}>
            <h3>
              {pointSale}
              <br></br>
              {address}
            </h3>
          </div>
          <div className="error-msg" style={{ display: error_msg }}>
            <h2>
              <i className="fa fa-times-circle"></i>
              {equality}
            </h2>
          </div>
          <div className="new_table_v2">
            <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              enableStickyFooter
              // enableRowPinning
              enableColumnFilterModes
              // enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              initialState={{ showGlobalFilter: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
                      muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
            />
          </div>
          
        </div>
      </div>
    );
  } else {
    return <div className="loader"></div>;
  }
}

export default ViewDetail_Detail_Bonus_v2;
