import React, { useEffect, useState } from 'react';
import marker1 from "../../../../imgComponents/marker1.png";
import marker2 from "../../../../imgComponents/marker2.png";
import marker3 from "../../../../imgComponents/marker3.png";
import marker4 from "../../../../imgComponents/marker4.png";
import marker5 from "../../../../imgComponents/marker5.png";
import marker6 from "../../../../imgComponents/marker6.png";

export const SYMBOLOGY_MAP = (props) => {

    const [dat, setdat] = useState([
        {
            label: "Punto de Venta",
            item: 1,
            img: marker1,
            count: 0
        },
        {
            label: "Ventas",
            item: 2,
            img: marker2,
            count: 0
        },
        {
            label: "Notas",
            item: 3,
            img: marker3,
            count: 0
        },
        {
            label: "Sin Escaneo",
            item: 4,
            img: marker4,
            count: 0
        },
        {
            label: "Sin Congelador",
            item: 5,
            img: marker5,
            count: 0
        },
        {
            item: 9,
            label: "No Visitado",
            img: marker6,
            count: 0
        }
    ])
    const filter = (e) =>{
        const fil = props?.markers2?.filter(i=>i?.tipo === e?.item);
        props?.setmarkers(fil);
    }
    const count_filter = () =>{
        dat.forEach(d => {
            d.count = 0;
        });
        props?.markers?.forEach(marker => {
            const matchingItem = dat.find(d => d.item === marker.tipo);
            if (matchingItem) {
                matchingItem.count += 1;
            }
        });
    }

    useEffect(() => {
        count_filter();
    }, [props?.data, props?.datasv]);
    



 return (
    <div className='symbologic-filter'>
        <div className='symbology-items'>
            {
                dat?.map((item, index)=>(
                    <div className='symbology-grid-items-realtime' key={index}>
                        <img src={item?.img} alt='imagen' onClick={()=>filter(item)} style={{cursor:"pointer"}}></img>
                        <p>{item?.label}&nbsp;&nbsp;&nbsp;<span>{item?.count}</span></p>
                    </div>
                ))
            }
        </div>
    </div>
  )
}
