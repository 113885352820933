import { useEffect, useState } from "react";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { get_users } from "../../services/configuration/configuration";

import { Autocomplete, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDialog } from "react-st-modal";
import { put_send_email } from "../../services/supports/avisos";
import load from "../../imgComponents/iconos/loading.gif";

export const SEND_EMAIL = (props) => {


    const [userdata, setuserdata] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
   const dialog = useDialog();
    const [subject, setsubject] = useState("");
    const [msj, setmsj] = useState("");
    const [loadimg, setloadimg] =useState(false);
    
    const [wind, setwid] = useState(true);

    useEffect(() => {
      usersfc();
    }, [])
    
  const handleUserChange = (event, newValue) => {
    if (newValue && !selectedUsers.includes(newValue)) {
      setSelectedUsers([...selectedUsers, newValue]);
      
    }
  };
    
    const usersfc = async ()=>{
        try{
            props?.setmodalT(true);
            const ref = await refresh_token();
            if(ref){
                setTimeout(async() => {
                    try{
                        const d = await get_users();
                        console.log(d?.data?.users);
                        props?.setmodalT(false);
                        setuserdata(d?.data?.users ? d?.data?.users : []);
                    }catch(err){
                        console.log(err);
                        props?.alertas("Error al consultar los usuarios", false);
                        props?.setmodalT(true);
                    }
                }, 1500);
            }
        }catch(err){
            console.log(err);
        }

    }


    const deletem = (item) =>{
        const updatedUsers = selectedUsers.filter(user => user.id !== item.id);
        console.log(updatedUsers);
        setSelectedUsers(updatedUsers);
    }

    const handlesend = async() =>{
        const emailArray = selectedUsers.map(user => user.email);
        var data = {
            subject: subject,
            msj: msj,
            send: emailArray,
            pdf: props?.row?.original?.pdf
        }
        setloadimg(true);
        props?.setmodalT(true);
        try{
            const rf_t = await refresh_token();
            if (rf_t){
                setTimeout(async() => {
                    try{
                        const d = await put_send_email(data);
                        console.log(d);
                        props?.setmodalT(false);
                        props?.alertas("Correo enviado con éxito", true);
                        dialog.close();
                    }catch(err){
                        console.log(err);
                        props?.setmodalT(false);
                        props?.alertas("Error al enviar el correo", false);
                        setloadimg(false);
                    }
                }, 1000);
            }
        }catch(err){
            console.log(err);
        }
    }


 return (
    <div>
        {
            loadimg===false?
        
        <div className="form-content">
            <div className="form-secctions">
                {
                    wind===true?
                    <div className="users">
                <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', margin: '0 auto', width: '100%', marginTop: '10px', }}>

                <Autocomplete
                    id="user-autocomplete"
                    options={userdata}
                    getOptionLabel={(option) => option.fullusername}
                    style={{ width: "100%" }}
                    onChange={handleUserChange}
                    renderInput={(params, index) => (
                    <TextField
                        {...params}
                        label="Usuario"
                        required
                        InputLabelProps={{ shrink: true }}
                        size="small"
                    />
                    )}
                />
                </Box>
                <h2>Usuarios Seleccionados:</h2>
                <div className="list-users">
                    
                    {
                        selectedUsers?.map((item, index)=>(
                            <div className="user-select-send-email" key={index}>
                                <DeleteIcon onClick={()=>deletem(item)}></DeleteIcon>
                                <p>{item?.fullusername}</p>
                            </div>
                        ))
                    }
                </div>
                <br></br>
                <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', margin: '0 auto', width: '95%', marginTop: '10px', }}>
                    <Button
                        variant="contained"
                        style={{ "backgroundColor": "#1f96ca" }}
                        sx={{ width: "50%" }}
                        onClick={() => {setwid(false)}}
                    >
                        Siguiente
                    </Button>
                    <Button
                        style={{ "backgroundColor": "red" }}
                        variant="contained"
                        sx={{ width: "50%" }}
                        onClick={() => {
                            dialog.close();
                        }}
                    >
                    cancelar
                </Button>
            </Box>
                    </div>:
                    <div className="form">
                        <div className="items">
                        <TextField
                            label="Asunto"
                            required
                            value={subject}
                            sx={{ width: "100%" }}
                            onChange={(event) => setsubject(event.target.value)}
                            InputLabelProps={{ shrink: true }}
                            ></TextField>

                        </div>
                        <div className="items">
                            <TextField
                                label="Mensaje"
                                required
                                value={msj}
                                sx={{ width: "100%" }}
                                onChange={(event) => setmsj(event.target.value)}
                                InputLabelProps={{ shrink: true }}
                                multiline
                                rows={4} // Número de filas visibles por defecto
                                maxRows={10} // Número máximo de filas visibles antes de mostrar scroll
                            ></TextField>
                        </div> 

                        <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', margin: '0 auto', width: '95%', marginTop: '10px', }}>

                            <Button
                                variant="contained"
                                style={{ "backgroundColor": "#1f96ca"}}
                                sx={{ width: "33%" }}
                                onClick={() => {setwid(true)}}
                            >
                                Regresar
                            </Button>
                            <Button
                                variant="contained"
                                style={{ "backgroundColor": "green" }}
                                sx={{ width: "33%" }}
                                onClick={() => {handlesend()}}
                            >
                                Enviar
                            </Button>
                            <Button
                                style={{ "backgroundColor": "red" }}
                                variant="contained"
                                sx={{ width: "33%" }}
                                onClick={() => {
                                    dialog.close();
                                }}
                            >
                            cancelar
                        </Button>
                    </Box>                       
                    </div>
                }
                
            </div>
        </div>
        :<div className="load-sendmail">
            <img src={load} alt="loading"></img>
            <h2 id="animated-heading"> Enviando Correo ...</h2>
        </div>
        }
    </div>
  )
}
