import { useEffect, useMemo, useRef, useState } from 'react';
import "../container_v2.css"
import { CustomDialog } from "react-st-modal";
import {
    Box,
    Button,
    ListItemIcon,
    MenuItem,
} from '@mui/material';
import ReenvioCorreo from '../report_email/send_invoice';
import XML from "../../../../imgComponents/xml.png";
import CANCEL_DOCT from "../../../../imgComponents/tache.png";
import tacheCANCEL from "../../../../imgComponents/re-facturar.png";
import PDF from "../../../../imgComponents/pdf.png";
import PDFCANCEL from "../../../../imgComponents/pdfcancel.png";
import XMLCANCEL from "../../../../imgComponents/xmlcancel.png";
import EMAIL from "../../../../imgComponents/correoElectronico.png";
import { get_status, download_invoice } from '../../../../services/invoices/invoice';
import { MAP_RELATION_INVOICE } from '../../map_relation/map_relation';
import MAPREL from "../../../../imgComponents/iconos/mapa_relaciones.png";
import CANCEL_INVOICE from '../../cancel_invoice/cancel_invoice';
import { base64toBlobxml, base64toBlob } from '../../../../main_components/donwloadfuncion/download';


const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const download_dt = async (typedocumentexmlorpdf, id_invoce, fileName_document) => {
        props.setmenssage("Descargando " + typedocumentexmlorpdf);
        props.setmodalGeneral(true);
        props.setmodalT(true);

        try {
            const data = await download_invoice(
                typedocumentexmlorpdf, 
                fileName_document,
                id_invoce
            );
            
            var dt = data.data.file;
            var blob;
            if (typedocumentexmlorpdf === "xml") {
                blob = base64toBlobxml(dt);
            } else {
                blob = base64toBlob(dt);
            }
            const blobUrl = URL.createObjectURL(blob);
            const downloadLink = document.createElement("a");
            downloadLink.href = blobUrl;
            let arr = fileName_document.split("/");
            downloadLink.download = arr[4] + `.${typedocumentexmlorpdf}`;
            downloadLink.click();
            props.setmodalGeneral(false);
            props.setmodalT(false);
        } catch (err) {
            props.setmodalGeneral(false);
            props.setmodalT(false);
        }
    }

    const handle_status = async (id, uuid, cancel) => {
        props.setmenssage("Verificando estado de la factura");
        props.setmodalGeneral(true);
        props.setmodalT(true);
        props.setreconsult(true)
        try {
            var rf_token = await props.refresh_token();
            if (rf_token === true) {
                setTimeout(async () => {
                    try {
                        const dt = await get_status(id, uuid);
                        sessionStorage.setItem("faCancel", dt?.data?.cancellation_request_id);
                        alert("Estado actualizado");
                        props.setreconsult(false)
                        props.setmodalT(false);
                        props.setmodalGeneral(false);
                        props.handle_get_invoice();

                    } catch (err) {
                        alert(JSON.stringify(err.response.data));
                        props.setreconsult(false)
                        props.setmodalGeneral(false);
                        props.setmodalT(false);
                    }
                }, 1000);
            } else { }
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <div>
            {/* <MenuItem
                key={"action1"}
                style={{ display: props.row.original.cancel === true ? "none" : "" }}
                onClick={async () => {
                    props.closeMenu();
                    if (
                        sessionStorage.getItem("PERMISOCANCEL") ===
                        "ROLE_CANCEL_INVOICE"
                    ) {
                        console.log(props.row.original.rfc_business_partner)
                        sessionStorage.setItem("rfcBusqueda", props.row.original.rfc_business_partner);
                        sessionStorage.setItem("rowUuid", props.row.original.uuid_sat);
                        sessionStorage.setItem("id_cancelF", props.row.original.id);
                        props.setmodalT(true);
                        props.setmenssage("Verificando estado de la factura");
                        props.setmodalGeneral(true);
                        try {
                            const dt = await get_status(props.row.original.id, props.row.original.uuid_sat);
                            sessionStorage.setItem("faCancel", dt?.data?.cancellation_request_id);
                            props.setmodalT(false);
                            props.setmodalGeneral(false);
                            await CustomDialog(
                                <CANCEL_INVOICE
                                    idCancel={props.row.original.id}
                                    handle_get_invoice={props.handle_get_invoice}
                                    serie={props.row.original.serie}
                                    folio={props.row.original.folio}
                                ></CANCEL_INVOICE>
                                
                                ,
                                {
                                    className: "custom-modal-2",
                                    title: `Seleccione el motivo por el cual desea cancelar la factura ${props.row.original.serie}${props.row.original.folio}`,
                                    showCloseIcon: true,
                                }
                            );
                        } catch (err) {
                            alert(JSON.stringify(err?.response?.data));
                            props.setmodalGeneral(false);
                            props.setmodalT(false);
                        }

                    } else {
                        alert("No tienes permisos para cancelar");
                    }
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={CANCEL_DOCT}
                        title="Cancelar"
                        className=""
                    ></img>
                </ListItemIcon>
                Cancelar Factura
            </MenuItem> */}

            <MenuItem
                key={"action2"}
                onClick={() => handle_status(props.row.original.id, props.row.original.uuid_sat)}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={tacheCANCEL}
                        title="REVERIFICAR ESTADO DE LA FACTURA"
                    ></img>
                </ListItemIcon>
                Actualizar Estado
            </MenuItem>
            <MenuItem
                key={"action3"}
                onClick={() => download_dt("xml", props.row.original.id, props.row.original.filename)
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={XML}
                        title="Descargar XML"
                        className=""
                    ></img>
                </ListItemIcon>
                Descargar XML
            </MenuItem>
            <MenuItem
                key={"action4"}
                onClick={() => download_dt("pdf", props.row.original.id, props.row.original.filename)
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={PDF}
                        title="Descargar PDF"
                        className=""
                    ></img>
                </ListItemIcon>
                Descargar PDF
            </MenuItem>
            <MenuItem
                key={"action5"}
                style={{ display: props.row.original.cancel === false ? "none" : "" }}
                onClick={() => download_dt("xml", props.row.original.id, props.row.original.filename_cancel)}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={XMLCANCEL}
                        className=""
                        title="Descargar XML  "
                    ></img>
                </ListItemIcon>
                Descargar Acuse de cancelación (XML)
            </MenuItem>
            <MenuItem
                key={"action6"}
                onClick={() => download_dt("pdf", props.row.original.id, props.row.original.filename_cancel)}
                style={{ display: props.row.original.cancel === false ? "none" : "" }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={PDFCANCEL}
                        className=""
                        title="Descargar PDF"
                    ></img>
                </ListItemIcon>
                Descargar Acuse de cancelación (PDF)
            </MenuItem>
            <MenuItem
                key={"action7"}
                onClick={async () => {
                    props.closeMenu();
                    sessionStorage.setItem("FACTURAMANDAR", props.row.original.uuid_sat);
                    await CustomDialog(
                        <ReenvioCorreo
                            report={false}
                            id={props.row.original.id}
                            uuid={props.row.original.uuid_sat}
                        ></ReenvioCorreo>,
                        {
                            className: "custom-modal",
                            title:
                                'Nota: SEPARA LOS CORREOS ELECTRÓNICOS A TRAVÉS DE UNA "," SI DESEAS ENVIAR MÁS DE UN CORREO ELECTRÓNICO',
                            showCloseIcon: true,
                        }
                    );
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={EMAIL}
                        className=""
                        title="Envíar"
                    ></img>
                </ListItemIcon>
                Envíar Correo Electrónico
            </MenuItem>

            <MenuItem
                key={"action8"}
                onClick={async () => {
                    props.closeMenu();
                    await CustomDialog(
                        <MAP_RELATION_INVOICE row={props.row} setmodalT={props.setmodalT}></MAP_RELATION_INVOICE>,
                        {
                            className: "modal-mapa-rel",
                            title: 'Mapa de relaciones',
                            showCloseIcon: true,
                            isCanClose: false,
                        }
                    )
                }
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={MAPREL}
                        className="mapa_icono"
                        title="Mapa de Relaciones"
                    ></img>
                </ListItemIcon>
                Mapa de Relaciones
            </MenuItem>

        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
