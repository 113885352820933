//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
import TextField from "@mui/material/TextField";
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
import "./freez.css"; 
//IMAGENES/ICONOS
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { pagination } from "../../main_components/pagination/pagination";
import { ConfirmDesc } from "./utilities/ConfirmDesc";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { MOVAGROUP } from "./utilities/movagroup";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./utilities/render_row_action_menuItems";
import { HistoryFrezz_v2 } from "./utilities/HistoryFrezz_v2";
import CreateFreez from "./utilities/CreateFreez";
import CREATE from "../../imgComponents/create.png";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";
import { UPLOAD_FILE_CABINETS } from "./utilities/upload_file";
import BackupIcon from '@mui/icons-material/Backup';

const Freezer_v2 = () => {

  //indices inicial y final

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [lote, setlote]=useState(false);

  const [open, setopen]=useState(false);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [descapitalizado, setdescapitalizado]=useState(false);
  var entrada = 0;
  const [viewcomodatado, setviewcomodatado]=useState(false);
  
  
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});

  const [selectedmov, setselectedmov]=useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [total_rows, settotal_rows] = useState("");

  const update_identifiers =
  [
    { lending: 'string' },
    { economic_number: 'string' },
    { fixed_asset: 'String' },
    { serial_number: 'string' },
    { brand: 'string' },
    { model: 'string' },
    { production_year: 'string' },
    { capitalization_date: 'string' },
    { note: 'string' },
    { warehouse: 'string' },
    { pos_code: 'string' },
    { pos_name: 'string' },
    { route: 'string' },
    { employee: 'string' },
    { state: 'string' }
  ];

const [viewmovgral, setviewmovgral]= useState(
  localStorage.getItem("viewmovgral") === null 
  ? true
  : localStorage.getItem("viewmovgral") === "true"
  ? true
  : false
);

const [query_filters, setquery_filters] = useState({});
const [query_filters_dt, setquery_filters_data] = useState("");

var permisosUsuario = sessionStorage.getItem("roles_user");
var permisoUsuarioReplace = permisosUsuario?.replace(/ /g, "");
var permisos = permisoUsuarioReplace?.split(",");
var permisoconfig = permisos?.filter((x)=>x === "ROLE_CONFIGURATION");

function alertas(msj,status){
  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const reconsultar=(n)=>{
  if(n===true){
    setmodalT(true);  
  }else{
    setmodalT(false);
  } 
}
const handleSubmit = async (query_filters_flag, query_filters_data) => {

  if(entrada===0){
    setmodalGeneral(true);
    entrada=entrada+1;
  }
  setData([]);
  setopen(true);
  setmenssage("Buscando Congeladores");
  setmodalT(true);

  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        if(query_filters_flag){
          const d = await pagination(3, "cabinets");
          setquery_filters(query_filters_data);
        }else{
          var prueba = await pagination(3, "cabinets");
          var ndt = update_null_identifiers(prueba, update_identifiers);
          setData(ndt === null ? [] : ndt.filter(item=>item.id_cabinets_state_fk!=16));
          setData2(ndt === null ? [] : ndt.filter(item=>item.id_cabinets_state_fk==16));
          setopen(false);
          setmodalGeneral(false);
          setmodalT(false);
        }
      }, 1000);
    }else{
      setmodalT(false);
      setmodalGeneral(false);
    }
  } catch (error) {
    console.error(error);
    setopen(false);
    setmodalGeneral(false);
    setmodalT(false);
  }
};

const columns = useMemo(
  () => [
    {
      id: "lending",
      header:"Comodatado",
      accessorKey: "lending",
      accessorFn:(d)=>{
        return d.lending===true?"Si":"No"
      }
      ,
      filterFn: "startsWith"

    },
    {
      header: "Número Económico",
      accessorKey: "economic_number",
      id: "economic_number"

    },
    {
      header: "Activo Fijo",
      accessorKey:"fixed_asset",
      id: "fixed_asset",
      filterFn: "startsWith"
    },
    {
      header: "Número Serie",
      accessorKey:"serial_number",
      id:"serial_number",
      filterFn: "startsWith"
    },
    {
      header: "Marca",
      accessorKey: "brand",
      id: "brand",
      filterFn: "startsWith"
    },
    {
      header: "Modelo",
      accessorKey: "model",
      id:"model",
      filterFn: "startsWith"
    }, 
    {
      header: "Producción",
      accessorKey: "production_year",
      id: "production_year",
      filterFn: "startsWith"
    },
    {
      header:"Capitalización",
      accessorKey: "capitalization_date",
      id: "capitalization_date",
      filterFn: "startsWith"
    },
    {
      header:"Nota",
      accessorKey: "note",
      id: "note",
      filterFn: "startsWith"
    },
    {
      header: "Almacén",
      accessorKey: "warehouse",
      id: "warehouse",
      filterFn: "startsWith"
    },
    {
      header: "Código",
      accessorKey:"pos_code",
      id: "pos_code",
      filterFn: "startsWith"
    },
    {
      header: "Punto de Venta",
      accessorKey: "pos_name",
      id:"pos_name",
      filterFn: "startsWith"
    },
    {
      header: "Ruta",
      accessorKey: "route",
      id:"route",
      filterFn: "startsWith"
    },
    {
      header: "Empleado",
      accessorKey:"employee",
      id:"employee",
      filterFn: "startsWith"
    },
    {
      header: "Estado",
      accessorKey: "state",
      id:"state",
      filterFn: "startsWith"
    },
    ],
    [
      selectedRows,
      selectedmov,
      descapitalizado,
      viewcomodatado,
      lote,
      viewmovgral
    ]
);


function handledescap(){
  const array = selectedRows.map(item=>item.id);  
  if(array.length===0){
    alertas("No ha seleccionado Congeladores", false);
  }

  CustomDialog(               
    <div> 
     <ConfirmDesc setSelectedRows={setSelectedRows} detalles={selectedRows} array={array} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></ConfirmDesc>
    </div>,
  {
    className: "modalTItle",
    title:"Confirmar Descapitalización",
    showCloseIcon: true,
    isCanClose:false,
  })
}


function viewdesc(){

  if(descapitalizado===true){
    setdescapitalizado(false);
    setviewcomodatado(false);
  }else
  if(descapitalizado===false){
    setlote(false);
    setRowSelection([]);
    setdescapitalizado(true);
    setviewcomodatado(false);
  }else
    if(descapitalizado==="comodato"){
      setviewcomodatado(true);
      if(descapitalizado===false){
        setData3(data.filter(item=>item.lending===true));
      }else{
        setData3(data2.filter(item=>item.lending===true));
      }
    }
}

function handlelote(){
  if(lote===false){

    const d = data.filter(item=>item.id_warehouse_fk===11 && item.id_cabinets_state_fk===5);
    const e = data.filter(item=>item.id_warehouse_fk===13 && item.id_cabinets_state_fk===11);
    const arr = [...d, ...e];

    setData4(arr);
    setlote(true);
  }else{
    setlote(false);
  }
}


useEffect(() => {
  if(viewmovgral===false){
    setselectedmov([]);
  }
}, [viewmovgral])



useEffect(()=>{
  localStorage.setItem("viewmovgral", viewmovgral);
},
[viewmovgral]);

const movagroup = async() =>{

  const objetosFilasSeleccionadas = Object.keys(rowSelection).map((filaId) => {
    const filaSeleccionada = data.find((fila) => fila.id === parseInt(filaId));
    return filaSeleccionada ?  filaSeleccionada : null;
  }).filter(Boolean);



  await CustomDialog(               
    <div> 
     <MOVAGROUP setselectedmov={setRowSelection} detalles={objetosFilasSeleccionadas} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></MOVAGROUP>
    </div>,
  {
    className: "modalTItle",
    title:"Movimiento Agrupado",
    showCloseIcon: true,
    isCanClose:false,
  })
}

const rowselect = (row) =>{

  const objetosFilasSeleccionadas = Object.keys(rowSelection).map((filaId) => {
    const filaSeleccionada = data.find((fila) => fila.id === parseInt(filaId));
    return filaSeleccionada ?  filaSeleccionada : null;
  }).filter(Boolean);

  if(row?.original?.id_warehouse_fk===null || row?.original?.id_warehouse_fk === 9){
    return false;
  }else{
    if(objetosFilasSeleccionadas.length===0){
      return true;
    }else 
      if(objetosFilasSeleccionadas[0]?.id_warehouse_fk===row.original.id_warehouse_fk){
        return true;
      }else{
        return false;
    }
  }
}
const handleStateChange = (newState) => {
  const filteredRowsCount = newState.filteredRowModel
  settotal_rows(filteredRowsCount);
};

const handle_success_filter = (a, b) => {
  handleSubmit(a, b);
  notificationSystemRef.current.addNotification({
    message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
    level: "success",
    position: "br",
    autoDismiss: 10,
  });
}

useEffect(() => {
  if (total_rows === "") {
  } else {
    const getCurrentPath = () => {
      const search = window.location.search;
      const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
      return pathWithoutQuestionMark;
    };

    const pathWithoutQuestionMark = getCurrentPath();
    setquery_filters_data(pathWithoutQuestionMark)
  }
}, [total_rows])

const [disabledglobal, setdisabledglobal]=useState(false);


useEffect(() => {
  const handleResize = () => {
    if(window.innerWidth < 1000 && window.innerWidth > 720){
      setdisabledglobal(true);
    }else{
      setdisabledglobal(false);
    }
  };
  window.addEventListener('resize', handleResize);
  handleResize();
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

  const method_upload = async() =>{
    await CustomDialog(
        <UPLOAD_FILE_CABINETS
          setmodalT={setmodalT}
          alertas={alertas}
          handleSubmit={handleSubmit}
        ></UPLOAD_FILE_CABINETS>,
    {
        className: "custom-modal-3",
        title: "Registrar Congeladores",
        showCloseIcon: true,
    })
  }

  return (
    <>
    <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR CONGELADORES", path: null},
                  {label: "MOVIMIENTOS DE CONGELADORES", path: "/consult_warehouse_movement_cabinets_v2"}, 
                ]
              }
            cards_dashboard={true}
            total_rows={total_rows !== "" ? total_rows : 0}
            filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
            visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
            total={data?.length}
      ></BREADCRUMBS>
      
<div className="new_table_v2">
  <div >         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
    
    <>     
      <div className="containerselectfrezz">
        <div className="selectfres_upload">
            <button className="button-upload-file-gsheets" onClick={()=>method_upload()}>
              <BackupIcon></BackupIcon>
            </button>
        </div>
        <div className="selectfres">
            <TextField
                  select
                  label="Congeladores"
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={viewdesc}
                >
                  <option key={1} value={true}>
                      {"Congeladores Capitalizados"}
                  </option>
                  
                  <option key={2} value={false}>
                      {"Congeladores Descapitalizados"}
                  </option>

                  <option key={3} value={"comodato"}>
                    {"Congeladores en Comodato"}
                  </option>
             </TextField>
        </div>
        <div className="selectfres2" style={permisoconfig.length===1?{"display":"block"}:{"display":"none"}}>
        
        {
          descapitalizado===false?
          lote===false?
            <button 
              className="button" 
              onClick={
                ()=>{
                  Object.keys(rowSelection).length>0? 
                  alertas("No puede realizar esta acción", false) : 
                  handlelote()
                }
              }>Descapitalización</button>:
            <button 
              className="button-cancel" 
              onClick={handlelote}
            >Cancelar
            </button>
          :<></>
        }
        </div>
        <div className="selectfres3">
          {
            descapitalizado===false?
            lote===false?<></>:
            <button 
              className="button-confirm" 
              onClick={handledescap}
            >Descapitalizar Lote
            </button>:
            <></>
          }
        </div>
          {
            Object.keys(rowSelection).length > 0 ?
              lote === false ? 
                <div className="selectfres3">
                  <button className="button" onClick={()=>movagroup()}>Movimiento Agrupado</button>      
                </div>: <></>: <></>
          }
      </div>

      <MaterialReactTable
        columns={columns}
        data={
          lote===true?data4:viewcomodatado===true?data3:descapitalizado===false?data:data2
        }
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection = {
          (row) => rowselect(row)
        }
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        onStateChange={handleStateChange}
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            Header: () => (
              <div>
                <img
                  title="Registrar Congelador"
                  src={CREATE}
                  style={{"cursor":"pointer"}}
                  className=""
                  onClick={async () => {
                    const result = await CustomDialog(
                      <CreateFreez
                              handleSubmit={handleSubmit} 
                              alertas={alertas}
                              reconsultar={setmodalT}
                          ></CreateFreez>,
                      {
                        className: "custom-modal-3",
                        title: "Registrar Congelador",
                        showCloseIcon: true,
                        isCanClose:false,
                      }
                    );
                  }}
              ></img>
              </div>
            ),
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
            size: 70
          },
          'mrt-row-expand': {
            enableHiding: true,
            size: 75
          },
          'mrt-row-select': {
            enableHiding: true             
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderDetailPanel={({ row }) => {
          return(
            <HistoryFrezz_v2
              reconsultar={setmodalT}
              alertas={alertas}
              defaultPageSize={3}
              showPagination={false}
              id={row?.original?.id}
            />
            
            )     
        }}

        renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
              closeMenu={closeMenu}
              setmodalT={setmodalT}
              setmenssage={setmenssage}
              setmodalGeneral={setmodalGeneral}
              row={row}
              handleSubmit={handleSubmit}
              refresh_token={refresh_token}
              alertas={alertas}
              descapitalizado={descapitalizado}
              setRowSelection={setRowSelection}
            />
          ]}
        renderToolbarInternalActions={({ table, index }) => (
           <RENDER_TOOLBAR_INTERNAL_ACTIONS
             table={table} 
             file_name={"Consultar Congeladores"}
             update_identifiers={update_identifiers}
             settotal_rows={settotal_rows}
              query_filters={query_filters}
              query_filters_dt={query_filters_dt}
              handle_get_consult={handleSubmit}
              key={index}
              handle_success_filter={handle_success_filter}
              share_filter={true}
              total_rows={total_rows !== "" ? total_rows : 0}
           />
         )
        }
      />
          </>   
          </div>
        </div>
    </>
  );
}

export default Freezer_v2;
