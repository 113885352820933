import React, { Fragment, useEffect, useState, useRef } from "react";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import JsPDF from "jspdf";
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect, useResizeColumns
} from "react-table";
import { Table, Row, Col, Button, CustomInput } from "reactstrap";
import { Filter, DefaultColumnFilter } from "../../components_cfdi/filters";
import ICON_CSV from "../../imgComponents/icons/csv.png";
import { Exportgs } from "./Exportgs";
import ICON_PDF from "../../imgComponents/icons/pdf.png";
import "./tablecontainer.css";
import "jspdf-autotable";
import "../../componentsNewDashboard/dashboar.css";
import { INVOICE_COMPRIMED } from "./export/invoice_comprimed";
import ICON_ZIP from "../../imgComponents/icons/zip.png";
import { CustomDialog } from "react-st-modal";
import CUSTOMZEDMENUS from "./button_group";

const multiSelectFilterFn = (rows, index, filterValue) => {
  if (filterValue && Array.isArray(filterValue) && filterValue.length > 0) {
    return rows.filter((row) => {
      const rowValue = row.values[index];
      return filterValue.some((filter) => filter.value === rowValue);
    });
  } else {
    return rows;
  }
};

const TableContainer = ({
  paginado,
  consultas,
  exportar,
  exportariconos,
  columns,
  data,
  renderRowSubComponent,
  nametable,
  Gmodal,
  minfilas,
  Grantotal,
  GrantotalCabeceras,
  hpdf,
  v,
  load_table,
  title,
  zipfacturas,
  dataextra,
  invoicepago,
  sinval,
  report_old_balances,
  pdfconfig,
  columnextra,
  function_ext,
  function_ext2,
  function_ext3,
  function_ext4,
  function_ext5,
  fullscreen_table
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    footerGroups,
    canNextPage,
    rows,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    exportData,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        includes: multiSelectFilterFn,
      },
      initialState: {
        hiddenColumns: columns
          .filter((col) => col.show === false)
          .map((col) => col.id),
        pageIndex: 0,
        pageSize: minfilas === true ? 6 : 10,
      },
      getExportFileBlob,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useExportData,
    useResizeColumns,
    useRowSelect
  );

  //gran total para table container se indica en true si se requiere

  const [counter, setcounter] = useState(0);

  const [datagg, setdatagg] = useState([]);
  const [dataggf, setdataggf] = useState([]);


  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Función para actualizar las dimensiones de la ventana cuando cambie el tamaño de la ventana
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Agregar un listener de cambio de tamaño de ventana
    window.addEventListener('resize', handleResize);

    // Limpieza del listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


console.log(useExportData)

  useEffect(() => {
    setTimeout(() => {
      handlegrantotal();
    }, 2000);
  }, [page.length, columns, counter, rows.length, page[0]?.original]);

  const handlegrantotal = () => {
    var cant = columns.filter((item) => item.show !== false);
    var gtt = new Array(cant.length).fill(0);
    var gttf = new Array(cant.length).fill("");
    for (var i = 0; i < page.length; i++) {
      for (var j = 0; j < page[i].cells.length; j++) {
        if (GrantotalCabeceras?.includes(cant[j]?.Header)) {
          gtt[j] = "";
          gttf[j] = "no incluido";
        } else {
          if (typeof page[i].cells[j].value === "string" && page[i].cells[j].value.startsWith("$")) {
            const newValue = parseFloat(
              page[i].cells[j].value.replace(/[$,]/g, "")
            );
            gtt[j] += newValue;
            gttf[j] = "moneda";
          } else if (
            typeof page[i].cells[j].value === "string" &&
            page[i].cells[j].value.endsWith("%")
          ) {
            const newValue = parseFloat(
              page[i].cells[j].value.replace(/[%,]/g, "")
            );
            gtt[j] += newValue;
            gttf[j] = "porcentaje";
          } else if (
            !isNaN(page[i].cells[j].value) &&
            typeof parseFloat(page[i].cells[j].value) === "number"
          ) {
            const newValue = parseFloat(page[i].cells[j].value);
            gtt[j] += newValue;
            gttf[j] = "numerico";
          } else {
            gtt[j] = "";
            gttf[j] = "text";
          }
        }
      }
    }

    var suma = 0.0;
    if (report_old_balances === true) {
      gtt.map((item) => {
        if (item === "") { } else { suma = suma + item; }
      })
      gtt[1] = suma
      gttf[1] = "moneda"
    }


    console.log(gtt);
    console.log(gttf);

    setdatagg(gtt);
    setdataggf(gttf);
  };

  const [gs, setgs] = useState([]);
  const handleshet = () => {
    console.log(exportData("gs", false))
    exportData("gs", false);
  };

  function getExportFileBlob({ columns, data, fileType, fileName }) {
    var totals2 = new Array(page[0]?.length).fill(0);

    console.log(data)

    if (fileType === "gs") {
      var data2 = data.slice(0, page.length);

      var totals = new Array(data2[0].length).fill(0);

      for (let i = 0; i < data2.length; i++) {
        for (let j = 0; j < data2[i].length; j++) {
          if (typeof data2[i][j] === "string" && data2[i][j].startsWith("$")) {
            const newValue = parseFloat(data2[i][j].replace(/[$,]/g, ""));
            data2[i][j] = isNaN(newValue) ? 0 : newValue;
            totals[j] += isNaN(newValue) ? 0 : newValue;
          } else {
            totals[j] = "vacio";
          }
        }
      }
      const headerNames = columns.map((col) => col.exportValue);

      const totalsRow = totals.map((total) =>
        total === "vacio" ? " " : total?.toFixed(2)
      );
      data2.push(totalsRow);
      const csvStringWithTotals = Papa.unparse({
        fields: headerNames,
        data: data2,
      });
      console.log(csvStringWithTotals);
      setgs(csvStringWithTotals);
    } else if (fileType === "csv") {
      var totalscsv = new Array(columns.length).fill(0);
      var longitud = data.slice(0, page.length);

      for (let i = 0; i < longitud.length; i++) {
        for (let j = 0; j < longitud[i].length; j++) {
          if (
            typeof longitud[i][j] === "string" &&
            longitud[i][j].startsWith("$")
          ) {
            const newValue = Number(data[i][j].replace(/[$,]/g, ""));
            console.log(newValue)
            longitud[i][j] = newValue === undefined ? 0 : newValue;
            totalscsv[j] += newValue;
          } else {
            totalscsv[j] = "vacio";
          }
        }
      }

      const headerNames = columns.map((col) => col.exportValue);
      const totalsRow = totalscsv.map((total) =>
        total === "vacio" ? "" : total
      );
      longitud.push(totalsRow);
      const csvString = Papa.unparse({ fields: headerNames, data: longitud });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(
        new Blob([csvString], { type: "text/csv" })
      );
      const tiempoTranscurrido = Date.now();
      const hoy = new Date(tiempoTranscurrido);
      hoy.toUTCString();
      link.download = nametable ? nametable : "data.csv";
      link.click();

      return URL.revokeObjectURL(link.href);
    } else if (fileType === "xlsx") {
      // XLSX example

      const header = columns.map((c) => c.exportValue);
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[index];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);
      return false;
    }
    if (fileType === "pdf") {
      const headerNames = columns.map((column) => column.exportValue);

      if (report_old_balances === true) {
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          for (let j = 0; j < item.length; j++) {
            if (item[j] === "$0.00") {
              item[j] = "";
            }
          }
        }
        datagg.map((item, index) => {
          if (item === "") {
          } else {
            datagg[index] = Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            })?.format(item)
          }
        })
        datagg[0] = "Saldo";
      }

      var pdfexport = [
        ...data,
        ...[datagg?.slice(hpdf === undefined ? 0 : hpdf)],
      ];

      const doc = new JsPDF(v === true ? "p" : pdfconfig ? pdfconfig.orientation : "ledger");
      const pageTitle = pdfconfig?.title === undefined ? "" : pdfconfig?.title

      //Centrar title
      const docWidth = doc.internal.pageSize.getWidth();
      const titleWidth = doc.getStringUnitWidth(pageTitle) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const centerX = (docWidth - titleWidth) / 2;

      doc.text(pdfconfig?.title_center === undefined ? "" : pdfconfig?.title_center, v === true ? 35 : pageTitle === "Antigüedad de Facturas Pagadas" ? centerX + 15 : centerX, 10);

      if (report_old_balances === true) {
        doc.setLineWidth(0.5); // Establecer el grosor de la línea
        doc.line(10, 12, doc.internal.pageSize.getWidth() - 15, 12); // Dibuja la línea horizontal
        doc.setFontSize(12);
        doc.text(pdfconfig.title2, 10, 19);
        doc.text(`Del período: ${pdfconfig.fi} al ${pdfconfig.ff}`, 10, 25);
        doc.text("Moneda: Pesos", 10, 31);
        doc.text("Tipo cambio: 1.000000", 90, 31);
      }

      // Calcula el ancho de la página actual
      var pageWidth = doc.internal.pageSize.width || 0; // Asegúrate de que pageWidth sea un número válido
      // Calcula el ancho de la tabla
      var tableWidth = doc.autoTable.previous.finalX || 0; // Asegúrate de que tableWidth sea un número válido
      // Calcula la posición horizontal para centrar la tabla
      var startX = (Number(pageWidth.toFixed(0)) - Number(tableWidth.toFixed(0))) / 2;

      var headStyles = {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 11
      }
      var bodyStyles = {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 11
      }
      doc.text(pageTitle, v === true ? 35 : 10, 10);
      pageWidth = doc.internal.pageSize.width || 0;
      tableWidth = doc.autoTable.previous.finalX || 0;
      var startX =
        (Number(pageWidth.toFixed(0)) - Number(tableWidth.toFixed(0))) / 2;


      if (columnextra === true) {
        headerNames.push("            ");
      }

      doc.autoTable({
        head: [headerNames.slice(0, v === true ? sinval === true ? 13 : 5 : 13)],
        body: pdfexport,
        headStyles: report_old_balances === true ? headStyles : "auto",
        bodyStyles: report_old_balances === true ? bodyStyles : "auto",
        margin: { top: report_old_balances === true ? 36 : 15, left: v === true ? sinval === true ? 10 : 35 : 10 },
        styles: {
          minCellHeight: 5,
          halign: "left",
          valign: "top",
          fontSize: v === true ? 9 : 8.2,
          cellWidth: headerNames.length >= 10 ? 20 : null,
          lineColor: v === true ? [0, 0, 0] : 0,
          lineWidth: v === true ? 0.5 : 0.1,
          cellPadding: columnextra === true ? 1.5 : sinval === true ? 3 : 1,
          cellStyles: {
            whiteSpace: "normal",
          },
        },
        columnStyles: {
          0: { cellWidth: headerNames[0] === "Código" ? 20 : "auto" },
          1: { cellWidth: headerNames[1] === "Producto" ? 75 : headerNames[1] === "Nombre del producto" ? 30 : "auto" },
          2: { cellWidth: headerNames[2] === "Venta Neta" ? 14 : headerNames[2] === "Descripción" ? 30 : "auto" },
          3: { cellWidth: headerNames[3] === "Inv. Final" ? 14 : "auto" },
          4: { cellWidth: headerNames[4] === "Entrega" ? 14 : "auto" },
          5: { cellWidth: headerNames[3] === "Inv. Final" ? 14 : "auto" },
          6: { cellWidth: headerNames[4] === "Entrega" ? 14 : "auto" },
          7: { cellWidth: headerNames[7] === "Minima" ? 16 : "auto" },
        },
      });

      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text(`Pag. ${i}`, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10);
      }
      var pdfBlob = doc.output("blob");
      window.open(URL.createObjectURL(pdfBlob));

      return false;
    }

    return false;
  }


  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };
  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };



  const menuItemsData = [
    {
      icon: <img src={ICON_CSV} className="icon-export-pdf" alt="" id="" title="Exportar CSV" onClick={() => { exportData("csv", false); }}></img>,
    },
    {
      icon: <img src={ICON_PDF} className="icon-export-pdf" alt="" id="" title="Exportar PDF" onClick={() => { exportData("pdf", false); }}></img>,
    },
    {
      icon: <Exportgs data={data} page={page} handleshet={handleshet} gs={gs} nametable={nametable}></Exportgs>,
    },
    {
      icon: <img src={ICON_ZIP} style={{ display: zipfacturas === true ? "inline-block" : "none" }} className="icon-export-pdf" alt="" id="" title="ZIP" onClick={async () => { await CustomDialog(<INVOICE_COMPRIMED page={page}></INVOICE_COMPRIMED>, { className: "modalmini", title: "Comprimir facturas", showCloseIcon: true, isCanClose: false, }); }}></img>,
    },
    {
      icon: function_ext,
    },
    {
      icon: function_ext2,
    },
    {
      icon: function_ext3,
    },
    {
      icon: function_ext4,
    },
    {
      icon: function_ext5,
    },

  ];


  return (
    <>
      <div className="containerExportC">
        <div>
          <CUSTOMZEDMENUS data={menuItemsData}></CUSTOMZEDMENUS>
        </div>
      </div>
      <Fragment>
        <div className={page?.length <= 10 ? fullscreen_table === false ? "tableFixHead2-0" : "tableFixHead" : fullscreen_table === false ? "tableFixHead2-0" : "tableFixHead"}>
          <Table className="tableposition" bordered hover {...getTableProps()} >
            <thead className="fijoHeader">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps({
                        className: column.className,
                        style: {
                          width: column.width,
                          backgroundColor: column.backgroundColor,
                          whiteSpace: column.whiteSpace,
                          maxWidth: column.maxWidth,
                          minWidth: column.width,
                        },
                      })}
                    >
                      <div {...column.getSortByToggleProps()} title={"Ordenar"}>
                        {column.render("Header")}
                        {generateSortingIndicator(column)}
                      </div>
                      <div
                        {...column.getResizerProps()}
                        className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                      />
                      <Filter column={column} />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <div
              className="sk-fading-circle"
              style={{ display: load_table === true ? "" : "none" }}
            >
              <div className="sk-circle1 sk-circle"></div>
              <div className="sk-circle2 sk-circle"></div>
              <div className="sk-circle3 sk-circle"></div>
              <div className="sk-circle4 sk-circle"></div>
              <div className="sk-circle5 sk-circle"></div>
              <div className="sk-circle6 sk-circle"></div>
              <div className="sk-circle7 sk-circle"></div>
              <div className="sk-circle8 sk-circle"></div>
              <div className="sk-circle9 sk-circle"></div>
              <div className="sk-circle10 sk-circle"></div>
              <div className="sk-circle11 sk-circle"></div>
              <div className="sk-circle12 sk-circle"></div>
              <h2>Agrupando lista de precios...</h2>
            </div>

            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps({
                              className: cell.column.className,
                            })}
                          >
                            {" "}
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded && (
                      <tr>
                        <td colSpan={visibleColumns.length}>
                          {renderRowSubComponent(row)}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
            {Grantotal === true ? (
              <tbody className="totalesfijo">
                <tr>
                  {datagg.map((item, index) => { })}
                  {datagg.map((item, index) => (
                    <td
                      className={
                        dataggf[index] === "moneda" ? "right_data" : ""
                      }
                    >
                      {index === 0
                        ? "Gran Total"
                        : item === "NaNNaN" || item === "NaN"
                          ? null
                          : item === ""
                            ? ""
                            : dataggf[index] === "moneda"
                              ? Intl.NumberFormat("es-MX", {
                                style: "currency",
                                currency: "MXN",
                              })?.format(item)
                              : dataggf[index] === "porcentaje"
                                ? item > 100
                                  ? 100 + "%"
                                  : item?.toFixed(2) + "%"
                                : dataggf[index] === "numerico"
                                  ? item?.toFixed(2)
                                  : ""}
                    </td>
                  ))}
                </tr>
              </tbody>
            ) : (
              <></>
            )}
            {invoicepago === true ? (
              <tbody className="totalesfijo">
                <tr>
                  <td>Gran Total</td>
                  <td></td>
                  <td style={{ textAlign: "right" }}>
                    {dataextra?.total === undefined
                      ? Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      })?.format(0.0)
                      : Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      })?.format(dataextra?.total)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {dataextra?.baseIEPS8 === undefined
                      ? Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      })?.format(0.0)
                      : Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      })?.format(dataextra?.baseIEPS8)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {dataextra?.baseIVA0 === undefined
                      ? Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      })?.format(0.0)
                      : Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      })?.format(dataextra?.baseIVA0)}
                  </td>
                </tr>
              </tbody>
            ) : (
              <></>
            )}
          </Table>

          <div
            className="sk-fading-circle"
            style={{ display: Gmodal === true ? "" : "none" }}
          >
            <div className="sk-circle1 sk-circle"></div>
            <div className="sk-circle2 sk-circle"></div>
            <div className="sk-circle3 sk-circle"></div>
            <div className="sk-circle4 sk-circle"></div>
            <div className="sk-circle5 sk-circle"></div>
            <div className="sk-circle6 sk-circle"></div>
            <div className="sk-circle7 sk-circle"></div>
            <div className="sk-circle8 sk-circle"></div>
            <div className="sk-circle9 sk-circle"></div>
            <div className="sk-circle10 sk-circle"></div>
            <div className="sk-circle11 sk-circle"></div>
            <div className="sk-circle12 sk-circle"></div>
          </div>
        </div>

        <Row style={{ maxWidth: 900, margin: "0 auto", textAlign: "center" }}>
          <Col md={3}>
            <Button
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                previousPage();
                setcounter(counter + 1);
              }}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </Col>
          <Col md={2} style={{ marginTop: 7 }}>
            Pagina{" "}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>
          </Col>

          <Col md={4}>
            <CustomInput
              id="select"
              type="select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {minfilas === true && data.length <= 10
                ? [6, data.length].map((pageSize, index) => (
                  <option key={index} value={pageSize}>
                    {paginado} {pageSize}
                  </option>
                ))
                : minfilas === true && data.length > 10
                  ? [
                    6,
                    Math.round(data.length / 4),
                    Math.round(data.length / 2),
                    data.length,
                  ].map((pageSize, index) => (
                    <option key={index} value={pageSize}>
                      {paginado} {pageSize}
                    </option>
                  ))
                  : data.length < 10
                    ? [data.length].map((pageSize, index) => (
                      <option key={index} value={pageSize}>
                        {paginado} {pageSize}
                      </option>
                    ))
                    : [
                      10,
                      Math.round(data.length / 4),
                      Math.round(data.length / 2),
                      data.length === 10 ? 10 : data.length,
                    ].map((pageSize, index) => (
                      <option key={index} value={pageSize}>
                        {paginado} {pageSize}
                      </option>
                    ))}
            </CustomInput>
          </Col>
          <Col md={3}>
            <Button
              color="primary"
              onClick={() => {
                nextPage();
                setcounter(counter + 1);
              }}
              disabled={!canNextPage}
            >
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </Col>
        </Row>
        <h3 className="footer-table-container">
          {consultas} {page.length} de {data.length}
        </h3>
      </Fragment>
    </>
  );
};

export default TableContainer;
