import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import OPERATION_ACCOUNTING_ACCOUNT from "./op_accounting_account";
import Edit from "../../imgComponents/save.png";
import Delete from "../../imgComponents/borrar.png";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { delete_accountingA } from "../../services/finances/finances";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const DeleteSector =async () =>{
        props.setmodalT(true);
        props.setreconsult(true);
        try{
             var rf_token = await refresh_token();
             if(rf_token===true){
               setTimeout(async() => {
                 try{
                   const d = await delete_accountingA(props?.row?.original?.id);
                   console.log(d);
                   props.alertas("Cuenta Contable Eliminada", true);
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.handleSubmit();
                 }catch(err){
                   console.log("error", err);
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.alertas("Error, no se pudo eliminar la cuenta contable", false);
                 }
               }, 1000);
             }else{}
           }catch(err){
            console.log(err);
           }
    }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar la cuenta contable?','Eliminar Cuenta Contable','Si','No');
                    if(result){
                        DeleteSector()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Cuenta Contable"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Cuenta Contable
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <OPERATION_ACCOUNTING_ACCOUNT 
                            operation={"EDIT"}  
                            row={props?.row} 
                            handleSubmit={props?.handleSubmit} 
                            alertas={props?.alertas} 
                            setmodalT={props.setmodalT}
                        />,
                    {
                        className: "custom-modal",
                        title: "EDITAR CUENTA CONTABLE",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Cuenta Contable"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Cuenta Contable
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
