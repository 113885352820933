/* global google */

import axios from "axios";
import './style.css'
import { Button, FormControl } from "@mui/material";
import { Done, Folder } from "@material-ui/icons";
import { Google } from "@mui/icons-material";
import { Backdrop, CircularProgress, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import AlertResponse from "../main_components/alerts/alertResponse";
import { crearEstructuraArbol } from "./Auxi";
import NotificationSystem from 'react-notification-system';
import loadgif from "../imgComponents/iconos/loading.gif";
import WARNING from "../main_components/alerts/warning";

const CLIENT_ID = '301722987834-pec5a9vlojn6redpcneblrgd2ckqdj05.apps.googleusercontent.com';
const CLIENT_SECRET = "JhDQNqt9CC0Fvw_8JZ3aZCBg";
const TOKEN_URL = 'https://oauth2.googleapis.com/token';
const URL_API_DRIVE = "https://www.googleapis.com/drive/v3/files";

export const Autorization = () => {
  const [accessToken1, setAccessToken1] = useState({})
  const [authorized1, setAuthorized1] = useState(false)
  const [authorized2, setAuthorized2] = useState(false)
  const [accessToken2, setAccessToken2] = useState({})
  const [showlist, setShowlist] = useState(false)
  const [files, setFiles] = useState([])
  const [filesinfolder, setFilesInFolder] = useState([])
  const [idfolder, setIdfolder] = useState(null);
  const [sendding, setSendding] = useState(false)
  const [foldername, setFoldername] = useState('')
  const [sender_email, setSenderemail] = useState('')
  const notificationSystemRef = useRef();
  
  const [load, setload]=useState(false);
  const [msg, setmsg]=useState("");


  const[dataarbol, setdataarbol]=useState([]);

  
function alertas(msj,status){

  if(status==="warning"){
    notificationSystemRef.current.addNotification({
      message: <WARNING msj={msj} view={true}></WARNING>,
      level: "success",
      position: "br",
      autoDismiss: 30,
    });
  } else if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}


  const handleFolderIdChange = async (event) => {
    setIdfolder(event.target.value);
    try {
      const response = await getFilesInFolder(event.target.value)
      console.log("Response", response)
      await getFilePath(event.target.value, accessToken1.access_token)
      
    } catch(error){
        console.log(error)
    }
  };


  const addParents = async (files) =>  {
    let filesHere = files.map( async (item) => {

      try {
        const path = await getFilePath(item.id, accessToken1.access_token)
        const newPath = path.split('/').length > 0 ? path : `My Drive/${path}`
        const newItem = {
          kind: item.kind,
          mimeType: item.mimeType,
          id: item.id,
          name: item.name,
          parents: newPath
        }
        return Promise.resolve(newItem)

      } catch (error) {
          return Promise.reject(error)
      }
      
    })

    let filesPath = []

    try {

      filesPath = await Promise.all(filesHere)

      return Promise.resolve(filesPath)

    } catch(error) {
        return Promise.reject(error)
    }
  
  }
  

  const findTree = async (folderId, accessToken) => {
    let tree = [];

    const getFilesAndFolders = async (parentId) => {
        try {
            const response = await fetch(`https://www.googleapis.com/drive/v3/files?q='${parentId}'+in+parents&fields=files(id,name,mimeType,parents)`, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });
            const data = await response.json();
            return data.files;
        } catch (error) {
            console.error("Error fetching files and folders:", error);
            return [];
        }
    };

   const buildTree = async (parentId, path = '') => {
        const items = await getFilesAndFolders(parentId);
        findTree(folderId, accessToken1.access_token)
    .then(tree => console.log(tree))
    .catch(error => console.error("Error finding tree:", error));
        for (const item of items) {
            const currentPath = path + '/' + item.name;
            tree.push({ ...item, path: currentPath });
            if (item.mimeType === 'application/vnd.google-apps.folder') {
                await buildTree(item.id, currentPath);
            }
        }
    };

    await buildTree(folderId);
    return tree;
};

  

  const client1 = google.accounts.oauth2.initCodeClient({
    client_id: CLIENT_ID,
    scope: 'https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/spreadsheets', 
    callback: async (tokenResponse)=>{ 

      if(tokenResponse) 
          await getTokens( 1, tokenResponse.code)   
      else 
        console.log("No trae nada")
    }, 
    });


  const client2 = google.accounts.oauth2.initCodeClient({
    client_id: CLIENT_ID,
    scope: 'https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/spreadsheets',
    callback: async (tokenResponse)=>{
      if(tokenResponse) {
        await getTokens( 2, tokenResponse.code)
      } else {
        console.log("No trae nada")
      }
    }, 
  });


  const getTokens = async (client= 0, code = "") => {

    const data = {
      code: code,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      redirect_uri: 'https://od2.fricongelados.com',
      grant_type: 'authorization_code',
    };

    try {
      const response = await axios.post(TOKEN_URL, data)
      const expiresIn = response.data.expires_in;
      const currentTime = Date.now();
      const tokenExpiry = new Date(currentTime + expiresIn * 1000);

      const credentials = {
        access_token: response.data.access_token,
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        refresh_token: response.data.refresh_token,
        token_expiry: tokenExpiry,
        id_token: response.data.id_token,
        id_token_jwt: null,
        token_response: {
          access_token: response.data.access_token,
          expires_in: response.data.expires_in, 
          refresh_token: response.data.refresh_token,
          scope: "https://www.googleapis.com/auth/drive",
          token_type: response.data.token_type
        },
        scopes: ["https://www.googleapis.com/auth/drive"],
        token_info_uri: "https://oauth2.googleapis.com/tokeninfo",
        invalid: false,
        _class: "OAuth2Credentials",
        _module: "oauth2client.client"
      }

      const email = await getUserInfo(response.data.access_token)
      console.log("EMAIL", email)

        if(client === 1 ){
          setAuthorized1(true)
          setAccessToken1(credentials)
          setSenderemail(email.email)
        } else if( client === 2){
          setAuthorized2(true)
          setAccessToken2(credentials)
        }

      return Promise.resolve(JSON.stringify(credentials));
    } 
    catch(error){
      console.log("Error al obtener tokens")
      return Promise.reject(error);
    }
  }


   const getFilePath = async (fileId, accessToken) => {
    let path = [];
    let currentId = fileId;
  
    while (currentId) {
      // Obtener los metadatos del archivo o carpeta actual.
      const response = await axios.get(`${URL_API_DRIVE}/${currentId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          fields: 'id, name, parents',
        },
      });
  
      const file = response.data;
      path.unshift(file.name); // Agregar el nombre del archivo o carpeta al principio de la ruta.
  
      // Si no hay padres, hemos llegado a la raíz.
      if (!file.parents || file.parents.length === 0) {
        break;
      }
  
      // Subir a la carpeta padre para la siguiente iteración.
      currentId = file.parents[0];
    }
  
    return path.join('/'); // Unir todos los nombres para formar la ruta.
  }


  const getFiles = async () => {

    if(authorized1===false){
      alertas("Debe autorizar la cuenta 1 para visualizar sus carpetas", false);
      return null;
    }

    let pageToken = null;
    let files = [];
    setload(true);
    setmsg("Buscando Carpetas...");
    try {
        do {
            const response = await axios.get(
              `${URL_API_DRIVE}?q=trashed=false and mimeType = 'application/vnd.google-apps.folder'${pageToken ? '&pageToken=' + pageToken : ''}`, 
              {           headers: {
                    Authorization: `Bearer ${accessToken1.access_token}`,
                },
            });

            files = files.concat(response.data.files);
            pageToken = response.data.nextPageToken;

            console.log("Batch of files", response.data.files);
        } while (pageToken);

        console.log("All files", files);
        const filesParents = await addParents(files);
        console.log("Files with parents", filesParents);
        const structureTree = crearEstructuraArbol(filesParents)
        console.log("Tree structure", structureTree);
        console.log("Keys from object", Object.keys( structureTree))


        setFiles(files);
        setdataarbol(structureTree);
        setload(false);
        //setFiles(structureTree)
      } catch (error) {
        console.log("ERROR AL Imprimir files", error);
    }
};



  const getFilesInFolder = async (folderId) => {
    try {
      const response = await axios.get(
        `${URL_API_DRIVE}?q='${folderId}' in parents and trashed=false`,
        {
          headers: {
            Authorization: `Bearer ${accessToken1.access_token}`,
          },
        }
      );
      
      const filesInFolder = response.data.files;
      setFilesInFolder(filesInFolder);

      console.log("Archivos en la carpeta:", filesInFolder);
    } catch (error) {
      console.error("Error al buscar archivos en la carpeta:", error);
    }
  };


  const buildTree = (files) => {
    const root = { id: 'root', name: 'My Drive', children: [] };

    const createPath = (node, path, file) => {
      path.forEach((name, index) => {
        if (!node.children) {
          return;
        }
        let child = node.children.find(child => child.name === name);
        if (!child) {
          child = { name, children: [] };
          node.children.push(child);
        }
        if (index === path.length - 1) {
          child.id = file.id;
          child.mimeType = file.mimeType;
          delete child.children;
        }
        node = child;
      });
    };
  
    files.forEach(file => {
      if (!file.parents) {
        root.children.push({
          id: file.id,
          name: file.name,
          mimeType: file.mimeType
        });
        return;
      }
      
      const path = file.parents.split('/');
      const nameIndex = path.indexOf(file.name);
      if (nameIndex !== -1) {
        path.splice(nameIndex, 1);
      }
  
      createPath(root, path, file);
    });
    return root;
  }


  const sendFiles = async () => {

    if(authorized2===false){
      alertas("Falta autorizar la cuenta 2", false);
      return null;
    }

    if(foldername===null || foldername==="" || foldername?.trim()===""){
      alertas("Falta asignar el nombre de la carpeta a crear", false);
      return null;
    }

    setSendding(true);

    const url = "https://suluptpy7d.execute-api.us-east-1.amazonaws.com/test"
    const data = {
      folder_id: idfolder,
      sender_user: accessToken1,
      recipient_user: accessToken2,
      folder_name: foldername,
      email: sender_email
    };
    console.log("Data enviada", data)
    try {
      const response = await axios.post(url, data, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          // "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
        alertas("Solicitud enviada al servidor, trabajando en ello...", true);
        alert("Considera que para 10GB son 30 minutos");
      return Promise.resolve(response);
    } 
    catch (error) {
      alertas("No se pudo copiar la carpeta", false)
      return Promise.reject(error);
    }
    finally {
      setFoldername('')
      setSendding(false)
    }
  }
  


  const authorizeFirstAccount = async () => {
    try { 
      client1.requestCode(); 
    } catch (error) {
       console.error('Error durante la autorización:', error); 
    }
  };


  const authorizeSecondAccount = async () => {
    try { client2.requestCode() } catch (error) { console.error('Error durante la autorización:', error); }
  };


  const handleFolderNameChange = (event) => {
      setFoldername(event.target.value)
  }

  useEffect(() => {
    if(files.length > 0){
      setShowlist(true)
    }
  else     
      setShowlist(false)   
  }, [files, idfolder])


  const getUserInfo = async (accessToken) => {
    try {
      const response = await axios.get(
        'https://www.googleapis.com/oauth2/v2/userinfo', // Endpoint para obtener la información del usuario
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response.data); // La respuesta incluirá la información del perfil del usuario
      return response.data; // Retorna los datos del usuario, incluyendo el email
    } catch (error) {
      console.error('Error al obtener la información del usuario', error);
      throw error;
    }
  };
  

  const revokeAccessToken = async (accessToken) => {
    try {
      const response = await fetch(`https://oauth2.googleapis.com/revoke?token=${accessToken}`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/x-www-form-urlencoded'
        },
      });
  
      if (response.ok) {
        console.log('Access token was revoked successfully');
      } else {
        console.error('Failed to revoke access token');
      }
    } catch (error) {
      console.error('Error revoking access token', error);
    }
  };
  

return (
  <div className="modal-content">
    <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
    <h1>Compartir Drive a Drive</h1>
      <div className="modal-header">
          <Button 
              className="btn-authorizer"
              variant={authorized1 ? "contained" : "outlined"}
              color={authorized1 ? "success" : "inherit"}
              startIcon={authorized1 ? <Done /> : <Google />}
              onClick={async () => await authorizeFirstAccount()}
            >
              { authorized1 ? "Cuenta 1 autorizada" : "Autorizar cuenta 1"}
          </Button>
          <Button 
            className="btn-authorizer"
            variant={authorized2 ? "contained" : "outlined"}
            color={authorized2 ? "success" : "inherit"}
            startIcon={authorized2 ? <Done /> : <Google />}
            onClick={async () => await authorizeSecondAccount()}
          >
            { authorized2 ? "Cuenta 2 autorizada" : "Autorizar cuenta 2"}
          </Button>
      </div>

    <Button onClick={ async () => await getFiles()}> { files.length > 0 ? ("Carpetas encontradas " + files.length ) : "Buscar carpetas" } </Button>

    <div style = {{display: files.length > 0 ? "block" : "none"}}>

      <div className="content-folder">
            {console.log("Files length", files.length)}
            <div className="folder-select">
              <FormControl fullWidth>
              <span className="input-label">Carpetas en drive</span>
                <Select value={idfolder} onChange={ async (event) => await handleFolderIdChange(event)}>
                {files.map((folder) => (
                    <MenuItem key={folder.id} value={folder.id}>
                      <Folder /> {folder.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <p className="span-advertencia-drive">El usuario al no seleccionar una carpeta específica, se procederá a transferir la totalidad del contenido de la unidad de Google Drive.</p>
            </div>
          
            <div className= "files-list" style={{display: files.length > 0 ? "block" : "none", border: "1px balck solid"}}>
              <FormControl fullWidth>
                <span className="input-label">Contenido de la carpeta seleccionada</span>
                <ul>
                  { filesinfolder.map( (iterador) => 
                    (<li className="li-list-drive" key={iterador.id}> * {iterador.name} </li> ))
                  }
                </ul>
              </FormControl>
            </div>
          </div>
          <div className="footer">
            <FormControl variant='outlined' fullWidth >
                    <TextField    
                    style={{width:"60%"}}                
                      label="Nombre de la carpeta a crear"
                      value={foldername}
                      onChange={handleFolderNameChange}
                      required
                      variant="outlined"
                    />
                </FormControl> 
            <Button 
              onClick={ async ()=> await sendFiles() }
              style={{display: showlist ? "inline" : "none"}}
              startIcon={ sendding ? <CircularProgress /> : "" } 
              variant={ sendding ? "outlined" : "contained"}
              > 
              { sendding ? "Copiando..." : "Empezar Transferencia"} 
            </Button>  
            <Backdrop className="backdrop" style={{ width:"100%", position: "absolute", backgroundColor: "#615c5c", zIndex: 10010 }} sx={{ color: '#fff' }}
                open={sendding}          
              >
                  <CircularProgress  style={{display: "block", fontSize: "large"}} color="primary" />
                    <Typography component="legend"> Copiando...... </Typography>              
              </Backdrop>
          </div>             
    </div>
    <div 
      style={{display: load? "block" : "none"}}
    >
        <div className="load-files">
            <img src={loadgif} alt="loading..."></img>
            <p>{msg}</p>
        </div>
    </div>
    
    </div>
    
);
}
