import { useEffect, useMemo, useRef, useState } from 'react'
import { get_cabinets_brands, get_cabinets_models, post_cabinets } from '../../../services/assets/assets';
import "./upload_file_frezz.css";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { Box } from '@mui/material';
import RENDER_ROW_ACTION_MENU_ITEMS from './render_row_action_upload';
import loadimg from "../../../imgComponents/iconos/loading.gif";
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';
import { useDialog } from 'react-st-modal';


export const UPLOAD_FILE_CABINETS = (props) => {
  
  const [file_cabinets, setfile_cabinets] = useState([]);
  const [file_cabinets_error, setfile_cabinets_error] = useState([]);
  const dialog = useDialog();
  const [highlight, setHighlight] = useState(false);
  const fileInputRef = useRef(null);
  const [file, setfile]=useState(null);
  const [errmsjf, seterrmsjf]=useState("");
  const [errfile, seterrfile] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [databrand, setdatabrand] = useState([]);
  const [datamodels, setdatamodels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});

  const [process_num, setprocess_num] = useState(1);
  const [progr, setProgr] = useState(0);
  var progress = 0;

  const [msj, setmsj] = useState("");

  const[dowp, setdowp] = useState(false);

  const CLIENT_ID = '301722987834-pec5a9vlojn6redpcneblrgd2ckqdj05.apps.googleusercontent.com';
  const API_KEY = 'AIzaSyBTswKLvFOwWbnlFqwQMqpUgUEL3LQu7ls';
  const SCOPES = 'https://www.googleapis.com/auth/spreadsheets';
  

  useEffect(() => {
    handlesubmit();
  }, [])

  useEffect(() => {
    let script;

   if(dowp === true){
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    script.onload = () => {
      window.gapi.load('client:auth2', initClient);
    };
    document.body.appendChild(script);
   }else{
      const existingScript = document.querySelector('script[src="https://apis.google.com/js/api.js"]');
      if (existingScript) {
        existingScript.remove();
      }
      if (window.gapi && window.gapi.auth2) {
        window.gapi.auth2.getAuthInstance()?.signOut();
        console.log("Google API desactivado y sesión cerrada.");
      }
    }
    return () => {
      if (script && document.body.contains(script)) {
        script.remove();
      }
    }
  }, [dowp]);


  const initClient = () => {
    window.gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
      scope: SCOPES,
    }).then(() => {
      window.gapi.auth2.getAuthInstance().isSignedIn.listen(setIsSignedIn);
      setIsSignedIn(window.gapi.auth2.getAuthInstance().isSignedIn.get());
    });
  };
  const handleAuthClick = () => {
    window.gapi.auth2.getAuthInstance().signIn({ prompt: 'select_account' }).then(() => {
      handleCreateSheet();
    });
  };

  const handleCreateSheet = () => {
    window.gapi.client.sheets.spreadsheets.create({
      properties: {
        title: "Plantilla_Congeladores"
      }
    }).then((response) => {
      console.log('Hoja de Cálculo Creada', response.result);
      const newSheetName = "Sheet"; 
      renameFirstSheet(response.result.spreadsheetId, newSheetName,response.result.spreadsheetUrl);
    }).catch((error) => {
      alert("No se logro crear la hoja de calculo comuniquese con soporte")
      console.error('Error al crear la hoja de cálculo', error);
      setdowp(false);
    });
  };
  const renameFirstSheet = (spreadsheetId, newSheetName,urlSheet) => {
    window.gapi.client.sheets.spreadsheets.batchUpdate({
      spreadsheetId: spreadsheetId,
      requests: [{
        updateSheetProperties: {
          properties: {
            sheetId: 0,
            title: newSheetName
          },
          fields: 'title'
        }
      }]
    }).then((response) => {
      console.log('Nombre de la hoja cambiado', response.result);
      updateSheet(spreadsheetId, urlSheet);
    }).catch((error) => {
      setdowp(false);
    });
  };
  const updateSheet = (id, url) => {

    const headers = [
      "Numero economico",
      "Numero activo fijo",
      "Numero serie",
      "Comodato",
      "Año",
      "Marca",
      "Modelo",
      "Nota",
    ];
    
    const values = [headers];
    const range = "Sheet";
    const spreadsheetId = id;

    window.gapi.client.sheets.spreadsheets.values.update({
      spreadsheetId: spreadsheetId,
      range: range,
      valueInputOption: "USER_ENTERED",
      resource: {
        values: values
      }
    }).then((response) => {
      console.log('Datos actualizados', response.result);
      addDropdownValidation(spreadsheetId, url);
    }).catch((error) => {
      alert("No se logro actualizar la hoja de calculo comuniquese con soporte")
      console.error('Error al actualizar la hoja de cálculo', error);
      setdowp(false);
    });
  };
  const addDropdownValidation = (spreadsheetId, url) => {
    
    const namesArray = databrand.map(item => item.name);
    const modelsArray = datamodels.map(item =>item.name_model);
  
    const requests = [
      {
        repeatCell: {
          range: {
            sheetId: 0, // Primera hoja
            startRowIndex: 1, // Desde la fila 2
            endRowIndex: 100, // Hasta la fila 100
            startColumnIndex: 3, // Columna F (índice base 0)
            endColumnIndex: 4, // Columna F (no inclusivo)
          },
          cell: {
            dataValidation: {
              condition: {
                type: "ONE_OF_LIST",
                values: ["SI", "NO"].map(name => ({ userEnteredValue: name })),
              },
              strict: true,
              showCustomUi: true,
            },
          },
          fields: "dataValidation",
        },
      },
      {
        repeatCell: {
          range: {
            sheetId: 0, // Primera hoja
            startRowIndex: 1, // Desde la fila 2
            endRowIndex: 100, // Hasta la fila 100
            startColumnIndex: 5, // Columna F (índice base 0)
            endColumnIndex: 6, // Columna F (no inclusivo)
          },
          cell: {
            dataValidation: {
              condition: {
                type: "ONE_OF_LIST",
                values: namesArray.map(name => ({ userEnteredValue: name })), // Opciones para la columna F
              },
              strict: true,
              showCustomUi: true,
            },
          },
          fields: "dataValidation",
        },
      },
      {
        repeatCell: {
          range: {
            sheetId: 0, // Primera hoja
            startRowIndex: 1, // Desde la fila 2
            endRowIndex: 100, // Hasta la fila 100
            startColumnIndex: 6, // Columna G (índice base 0)
            endColumnIndex: 7, // Columna G (no inclusivo)
          },
          cell: {
            dataValidation: {
              condition: {
                type: "ONE_OF_LIST",
                values: modelsArray.map(model => ({ userEnteredValue: model })), // Opciones para la columna G
              },
              strict: true,
              showCustomUi: true,
            },
          },
          fields: "dataValidation",
        },
      },
    ];


    window.gapi.client.sheets.spreadsheets.batchUpdate({
      spreadsheetId: spreadsheetId,
      resource: {
        requests: requests,
      },
    })
      .then((response) => {
        console.log("Menú desplegable configurado", response.result);
        window.open(url, "_blank");
        window.gapi.auth2.getAuthInstance().signOut().then(() => {
          setIsSignedIn(false);
          console.log("Sesión cerrada con éxito");
        });
      })
      .catch((error) => {
        alert("No se logró configurar el menú desplegable. Comuníquese con soporte.");
        console.error("Error al configurar el menú desplegable", error);
        setdowp(false);
      });
  };
  
  const handlesubmit = async() =>{

    try{
      props?.setmodalT(true);
      var d = await get_cabinets_models();
      var d2 = await get_cabinets_brands();
      setdatamodels(d === null ? [] : d.data.models);
      setdatabrand(d === null ? [] : d2.data.brands);
      props?.setmodalT(false);
    }catch(err){
      props?.setmodalT(false);
      console.log(err);
    }
  }
  
  const handleDrop = (e) => {
    e.preventDefault();
    setHighlight(false);
    const files = e.dataTransfer.files;
    setfile(files[0]);
    handleFiles(files);
  };
  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };  
  const handleFileInputChange = (e) => {
    const files = e.target.files;
    setfile(files[0]);
    handleFiles(files);
  };

  const handleFiles = (files) => {
      seterrmsjf("");
      
    for (const file of files) {
      if (file.type === 'text/csv') {
        const reader = new FileReader();
        reader.onload = (e) => {
          const contents = e.target.result;
          analisis(contents);
        };
        reader.readAsText(file);
      } else {
        alert('Por favor, selecciona un archivo CSV.');
        setfile(null);
      }
    } 
  };

  const analisis = (content) =>{
    const lines = content.trim().split('\n');
    const headers = lines[0].split(',');
    const result = [];
    for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentLine = lines[i].split(',');

        headers.forEach((header, index) => {
            obj[header.trim().replace(/"/g, '')] = currentLine[index].trim().replace(/"/g, '');
        });
        result.push(obj);
    }
    headers_check(result);
  }

  const headers_check = (result) =>{
    const requiredFields = [
      "Numero economico",
      "Numero activo fijo",
      "Numero serie",
      "Comodato",
      "Año",
      "Marca",
      "Modelo",
      "Nota",
    ];
    const firstObject = result[0];
      const missingFields = requiredFields.filter(field => !firstObject.hasOwnProperty(field));
      if (missingFields.length > 0) {
          seterrmsjf("Faltan los siguientes campos: "+ missingFields.join(', '));
          seterrfile(true);
      }

    const cleanedResult = result.map(item => {
      const cleanedItem = {};
      for (const key of Object.keys(item)) {
          if (requiredFields.includes(key) && item[key] != null && item[key] !== '') {
              cleanedItem[key] = item[key];
          }
      }
      return cleanedItem;
    }).filter(item => Object.keys(item).length > 0);
    
    if (cleanedResult.length > 0) {
      validate_data(cleanedResult);
    } else {
        seterrmsjf("Todos los objetos están vacíos después de limpiar los campos.");
        seterrfile(true);
    }
  }

  const validate_data = (result) =>{
    result.map(item=>{
      databrand.map(brand=>{
        if(brand.name === item['Marca']){
          item.brand_validate = true
          item.id_brand = brand.id
        }
      })

      if(item.brand_validate || item.brand_validate == true){
          const modeld = datamodels;
          const modeldataaux = modeld.filter(i => i.id_brand_fk === item.id_brand);
          modeldataaux?.map(model=>{
            if(model.name_model === item['Modelo']){
              item.model_validate = true;
              item.id_model = model.id;
            }
          })
          if(item.model_validate){
            console.log("data sucess");
          }else{
            item.model_validate = false;
          }
        }
        else{
          item.brand_validate = false;
          item.model_validate = false;
        }
    })
    console.log(result);
    setfile_cabinets(result);
    setprocess_num(2);   
  }

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const columns = useMemo(
    () => [
      {
        id: "success",
        header: "Estado",
        accessorKey: "success",
        size: 80,
        muiTableBodyCellProps:{
          align: "center"
        },
        accessorFn:(d)=>{
          return d['brand_validate'] === false || d['model_validate'] === false ? "❌" : "✅"
        }
      },
      {
        id: "economic_number",
        header: "Número Económico",
        accessorKey: "economic_number",
        accessorFn:(d)=>{
          return d['Numero economico']
        },
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "fixed_asset",
        header: "Numero Activo Fijo",
        accessorKey: "fixed_asset",
        accessorFn: (d) =>{
          return d['Numero activo fijo'];
        },
        muiTableBodyCellProps:{
          align: "center"
        }
      },
      {
        id: "serial_number",
        header: "Numero de Serie",
        accessorKey: "serial_number",
        accessorFn: (d) =>{
          return d['Numero serie'];
        },
        muiTableBodyCellProps:{
          align: "center"
        }
      },
      {
        id: "Comodato",
        header: "Comodato",
        accessorKey: "Comodato",
        accessorFn: (d) =>{
          return d['Comodato'];
        },
        muiTableBodyCellProps:{
          align: "center"
        }
      },
      {
        id: "brand",
        header: "Marca",
        accessorKey: "brand",
        accessorFn: (d) =>{
          return d['Marca'];
        },
        muiTableBodyCellProps:{
          align: "center"
        },
        Cell: (d) => {
          console.log(d?.row?.original["Marca"]);
          return(
          <Box
            component="span"
            sx={(theme) => ({
              color: d?.row?.original['brand_validate'] === false ? 'red': 'black'
            })}
          >
          {
            d?.row?.original['Marca']
          }
          </Box>
        )}
      },
      {
        id: "model",
        header: "Modelo",
        accessorKey: "model",
        accessorFn: (d) =>{
          return d['Modelo'];
        },
        muiTableBodyCellProps:{
          align: "center"
        },
        Cell: (d) => {
          console.log(d?.row?.original["Modelo"]);
          return(
          <Box
            component="span"
            sx={(theme) => ({
              color: d?.row?.original['model_validate'] === false ? 'red': 'black'
            })}
          >
          {
            d?.row?.original['Modelo']
          }
          </Box>
        )},
      }
    ],
    []
  );

  
  const columns2 = useMemo(
    () => [
      {
        id: "register",
        header: "Registro",
        filterVariant: 'select',
        accessorKey: "register",
        size: 80,
        muiTableBodyCellProps:{
          align: "center"
        },
        accessorFn:(d)=>{
          return d['status_register'] === true ? "✅" : "❌"; 
        }
      },
      {
        id: "economic_number",
        header: "Número Económico",
        accessorKey: "economic_number",
        accessorFn:(d)=>{
          return d['Numero economico']
        },
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "fixed_asset",
        header: "Numero Activo Fijo",
        accessorKey: "fixed_asset",
        accessorFn: (d) =>{
          return d['Numero activo fijo'];
        },
        muiTableBodyCellProps:{
          align: "center"
        }
      },
      {
        id: "serial_number",
        header: "Numero de Serie",
        accessorKey: "serial_number",
        accessorFn: (d) =>{
          return d['Numero serie'];
        },
        muiTableBodyCellProps:{
          align: "center"
        }
      },
      {
        id: "status",
        header: "Estado",
        accessorKey: "status",
        accessorFn: (d)=>{
          return d['response_error_register'] === "" ? "Congelador Registrado": d['response_error_register']
        }
      }
    ], []
  );
  
  const update_data = (dat) =>{
    setfile_cabinets([...dat]);
  }
  const update_data_error = (dat) =>{
    setfile_cabinets_error([...dat]);
  }


  const upload_lote = async() =>{

    const ef = file_cabinets.filter(item=>item?.brand_validate === false || item?.model_validate === false)
    if(ef.length > 0){
      const numerosEconomicos = ef.map(item => item['Numero economico']).join(', ');
      props?.alertas(`Verifica la información de los congeladores con número económico ${numerosEconomicos}`, false);
      return false;
    }
    setprocess_num(3);      
    try{
      const invoiceData = [];
      for (const item of file_cabinets) {
        const data = await processQueueItem(item);
        invoiceData.push(data);
      }
      console.log(invoiceData);
      setprocess_num(4);
      setfile_cabinets_error(invoiceData);
    }catch(err){
      console.log(err);
      setprocess_num(2);
    }
  }

  const processQueueItem = async(item)=>{
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    var data = {
      "economic_number": item['Numero economico'],
      "fixed_asset": item['Numero activo fijo'],
      "serial_number": item['Numero serie'],
      "capitalization_date": formattedDate,
      "note": item['Nota'],
      "id_warehouse": 8,
      "id_cabinets_state": 3,   
      "id_cabinets_model": item['id_model'],
      "lending": item["Comodato"] === "SI" || item["Comodato"] === true ? true : false,   
      "production_year": item['Año']
    }

    progress = progress + 1 / file_cabinets?.length * 100;
    console.log(progress)
    setProgr(progress);
    setmsj("Registrando Congelador con Número Económico " + item['Numero economico']);
    try {
      await post_cabinets(data);
      item['status_register'] = true;
      item['response_error_register'] = "";
      return item;
    }
    catch(err){
      console.log(err);
      if(err?.response?.data?.errorMessage){
        item['response_error_register'] = 
          err?.response?.data?.errorMessage == "A cabinet already exists with the same serial number." ? 
            "Ya existe un congelador con el mismo número de serie.":
            "Verifica los datos del congelador.";
      }else{
        item['response_error_register'] = "Error desconocido comuníquese con soporte.";
      }
      item['status_register'] = false;
      return item;
    }
  }
  return (
    <div className='content-upload-file-csv-frezzer'>
      <div className=''>
        {
          process_num === 1?
          <div className=''>
            <div className={`drop-area ${highlight ? 'highlight' : ''}`}
              onDrop={handleDrop}
              onDragOver={preventDefaults}
              onDragEnter={() => setHighlight(true)}
              onDragLeave={() => setHighlight(false)}
              onClick={handleFileInputClick}>
              <p>Arrastra y suelta un archivo CSV aquí o haz clic para seleccionarlo.</p>
              <input
                  type="file"
                  accept=".csv"
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                  style={{ display: 'none' }}
              />
            </div>
            <div className='download-csv-plantilla' style={{"textAlign": "center"}}>
              <button onClick={()=>{
                setdowp(true);
                setTimeout(() => {
                  handleAuthClick()
                }, 1000);
              }}>
                Generar Plantilla</button>
            </div>
          </div>:
          process_num === 2 ?
          <div className='register-new-cabinets'>
            <div className='rnc-info'>
              <h2>Congeladores a Registrar</h2>
              <div className='rnc-grid-button'>
                <button onClick={()=>upload_lote()}>Empezar Proceso</button>
                <button onClick={()=>{dialog.close()}} style={{backgroundColor: "red"}}>Cancelar</button>    
              </div>
            </div>
            <div className='new_table_v2 table-add-cabinets-file-csv-upload'>
              <MaterialReactTable
                    columns={columns}
                    data={file_cabinets}
                    enableGrouping
                    enableFacetedValues
                    enableStickyHeader
                    onStateChange={handleStateChange}
                    enableStickyFooter
                    enableColumnFilterModes
                    enableRowActions
                    getRowId={(row) => row?.id}
                    onRowSelectionChange={setRowSelection}
                    enableTooltips={false}
                    localization={MRT_Localization_ES}
                    state={{ rowSelection, isLoading: isLoading }}
                    enableColumnResizing
                    enableColumnPinning
                    enableColumnOrdering
                    enableExpandAll={false}
                    enableColumnDragging={false}
                    initialState={{ showGlobalFilter: true, showColumnFilters: true }}
                    muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
                    muiSkeletonProps={{ animation: 'pulse', height: 28 }}
                    rowVirtualizerInstanceRef
                    rowVirtualizerOptions={{ overscan: 5 }}
                    columnVirtualizerOptions={{ overscan: 2 }}
                    muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
                    positionToolbarAlertBanner="bottom"
                    paginationDisplayMode='pages'
                    rowPinningDisplayMode='sticky'
                    layoutMode="grid"
                    muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

                    muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
                    muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
                    displayColumnDefOptions={{
                      'mrt-row-pin': {
                        enableHiding: true,
                      },
                      'mrt-row-actions': {
                        enableHiding: true,
                        size: 80
                      },
                      'mrt-row-expand': {
                        enableHiding: true,
                      },
                      'mrt-row-select': {
                        enableHiding: true,
                      }
                    }}
                    muiTableBodyRowDragHandleProps={({ table }) => ({
                      onDragEnd: () => {
                        const { draggingRow, hoveredRow } = table.getState();
                        if (hoveredRow && draggingRow) {
                          file_cabinets.splice(
                            hoveredRow.index,
                            0,
                            file_cabinets.splice(draggingRow.index, 1)[0],
                          );
                          setfile_cabinets([...file_cabinets]);
                        }
                      },
                    })}
                    muiTableBodyRowProps={
                      ({ row, table }) => {
                        settotal_rows(table);
                        const { density } = table.getState();
                        return {
                          sx: {
                            height: row.getIsPinned()
                              ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                              }px`
                              : undefined,
                          },
                        };
                      }
                    }
                    renderRowActionMenuItems={({ row, closeMenu }) => [
                      <RENDER_ROW_ACTION_MENU_ITEMS
                        closeMenu={closeMenu}
                        row={row}
                        file_cabinets={file_cabinets}
                        setfile_cabinets={setfile_cabinets}
                        update_data={update_data}
                        databrand = {databrand}
                        datamodels = {datamodels}  
                        process={1}
                        setmodalT={props?.setmodalT}
                        alertas={props?.alertas}
                      />
                    ]}
                    />
            </div>
          </div>:
          process_num === 3 ?
          <div className='process-loadbar-lote-register'>
            <div className='process-content-bar-csv'>
              <div className='cargando-facturas'>
                <div className='loading-zip'>
                    <img src={loadimg} alt=''></img>
                    <p className='msj-zip'>{msj}</p>
                </div>
                <div className='load-progress'>
                    <div className='progress-bar' style={{ width: `${progr}%` }}></div>
                </div>
              </div>
            </div>
          </div>:
          process_num === 4 ?
          <div className='progress-finaly-new_table_upload_csv'>
            <div className='success-register-cabinets'>
              <h2 style={{fontSize: "20px"}}>Congeladores Registrados: {
                file_cabinets_error.filter(item=>item?.status_register === true)?.length
            } / {file_cabinets_error?.length}</h2>
              <div className='new_table_v2'>
              <MaterialReactTable
                columns={columns2}
                data={file_cabinets_error}
                enableGrouping
                enableFacetedValues
                enableStickyHeader
                onStateChange={handleStateChange}
                enableStickyFooter
                enableColumnFilterModes
                enableRowActions
                getRowId={(row) => row?.id}
                onRowSelectionChange={setRowSelection}
                enableTooltips={false}
                localization={MRT_Localization_ES}
                state={{ rowSelection, isLoading: isLoading }}
                enableColumnResizing
                enableColumnPinning
                enableColumnOrdering
                enableExpandAll={false}
                enableColumnDragging={false}
                initialState={{ showGlobalFilter: true, showColumnFilters: true }}
                muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
                muiSkeletonProps={{ animation: 'pulse', height: 28 }}
                rowVirtualizerInstanceRef
                rowVirtualizerOptions={{ overscan: 5 }}
                columnVirtualizerOptions={{ overscan: 2 }}
                muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
                positionToolbarAlertBanner="bottom"
                paginationDisplayMode='pages'
                rowPinningDisplayMode='sticky'
                layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

                muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
                muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
                displayColumnDefOptions={{
                  'mrt-row-pin': {
                    enableHiding: true,
                  },
                  'mrt-row-actions': {
                    enableHiding: true,
                    size: 80
                  },
                  'mrt-row-expand': {
                    enableHiding: true,
                  },
                  'mrt-row-select': {
                    enableHiding: true,
                  }
                }}
                muiTableBodyRowDragHandleProps={({ table }) => ({
                  onDragEnd: () => {
                    const { draggingRow, hoveredRow } = table.getState();
                    if (hoveredRow && draggingRow) {
                      file_cabinets.splice(
                        hoveredRow.index,
                        0,
                        file_cabinets.splice(draggingRow.index, 1)[0],
                      );
                      setfile_cabinets([...file_cabinets]);
                    }
                  },
                })}
                muiTableBodyRowProps={
                  ({ row, table }) => {
                    settotal_rows(table);
                    const { density } = table.getState();
                    return {
                      sx: {
                        height: row.getIsPinned()
                          ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                          }px`
                          : undefined,
                      },
                    };
                  }
                }
                renderRowActionMenuItems={({ row, closeMenu }) => [
                  <RENDER_ROW_ACTION_MENU_ITEMS
                    closeMenu={closeMenu}
                    row={row}
                    file_cabinets={file_cabinets}
                    setfile_cabinets={setfile_cabinets}
                    file_cabinets_error={file_cabinets_error}
                    setfile_cabinets_error={setfile_cabinets_error}
                    update_data={update_data}
                    databrand = {databrand}
                    datamodels = {datamodels}  
                    process={2}
                    setmodalT={props?.setmodalT}
                    alertas={props?.alertas}
                    update_data_error={update_data_error}
                  />
                ]}
                />

            </div>
          </div>
          <div className='finish_process_uploadfile_cabinets'>
                <button
                  onClick={()=>{
                    props?.handleSubmit();
                    dialog.close();
                  }}
                >
                  Finalizar
                </button>
              </div>
          </div>:
          <></>
        }
      </div>
    </div>
  )
}
