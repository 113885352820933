import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/lupa.png";
import { CustomDialog } from "react-st-modal";
import { POINT_OF_SALE_NOT_VISIT } from '../pointnovisit';

const RENDER_ROW_ACTION_MENU_ITEMS_NOT_VISIT = (props) => {
    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <POINT_OF_SALE_NOT_VISIT
                            row={props?.row} 
                            handleSubmit={props?.handleSubmit} 
                            alertas={props?.alertas} 
                            setmodalT={props.setmodalT} 
                        />,
                    {
                        className: "custom-modal-3",
                        title: "Puntos de Venta No Visitados",
                        showCloseIcon: true,
                        isCanClose: false,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Puntos de Venta No Visitados"
                        className=""
                    ></img>
                </ListItemIcon>
                Puntos de Venta No Visitados
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS_NOT_VISIT;
