import React, { useEffect, useMemo, useState} from "react";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { get_socioHabilitado } from "../../../services/businesspartners/businesspartners";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

function SubTable_V2P (props) {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  
const handleSubmit = async() => {

  setIsLoading(true);
  try{
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          const d = await get_socioHabilitado(props?.id);
          const data = d === null ? [] : d.data;
          console.log(d.data);
          setData([data]);
          console.log([data]);

          setIsLoading(false);
        }catch(err){
          console.log("error", err);
          setData([]);
          setIsLoading(false);
        }
      }, 1000);
    }else{}
  }catch(err){
    console.log(err);
  }
};

const columns = useMemo(
  () => [
    {
      id: "num_reg_id_trib",
      size:200,
      header: "Número de régimen tributario",
      accessorKey: "num_reg_id_trib",
    },
    {
      id: "tax_residence",
      size:200,

      header: "Residencia Fiscal",
      accessorKey: "tax_residence",
    },
    {
      id: "email",
      size:400,
      header: "Correo Electrónico",
      accessorKey: "email",
    },
  ],
  [data]
);


useEffect(() => {
  handleSubmit();
}, []);


  return (
    <div style={{width:"100%"}}>
      <MaterialReactTable
      key={"subtabla"}
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        // enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        //  renderRowActionMenuItems={({ row, closeMenu }) => [
        //    <RENDER_ROW_ACTION_MENU_ITEMS
        //      closeMenu={closeMenu}
        //      setmodalT={setmodalT}
        //      setmenssage={setmenssage}
        //      setmodalGeneral={setmodalGeneral}
        //      row={row}
        //      handleSubmit={handleSubmit}
        //      setreconsult={setreconsult}
        //      refresh_token={refresh_token}
        //      alertas={alertas}
        //    />
        //  ]}
        // renderToolbarInternalActions={({ table }) => (
        //   <RENDER_TOOLBAR_INTERNAL_ACTIONS
        //     table={table} file_name={"Cuentas Contables"}
        //     update_identifiers={update_identifiers}
        //     create_modul={"accounting_account"}
        //     setmodalT={setmodalT}
        //     handleSubmit={handleSubmit}
        //     alertas={alertas}
        //     settotal_rows={settotal_rows}
        //   />
        // )
        // }
      />
    </div>
  );
};

export default SubTable_V2P;
