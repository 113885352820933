//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, Confirm, useDialog } from "react-st-modal";
import NotificationSystem from 'react-notification-system';

//COMPONENTES
import { get_price_list } from "../../services/inventory/inventory";
import CREATE_UPDATE from "./tools/create_update";
import { get_price_list_products } from "../../services/inventory/inventory";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "./price_list.css"
//IMAGENES/ICONOS
import CREATE from "../../imgComponents/create.png";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";

const PRICE_LIST_v2 = () => {
  const [data, setData] = useState([]);
  const [menssage, setMenssage] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const dialog = useDialog();
  const notificationSystemRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [total_rows, settotal_rows] = useState("");

  const handleSubmit = async (a, b) => {
    setmodalT(true);
    setData([]);
    setIsLoading(true);
    setMenssage("Consultando lista de precios...");
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_price_list();
            var ndt = update_null_identifiers(dt?.data, update_identifiers);
            setData(ndt);
            setIsLoading(false);
            if (a) {
              handle_alert(b, a)
            }
            setmodalT(false);
          } catch (err) {
            setData([]);
            setMenssage("");
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const [productsData, setProductsData] = useState([]);
  const [currentStoreIndex, setCurrentStoreIndex] = useState(0);

  const handle_detail_warehouse = async (id) => {
    try {
      const products = await get_price_list_products(id);
    setProductsData((prevProductsData) => [
        ...prevProductsData,
        ...products.data.map((product) => ({
          id: id,
          name: data.find((store) => store.id === id)?.name,
          ...product,
        })),
      ]);

    } catch (err) {
      console.log(err);
    }

    // Incrementar el índice de la tienda actual
    setCurrentStoreIndex((prevIndex) => prevIndex + 1);
  };

  // Llamar a la función para obtener los datos de manera recursiva
  const fetchProductsRecursively = () => {
    if (currentStoreIndex < data.length) {
      handle_detail_warehouse(data[currentStoreIndex].id);
    }
  };

  // Iniciar la obtención de datos cuando el componente se monte
  useEffect(() => {
    fetchProductsRecursively();
  }, []);

  // Llamar a la función recursiva cuando se actualice el índice
  useEffect(() => {
    fetchProductsRecursively();
  }, [currentStoreIndex, data]);


  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  useEffect(() => {

    const groupedProducts = {};

    // Iteramos a través de los productos y los agrupamos por código
    productsData.forEach((product) => {
      if (!groupedProducts[product.code]) {
        // Si no existe el grupo para este código, lo creamos
        groupedProducts[product.code] = {
          code: product.code,
          product_name: product.product_name,
          store: [],
        };
      }

      // Agregamos el producto a su grupo correspondiente
      groupedProducts[product.code].store.push(product);
    });

    // Convertimos el objeto en un arreglo de grupos
    const groupedProductsArray = Object.values(groupedProducts);

    console.log(groupedProductsArray);

  }, [productsData]);

  console.log(productsData);



  const handle_reconsult = (result) => {
    if (result?.confirm) {
      handleSubmit(result?.confirm, result.msj);
    } else if (result?.confirm === false) {
      handle_alert(result.msj, result.confirm);
    }
  };

  const handle_alert = (msj, status) => {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);
  const columns = useMemo(
    () => [
      {
        header: "Nombre de la lista de precios",
        accessorKey: "name",
        id: "name",
        size: 900
      },

    ],
    []
  );


  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }

  const update_identifiers =
    [
      { name: 'string' }
    ];



  return (
    <div >
      <div></div>
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
      <BREADCRUMBS niveles={
        [
          { label: "LISTA DE PRECIOS", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <div className="new_table_v2">
        <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          onStateChange={handleStateChange}
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          enableRowActions
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          enableExpandAll={false}
          enableColumnDragging={false}
          initialState={{ showGlobalFilter: true, showColumnFilters: true }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
          muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              Header: () => (
                <div>
                  <img
                    title="Crear Lista de Precios"
                    src={CREATE}
                    className=""
                    onClick={async () => {
                      const result = await CustomDialog(
                        <CREATE_UPDATE
                          price_list={""}
                          method={"CREATE"}
                          handleSubmit={handleSubmit}
                        ></CREATE_UPDATE>,
                        {
                          className: "custom-modal",
                          title: "Crear Lista de Precios",
                          showCloseIcon: true,
                          isCanClose: false,
                        }
                      );
                    }}
                  ></img>
                </div>
              ),
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              settotal_rows(table);
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
              closeMenu={closeMenu}
              setmodalT={setmodalT}
              row={row}
              handleSubmit={handleSubmit}
              setreconsult={setreconsult}
              refresh_token={refresh_token}
              alertas={alertas}
              handle_reconsult={handle_reconsult}
            />
          ]}
          renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table}
              file_name={"Lista de Precios"}
              update_identifiers={update_identifiers}
              settotal_rows={settotal_rows}
            />
          )
          }
        />
      </div>
      {/* <div className="TablePriceList">
        <div >
          <TableContainer
            columns={columns}
            data={data}
            exportar={true}
            function_ext={
              <img alt="" src={product_img} className="reportInvoice" title="Reporte de productos"
                onClick={()=>window.location="/PRICE_LIST_REPORT"}
              ></img>
            }
            nametable={"Lista de Precios"}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <PRODUCTS subtabla={true}/>
                </div>
              );
            }}
          />
        </div>
      </div> */}
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>

    </div>
  );
};

export default PRICE_LIST_v2;
