import { useEffect, useState, Fragment } from 'react';
import {
  Box,
  Button,
  MenuItem,
} from '@mui/material';

import { CustomDialog, Confirm } from "react-st-modal";

//Date Picker Imports

import FormControl from '@mui/material/FormControl';

import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import PUBLIC from "../../../../../imgComponents/publico_general.png";
import PDF from "../../../../../imgComponents/pdf.png";
import RECARGA from "../../../../../imgComponents/actualizar.png";
import INVOICE from "../../../../../imgComponents/invoice.png";
import CP from "../../../../../imgComponents/invoice_cp.png";
import REFACTURAR from "../../../../../imgComponents/re-facturar.png";
import REFACTURARPG from "../../../../../imgComponents/refacturar-pg.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MaterialUISwitch } from "../../../../../main_components/material_switch/switch";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SaveIcon from '@mui/icons-material/Save';
import DoorBackIcon from '@mui/icons-material/DoorBack';
import CONSULT_CASHCLOSING_SECTORS from './sectors';
const SEARCH_FILTERS = (props) => {

  const [count, setcount] = useState(2);

  return (
    <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>

      <FormControl required sx={{ width: 220 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='GUARDAR LIQUIDACIÓN'
            sx={{ width: "100%", backgroundColor: "green", fontSize: "11.5px" }}
            // onClick={(e) => {
            //   props.handel_save_cashclosing()
            // }}
            onClick={async () => {
              const result = await Confirm("¿Esta usted seguro de guardar la liquidación?", "Guardar Liquidación", "Si", "No");
              if (result) { props.handel_save_cashclosing() } else { }
            }}
          >
            <SaveIcon></SaveIcon>GUARDAR LIQUIDACIÓN
          </Button>
        </Box>
      </FormControl>

      <FormControl required sx={{ width: 220 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='CERRAR LIQUIDACIÓN'
            sx={{ width: "100%", backgroundColor: "#de6565ed", fontSize: "11.5px" }}
            // onClick={(e) => {
            //   props.handelCashclosing()
            // }}
            onClick={async () => {
              const result = await Confirm("¿Esta usted seguro de cerrar la liquidación?", "Cerrar Liquidación", "Si", "No");
              if (result) { props.handelCashclosing() } else { }
            }}
          >
            <DoorBackIcon></DoorBackIcon>CERRAR LIQUIDACIÓN
          </Button>
        </Box>
      </FormControl>
      <FormControl required sx={{ width: 70 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='GENERAR FACTURA PUBLICO EN GENERAL'
            sx={{ height: '35px', borderRadius: "15px", width: "50%", backgroundColor: 'rgb(240, 202, 112)' }}
            // onClick={(e) => {
            //   props.handel_toinvoce()
            // }}
            onClick={async () => {
              const result = await Confirm("¿Esta usted seguro de facturar todo publico en general?", "Facrtura Oportuno", "Si", "No");
              if (result) { props.handel_toinvoce() } else { }
            }}
          >
            <img src={PUBLIC}></img>
          </Button>
        </Box>
      </FormControl>
      <FormControl required sx={{ width: 70 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='GENERAR CARTA PORTE'
            sx={{ height: '35px', borderRadius: "15px", width: "50%", backgroundColor: 'transparent' }}
            // onClick={(e) => {
            //   props.handel_toinvoce_fromdocument()
            // }}
            onClick={async () => {
              if (props?.id_sale_type_fk_dt === 2) {
                await CustomDialog(
                  <CONSULT_CASHCLOSING_SECTORS sectors={props.sectors_data} id_route={props.id_route_cashclosing} ></CONSULT_CASHCLOSING_SECTORS>,
                  {
                    className: "custom-modal-2",
                    title: "Configurar venta",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );

              } else if (props?.id_sale_type_fk_dt === 1){
                const result = await Confirm("¿Esta usted seguro de generar todas las cartas porte de esta liquidación?", "Carta Porte", "Si", "No");
                if (result) { props.handel_toinvoce_fromdocument() } else { }
              }

            }}

          >
            <img src={CP}></img>
          </Button>
        </Box>
      </FormControl>

      <FormControl required sx={{ width: 120 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='CONSULTAR'
            sx={{ width: "100%", backgroundColor: 'rgb(158, 90, 175)', fontSize: "11.5px" }}
            onClick={(e) => {
              console.log(count % 2)
              if (count % 2 === 0) {
                props.handle_filter_bonus_dev(false);
                setcount(count + 1);
              } else {
                props.handle_filter_bonus_dev(true);
                setcount(count + 1);
              }

            }}
          >
            <FilterAltIcon></FilterAltIcon>{count % 2 === 0 ? "BON/DEV" : "VENTAS"}
          </Button>
        </Box>
      </FormControl>


    </Box>
  );
};

export default SEARCH_FILTERS;
