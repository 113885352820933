import React, { useState, useEffect } from "react";
import "../generate_invoice/factura.css";
import "./cartaporte.css";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Button from "@mui/material/Button";
import Borrar from "../../../imgComponents/borrar.png";
import { createTheme } from "@mui/material/styles";
import Spinner from "../../../imgComponents/S.gif";
import Relacionar from "../../Relacionar";
import { DistanceMatrixService } from "@react-google-maps/api";
import { CustomDialog } from "react-st-modal";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
//Conponentes
import { calculate_concept, post_invoices } from "../../../services/invoices/invoice";
import { get_series } from "../../../services/invoices/invoice";
import { pagination } from "../../../main_components/pagination/pagination";
import { get_address } from "../../../services/businesspartners/businesspartners";
import { get_drivers } from "../../../services/invoices/invoice";
import { get_vehicles } from "../../../services/invoices/invoice";
import { get_concept_ctp } from "../../../services/invoices/invoice";
import JOIN_INVOICE from "../join_invoice/join_invoice";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import "../generate_invoice/factura.css";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const { compose, withProps, lifecycle } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
} = require("react-google-maps");

const op5 = [
  { value: "No", label: "NO" },
  { value: "Si", label: "SI" },
];

const op6 = [
  { value: "Origen", label: "ORIGEN" },
  { value: "Destino", label: "DESTINO" },
];

const rl0T = [
  { value: "", label: "Selecciona un tipo de relacion" },

  { value: "04", label: "Sustitución de los CFDI previos" },
  { value: "05", label: "Traslados de mercancías facturados previamente" },
];

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "green",
    borderWidth: 2,
    height: 42,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "1.2rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
};
function CARTA_PORTE_V2() {
  const [busnes, setBusnes] = useState([]);
  const [selected_drivers, setselected_drivers] = useState([]);
  const [select_driver, setselect_driver] = useState([]);
  const [vehicle, setvehicle] = useState([]);
  const [vehicles, setvehicles] = useState([]);
  const [direc, setDirec] = useState([]);
  const [distancia, setDistancia] = useState([]);
  const [tiempo, setTiempo] = useState([]);
  const [ser, setSer] = useState([]);
  const [concep, setConcep] = useState([]);
  const [concept, setConcept] = useState([]);
  const [bussines, setBussines] = useState([]);
  const [conductor, setConductor] = useState([]);
  const [direct, setDirect] = useState([]);
  const [uuid_rel, setuuid_rel] = useState(false);
  const [numSer, setNumSer] = useState();
  const [idUbicacion, setidUbicacion] = useState("");
  const [origenDestino, setorigenDestino] = useState("Destino");
  const [OrigenDefinido, setOrigenDefinido] = useState("");
  const [Origendefault, setOrigendefault] = useState("");
  const [isLoding, setIsLoding] = useState(false);
  const [opp5, setOP5] = useState("No");
  const [fi, setfi] = useState("");
  const [conceptomandar, setconceptomandar] = useState([]);
  const [newRE2, setNewRe2] = useState("");
  const [cantidad, setCantidad] = useState(1);
  const [people, setPeople] = useState([]);
  const [domicilio, setdomicilio] = useState([]);
  console.log(domicilio);
  const [domiciliomandar, setdomiciliomandar] = useState([]);
  const [vSumTotal, setVSumTotal] = useState(0);
  const [vSumPesoTotal, setVSumPesoTotal] = useState(0);
  const [vSumDistancioaRecorrida, setVSumDistancioaRecorrida] = useState(0);
  const [mostrarComponente3, setMostrarComponente3] = useState(false);
  const [vSumTotalSub, setVSumTotalSub] = useState(0);
  const [vSumDescuento, setVDescuento] = useState(0);
  const [vSumTotalCantidad, setVSumTotalCantidad] = useState(0);
  const [psinIva, setpsinIva] = useState();
  const [precioP, setprecioP] = useState("");
  const [pesoK, setpesoK] = useState("");
  const [cantidadP, setCantidadP] = useState("");
  const [logisticainversarecolecciondevolucion_data, setlogisticainversarecolecciondevolucion_data] = useState(false);
  const [editDescripcion, setEditDescripcion] = useState("");
  const [nota, setNota] = useState("");
  const [editBusnesrfc, seteditBusnesrfc] = useState("");
  const [numPermisoSCT, setnumPermisoSCT] = useState("Permiso no contemplado en el catálogo");
  const [permisoSCT, setpermisoSCT] = useState("TPXX00");
  const [mensajeModal, setmensajeModal] = useState("");



  const handleChangeeBorrarP = (event) => {
    setprecioP(" " + concept.valor_unitario);
  };
  const handleChangeeBorrarK = (event) => {
    setpesoK(" " + concept.peso_en_kg);
  };
  const handleeditRFC = (event) => {
    seteditBusnesrfc(event.target.value);
  };
  const handlePerSCT = (event) => {
    setpermisoSCT(event.target.value);
  };
  const handleNumPerSCT = (event) => {
    setnumPermisoSCT(event.target.value);
  };
  const mostarMapa = () => {
    setMostrarComponente3(true);
  };
  const ocultarMapa = () => {
    setMostrarComponente3(false);
  };
  const handleChangeeDescripcion = (event) => {
    setEditDescripcion(event.target.value);
  };
  const handleChangeeNota = (event) => {
    setNota(event.target.value);
  };
  const handleChangeeCantidad = (event) => {
    setCantidad(event.target.value);
  };
  const handleChangeePrecionP = (event) => {
    setprecioP(event.target.value);
  };
  const handleChangeepesoK = (event) => {
    setpesoK(event.target.value);
  };

  const handleRemoveItem = (e) => {
    const id = e.target.getAttribute("name");
    setPeople(people.filter((item) => item.id != id));
    setconceptomandar(conceptomandar.filter((item) => item.id != id));
  };

  const handle_remove_driver = (e) => {
    const id = e.target.getAttribute("name");
    setselected_drivers(selected_drivers.filter((item) => item.id != id));
  };

  const handleRemoveItem2 = (e) => {
    const id = e.target.getAttribute("name");
    setdomicilio(domicilio.filter((item) => item.id != id));
    setdomiciliomandar(domiciliomandar.filter((item) => item.id != id));
  };
  const handleChangeOriDes = (event) => {
    setorigenDestino(event.target.value);
  };
  const handleChangee0 = (event) => {
    setNumSer(event.target.value);
  };

  function numType() {
    var indice2 = ser.findIndex((filio) => filio.serie === numSer);

    if (indice2 === -1) {
      try {
        setNewRe2(ser[0].serie);
      } catch (err) {
        console.log(err);
      }
    } else {
      setNewRe2(ser[indice2]?.serie);
    }
  }
  const handleChangee5 = (event) => {
    setOP5(event.target.value);
  };
  const handleOnSearch = (string, results) => { };
  const handleOnSearch2 = (string, results) => {
    setConcept("");
    setCantidadP("");
    setprecioP("");
    setpsinIva("");
    setEditDescripcion("");
  };

  const handleOnHover = (result) => { };
  const handleOnHover2 = (result) => { };
  const handleOnSelect = (item) => {
    setBusnes(item);
    console.log(item.id)
    handle_address(item.id);
  };
  const handleOnSelect4 = (item) => {
    setvehicle(item);
    handle_get_concept();
  };

  const handleOnSelect5 = (item) => {
    setDirect(item);
    mostarMapa();
  };
  const handleOnSelect2 = (item) => {
    setConcept(item);
    console.log("------------------------");
    console.log(item);
    console.log("------------------------");

    setEditDescripcion(item?.descripcion);
  };
  const handleOnFocus = () => {
    console.log("Focused");
    setConcept("");
    setCantidadP("");
    setprecioP("");
    setpsinIva("");
    setEditDescripcion("");
  };


  const handleOnFocus2 = () => {
    console.log("Focused");
    handle_get_concept();
  };
  const handleOnClear = () => {
    console.log("Cleared");
  };
  const handleOnClear2 = () => {
    console.log("Cleared");
  };


  useEffect(() => {
    const handlesumarCantidad = () => {
      const sumar = people
        .map((saldo) => parseFloat(saldo.cantidad))
        .reduce((previous, current) => {
          return previous + current;
        }, 0);
      setVSumTotalCantidad(sumar);
    };
    handlesumarCantidad();
  });

  useEffect(() => {
    const handelIDUB = () => {
      if (origenDestino === "Origen") {
        setidUbicacion("OR000001");
      }

      if (origenDestino === "Destino") {
        setidUbicacion("DE000001");
      }
      if (origenDestino === "") {
        setidUbicacion("");
      }
    };
    handelIDUB();
  });

  useEffect(() => {
    const handlesumarT = () => {
      const sumar = people
        .map((saldo) => parseFloat(saldo.total))
        .reduce((previous, current) => {
          return previous + current;
        }, 0);
      setVSumTotal(sumar);
    };
    handlesumarT();
  });

  useEffect(() => {
    const handlesumarPeso = () => {
      const sumar = people
        .map((saldo) => parseFloat(saldo.pesoenkg))
        .reduce((previous, current) => {
          return previous + current;
        }, 0);
      setVSumPesoTotal(sumar);
    };
    handlesumarPeso();
  });

  useEffect(() => {
    const handlesumarDist = () => {
      const dist = domicilio.map((x) => Number(x.distancia));
      const newdist = dist.sort((a, b) => (a > b ? 1 : -1));

      console.log(newdist);

      var sumar = newdist[0];
      var sumDistancia;
      if (newdist[1] !== undefined) {
        for (var i = 1; i < newdist.length; i++) {
          console.log(newdist[i]);
          sumar = newdist[i] - sumar;
        }
        sumDistancia = sumar + newdist[0] + 1;
      } else {
        sumDistancia = newdist[0] + 1;
      }

      setVSumDistancioaRecorrida(sumDistancia?.toFixed(2));
    };

    handlesumarDist();
  });

  useEffect(() => {
    const handlesumarT = () => {
      const sumar = people
        .map((saldo) => parseFloat(saldo.subtotal))
        .reduce((previous, current) => {
          return previous + current;
        }, 0);
      setVSumTotalSub(sumar);
    };
    handlesumarT();
  });

  useEffect(() => {
    handle_businesspartners();
    handle_series();
  }, []);

  useEffect(() => {
    const handlesumarT = () => {
      const sumar = people
        .map((saldo) => parseFloat(saldo.descuento))
        .reduce((previous, current) => {
          var cuenta = previous + current;
          if (cuenta === 0) {
            var retorna = null;
          } else {
            retorna = cuenta;
          }
          return retorna;
        }, 0);
      setVDescuento(sumar);
    };
    handlesumarT();
  });

  const handle_calculate_concept = async () => {
    var data = {
      concepto: {
        concept: concept.concept,
        descripcion: editDescripcion,
        clave_prod_serv: concept.clave_prod_serv,
        clave_unidad: concept.clave_unidad,
        unidad: concept.unidad,
        no_identificacion: concept.no_identificacion,
        valor_unitario:
          Number(precioP) || Number(psinIva) || concept.valor_unitario,
        peso_en_kg: pesoK || concept.peso_en_kg,
        cantidad: Number(cantidad),
        descuento: concept.descuento,
        importe: concept.importe,
        impuestos: concept.impuestos,
        implocales: concept.implocales,
      },
    };

    try {
      const dt = await calculate_concept(data);

      const newConcept = dt.data;
      const newConcept02 = dt.data.concepto.impuestos.traslados;
      const newConcept02I = dt.data.concepto.implocales.traslados;
      const newConcept03 = dt.data.concepto.impuestos;
      const newConcept03I = dt.data.concepto.implocales;
      var sumall2;
      const sumall = newConcept02
        .map((item) => item.importe)
        .reduce((prev, curr) => prev + curr, 0);
      if (newConcept02I != null) {
        sumall2 = newConcept02I
          .map((item) => item.importe)
          .reduce((prev, curr) => prev + curr, 0);
      } else {
        sumall2 = 0;
      }

      var cont = people.length;

      function isExist(id) {
        if (people.indexOf(id) > -1) {
          return true;
        } else {
          return false;
        }
      }

      function establecer(cont) {
        if (isExist(cont) === true) {
          cont = cont + 2;
        } else {
          cont = cont + 1;
          if (isExist(cont) === true) {
            cont = cont + 2;
          } else {
            cont = cont + 1;
            if (isExist(cont) === true) {
              establecer(cont);
            } else {
              cont = cont + 1;
            }
          }
        }
      }

      establecer(cont);
      var impuestolocal;
      if (concept.implocales?.traslados === null) {
        impuestolocal = "";
      } else {
        impuestolocal =
          concept.implocales?.traslados[0].implocaltrasladado === "ISH"
            ? "ISH(" + newConcept02I[0].importe + ")"
            : "";
      }
      setPeople([
        ...people,
        {
          id: cont,
          subtotal: newConcept.concepto.importe,
          total: newConcept.total,
          timpuestos: sumall,
          timpuestosLocales: sumall2,
          precio: newConcept.concepto.valorunitario,
          concepto: concept.concept,
          implocales: impuestolocal,
          pesoenkg: newConcept.concepto.pesoenkg,
          impuestos:
            (concept.impuestos?.traslados[0]?.impuesto === "001"
              ? "ISR" +
              "(" +
              (newConcept02[0]?.importe === 0
                ? "0.0000"
                : newConcept02[0]?.importe) +
              ")"
              : concept.impuestos?.traslados[1]?.impuesto === "001"
                ? "ISR" +
                "(" +
                (newConcept02[1]?.importe === 0
                  ? "0.0000"
                  : newConcept02[1]?.importe) +
                ")"
                : concept.impuestos?.traslados[2]?.impuesto === "001"
                  ? "ISR" +
                  "(" +
                  (newConcept02[2]?.importe === 0
                    ? "0.0000"
                    : newConcept02[2]?.importe) +
                  ")"
                  : "") +
            (concept.impuestos?.traslados[0]?.impuesto === "002"
              ? "IVA" +
              "(" +
              (newConcept02[0]?.importe === 0
                ? "0.0000"
                : newConcept02[0]?.importe) +
              ")"
              : concept.impuestos?.traslados[1]?.impuesto === "002"
                ? "IVA" +
                "(" +
                (newConcept02[1]?.importe === 0
                  ? "0.0000"
                  : newConcept02[1]?.importe) +
                ")"
                : concept.impuestos?.traslados[2]?.impuesto === "002"
                  ? "IVA" +
                  "(" +
                  (newConcept02[2]?.importe === 0
                    ? "0.0000"
                    : newConcept02[2]?.importe) +
                  ")"
                  : "") +
            (concept.impuestos?.traslados[0]?.impuesto === "003"
              ? "IEPS" +
              "(" +
              (newConcept02[0]?.importe === 0
                ? "0.0000"
                : newConcept02[0]?.importe) +
              ")"
              : concept.impuestos?.traslados[1]?.impuesto === "003"
                ? "IEPS" +
                "(" +
                (newConcept02[1]?.importe === 0
                  ? "0.0000"
                  : newConcept02[1]?.importe) +
                ")"
                : concept.impuestos?.traslados[2]?.impuesto === "003"
                  ? "IEPS" +
                  "(" +
                  (newConcept02[2]?.importe === 0
                    ? "0.0000"
                    : newConcept02[2]?.importe) +
                  ")"
                  : ""),
          descripcion: newConcept.concepto.descripcion,
          claveprodserv: newConcept.concepto.claveprodserv,
          claveunidad: newConcept.concepto.claveunidad,
          unidad: newConcept.concepto.unidad,
          noidentificacion: newConcept.concepto.noidentificacion,
          valorunitario: newConcept.concepto.valorunitario,
          cantidad: newConcept.concepto.cantidad,
          descuento: newConcept.concepto.descuento,
          importe: newConcept.concepto.importe,
        },
      ]);

      const CONCEPTOOO = [
        ...conceptomandar,
        {
          id: cont,
          descripcion: newConcept.concepto.descripcion,
          claveprodserv: newConcept.concepto.claveprodserv,
          claveunidad: newConcept.concepto.claveunidad,
          unidad: newConcept.concepto.unidad,
          noidentificacion: newConcept.concepto.noidentificacion,
          valorunitario: newConcept.concepto.valorunitario,
          cantidad: newConcept.concepto.cantidad,
          descuento: newConcept.concepto.descuento,
          importe: newConcept.concepto.importe,
          impuestos: newConcept03,
          implocales: newConcept03I,
          pesoenkg: newConcept.concepto.pesoenkg,
        },
      ];

      setconceptomandar(CONCEPTOOO);
      setConcept(" ");
      setCantidadP(" ");
      setprecioP(" ");
      setEditDescripcion("");
    } catch (err) { }
  };



  const SetDireccion = () => {
    handle_drivers();
    handel_vehicle();
    if (fi === "") {
      ocultarMapa();
      alert("El campo Fecha Hora Salida/Llegada no puede ir vacía");
      return;
    }

    var cont = domicilio.length;

    cont = getNextId(cont);

    setdomicilio([
      ...domicilio,
      {
        id: cont,
        estado: getEstadoAbrev(direct),
        direccion: buildDireccion(direct),
        fecha: fi + ":00",
        distancia: distancia,
        remitente: busnes.rfc,
        idubicacion: origenDestino,
      },
    ]);

    setdomiciliomandar([
      ...domiciliomandar,
      {
        id: cont,
        tipoubicacion: origenDestino,
        idubicacion: idUbicacion,
        fechahorasalidallegada: fi + ":00",
        distanciarecorrida: distancia,
        rfcremitentedestinatario: editBusnesrfc || busnes.rfc,
        domicilio: {
          estado: getEstadoAbrev(direct),
          codigopostal: direct.codigoPostal,
        },
      },
    ]);

    function getNextId(currentId) {
      var newId = currentId;
      while (domicilio.findIndex(item => item.id === newId) !== -1) {
        newId++;
      }
      return newId;
    }

    function getEstadoAbrev(direct) {
      const estados = {
        "AGUASCALIENTES": "AGU",
        "BAJA CALIFORNIA NORTE": "BCN",
        "BAJA CALIFORNIA SUR": "BCN",
        "CAMPECHE": "CAM",
        "CHIAPAS": "CHP",
        "CHIHUAHUA": "CHH",
        "COAHUILA": "COA",
        "COLIMA": "COL",
        "CIRCUNSCRIPCIÓN 1": "CR1",
        "CIRCUNSCRIPCIÓN 2": "CR2",
        "CIRCUNSCRIPCIÓN 3": "CR3",
        "CIRCUNSCRIPCIÓN 4": "CR4",
        "CIRCUNSCRIPCIÓN 5": "CR5",
        "CIRCUNSCRIPCION 1": "CR1",
        "CIRCUNSCRIPCION 2": "CR2",
        "CIRCUNSCRIPCION 3": "CR3",
        "CIRCUNSCRIPCION 4": "CR4",
        "CIRCUNSCRIPCION 5": "CR5",
        "CIUDAD DE MEXICO": "DFI",
        "CIUDAD DE MÉXICO": "DFI",
        "DURANGO": "DUR",
        "GUANAJUATO": "GUA",
        "GUERRERO": "GRO",
        "HIDALGO": "HID",
        "JALISCO": "JAL",
        "MEXICO": "MEX",
        "MÉXICO": "MEX",
        "ESTADO DE MEXICO": "MEX",
        "MICHOACÁN": "MIC",
        "MICHOACAN": "MIC",
        "MORELOS": "MOR",
        "NACIONAL": "NAC",
        "NAYARIT": "NAY",
        "NUEVO LEON": "NLE",
        "NUEVO LEÓN": "NLE",
        "OAXACA": "OAX",
        "PUEBLA": "PUE",
        "QUERÉTARO": "QUE",
        "QUERETARO": "QUE",
        "QUINTANA ROO": "ROO",
        "SAN LUIS POTOSI": "SLP",
        "SAN LUIS POTOSÍ": "SLP",
        "SINALOA": "SIN",
        "SONORA": "SON",
        "TABASCO": "TAB",
        "TAMAULIPAS": "TAM",
        "TLAXCALA": "TLA",
        "VERACRUZ": "VER",
        "VERACRUZ DE IGNACIO DE LA LLAVE": "VER",
        "YUCATÁN": "YUC",
        "YUCATAN": "YUC",
        "ZACATECAS": "ZAC"
      };
      return estados[direct.estado] || "";
    }

    function buildDireccion(direct) {
      return `${direct.calle}, ${direct.colonia}, ${direct.municipio}, ${direct.estado}, ${direct.codigoPostal}`;
    }

  };



  const hanlde_set_driver = (item) => {
    ocultarMapa();    
    setselected_drivers([
      ...selected_drivers,
      {
        id: select_driver.id,
        nombrefigura: select_driver.driver_name,
        rfcfigura: select_driver.rfc,
        numlicencia: select_driver.license_num,
      },
    ]);
  };

  const hanlde_select_driver = (item) => {
    setselect_driver(item)
  };


  const handle_series = async () => {
    try {
      const dt = await get_series("T");
      setSer(dt.data);
    } catch (err) {
      setSer([]);
    }
  };

  const handle_businesspartners = async (event) => {
    setIsLoding(true);
    setmensajeModal("Ingresando...");
    try {
      var dt = await pagination(3, "bp", null);
      setBussines(dt === null ? [] : dt);
      setIsLoding(false);
    } catch (err) {
      setIsLoding(false);
      setBussines([]);
    }
  };

  const handle_address = async (id) => {
    try {
      const dt = await get_address(id);
      var data = dt.data
      data.push({
        nombre: "FRICONGELADOS DESTINO",
        calle: "BELISARIO DOMINGUEZ",
        numExterior: "588",
        numInterior: "SN",
        colonia: "BARRIO NUEVO",
        localidad: "ORIZABA",
        referencia: "",
        municipio: "ORIZABA",
        estado: "VERACRUZ",
        codigoPostal: "94340",
      });
      console.log(data);
      setDirec(data);
    } catch (err) {
      console.log("puede aver mas de un mismo socio de negocios");
    }
  };

  const handle_drivers = async (event) => {
    try {
      const dt = await get_drivers();
      setConductor(dt.data);

    } catch (err) {

    }
  };

  const handel_vehicle = async (event) => {
    try {

      const dt = await get_vehicles();
      setvehicles(dt.data)
    } catch (err) {
      setvehicles([])
    }
  };

  const handle_get_concept = async () => {
    try {
      const dt = await get_concept_ctp();
      setConcep(dt.data);

    } catch (err) {
      setConcep([]);
    }

  }

  var dato = {
    comprobante: {
      serie: newRE2,

      formapago: opp5,

      condicionesdepago:  opp5,

      subtotal: vSumTotalSub,

      descuento: vSumDescuento,

      moneda: "MXN",

      tipocambio: null,

      total: vSumTotal,

      tipodecomprobante: "T",

      exportacion: "01",

      metodopago: null,
    },

    receptor: {
      id: busnes.id,

      nombre: busnes.business_partner,

      rfc: busnes.rfc,

      usocfdi: "S01",

      residenciafiscal: null,

      numregidtrib: null,

      regimenfiscalreceptor: null,

      domiciliofiscalreceptor: null,
    },

    conceptos: conceptomandar,

    cartaporte: {
      transpinternac: opp5,

      totaldistrec: vSumDistancioaRecorrida,
    },

    ubicaciones: domiciliomandar,

    mercancia: {
      pesobrutototal: vSumPesoTotal,

      unidadpeso: "KGM",

      numtotalmercancias: vSumTotalCantidad,
    },

    autotransporte: {
      permsct: permisoSCT,

      numpermisosct: numPermisoSCT,

      identificacionvehicular: {
        id: vehicle.id,

        placavm: vehicle.license_plate,

        aniomodelovm: vehicle.year_model,
      },
    },

    figuratransporte: selected_drivers,
    cfdirelacionados:
      uuid_rel === undefined ? null : uuid_rel === false ? null :
        [
          {
            tiporelacion: uuid_rel?.motivo,
            uuid: uuid_rel?.uuid_sat
          },
        ],

    nota: nota,
  };
  console.log(dato);
  const handleSubmit = async (event) => {
    event.preventDefault();

    var dato = {
      comprobante: {
        serie: newRE2,
        formapago: opp5,
        condicionesdepago:  opp5,
        subtotal: vSumTotalSub,
        descuento: vSumDescuento,
        moneda: "MXN",
        tipocambio: null,
        total: vSumTotal,
        tipodecomprobante: "T",
        exportacion: "01",
        metodopago: null,
      },
      receptor: {
        id: busnes.id,
        nombre: busnes.business_partner,
        rfc: busnes.rfc,
        usocfdi: "S01",
        residenciafiscal: null,
        numregidtrib: null,
        regimenfiscalreceptor: null,
        domiciliofiscalreceptor: null,
      },
      conceptos: conceptomandar,
      cartaporte: {
        transpinternac: opp5,
        totaldistrec: vSumDistancioaRecorrida,
      },
      ubicaciones: domiciliomandar,
      mercancia: {
        pesobrutototal: vSumPesoTotal,
        unidadpeso: "KGM",
        numtotalmercancias: vSumTotalCantidad,
        logisticainversarecolecciondevolucion: logisticainversarecolecciondevolucion_data === true ? "Sí" : null
      },
      autotransporte: {
        permsct: permisoSCT,
        numpermisosct: numPermisoSCT,
        identificacionvehicular: {
          id: vehicle.id,
          placavm: vehicle.license_plate,
          aniomodelovm: vehicle.year_model,
        },
      },
      figuratransporte: selected_drivers,
      cfdirelacionados:
        uuid_rel === undefined ? null : uuid_rel === false ? null :
          [
            {
              tiporelacion: uuid_rel?.motivo,
              uuid: uuid_rel?.uuid_sat
            },
          ],

      nota: nota,
    };

    setIsLoding(true);
    setmensajeModal("Generando carta porte...");

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            await post_invoices(dato);
            alert("Se creo con exito la carta porte");
            setIsLoding(false);
            window.location = "/cartaporte";
          } catch (err) {
            catchErrorModul("Cuentas Contables", JSON.stringify(dato), JSON.stringify(err?.response === undefined ? "Token expirado" : err?.response));

            var erroM = err?.response?.data?.errorMessage;
            alert(erroM);
            setIsLoding(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }

  }

  const Maping = compose(
    withProps({
      googleMapURL:
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyC7cuVKbSoJoTs6X5XxO3glHExD6Ax62Xo&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: (
        <div style={{ width: `810px`, height: `400px`, margin: "0 auto" }} />
      ),
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap,

    lifecycle({
      componentDidMount() {
        const dist = domicilio.map((x) => x.idubicacion);
        if (dist.includes("Origen") === false) {
          setOrigenDefinido("");
          setOrigendefault("ORIZABA, VER, FRICONGELADOS");
        } else {
          setOrigendefault("");
        }
        const DirectionsService = new window.google.maps.DirectionsService();
        if (origenDestino === "Origen") {
          setOrigenDefinido(
            direct.calle +
            ", " +
            direct.colonia +
            ", " +
            direct.municipio +
            ", " +
            direct.estado +
            ", " +
            direct.codigoPostal
          );
          setOrigendefault("");
        } else {
          if (OrigenDefinido === "") {
            setOrigendefault("ORIZABA, VER, FRICONGELADOS");
          } else {
            setOrigendefault("");
          }
        }
        DirectionsService.route(
          {
            origin: OrigenDefinido || Origendefault,
            destination:
              direct.calle +
              ", " +
              direct.colonia +
              ", " +
              direct.municipio +
              ", " +
              direct.estado +
              ", " +
              direct.codigoPostal,

            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              this.setState({
                directions: result,
              });
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        );
      },
    })
  )((props) => (
    <>
      <GoogleMap defaultZoom={7}>
        {props.directions && (
          <DirectionsRenderer directions={props.directions} />
        )}
      </GoogleMap>
      <DistanceMatrixService
        options={{
          destinations: [
            direct.calle +
            ", " +
            direct.colonia +
            ", " +
            direct.municipio +
            ", " +
            direct.estado +
            ", " +
            direct.codigoPostal,
          ],
          origins: [OrigenDefinido || Origendefault],
          travelMode: "DRIVING",
        }}
        callback={(res) => {
          try {
            var timee = res.rows[0].elements[0].duration.value;
            console.log(timee);
            var tiemponuevoM = Math.round(timee / 3600);
            var Ttiempohoras = Number(tiemponuevoM) + 1;
            setTiempo(Ttiempohoras);
            var distanciaRecorrida = res.rows[0].elements[0].distance.value;
            var ndistancireocrrida = distanciaRecorrida / 1000;
            setDistancia(ndistancireocrrida?.toFixed(2));
          } catch (error) {
            console.log(error);
          }
        }}
      />
    </>
  ));

  return (
    <>
      <BREADCRUMBS
        niveles={
          [
            { label: "CARTA PORTE", path: null },
          ]
        }
      ></BREADCRUMBS>


      <MODAL_TABLE modalGeneral={isLoding} modalT={isLoding} message={mensajeModal || "Buscando..."}></MODAL_TABLE>


      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '90%',
          m: 1,
          margin: "0 auto"
        }}
      >
        <TextField
          id="Serie"
          select
          label="Tipo de serie"
          value={numSer}
          sx={{ m: 1.5, width: "33.33%" }}
          onChange={handleChangee0}
          size="small"
          name="CFDIUse"
          onClick={numType}
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            native: true,
          }}
        >
          {ser.map((option) => (
            <option key={option.value} value={option.value}>
              {option.serie}
            </option>
          ))}
        </TextField>
        <TextField
          id="condiciones de pago"
          select
          label="Transporte Internacional"
          value={opp5}
          sx={{ m: 1.5, width: "33.33%" }}
          onChange={handleChangee5}
          size="small"
          name="metodoPago"
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            native: true,
          }}
        >
          {op5.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
        <Button
          sx={{ m: 1.5, width: "33.33%" }}
          variant="contained"
          onClick={async () => {
            const result = await CustomDialog(
              <JOIN_INVOICE
                type={rl0T}
                rfc={busnes.rfc}
              ></JOIN_INVOICE>,
              {
                className: "modalCancelR",
                title: "Relacionar Factura",
                showCloseIcon: true,
              }
            );
            setuuid_rel(result);
          }
          }
        >
          {uuid_rel === undefined ? "Relacionar" : uuid_rel === false ? "Relacionar" : "UUID_SAT:" + uuid_rel?.uuid_sat + " Motivo:" + uuid_rel?.motivo}
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '90%',
          m: 1,
          margin: "0 auto",
          '@media (max-width: 1444px)': {
            width: '89.4%'
          },
        }}
      >
        <div style={{ width: "55%", margin: "0 auto" }}>
          <ReactSearchAutocomplete
            items={bussines}
            fuseOptions={{ keys: ["rfc", "business_partner"] }}
            resultStringKeyName="business_partner"
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            maxResults={10}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            onClear={handleOnClear}
            showIcon={true}
            placeholder="Buscar socio de negocios..."
            styling={{
              height: "34px",
              border: "1px solid darkgreen",
              borderRadius: "4px",
              position: "absolute",
              backgroundColor: "white",
              boxShadow: "none",
              hoverBackgroundColor: "lightgreen",
              color: "black",
              iconColor: "black",
              lineColor: "black",
              placeholderColor: "black",
              clearIconMargin: "3px 8px 0 0",
              zIndex: 10,
            }}
          />
        </div>

        <ValidationTextField
          label="RFC"
          variant="outlined"
          placeholder="RFC"
          sx={{ m: 1.5, width: "20%" }}
          value={busnes.rfc}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          select
          label="Tipo de ubicación"
          value={origenDestino}
          sx={{ m: 1.5, width: "20%" }}
          onChange={handleChangeOriDes}
          size="small"
          name="CFDIUse"
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            native: true,
          }}
        >
          {op6.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '90%',
          m: 1,
          margin: "0 auto",
          '@media (max-width: 1444px)': { width: '90%' },
        }}
      >
        <ValidationTextField
          label="Id ubicación"
          variant="outlined"
          placeholder="Id ubicación"
          sx={{ m: 1.5, width: "45%" }}
          value={idUbicacion}
          InputLabelProps={{ shrink: true }}
        />
        <ValidationTextField
          label="RFC Remitente/Destinatario"
          variant="outlined"
          placeholder="RFC Remitente/Destinatario"
          sx={{ m: 1.5, width: "40%" }}
          onChange={handleeditRFC}
          value={editBusnesrfc || busnes.rfc}
          InputLabelProps={{ shrink: true }}
        />
        <input
          type="text"
          className="date-local-cp"
          onFocus={(e) => {
            e.currentTarget.type = "datetime-local";
            e.currentTarget.focus();
          }}
          placeholder="Fecha Hora salida/llegada"
          onChange={(event) => setfi(event.target.value)}
        ></input>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '90%',
          m: 1,
          margin: "0 auto",
          '@media (max-width: 1444px)': {
            width: '89.4%'
          },

        }}
      >
        <div style={{ width: "55%", margin: "0 auto" }}>
          <ReactSearchAutocomplete
            items={direc}
            fuseOptions={{ keys: ["nombre"] }}
            resultStringKeyName="nombre"
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect5}
            onClear={handleOnClear}
            showIcon={true}
            placeholder="Buscar Dirección..."
            styling={{
              height: "34px",
              border: "1px solid darkgreen",
              borderRadius: "4px",
              backgroundColor: "white",
              boxShadow: "none",
              hoverBackgroundColor: "lightgreen",
              color: "black",
              iconColor: "black",
              lineColor: "black",
              placeholderColor: "black",
              clearIconMargin: "3px 8px 0 0",
              zIndex: 9,
            }}
          />
        </div>

        <ValidationTextField
          label="distanciarecorrida"
          variant="outlined"
          placeholder="distanciarecorrida"
          sx={{ m: 1.5, width: "20%" }}
          value={distancia + "KM"}
          InputLabelProps={{ shrink: true }}
        />

        <ValidationTextField
          label="Tiempo Estimado"
          variant="outlined"
          placeholder="Tiempo Estimado"
          sx={{ m: 1.5, width: "20%" }}
          value={tiempo + "HR"}
          InputLabelProps={{ shrink: true }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '90%',
          m: 1,
          margin: "0 auto",

        }}
      >
        <ValidationTextField
          label="Estado"
          variant="outlined"
          placeholder="Estado"
          sx={{ m: 1.5, width: "50%" }}
          value={
            (direct.estado === "AGUASCALIENTES" ? "AGU" : "") +
            (direct.estado === "BAJA CALIFORNIA NORTE" ? "BCN" : "") +
            (direct.estado === "BAJA CALIFORNIA SUR" ? "BCN" : "") +
            (direct.estado === "CAMPECHE" ? "CAM" : "") +
            (direct.estado === "CHIAPAS" ? "CHP" : "") +
            (direct.estado === "CHIHUAHUA" ? "CHH" : "") +
            (direct.estado === "COA" ? "COAHUILA" : "") +
            (direct.estado === "COLIMA" ? "COL" : "") +
            (direct.estado === "CIRCUNSCRIPCIÓN 1" ? "CR1" : "") +
            (direct.estado === "CIRCUNSCRIPCIÓN 2" ? "CR2" : "") +
            (direct.estado === "CIRCUNSCRIPCIÓN 3" ? "CR3" : "") +
            (direct.estado === "CIRCUNSCRIPCIÓN 4" ? "CR4" : "") +
            (direct.estado === "CIRCUNSCRIPCIÓN 5" ? "CR5" : "") +
            (direct.estado === "CIRCUNSCRIPCION 1" ? "CR1" : "") +
            (direct.estado === "CIRCUNSCRIPCION 2" ? "CR2" : "") +
            (direct.estado === "CIRCUNSCRIPCION 3" ? "CR3" : "") +
            (direct.estado === "CIRCUNSCRIPCION 4" ? "CR4" : "") +
            (direct.estado === "CIRCUNSCRIPCION 5" ? "CR5" : "") +
            (direct.estado === "CIUDAD DE MEXICO" ? "DFI" : "") +
            (direct.estado === "CIUDAD DE MÉXICO" ? "DFI" : "") +
            (direct.estado === "DURANGO" ? "DUR" : "") +
            (direct.estado === "GUANAJUATO" ? "GUA" : "") +
            (direct.estado === "GUERRERO" ? "GRO" : "") +
            (direct.estado === "HIDALGO" ? "HID" : "") +
            (direct.estado === "JALISCO" ? "JAL" : "") +
            (direct.estado === "MEXICO" ? "MEX" : "") +
            (direct.estado === "MÉXICO" ? "MEX" : "") +
            (direct.estado === "MICHOACÁN" ? "MIC" : "") +
            (direct.estado === "MICHOACAN" ? "MIC" : "") +
            (direct.estado === "MORELOS" ? "MOR" : "") +
            (direct.estado === "NACIONAL" ? "NAC" : "") +
            (direct.estado === "NAYARIT" ? "NAY" : "") +
            (direct.estado === "NUEVO LEON" ? "NLE" : "") +
            (direct.estado === "NUEVO LEÓN" ? "NLE" : "") +
            (direct.estado === "OAXACA" ? "OAX" : "") +
            (direct.estado === "PUEBLA" ? "PUE" : "") +
            (direct.estado === "QUERÉTARO" ? "QUE" : "") +
            (direct.estado === "QUERETARO" ? "QUE" : "") +
            (direct.estado === "QUINTANA ROO" ? "ROO" : "") +
            (direct.estado === "SAN LUIS POTOSI" ? "SLP" : "") +
            (direct.estado === "SAN LUIS POTOSÍ" ? "SLP" : "") +
            (direct.estado === "SINALOA" ? "SIN" : "") +
            (direct.estado === "SONORA" ? "SON" : "") +
            (direct.estado === "TABASCO" ? "TAB" : "") +
            (direct.estado === "TAMAULIPAS" ? "TAM" : "") +
            (direct.estado === "TLAXCALA" ? "TLA" : "") +
            (direct.estado === "VERACRUZ" ? "VER" : "") +
            (direct.estado === "VERACRUZ DE IGNACIO DE LA LLAVE"
              ? "VER"
              : "") +
            (direct.estado === "YUCATÁN" ? "YUC" : "") +
            (direct.estado === "YUCATAN" ? "YUC" : "") +
            (direct.estado === "ZACATECAS" ? "ZACATECAS" : "")
          }
          InputLabelProps={{ shrink: true }}
        />
        <ValidationTextField
          label="Localidad"
          variant="outlined"
          placeholder="Localidad"
          sx={{ m: 1.5, width: "50%" }}
          value={direct.localidad}
          InputLabelProps={{ shrink: true }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '90%',
          m: 1,
          margin: "0 auto"
        }}
      >
        <ValidationTextField
          label="NumExterior"
          variant="outlined"
          placeholder="NumExterior"
          sx={{ m: 1.5, width: "33.33%" }}
          value={direct.numExterior}
          InputLabelProps={{ shrink: true }}
        />
        <ValidationTextField
          label="NumInterior"
          variant="outlined"
          placeholder="NumInterior"
          sx={{ m: 1.5, width: "33.33%" }}
          value={direct.numInterior}
          InputLabelProps={{ shrink: true }}
        />
        <ValidationTextField
          label="Codigo Postal"
          variant="outlined"
          placeholder="Codigo Postal"
          sx={{ m: 1.5, width: "33.33%" }}
          value={direct.codigoPostal}
          InputLabelProps={{ shrink: true }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '90%',
          m: 1,
          margin: "0 auto"
        }}
      >
        <ValidationTextField
          label="Colonia"
          variant="outlined"
          placeholder="Colonia"
          sx={{ m: 1.5, width: "33.33%", }}
          value={direct.colonia}
          InputLabelProps={{ shrink: true }}
        />
        <ValidationTextField
          label="Municipio"
          variant="outlined"
          placeholder="Municipio"
          sx={{ m: 1.5, width: "33.33%" }}
          value={direct.municipio}
          InputLabelProps={{ shrink: true }}
        />
        <Button
          onClick={SetDireccion}
          sx={{ m: 1.5, width: "33.33%" }}
          variant="contained"
        >
          Agregar Dirección
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '88.5%',
          m: 1,
          margin: "0 auto"
        }}
      >
        <table>
          <thead>
            <tr className="cabezeracolor">
              <th className="unidadConcept">Origen/Destino</th>
              <th className="impuestosConcept">Fecha</th>
              <th className="descripcionConcept">Dirección</th>
              <th className="cantidadConcept">
                Remitente/Destinatario
              </th>
              <th className="unidadConcept">KM</th>

              <th className="accionesConcept">*</th>
            </tr>
          </thead>
          <tbody>
            {domicilio.map((row) => (
              <>
                <tr>
                  <td className="tdunidad">{row.idubicacion}</td>
                  <td className="tdunidad">{row.fecha}</td>
                  <td className="tdunidad">{row.direccion}</td>
                  <td className="tdunidad">{row.remitente}</td>
                  <td className="tdunidad">{row.distancia}</td>

                  <td className="borrarcolum">
                    <img
                      alt=""
                      src={Borrar}
                      name={row.id}
                      onClick={handleRemoveItem2}
                      className="borrarcl"
                    ></img>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
          <tbody>
            <tr className="totalesCalculados">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                {vSumDistancioaRecorrida === undefined
                  ? "0 KM"
                  : vSumDistancioaRecorrida + "KM"}{" "}
              </td>
              <td className="tdsubtotal"></td>
            </tr>
          </tbody>
        </table>
      </Box>

      <div className={mostrarComponente3 ? "show-element" : null}>
        {mostrarComponente3 && (
          <>
            <Maping></Maping>

            <Button
              onClick={ocultarMapa}
              sx={{ m: 2, width: "20%" }}
              variant="contained"
            >
              Ocultar mapa
            </Button>
          </>
        )}
      </div>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '90%',
          m: 1,
          margin: "0 auto",
          '@media (max-width: 1444px)': {
            width: '89.4%'
          },
        }}
      >
        <div style={{ width: "55%", margin: "0 auto" }}>
          <ReactSearchAutocomplete
            items={conductor}
            fuseOptions={{ keys: ["driver_name"] }}
            resultStringKeyName="driver_name"
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={hanlde_select_driver}
            onFocus={handleOnFocus}
            onClear={handleOnClear}
            showIcon={true}
            placeholder="Buscar conductor..."
            styling={{
              height: "34px",
              border: "1px solid darkgreen",
              borderRadius: "4px",
              backgroundColor: "white",
              boxShadow: "none",
              hoverBackgroundColor: "lightgreen",
              color: "black",
              iconColor: "black",
              lineColor: "black",
              placeholderColor: "black",
              clearIconMargin: "3px 8px 0 0",
              zIndex: 8,
            }}
          />
        </div>

        <ValidationTextField
          label="RFC"
          variant="outlined"
          placeholder="RFC"
          sx={{ m: 1.5, width: "20%" }}
          value={select_driver?.rfc}
          InputLabelProps={{ shrink: true }}
        />
         <Button
          onClick={hanlde_set_driver}
          sx={{ m: 2, width: "20%" }}
          variant="contained"
        >
          Agregar Conductor
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '88.5%',
          m: 1,
          margin: "0 auto"
        }}
      >
        <table>
          <thead>
            <tr className="cabezeracolor">
              <th className="">Conductor</th>
              <th className="">RFC</th>
              <th className="">Número de licencia</th>
              <th className="">*</th>

            </tr>
          </thead>
          <tbody>
            {selected_drivers.map((row) => (
              <>
                <tr>
                  <td className="tdunidad">{row.nombrefigura}</td>
                  <td className="tdprecio">{row.rfcfigura}</td>
                  <td className="tdprecio">{row.numlicencia}</td>
                  <td className="borrarcolum">
                    <img
                      alt=""
                      src={Borrar}
                      name={row.id}
                      onClick={handle_remove_driver}
                      className="borrarcl"
                    ></img>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '90%',
          m: 1,
          margin: "0 auto"
        }}
      >
        <ValidationTextField
          label="NumPermisoSCT"
          variant="outlined"
          placeholder="Aseguradora"
          onChange={handleNumPerSCT}
          sx={{ m: 1.5, width: "50%" }}
          value={numPermisoSCT}
          InputLabelProps={{ shrink: true }}
        />
        <ValidationTextField
          label="Permiso SCT"
          variant="outlined"
          onChange={handlePerSCT}
          placeholder="TPXX00"
          sx={{ m: 1.5, width: "50%" }}
          value={permisoSCT}
          InputLabelProps={{ shrink: true }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '90%',
          m: 1,
          margin: "0 auto",
          '@media (max-width: 1444px)': {
            width: '89.4%'
          },
        }}
      >
        <div style={{ width: "55%", margin: "0 auto" }}>
          <ReactSearchAutocomplete
            items={vehicles}
            fuseOptions={{ keys: ["description"] }}
            resultStringKeyName="description"
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect4}
            onFocus={handleOnFocus}
            onClear={handleOnClear}
            showIcon={true}
            placeholder="Buscar vehículo..."
            styling={{
              height: "34px",
              border: "1px solid darkgreen",
              borderRadius: "4px",
              backgroundColor: "white",
              boxShadow: "none",
              hoverBackgroundColor: "lightgreen",
              color: "black",
              iconColor: "black",
              lineColor: "black",
              placeholderColor: "black",
              clearIconMargin: "3px 8px 0 0",
              zIndex: 7,
            }}
          />
        </div>
        <ValidationTextField
          label="Model"
          variant="outlined"
          placeholder="Model"
          sx={{ m: 1.5, width: "20%" }}
          value={vehicle.year_model}
          InputLabelProps={{ shrink: true }}
        />
        <ValidationTextField
          label="Placa"
          variant="outlined"
          placeholder="Placa"
          sx={{ m: 1.5, width: "20%" }}
          value={vehicle.license_plate}
          InputLabelProps={{ shrink: true }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '90%',
          m: 1,
          margin: "0 auto"
        }}
      >
        <ValidationTextField
          label="Aseguradora"
          variant="outlined"
          placeholder="Aseguradora"
          sx={{ m: 1.5, width: "50%" }}
          value={vehicle.asegura_resp_civil}
          InputLabelProps={{ shrink: true }}
        />

        <ValidationTextField
          label="Poliza"
          variant="outlined"
          placeholder="Poliza"
          sx={{ m: 1.5, width: "50%" }}
          value={vehicle.poliza_resp_civil}
          InputLabelProps={{ shrink: true }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '90%',
          m: 1,
          margin: "0 auto"
        }}
      >
        <ValidationTextField
          label="NumTotalMercancias"
          variant="outlined"
          placeholder="NumTotalMercancias"
          sx={{ m: 1.5, width: "33.33%", }}
          value={vSumTotalCantidad}
          InputLabelProps={{ shrink: true }}
        />

        <ValidationTextField
          label="Peso Total Bruto"
          variant="outlined"
          placeholder="PesoTotalBruto"
          sx={{ m: 1.5, width: "33.33%" }}
          value={vSumPesoTotal}
          InputLabelProps={{ shrink: true }}
        />

        <ValidationTextField
          label="Unidad Peso"
          variant="outlined"
          placeholder="Unidad Peso"
          sx={{ m: 1.5, width: "33.33%" }}
          value={"KG"}
          InputLabelProps={{ shrink: true }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '90%',
          m: 1,
          margin: "0 auto",
          '@media (max-width: 1444px)': {
            width: '89.4%'
          },
        }}
      >
        <div style={{ width: "54.8%", margin: "0 auto" }}>
          <ReactSearchAutocomplete
            items={concep}
            fuseOptions={{ keys: ["concept"] }}
            resultStringKeyName="concept"
            onSearch={handleOnSearch2}
            onHover={handleOnHover2}
            onSelect={handleOnSelect2}
            onFocus={handleOnFocus2}
            onClear={handleOnClear2}
            showIcon={true}
            placeholder="Buscar concepto..."
            styling={{
              height: "34px",
              border: "1px solid darkgreen",
              borderRadius: "4px",
              backgroundColor: "white",
              boxShadow: "none",
              hoverBackgroundColor: "lightgreen",
              color: "black",
              iconColor: "black",
              lineColor: "black",
              placeholderColor: "black",
              clearIconMargin: "3px 8px 0 0",
              zIndex: 2,
            }}
          />
        </div>
        <TextareaAutosize
          label="Descripcion"
          variant="outlined"
          aria-label="empty textarea"
          value={editDescripcion}
          placeholder="Descripción del producto"
          style={{
            width: "43%",
            height: 44,
            marginTop: 14,
            marginRight: 10
          }}
          onChange={handleChangeeDescripcion}
          InputLabelProps={{ shrink: true }}
        />
      </Box>


      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '90%',
          m: 1,
          margin: "0 auto"
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              sx={{ m: 2, width: "20%" }}
              checked={logisticainversarecolecciondevolucion_data}
              onChange={(e) => setlogisticainversarecolecciondevolucion_data(e.target.checked)}
              name="gilad" />
          }
          label="Logistica Inversa"
        />
        <ValidationTextField
          label="Cantidad"
          variant="outlined"
          placeholder="Cantidad"
          sx={{ m: 2, width: "20%" }}
          onChange={handleChangeeCantidad}
          value={cantidadP || cantidad}
          InputLabelProps={{ shrink: true }}
        />
        <ValidationTextField
          label="Precio"
          variant="outlined"
          onClick={handleChangeeBorrarP}
          placeholder="Precio del producto"
          sx={{ m: 2, width: "20%" }}
          onChange={handleChangeePrecionP}
          value={precioP || psinIva || concept.valor_unitario}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <ValidationTextField
          label="Peso"
          variant="outlined"
          onClick={handleChangeeBorrarK}
          onChange={handleChangeepesoK}
          placeholder="Peso en KG"
          sx={{ m: 2, width: "20%" }}
          value={pesoK || concept.peso_en_kg}
          InputLabelProps={{ shrink: true }}
        />

        <Button
          onClick={handle_calculate_concept}
          sx={{ m: 2, width: "20%" }}
          variant="contained"
        >
          Agregar concepto
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '88.5%',
          m: 1,
          margin: "0 auto"
        }}
      >
        <table>
          <thead>
            <tr className="cabezeracolor">
              <th className="cantidadConcept">Cantidad</th>
              <th className="unidadConcept">KG</th>
              <th className="unidadConcept">Unidad</th>
              <th className="descripcionConcept">Descripción</th>
              <th className="accionesConcept">*</th>
            </tr>
          </thead>
          <tbody>
            {people.map((row) => (
              <>
                <tr>
                  <td className="tdunidad">{row.cantidad}</td>
                  <td className="tdprecio">{row.pesoenkg + "KG"}</td>
                  <td className="tdunidad">{row.unidad}</td>
                  <td className="tddescripcion">{row.descripcion}</td>
                  <td className="borrarcolum">
                    <img
                      alt=""
                      src={Borrar}
                      name={row.id}
                      onClick={handleRemoveItem}
                      className="borrarcl"
                    ></img>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '90%',
          m: 1,
          margin: "0 auto",
          '@media (max-width: 1444px)': {
            width: '89.4%'
          },
        }}
      >
        <TextareaAutosize
          label="Nota"
          variant="outlined"
          aria-label="empty textarea"
          value={nota}
          placeholder="Nota"
          style={{
            width: "80%",
            height: 44,
            marginTop: 14,
            marginLeft: 13
          }}
          onChange={handleChangeeNota}
          InputLabelProps={{ shrink: true }}
        />

        <Button
          onClick={handleSubmit}
          sx={{ m: 2, width: "20%" }}
          variant="contained"
        >
          Enviar
        </Button>
      </Box>
    </>

  );
}

export default CARTA_PORTE_V2;
