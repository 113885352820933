import React, { useEffect, useMemo, useRef, useState } from 'react'
import marker1 from "../../../../imgComponents/marker1.png";
import marker2 from "../../../../imgComponents/marker2.png";
import marker3 from "../../../../imgComponents/marker3.png";
import marker4 from "../../../../imgComponents/marker4.png";
import marker5 from "../../../../imgComponents/marker5.png";
import marker6 from "../../../../imgComponents/marker6.png";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "../../tools/render_row_action_menuItems";

export const TABLES_SALES = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({}); 

    const [total_rows, settotal_rows] = useState("");

    const update_identifiers =
    [
      { ordenvisita: 'string' },
      { code: 'string' },
      { date: 'String' },
      { name: 'string' },
      { ticket: 'string' },
      { economic_number: 'string'},
      { scan: 'string' },
      { saletype: 'string' },
      // { marker: 'string' }
    ];
    
    const update_identifiers2 =
    [
      { code: 'string' },
      { name: 'string' },
      { lat: 'String' },
      { lng: 'String' }
    ];
    
    
  const columns = useMemo(
    () => [
      {
        id: "ordenvisita",
        header:"Visita",
        accessorKey:"ordenvisita"
      },
      {
        id: "code",
        header:"Código",
        accessorKey:"code"
      },
      {
        id:"date",
        header:"Fecha",
        accessorKey: "date",
        accessorFn: (d)=>{
          return d?.sales[0]?.date
        }
      },
      {
        id: "name",
        header:"Punto de Venta",
        accessorKey:"name"
       },
      {
        id: "ticket",
        header:"Tickets",
        accessorKey: "ticket",
        accessorFn: (d)=>{
          return d?.sales[0]?.ticket
         }
      },
      {
        id: "economic_number",
         header:"Congelador",
         accessorKey: "economic_number",
         accessorFn: (d)=>{
        var congeladores="";
          for(var i=0; i<d.cabinets.length;i++){
            congeladores=congeladores + d.cabinets[i].economic_number+" ";
          }
        return congeladores;
         }
      },
      {
        id: "scan",
         header:"Con. Escaneado",
         accessorKey: "scan",
         accessorFn: (d)=>{
          var escaneado="";
          for(var i=0; i<d.sales.length;i++){
            escaneado=escaneado + d.sales[i].scan+" ";
          }
          return escaneado;
         }
      },
      {
        id: "saletype",
        header:"Tipo",
        accessorKey: "saletype",
        accessorFn: (d)=>{
          if(d.sales[0]?.saletype==="NO VENTA" || d.sales[0]?.saletype==="OTRO"){
            return "NOTA";
          }else if(d.cabinets.length===0){
            return "SIN CONGELADOR";
          }else if(d.sales[0].scan===null||""){
            return "SIN ESCANEO";
          }else{
            return "VENTA";
          }
        },
      },
      {
        id: "marker",
        header:"Marker",
        accessorKey: "marker",
        accessorFn:(d)=>{
          if(d.sales[0]?.saletype==="NO VENTA" || d.sales[0]?.saletype==="OTRO"){
            return <img src={marker3} alt=''></img>;
          }else if(d.cabinets.length===0){
            return <img src={marker5} alt=''></img>
          }else if(d.sales[0].scan===null||""){
            return <img src={marker4} alt=''></img>
          }else{
            return <img src={marker2} alt=''></img>
          }
        }
      }
      ],
      []
  );
  
  
  
  const columns2 = useMemo(
    () => [
      {
        id: "code",
        header:"Código",
        accessorKey:"code",
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        id: "name",
        header: "Nombre",
        accessorKey: "name",
        size: 400
      },
      {
        id: "lat",
        accessorKey: "lat",
        header: "Latitud"
      },
      {
        id: "lng",
        accessorKey: "lng",
        header: "Longitud"
      }
      ],
      []
  );
  
 
   const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  // useEffect(() => {
  //   if(total_rows === "" || total_rows === undefined || total_rows === null){   
  //   }else{
  //     if(total_rows?.getFilteredRowModel()?.rows?.length === props?.data?.length){
  //       props?.newjson(props?.data);
  //       // if(props?.datasv?.length > 0){
  //       //   props?.handlejoinmarker(props?.dataposs);
  //       // } 
  //     }else{
  //       const originalList = total_rows?.getFilteredRowModel()?.rows?.map(row => row?.original);
  //       props?.newjson(originalList);
  //     }
  //   }
  // }, [total_rows]);

   return (
    <>
    <div className="new_table_v2">
      <MaterialReactTable
        columns={columns}
        data={props?.data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        onStateChange={handleStateChange}
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
                props?.data.splice(
                 hoveredRow.index,
                 0,
                props?.data.splice(draggingRow.index, 1)[0],
               );
               props?.setdata([...props?.data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
             closeMenu={closeMenu}
             setmodalT={props?.setmodalT}
             row={row}
             handleSubmit={props?.handleSubmit}
             alertas={props?.alertas}
            />
          ]}
        renderToolbarInternalActions={({ table }) => (
           <RENDER_TOOLBAR_INTERNAL_ACTIONS
             table={table} 
             file_name={"Cuentas Contables"}
             update_identifiers={update_identifiers}
             settotal_rows={settotal_rows}
           />
         )
         }
      />
      </div>
      <div className="new_table_v2">
         <h2>Puntos de Venta sin Visitar</h2>

         <MaterialReactTable
            columns={columns2}
            data={props?.datasv}
            enableGrouping
            enablePinning
            enableFacetedValues
            enableStickyHeader
            onStateChange={handleStateChange}
            enableStickyFooter
            enableRowPinning
            enableColumnFilterModes
            // enableRowActions
            // enableRowSelection
            getRowId={(row) => row?.id}
            enableTooltips={false}
            localization={MRT_Localization_ES}
            state={{ rowSelection, isLoading: isLoading }}
            enableColumnResizing
            enableColumnPinning
            enableColumnOrdering
            enableExpandAll={false}
            initialState={{ showGlobalFilter: true }}
            muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
            muiSkeletonProps={{ animation: 'pulse', height: 28 }}
            rowVirtualizerInstanceRef
            rowVirtualizerOptions={{ overscan: 5 }}
            columnVirtualizerOptions={{ overscan: 2 }}
            muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
            positionToolbarAlertBanner="bottom"
            paginationDisplayMode='pages'
            rowPinningDisplayMode='sticky'
            layoutMode="grid"
                    muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

            muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
              'mrt-row-pin': {
                enableHiding: true,
              },
              'mrt-row-actions': {
                enableHiding: true,
              },
              'mrt-row-expand': {
                enableHiding: true,
              },
              'mrt-row-select': {
                enableHiding: true,
              }
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  props?.datasv.splice(
                    hoveredRow.index,
                    0,
                    props?.datasv.splice(draggingRow.index, 1)[0],
                  );
                  props?.setdatasv([...props?.datasv]);
                }
              },
            })}
            muiTableBodyRowProps={
              ({ row, table }) => {
                const { density } = table.getState();
                return {
                  sx: {
                    height: row.getIsPinned()
                      ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                      }px`
                      : undefined,
                  },
                };
              }
            }
            renderToolbarInternalActions={({ table }) => (
              <RENDER_TOOLBAR_INTERNAL_ACTIONS
                table={table} 
                file_name={"Puntos de Venta sin Visitar"}
                update_identifiers={update_identifiers2}
              />
            )
            }
          />
      </div>
    </>
  )
}
