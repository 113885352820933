import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const VIEW_FILES = (props) => {
  
    console.log(props);

  return (
    <div className="list-files-upload-ticket">
        
        <div className='list-files-upload-items'>
            {
                props?.selectedImages?.map((data, index)=>(
                    <div key={index} className='list-item-descripcion'>
                        <div className='buttons-actions'>
                            <DeleteIcon
                                title="Remover archivo" 
                                onClick={() => props?.handleRemoveImage(index)} 
                                className='icon-svg-del'
                            ></DeleteIcon>
                            {/* <VisibilityIcon className='icon-svg-view'></VisibilityIcon> */}
                        </div>
                        <p>
                            <span className="file-name">{data?.name}</span>
                            <span className="file-extension">.{data?.extension}</span>
                        </p>
                       
                    </div>
                ))
            }
        </div>
    </div>
  )
}
