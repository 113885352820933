import { useRef, useState } from 'react';
import { useDialog } from 'react-st-modal';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import {MenuItem} from '@mui/material';

export const UPLOAD_CSV = (props) => {

    const [highlight, setHighlight] = useState(false);
    const fileInputRef = useRef(null);
    const [file, setfile]=useState(null);
    const [band, setband]=useState(false);
    const [datacsv, setdatacsv]=useState([]);
    const [process, setprocess]=useState(false);
    const dialog = useDialog();
    const [errfile, seterrfile] = useState(false);
    const [errmsjf, seterrmsjf]=useState("");
    const[load, setload]=useState(false);
    const [select, setselect]= useState(2);
    const [points_delete, setpoints_delete] = useState([]);

    const preventDefaults = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    
    const handleDrop = (e) => {
      e.preventDefault();
      setHighlight(false);
      const files = e.dataTransfer.files;
      setfile(files[0]);
      handleFiles(files);
    };

    const handleFileInputClick = () => {
      fileInputRef.current.click();
      setprocess(false);
    };  
    const handleFileInputChange = (e) => {
      const files = e.target.files;
      setprocess(false);
      setfile(files[0]);
      handleFiles(files);
    };
  
    const handleFiles = (files) => {
        seterrmsjf("");
        seterrfile(false);
        setpoints_delete([]);

      for (const file of files) {
        if (file.type === 'text/csv') {
          const reader = new FileReader();
          reader.onload = (e) => {
            const contents = e.target.result;
            analisis(contents);
          };
          reader.readAsText(file);
        } else {
          alert('Por favor, selecciona un archivo CSV.');
          setfile(null);
        }
      }
    };

    const analisis = (content)=>{
        setband(true);
        const lines = content.trim().split('\n');
        const headers = lines[0].split(',');
        const result = [];
        for (let i = 1; i < lines.length; i++) {
            const obj = {};
            const currentLine = lines[i].split(',');
    
            headers.forEach((header, index) => {
                obj[header.trim().replace(/"/g, '')] = currentLine[index].trim().replace(/"/g, '');
            });
            result.push(obj);
        }
        props?.setprocesstwo(false);
        select===1 ? headers_check(result):
            select=== 2 ? headers_check_clasification(result):
                select === 3 ? headers_check_new_points(result):
                    console.log("");
    }

    const headers_check_new_points = (result) =>{

        const requiredFields = [
            "Código", "Punto de Venta", "Latitud", "Longitud"
        ];
        const firstObject = result[0];
        const missingFields = requiredFields.filter(field => !firstObject.hasOwnProperty(field));
        
        if (missingFields.length > 0) {
            seterrmsjf("Faltan los siguientes campos: "+ missingFields.join(', '));
            seterrfile(true);
            setband(false);
        } else {
            const data_null = result?.filter(item=>item?.Código);
            const d =join_points_proposals(data_null);
            props?.setprocesstwo(false);
            console.log(d);
            update_null_data(d);
        }

    }


    const headers_check_clasification = (result)=>{

        const requiredFields = [
            "Código", "Grupo","Sector","Latitud", "Longitud"
        ];
        const firstObject = result[0];
        const missingFields = requiredFields.filter(field => !firstObject.hasOwnProperty(field));
        if (missingFields.length > 0) {
            seterrmsjf("Faltan los siguientes campos: "+ missingFields.join(', '));
            seterrfile(true);
            setband(false);
        } else {
            const data_null = result?.filter(item=>item?.Código);
            const d =join_points_clasifications(data_null);
            if(d[0]?.sector === "N/A"){
                props?.setprocesstwo(false);
            }else{
                props?.setprocesstwo(true);
            }
            update_null_data(d);
        }
    }


    const headers_check=(result)=>{
        const requiredFields = [
            "Código"
        ];
        const firstObject = result[0];
        const missingFields = requiredFields.filter(field => !firstObject.hasOwnProperty(field));
        if (missingFields.length > 0) {
            seterrmsjf("Faltan los siguientes campos: "+ missingFields.join(', '));
            seterrfile(true);
            setband(false);
        } else {
            const data_null = result?.filter(item=>item?.Código);
            const d = join_points(data_null);
            update_null_data(d);
        }
    }

    const join_points_proposals = (d) =>{
        
        const uniqueGroups = [...new Set(d?.map(item=>item.Grupo))];
        const groupColors = assignColorsToGroups(uniqueGroups);
        const updatedData = d.map((item) => {    
            const matchingPoint = props?.datapoints.find(point => point.code === item["Código"]);
            if (matchingPoint) {
              
                return {
                    name: matchingPoint.name,
                    visit_days: matchingPoint.visit_days,
                    frequency: matchingPoint.frequency,
                    route: matchingPoint.route,
                    lat: matchingPoint.lat,
                    lon: matchingPoint.lng,
                    code: matchingPoint.code,
                    group: item['Grupo']?.toString(),
                    sector: item['Sector'],
                    color: groupColors[item['Grupo']]
                };
            }else{
                return {
                    name: item['Punto de Venta'],
                    lat: Number(item['Latitud']),
                    lon: Number(item['Longitud']),
                    code: item['Código'],
                    group: item['Grupo'],
                    color: groupColors[item['Grupo']],
                    sector: "N/A",
               };
            }
          }); 
        return updatedData;
    }


    const join_points_clasifications = (d) =>{
        
        const uniqueGroups = [...new Set(d?.map(item=>item.Grupo))];
        const groupColors = assignColorsToGroups(uniqueGroups);
        var uniqueSectors = [];
        var groupColors2 = [];
        let updatedatacompleta = [];

        if(d[0]['Sector']==="N/A"){
            console.log("Sin Sector");
            updatedatacompleta = d;
        }else{
            let sectorCounter = 1;
            let groupSectorMap = {};
            updatedatacompleta = d.map(item => {
                const { Grupo, Sector } = item;
                    if (!groupSectorMap[Grupo]) {
                        groupSectorMap[Grupo] = {};
                    }
                    if (groupSectorMap[Grupo][Sector] !== undefined) {
                        return { ...item, Sector: `${groupSectorMap[Grupo][Sector]}` };
                    } else {
                        groupSectorMap[Grupo][Sector] = sectorCounter;
                        const updatedItem = { ...item, Sector: sectorCounter };
                        sectorCounter++;
                        return updatedItem;
                    }
            });
            uniqueSectors = [...new Set(updatedatacompleta?.map(item=>item?.Sector))];
            groupColors2 = assignColorsToGroups(uniqueSectors);
            console.log(groupColors2);
        }
        const updatedData = updatedatacompleta.map((item) => {    
            if(item["Punto de Venta"]==="FRICONGELADOS CITLALTEPETL"){
                return {
                    name: item["Punto de Venta"],
                    code: item["Código"],
                    lat: 18.85020875064652,
                    lon: -97.07318829119332,
                    group: item['Grupo'],
                    sector: item['Sector'],
                    color: groupColors[1]
                }
            }
            const matchingPoint = props?.datapoints.find(point => point.code === item["Código"]);
            if (matchingPoint) {
                if(item['Sector']==="N/A"){
                    return {
                        name: matchingPoint.name,
                        visit_days: matchingPoint.visit_days,
                        frequency: matchingPoint.frequency,
                        route: matchingPoint.route,
                        lat: matchingPoint.lat,
                        lon: matchingPoint.lng,
                        code: matchingPoint.code,
                        group: item['Grupo'],
                        sector: item['Sector'],
                        color: groupColors[item['Grupo']]
                      };
                }else{
                    return {
                        name: matchingPoint.name,
                        visit_days: matchingPoint.visit_days,
                        frequency: matchingPoint.frequency,
                        route: matchingPoint.route,
                        lat: matchingPoint.lat,
                        lon: matchingPoint.lng,
                        code: matchingPoint.code,
                        group: item['Grupo'],
                        color: groupColors[item['Grupo']],
                        sector: item['Sector'],
                        color_two: groupColors2[item['Sector']]
                      };
                }
            }else{
                return {
                    name: item['Punto de Venta'],
                    visit_days: 'N/A',
                    frequency: 'N/A',
                    route: 'N/A',
                    lat: Number(item['Latitud']),
                    lon: Number(item['Longitud']),
                    code: item['Código'],
                    group: item['Grupo'],
                    color: groupColors[item['Grupo']],
                    sector: item['Sector'],
                    color_two: groupColors2[item['Sector']]                    
                  };
            }
            return item;
          });
        return updatedData;
    }

    const join_points = (d) =>{

        const uniqueGroups = [...new Set(props?.datapoints.map(point => point.route))];
        const groupColors = assignColorsToGroups(uniqueGroups);
        var dlpoint = [];

        const updatedData = d.map((item) => {    
            const matchingPoint = props?.datapoints.find(point => point.code === item["Código"]);
            if (matchingPoint) {
              return {
                name: matchingPoint.name,
                visit_days: matchingPoint.visit_days,
                frequency: matchingPoint.frequency,
                route: matchingPoint.route,
                lat: matchingPoint.lat,
                lon: matchingPoint.lng,
                code: matchingPoint.code,
                group: Number(matchingPoint.route),
                color: groupColors[matchingPoint.route]
              };
            }else{
                if(item){
                    dlpoint=[...dlpoint, item];
                }
                return {}
            }
            // return item;
          });
          setpoints_delete(dlpoint);
        return updatedData;
    }

    const assignColorsToGroups = (groups) => {
        const colorPalette = [
            '#000000', '#800080', '#000080', '#0000FF', '#008080', '#00FFFF','#008000', '#00FF00', '#FFFF00', '#800000', '#FF0000', '#808080', 
            '#fd53f8', '#faa437', '#fa3769', "#FFD633", "#D633FF", "#FFD6D6",
            "#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#33FFF5", "#FF8C33",
            "#8C33FF", "#FF3333", "#33FF33", "#337FFF", "#FF33FF", "#33FFCC",
            "#FFB533", "#B533FF", "#FF6666", "#66FF66", "#6666FF", "#FF66CC",
            "#66FFB3", "#FF9933", "#9933FF", "#FF9999", "#99FF99", "#9999FF",
            "#FF99FF", "#99FFCC", "#FFCC33", "#CC33FF", "#FFCCCC", "#CCFFCC",
            "#CCCCFF", "#FFCCFF", "#CCFF99"
        ];
        const groupColors = {};
        let colorIndex = 0;
        groups.forEach((group) => {
            if (!groupColors[group]) {
                groupColors[group] = colorPalette[colorIndex % colorPalette.length];
                colorIndex++;
            }
        });
        return groupColors;
    };

    const update_null_data = (result) =>{
        result.forEach(obj => {
            Object.keys(obj).forEach(key => {
                if (obj[key] === '') {
                    obj[key] = null;
                }
            });
        });
        const filteredResult = result.filter(obj => {
            return Object.values(obj).some(value => value !== null);
        }); 

        console.log(filteredResult)

        setband(false);
        setdatacsv(filteredResult);
        setprocess(true);
    }

 return (
    <div className='upload-csv-points'>
        
            <div className='content-mnu-process-upload-csv'>    
                <FormControl required sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-required-label">Proceso de Importación</InputLabel>
                    <Select
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        value={select}
                        sx={{ height: '35px'}}
                        label="Referencia Fricongelados"
                        onChange={(e) => setselect(e.target.value)}
                    >
                        <MenuItem key={0} value={1}>Puntos de Venta sin Procesar</MenuItem>
                        <MenuItem key={1} value={2}>Puntos de Venta Clasificados</MenuItem>
                        <MenuItem key={2} value={3}>Propuestas de Puntos de Venta</MenuItem>
                        
                    </Select>
                </FormControl>
            </div>
             <div className='container-upload-csv'>
             {
                 process === true ? <></>:
                     band === false?
                     <>
                         <div className={`drop-area ${highlight ? 'highlight' : ''}`}
                         onDrop={handleDrop}
                         onDragOver={preventDefaults}
                         onDragEnter={() => setHighlight(true)}
                         onDragLeave={() => setHighlight(false)}
                         onClick={handleFileInputClick}>
                         <p>Arrastra y suelta un archivo CSV aquí o haz clic para seleccionarlo.</p>
                         {
                            select===1?
                            <p>
                                El proceso es exclusivamente para importar puntos de venta en el mapa, 
                                se recomienda que el archivo sea en formato CSV y que contenga las columnas 
                                del codigo, latitud y longitud del Punto de venta.
                            </p>
                            :select===3?
                            <p>
                                El proceso es exclusivamente para importar puntos de venta que esten o no registrados en OD2
                            </p>:
                            <p>
                              El proceso es exclusivamente para importar el archivo descargado del módulo, 
                              una vez que se haya completado la reorganización de los puntos de venta.
                            </p>
                         }
                         <input
                             type="file"
                             accept=".csv"
                             ref={fileInputRef}
                             onChange={handleFileInputChange}
                             style={{ display: 'none' }}
                         />
                         </div>
                         {
                             errfile === true ? <p className='err-msj'>{errmsjf}</p>:<></>
                         }
                     </>
                     :
                         <div className='drop-load'>
                             <img src={load}></img>
                             <p>Analizando Archivo...</p>
                         </div>
                 }
                 
                 {
                     file===null?<></>:band===true?<></>:<p className='file-select'>Archivo Seleccionado: <span>{file?.name}</span></p>
                 } 
                 {
                  process===true?
                    <>
                        <div className='point-info'>
                            <h2>Puntos de venta a importar en Mapa</h2>
                            {
                                datacsv?.map((item, index)=>(
                                    <p key={index}>{item?.code} - {item?.name}</p>
                                ))
                            }
                        </div>
                        {
                            points_delete?.length > 0 ?
                            <><h2 className='points-delete-upload-csv-h2'>Puntos de Venta Eliminados</h2>
                            <div className='points-delete-upload-csv'>
                                {
                                    points_delete?.map(item=>{
                                        return(
                                            <p>{item["Código"]}</p>
                                        )
                                    })
                                }
                            </div></>:<></>
                        }
                        <div className=''>
                        <Box>
                            <Button
                                variant="contained"
                                style={{ "backgroundColor": "green" }}
                                sx={{ m: 1.5, width: "30ch" }}
                                onClick={()=>{
                                    props?.join_points(datacsv, datacsv[0]?.sector === "N/A" ? false : true)
                                    dialog.close();
                                }}
                            > DIBUJAR MAPA
                            </Button> 
                            <Button
                                style={{ "backgroundColor": "#007bff" }}
                                variant="contained"
                                sx={{ m: 1.5, width: "30ch" }}
                                onClick={() => {
                                    setprocess(false);
                                }}>CAMBIAR ARCHIVO
                            </Button>
                            <Button
                                style={{ "backgroundColor": "red" }}
                                variant="contained"
                                sx={{ m: 1.5, width: "20ch" }}
                                onClick={() => {
                                    dialog.close();
                                    }}>
                                cancelar
                            </Button>
                        </Box>
                        </div>
                    </>:<></>
                 }
             </div>       
    </div>
  )
}
