import USO_CFDI from "../../../../dataComponets/usoCFDI copy.json"
import METODO_PAGO from "../../../../dataComponets/metodo_pago copy.json"
import FORMA_PAGO  from "../../../../dataComponets/forma_pago copy.json"
import TIPO_CLIENTE from "../../../../dataComponets/bp/partner_type copy.json"
import TIPO_SOCIO from "../../../../dataComponets/bp/type_bp copy.json"
import REGIMEN from "../../../../dataComponets/regimen copy.json"
import STATE from "../../../../dataComponets/state copy.json"
import CustomTable from "./custom_table"
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import BadgeIcon from '@mui/icons-material/Badge';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TableChartIcon from '@mui/icons-material/TableChart';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
export const inf_partner = [
    {
        label: "Código",
        accessor: "code",
        sm: 3,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "invoices",
        sectionTitle: "Facturación",
        change:false,
        required:true,
        maxLength:100,
        minLength:0,
        visible:true,
        method:"ALL",
        icon: ReceiptIcon
    },

    {
        label: "Forzar Código",
        accessor: "force_code",
        sm:3,
        dfl: false,
        type: "checkbox",
        data_select: [],
        name_select: "Habilitado",
        section: "invoices", 
        change:true,
        required:true,
        visible:true,
        maxLength:1000,
        minLength:0,
        method:"ALL"


    },

    {
        label: "Socio de Negocios",
        accessor: "business_partner",
        sm: 12,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "invoices", 
        sectionTitle: "Facturación",
        change:true,
        required:true,
        maxLength:1000,
        minLength:2,
        visible:true,
        method:"ALL"

    },
    {
        label: "RFC",
        accessor: "rfc",
        sm: 6,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "invoices", 
        sectionTitle: "Facturación",
        change:true,
        required:true,
        maxLength:13,
        minLength:12,
        visible:true,
        method:"ALL"

    },
    {
        label: "Tipo de Persona",
        accessor: "person_type",
        sm: 6,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "invoices", 
        sectionTitle: "Facturación",
        change:false,
        required:true,
        maxLength:100,
        minLength:0,
        visible:true,
        method:"ALL"
    },

    {
        label: "Nombre del Representante Legal",
        accessor: "name",
        sm: 10,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "invoices", 
        sectionTitle: "Facturación",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:false,
        method:"ALL"
    },

    {
        label: "Apellido Paterno del Representante Legal",
        accessor: "first_last_name",
        sm: 10,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "invoices", 
        sectionTitle: "Facturación",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:false,
        method:"ALL"
    },

    {
        label: "Apellido Materno del Representante Legal",
        accessor: "second_last_name",
        sm: 10,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "invoices", 
        sectionTitle: "Facturación",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:false,
        method:"ALL"
    },

    {
        label: "Régimen Fiscal",
        accessor: "tax_regime",
        sm: 20,
        dfl: "N/A",
        type: "autocomplete",
        data_select: REGIMEN,
        name_select: "Régimen Fiscal",
        section: "invoices", 
        sectionTitle: "Facturación",
        change:true,
        maxLength:1000,
        minLength:0,
        required:true,
        visible:true,
        method:"ALL"
    },

    {
        label: "Régimen Capital",
        accessor: "capital_regime",
        sm: 10,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "invoices", 
        sectionTitle: "Facturación",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL"
    },


    {
        label: "Uso de CFDI",
        accessor: "cfdi_use",
        sm: 10,
        dfl: "N/A",
        type: "autocomplete",
        data_select: USO_CFDI,
        name_select: "Uso de CFDI",
        section: "invoices", 
        sectionTitle: "Facturación",
        change:true,
        maxLength:1000,
        minLength:0,
        required:true,
        visible:true,
        method:"ALL"
    },

    {
        label: "Método de Pago",
        accessor: "paymethod",
        sm: 10,
        dfl: "01",
        type: "autocomplete",
        data_select: METODO_PAGO,
        name_select: "Método de Pago",
        section: "invoices", 
        sectionTitle: "Facturación",
        change:true,
        maxLength:1000,
        minLength:0,
        required:true,
        visible:true,
        method:"ALL"
    },

    {
        label: "Forma de Pago",
        accessor: "metodo_pago",
        sm: 10,
        dfl: "PUE",
        type: "select",
        data_select: FORMA_PAGO,
        name_select: "Forma de Pago",
        section: "invoices", 
        sectionTitle: "Facturación",
        change:true,
        maxLength:1000,
        minLength:0,
        required:true,
        visible:true,
        method:"ALL"
    },

    {
        label: "Correo(s) Electronico(s)",
        accessor: "email",
        sm: 30,
        dfl: "",
        type: "multitext",
        data_select: [],
        name_select: "",
        section: "invoices", 
        sectionTitle: "Facturación",
        change:true,
        required:true,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL"
    },

    //INFORMACION GENERAL

    {
        label: "Número Teléfonico",
        accessor: "phone",
        sm: 10,
        dfl: 0,
        type: "number",
        data_select: [],
        name_select: "",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",
        icon:ContactEmergencyIcon
    },

    {
        label: "Linea de Crédito",
        accessor: "credit_limit_with_sipplier",
        sm: 10,
        dfl: 0,
        type: "number",
        data_select: [],
        name_select: "",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL"
    },

    {
        label: "Días de Crédito",
        accessor: "credit_days",
        sm: 10,
        dfl: 0,
        type: "number",
        data_select: [],
        name_select: "",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL"
    },

    {
        label: "Tipo de Socio de Negoicos",
        accessor: "business_partner_type",
        sm: 10,
        dfl: "CLIENTE",
        type: "select",
        data_select: TIPO_SOCIO,
        name_select: "Tipo de Socio de Negoicos",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL"
    },

    {
        label: "Tipo de Cliente",
        accessor: "partner_type",
        sm: 10,
        dfl: "PROPIO",
        type: "select",
        data_select: TIPO_CLIENTE,
        name_select: "Tipo de Cliente",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL"
    },

    {
        label: "Número de Cuenta",
        accessor: "account_number",
        sm: 10,
        dfl: 0,
        type: "int",
        data_select: [],
        name_select: "",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:4,
        minLength:4,
        visible:true,
        method:"ALL"
    },

    //EXTRANJEROS

    {
        label: "Residencia Fiscal (País)",
        accessor: "tax_residence",
        sm: 15,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "extranjero", 
        sectionTitle: "Extranjero",
        change:false,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",
        icon:BadgeIcon
    },

    {
        label: "Número de Registro de Identificación Fiscal",
        accessor: "num_reg_id_trib",
        sm: 15,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "extranjero", 
        sectionTitle: "Extranjero",
        change:false,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL"
    },

    //DIRECCIÓN

    {
        label: "Calle",
        accessor: "street",
        sm: 20,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "address", 
        sectionTitle: "Dirección",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",
        icon:FmdGoodIcon
    },

    {
        label: "Número Exterior",
        accessor: "outdoor_number",
        sm: 5,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "address", 
        sectionTitle: "Dirección",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL"
    },

    {
        label: "Número Interior",
        accessor: "inside_number",
        sm: 5,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "address", 
        sectionTitle: "Dirección",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL"
    },

    {
        label: "Colonia",
        accessor: "neighborhood",
        sm: 15,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "address", 
        sectionTitle: "Dirección",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL"
    },

    {
        label: "Código Postal",
        accessor: "zip_code",
        sm: 5,
        dfl: "",
        type: "int",
        data_select: [],
        name_select: "",
        section: "address", 
        sectionTitle: "Dirección",
        change:true,
        required:true,
        maxLength:5,
        minLength:4,
        visible:true,
        method:"ALL"
    },

    {
        label: "Municipio",
        accessor: "town",
        sm: 10,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "address", 
        sectionTitle: "Dirección",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL"
    },

    {
        label: "Estado",
        accessor: "state",
        sm: 10,
        dfl: "VERACRUZ DE IGNACIO DE LA LLAVE",
        type: "autocomplete",
        data_select: STATE,
        name_select: "Estado",
        section: "address",
        sectionTitle: "Dirección",
        change:true,
        required:true,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL"
    },

    {
        label: "Localidad",
        accessor: "city",
        sm: 10,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "address", 
        sectionTitle: "Dirección",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL"
    },

    {
        label: "País",
        accessor: "country",
        sm: 10,
        dfl: "MÉXICO",
        type: "text",
        data_select: [],
        name_select: "",
        section: "address", 
        sectionTitle: "Dirección",
        change:true, 
        required:false,  
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL" 
    },

    {
        label: "Referencia",
        accessor: "reference",
        sm: 20,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "address", 
        sectionTitle: "Dirección",
        change:true,     
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL"
    },

    {
        label: "Tipo de Dirección",
        accessor: "address_type",
        sm: 5,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "address", 
        sectionTitle: "Dirección",
        change:false,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL"
    },

    {
        label: "Sucursal",
        accessor: "subsidiary",
        sm: 5,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "address", 
        sectionTitle: "Dirección",
        change:false,     
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL"
    },

    {
        label: "system_commentary",
        accessor: "system_commentary",
        sm: 30,
        dfl: "",
        type: "comments",
        data_select: [],
        name_select: "",
        section: "system_commentary", 
        sectionTitle: "Historial",
        change:false,     
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"UPDATE",
        icon:SettingsBackupRestoreIcon
    },

    {
        label: "Table",
        accessor: "pointofsale",
        sm: 30,
        dfl: "",
        type: "custom",
        data_select: [],
        name_select: "",
        section: "custom", 
        sectionTitle: "Puntos de venta",
        change:false,     
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        customComponent: CustomTable,
        method:"UPDATE",
        icon:TableChartIcon
    },

];


