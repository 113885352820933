//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

//COMPONENTES
import TableContainer from "../../../main_components/Table/TableContainer";
import SubTable from "../detail_expense/datail";
import { date_current, previous_date } from "../../../main_components/date/date";
import { get_expense_group, delete_expense_group } from "../../../services/expenses/expenses";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
//ESTILOS
import "../../expense.css";
//IMAGENES/ICONOS
import DELETE from "../../../imgComponents/borrar.png";
import Spinner from "../../../imgComponents/S.gif";
import VISUALIZAR from "../../../imgComponents/lupa.png";

import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./detail/tools/render_row_action_menuItems";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CONSULT_GROUP_EXPENSE_V2 = () => {

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState("");
  const [ff, setff] = useState(date_current);
  const [fi, setfi] = useState(previous_date);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [modalT, setmodalT] = useState(false);
  const [reconsult, setreconsult] = useState(false);

  const [total_rows, settotal_rows] = useState("");

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setmodalT(true);
    setMenssage("Consultando grupo de gastos...");

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          var dt = await get_expense_group(fi, ff);
          const data = dt?.data?.sort((a, b) => a.id < b.id ? 1 : a.id > b.id ? -1 : 0);;
          setData(data);
          setmodalT(false);
          setMenssage("");
        } catch (err) {
          console.log("error", err);
          setData([]);
          setmodalT(false);
          setMenssage("");
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };

  const handle_delete_expense_group = async (id) => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          await delete_expense_group(id);
          setOpen(false);
          setMenssage("");
          handleReconsulta(true);
        } catch (err) {
          setOpen(false);
          setMenssage("");
          console.log();
          alert("Error.," + err.response?.data?.errorMessage);

        }
      } else { }
    } catch (err) {
      console.log(err);
    }
  }


  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      // {
      //   header: "Acciones",
      //   id: "Acciones",

      //   Cell: ({ valor, row }) => {
      //     function ConsultDetailGrupExpense() {
      //       if (sessionStorage.getItem("PERMISOPOLICY") === "ROLE_POLICY") {
      //         sessionStorage.setItem("IdGrupExpense", row.original?.id);
      //         sessionStorage.setItem(
      //           "nameConceptExpense",
      //           row.original?.concept
      //         );
      //         window.location = "/expensesGrupDetail";
      //       } else {
      //         alert(
      //           "No cuentas con el rol necesario para visualizar los detalles de grupo., Solicita el rol con tu supervisor."
      //         );
      //       }
      //     }



      //     return (
      //       <div>
      //         <img
      //           title="Ver detalles"
      //           alt=""
      //           src={VISUALIZAR}
      //           onClick={ConsultDetailGrupExpense}
      //           className="centerText, taxes"
      //         ></img>

      //         <img
      //           title="Eliminar Grupo de gastos"
      //           src={DELETE}
      //           alt=""
      //           className="centerText, taxes"
      //           onClick={async () => {
      //             const result = await Confirm(
      //               "¿Esta usted seguro de eliminar este grupo de gastos?",
      //               "Eliminar Grupo de gastos",
      //               "Si",
      //               "No"
      //             );
      //             if (result) {
      //               handle_delete_expense_group(row.original.id);
      //             } else {
      //             }
      //           }}
      //         ></img>
      //       </div>
      //     );
      //   },
      // },

      {
        id: "id",
        header: "No.",
        accessorKey: "id",
      },
      {
        id: "date",
        header: "Fecha",
        accessorKey: "date",
      },
      {
        id: "concept",
        header: "Concepto",
        accessorKey: "concept",
      },
      {
        id: "payment",
        header: "Pago",
        accessorKey: "payment",
      },
      {
        id: "total",
        header: "Total",
        accessorKey: "total",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        id: "user_name",
        header: "Usuario",
        accessorKey: "user_name",
      },
    ],
    []
  );

  const update_identifiers =
    [
      { id: 'string' },
      { date: 'string' },
      { concept: 'string' },
      { payment: 'string' },
      { total: 'string' },
      { user_name: 'string' }
    ];


  return (
    <div >
      <MODAL_TABLE
        open={true}
        message={""}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>


      <BREADCRUMBS niveles={
        [
          { label: "REPORTE DE GASTOS", path: "/expensesReport_v2" },
          { label: "REPORTE DE GASTOS AGRUPADOS", path: null },

        ]
      }

       cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>


      <div className="new_table_v2">
        <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          enableRowActions
          onStateChange={handleStateChange}
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          enableExpandAll={false}
          enableColumnDragging={false}
          initialState={{ showGlobalFilter: true, showColumnFilters: true }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
          muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              settotal_rows(table);
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }
          renderTopToolbarCustomActions={({ }) => (
            <div class="containerExpenseReport2 tools-report-expenses">
              <div class="expen-1 color-1">
                <input
                  type="text"
                  className="fExpense"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha inicial"
                  onChange={(event) => setfi(event.target.value)}
                ></input>
              </div>

              <div class="expen-2 color-2">
                <input
                  type="text"
                  className="fExpense"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha Final"
                  onChange={(event) => setff(event.target.value)}
                ></input>
              </div>

              <div class="expen-3 color-5">
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() => handleSubmit()}
                >
                  Buscar
                </Button>
              </div>
            </div>


          )}
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
              closeMenu={closeMenu}
              setmodalT={setmodalT}
              row={row}
              handleSubmit={handleSubmit}
              setreconsult={setreconsult}
            //  alertas={alertas}
            />
          ]}
          renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table}
              file_name={"Reporte de Gastos"}
              update_identifiers={update_identifiers}
              settotal_rows={settotal_rows}
            />
          )
          }
        />
      </div>

      {/* <div className="TableReport GrupExpense">
        <div style={{ overflowX: "auto" }}>
          <TableContainer
            exportar={true}
            columns={columns}
            data={data}
            nametable={"Reporte de Gastos Agrupados"}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <SubTable verData={true} />
                </div>
              );
            }}
          />
        </div>
      </div> */}
    </div>
  );
};

export default CONSULT_GROUP_EXPENSE_V2;
