import React, { useEffect, useRef } from 'react';
import icopdf from "../../../imgComponents/icons/papel.png";
import icodpdf from "../../../imgComponents/icons/dowpdf.png";
import { useState } from "react";
import { SO_CHECK } from '../so_check';
import "../version.css";
import { useLocation } from 'react-router-dom';
import { get_all_notices, post_confirm_notices, put_confirm_notices } from '../../../services/supports/avisos';
import imgload from "../../../imgComponents/iconos/loading.gif";
import NotificationSystem from 'react-notification-system';
import AlertResponse from '../../../main_components/alerts/alertResponse';

export const VERSION_DESCRIPTION = () => {
    
  const [open, setopen]=useState(true);
  const location = useLocation();
  const { status, date_confirmed, id, code, date, img, introduction, pdf, title, version, form } = location.state || {};
  const [os, setOs] = useState('');
  const [c, setc]= useState(false);
  const [load, setload] = useState(false);
  const notificationSystemRef = useRef();
  const [codec, setcodec] = useState(0);
    
  useEffect(() => {
    const systemO = SO_CHECK();
    if(systemO==="iOS" || systemO==="macOS"){
      setopen(false);
    }
    setOs(systemO);
}, [])


function alertas(msj, status) {

  if (status === true) {
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br',
      autoDismiss: 10,
    });
  } else {
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br',
      autoDismiss: 60,
    });
  }
}

  const handleDownload = () => {
    const pdfUrl = pdf;
    if (pdfUrl) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.setAttribute('download', 'manual_actualizacion_1_0_1.pdf');
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('PDF URL is not defined');
    }
  };

  const create_confirm = async() =>{
    setload(true);
    setc(false);
    const data ={
      id_aviso: id
    }
    try{
      const d = await post_confirm_notices(data);
      setload(false);
      console.log(d);
      alertas("Se ha enviado el código a su correo", true);
      setc(true);
    }catch(err){
      setload(false);
      console.log(err);
      setc(false);
      alertas("Error al enviar el correo", false);
    }
  }

  const validate = async() =>{

    var data = {
      id_aviso: id,
      code: codec
    }
    try{
      setload(true);
      const d = await put_confirm_notices(data);
      console.log(d);
      alertas("Código Verificado con Éxito", true);
      setTimeout(() => {
        window.location = "/patch_notes"
      }, 2000);
    }catch(err){
      alertas("Error al verificar Código", false);
      console.log(err);
      setload(false);
    }

  }


  return (
    <div className='version-info-container'>
     <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
         

    <div className='version-info-content'>
      <div className="navbar-content" id="version101"></div>
      <img src={img} alt="navbar"></img> 
    </div>
    <div className="version-info-body">
      <div className="version-info-sections">
        <div className="titles">
          <h2>NOTAS DE LA ACTUALIZACIÓN {version}</h2>
          <p>{title}</p>
          <div className="line-gold"></div>
          <p><span>Actulizaciones OD2 |</span> Herramientas Informáticas | {date}</p>
        </div>
      </div>
      <div className="version-info-notes">
        <div className="section1">
          <p>
            {
              introduction
            }
          </p>
        </div>
        <h2 className="title-tutorial">Manual de Actualización</h2>
        <div className="download">
          <p>Haz click en el ícono del PDF para descargar el manual completo de la actualización {version}</p>
          <div className="button-accions">
            <div className="button-pdf-manual" onClick={()=>handleDownload()}>
              <img src={icodpdf} alt="icon-pdf"></img>
              <p>Descargar PDF</p>
            </div>
            {
              os==="iOS" || os==="macOS"?
              <></>:
              <div 
              className="button-view-manual"
              onClick={()=>{open===false?setopen(true):setopen(false)}}
            >
              <img src={icopdf} alt="icon-view" ></img>
              <p>{open===false?"Visualizar PDF":"Ocultar PDF"}</p>
            </div>

            }
          </div>
        </div>
        {
          open===true?
          <div className="section6">
            <div className="view-section6-pdf">
              <iframe 
                src={pdf} 
                type="application/pdf" 
                width="100%" 
                height="600px"
              />
            </div>
          </div>:<></>
        }

        {
          status==="Confirmed"?<></>:
            form === null || form === "" || form === undefined?
            <></>:
            <>
              <h2 className='h2confirmnotice'>Formulario</h2>
              <div className='formulario'>
                <iframe 
                  src={form}            
                  width="100%" 
                  height="406" 
                  frameborder="0" 
                  style={{"border": 0, "width": "300%", maxWidth: "300%"}} 
                  allowfullscreen  
                >
                    Cargando…
                  </iframe>
              </div>
            </>
        }
        <br></br>
        <br></br>        
        <h2 className='h2confirmnotice'>Confirmación de Aviso</h2>
        <div className='confirm-notice'>
          <p>
            De acuerdo con la normativa de la empresa, se requiere que el usuario 
            confirme la lectura del aviso haciendo clic en el botón a continuación.
          </p>
          {
            status==="Confirmed"?
            <>
              <p>Aviso Confirmado: {date_confirmed}</p>
            </>:
            <div className='button-confirm-term-patch'>
              {
                load===true?
                <div className='load'>
                  <img src={imgload} alt='load-image'></img>
                </div>:
                c===false?
                <button onClick={()=>create_confirm()}>He leído y estoy acuerdo con las Actualizaciones</button>:
                <div className='grid-confirm-inputs'>
                  <input 
                    className='input-code-confirm-patch' 
                    placeholder='Escribe el Código'
                    onChange={(e)=>setcodec(e.target.value)}
                  
                  ></input>
                  <button onClick={()=>validate()}>Validar Código</button>
                  <button onClick={()=>create_confirm()}>Reenviar Código</button>
                </div>
              }
            </div>
          }
        </div>
        
        
      </div>
    </div>

  </div>
  )
}
