//PAQUETERIAS
import React, { useEffect} from "react";
import Box from "@mui/material/Box";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GroupSharpIcon from '@mui/icons-material/GroupSharp';
import MonetizationOnSharpIcon from '@mui/icons-material/MonetizationOnSharp';
import IsoSharpIcon from '@mui/icons-material/IsoSharp';
import ProductionQuantityLimitsSharpIcon from '@mui/icons-material/ProductionQuantityLimitsSharp';
//COMPONENTES
//ESTILOS
import "../components_cfdi/estilo.css";
import "../components_cfdi/estilo.css";
import "../components_cfdi/modal.css";
import "./dashboar.css";
import { BREADCRUMBS } from "../main_components/pagination/breadcrumbs";
import NewDashboardClient_v2 from "./client/clients_Dashboard_v2";
import NewDashboardDetailSales_v2 from "./sales/sales_Dasboard_v2";
import NewDashboardBonus_v2 from "./bonus/bonus_Dashboard_v2";
import NewDashboardProducts_v2 from "./products/products_Dashboard_v2";
import NewDashboardNoSale_v2 from "./no_sales/no_Sales_v2";

const DASHBOARD_CLIENTS_V2 = () => {
  const [value, setValue] = React.useState(0);
  const [clients, setclients] = React.useState("");
  const [detailSale, setdetailSale] = React.useState("none");
  const [bonus, setbonus] = React.useState("none");
  const [product, setproduct] = React.useState("none");
  const [noSale, setnoSale] = React.useState("none");



  const handelClient = () =>{
    setclients("");
    setdetailSale("none");
    setbonus("none");
    setproduct("none");
    setnoSale("none");
  }

  const handelDetailSales = () =>{
    setclients("none");
    setdetailSale("");
    setbonus("none");
    setproduct("none");
    setnoSale("none");
  }

  const handelBonus = () =>{
    setclients("none");
    setdetailSale("none");
    setbonus("");
    setproduct("none");
    setnoSale("none");
  }

  const handelProduct = () =>{
    setclients("none");
    setdetailSale("none");
    setbonus("none");
    setproduct("");
    setnoSale("none");
  }

  const handelNoSale = () =>{
    setclients("none");
    setdetailSale("none");
    setbonus("none");
    setproduct("none");
    setnoSale("");
  }

  useEffect(() => {
    setclients(clients);
    setdetailSale(detailSale);
    setbonus(bonus);
    setproduct(product);
    setnoSale(noSale);
  }, [setclients,setdetailSale,setbonus,setproduct,setnoSale]);



  
  return (
    <div >
        <BREADCRUMBS niveles={
      [
        {label:"DETALLES DE VENTAS", path: null}, 
      ]
    }  
    ></BREADCRUMBS>

      <Box sx={{ margin: "0 auto" ,width: 600, paddingBottom:"5px"}}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label="Clientes" icon={<GroupSharpIcon onClick={handelClient} />}  onClick={handelClient}/>
        <BottomNavigationAction label="Detalles de Venta" icon={<MonetizationOnSharpIcon onClick={handelDetailSales}/>} onClick={handelDetailSales}/>
        <BottomNavigationAction label="Detalles de Bonificación" icon={<IsoSharpIcon onClick={handelBonus} />} onClick={handelBonus}/>
        <BottomNavigationAction label="Productos" icon={<ProductionQuantityLimitsSharpIcon onClick={handelProduct} />} onClick={handelProduct}/>
        <BottomNavigationAction label="Puntos de Venta Sin Venta" icon={<LocationOnIcon  onClick={handelNoSale} />} onClick={handelNoSale}/>

      </BottomNavigation>
    </Box>

    <div  style={{display:clients}}>
      <NewDashboardClient_v2/>
    </div>
    <div  style={{display:detailSale}}>
      <NewDashboardDetailSales_v2/>
    </div>
    <div  style={{display:bonus}}>
      <NewDashboardBonus_v2/>
    </div>
    <div  style={{display:product}}>
    <NewDashboardProducts_v2/>
    </div>
    <div  style={{display:noSale}}>
    <NewDashboardNoSale_v2/>
    </div>

    </div>
  );
};

export default DASHBOARD_CLIENTS_V2;
