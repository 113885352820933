//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from 'react-notification-system';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//COMPONENTES
import AlertResponse from "../../../main_components/alerts/alertResponse";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { get_pos_sector} from "../../../services/businesspartners/businesspartners";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { CustomDialog } from "react-st-modal";
//ESTILOS
//IMAGENES/ICONOS
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import ADD_POINT_SALE_SECTOR from "./tools/add_point_sale";
import CREATE from "../../../imgComponents/create.png";
import "./mapa.css";
import mapboxgl from 'mapbox-gl';
// import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import axios from 'axios';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import RouteIcon from '@mui/icons-material/Route';
import DirectionsOffIcon from '@mui/icons-material/DirectionsOff';
import { route_opt } from "./tools/route_opt";
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import { CONFIGMAP } from "./tools/config-map";
import { update_null_identifiers } from "../../../main_components/methods_v2/auxiliar_funtion";

const POINT_OF_SALE_SECTOR_V2 = () => {
  const [data, setData] = useState([]);
  const [datamap, setdatamap] = useState([]);
  const [anima, setanima]=useState(true);
  const [open, setopen]=useState(false);
  const notificationSystemRef = useRef();
  
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult]=useState(false);
  var entrada=0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const [geometry_address, setgeometry_address] = useState([]);
  const [type_dc, setype_dc]=useState("driving");

useEffect(()=>{
  handleSubmit();
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

var permisosUsuario = sessionStorage.getItem("roles_user");
var permisoUsuarioReplace = permisosUsuario?.replace(/ /g, "");
var permisos = permisoUsuarioReplace?.split(",");
var permisoconfig = permisos?.filter((x)=>x === "ROLE_CONFIGURATION");



const update_identifiers =
[
  { orderSector: 'number'},
  { code: 'string'},
  { name: 'String'},
  { lat: 'number'},
  { lng: 'number '},
  { route: 'number'}
];

function alertas(msj,status){

  if(status==true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'tr', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'tr', 
      autoDismiss: 60, 
    });
  }  
}

function sortJSON(data, key, orden) {
  return data.sort(function (a, b) {
      var x = a[key],
      y = b[key];
      if (orden === 'asc') {
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      }
      if (orden === 'desc') {
          return ((x > y) ? -1 : ((x < y) ? 1 : 0));
      }
  });
}

const handleSubmit = async () => {
      
  if(entrada===0){
    setmodalT(true); 
    entrada=entrada+1;
  }else{
    setmodalT(true);
  }
  setData([]);
  setopen(true);
  setIsLoading(true);

    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => { 
          try{
            var d = await get_pos_sector();
            const data = d?.data?.points_of_sale;
            var dataorden = sortJSON(data,'orderSector','asc'); 
            var ndt = update_null_identifiers(dataorden, update_identifiers);
            setData(ndt);
            setdatamap(ndt);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
            setIsLoading(false);
          }catch(err){
            setData([]);
            setopen(false)
            setmodalGeneral(false);
            setmodalT(false);
            setIsLoading(false);
          }
        }, 1000);
      }else{}
    } catch (error) {
      console.log(error);
    }
};
const handleStateChange = (newState) => {
  const filteredRowsCount = newState.filteredRowModel
  settotal_rows(filteredRowsCount);
};



const columns = useMemo(
  () => [
    {
      id: "orderSector",
      header:"Orden Sector",
      accessorKey: 'orderSector',
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      id: "code",
      header: "Código",
      accessorKey: "code",
      muiTableBodyCellProps: {
        align: 'center'
      },
      
    },
    {
      id: "name",
      header: "Nombre",
      accessorKey: "name",
      muiTableBodyCellProps: {
        align: 'justify'
      },
      size: 300,
      
    },
    {
      id: "lat",
      header: "Latitud",
      accessorKey: "lat",
      muiTableBodyCellProps: {
        align: 'center'
      },
      
    },
    {
      id: "lng",
      header: "Longitud",
      accessorKey: "lng",
      muiTableBodyCellProps: {
        align: 'center'
      },
    },
    {
      id: "route",
      header: "Ruta",
      accessorKey: "route",
      muiTableBodyCellProps: {
        align: 'center'
      },
      
    }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
);


const mapRef = useRef(null);
const mapInstance = useRef(null);

useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiaXZhbmFndWlsYXIiLCJhIjoiY20zMGJoN2xuMGp0eTJwcTB4cHI5bGF6OSJ9.eCm-LTQv1QzDjsjhL3f4zA';
    const map = new mapboxgl.Map({
      container: mapRef.current, 
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [data[0]?.lng ? data[0]?.lng : -97.07318829119332, data[0]?.lat ? data[0]?.lat : 18.85020875064652], 
      zoom: 12
    })
    mapInstance.current = map; 

  if(data?.length>0){

    map.on('load', async ()=>{
      map.addControl(new mapboxgl.NavigationControl());
      get_coordenadas();
      data.forEach(item=>{
        const marker =  new mapboxgl.Marker({
           color: "red",
          //  draggable: true,
         })
        .setLngLat([item?.lng, item?.lat])
         .setPopup(new mapboxgl.Popup({ offset: 25 }).setText(`${item.code} - ${item.name}`))
         .addTo(map)
         const labelElement = document.createElement('div');
         labelElement.textContent = item.orderSector;
         labelElement.className = 'marker-label'; 
         marker.getElement().appendChild(labelElement);
       });
       
     });
    return () => {
      map.remove();
    };
  } 
}, [data]);

function chunkArray(array, chunkSize) {
  const chunks = [];
   for (let i = 0; i < array.length; i += chunkSize) {
     chunks.push(array.slice(i, i + chunkSize));
   }
   return chunks;
}
function loopChunks(chunks) {
  for (let i = 1; i < chunks.length; i++) {
      const lastElement = chunks[i - 1][chunks[i - 1].length - 1]; 
      chunks[i].unshift(lastElement);
  }
  return chunks;
}

const [conf, setconf]=useState(false);
const [t, sett] = useState("normal");
const [point_initial, setpoint_initial]=useState("default");


const CustomActionMenu = () =>{
  return (
    <>  
    <div className="controlss-pers">
      <span title="Trazar Ruta"><ForkRightIcon className="button-icon-mapb-pers" onClick={()=>markers_normal()}></ForkRightIcon></span> 
      <span title="Seguimiento Ruta"><RouteIcon className="button-icon-mapb-pers2" onClick={()=>{anima===true?animacion():alertas("Espera termine el proceso")}}></RouteIcon></span>  
      <span title="Eliminar Ruta"><DirectionsOffIcon className="button-icon-mapb-pers3" onClick={()=>delete_layer()}></DirectionsOffIcon></span>
      <span title="Configurar Ruta"><MiscellaneousServicesIcon className="button-icon-mapb-pers3" onClick={()=>setconf(!conf)}></MiscellaneousServicesIcon></span>
   </div>
   {
    conf===false?<></>:
    <div className="conf-map">
      <CONFIGMAP 
        t={t} 
        sett={sett} 
        point_initial={point_initial} 
        setpoint_initial={setpoint_initial} 
        config_run={config_run}
        type_dc={type_dc}
        setype_dc={setype_dc}
      ></CONFIGMAP>  
    </div>
   }
    </>
  )
}

const config_run = (t, i) =>{
  console.log(t);
  console.log(i);
  setconf(false);

  if(t==="normal"){
    handleSubmit();
  }else{
    if(i==="default"){
      analisis();
    }else{
      analisis_fc();
    }
  }
}


const analisis = async() =>{
  try{
    setmodalT(true);
    const dau = data;
    const d = await route_opt(dau, null, type_dc);
    d.map((item, index)=>{
      item.orderSector = index+1
    })

    if(data.length === d.length){  
      setData(d);
      setmodalT(false);  
    }else{
      const dNotInData = data.filter(dataItem => 
        !d.some(dItem => dItem.code === dataItem.code)
      ); 
      dNotInData.unshift(d[d.length-1]);
      setTimeout(async() => {
        const m = await route_opt(dNotInData, null, type_dc);  
        m.unshift(...d);
        m.map((item, index)=>{
          item.orderSector = index+1
        })
        setData(m);
        setmodalT(false);  
      }, 20000);
    }
  }catch(err){
    console.log(err);
    setmodalT(false);
  }
  }

const analisis_fc = async() =>{  
  try{
    setmodalT(true);
    const d = await route_opt(data, "fc", type_dc);
    console.log(d);
    d.map((item, index)=>{
      item.orderSector = index+1  
    })
    if(data.length === d.length){  
      setData(d);
      setmodalT(false);  
    }else{
      const dNotInData = data.filter(dataItem => 
        !d.some(dItem => dItem.code === dataItem.code)
      ); 
      dNotInData.unshift(d[d.length-1]);
      setTimeout(async() => {
        const m = await route_opt(dNotInData, null, type_dc);  
        m.unshift(...d);
        m.map((item, index)=>{
          item.orderSector = index+1
        })
        setData(m);
        setmodalT(false);  
      }, 10000);
    }
  }catch(err){
    console.log(err);
    setmodalT(false);
  }
  
}


const get_coordenadas = () =>{
  var urlcoordenadas = "";
  const lotes = chunkArray(data, 24);
  const lotesConLoop = loopChunks(lotes);

  const ddd = Promise.all(lotesConLoop?.map(async(item)=>{
    urlcoordenadas = "";
    item.map((d, i)=>{    
      if(item.length-1 === i){
        urlcoordenadas = urlcoordenadas + `${d?.lng}%2C${d?.lat}`;
      }else{
        urlcoordenadas = urlcoordenadas + `${d?.lng}%2C${d?.lat}%3B`;
      }
    })
    const datageomey = await get_geometry(urlcoordenadas);  
    return datageomey;
  })).then(results => {
    return results.flat();
  });
  
  ddd.then(geometryData => {
     setgeometry_address(geometryData);
  });
  return ddd
};

const get_geometry = async(d)=>{
  const url = `https://api.mapbox.com/directions/v5/mapbox/${type_dc}/${d}?alternatives=false&continue_straight=false&geometries=geojson&language=en&overview=full&steps=true&access_token=pk.eyJ1IjoiaXZhbmFndWlsYXIiLCJhIjoiY20zMGJoN2xuMGp0eTJwcTB4cHI5bGF6OSJ9.eCm-LTQv1QzDjsjhL3f4zA`;
  try {
     const response = await axios.get(url);
     const dtt = response?.data?.routes[0]?.geometry?.coordinates;
     return dtt;
   } catch (error) {
     console.log(error);
  }
}

const delete_layer = () =>{
  const map = mapInstance.current;
  if (map && map.getStyle()) {
    const layerId = 'route'; 
    if (map.getLayer(layerId)) {
      map.removeLayer(layerId);
    }
    if (map.getSource(layerId)) {
      map.removeSource(layerId);
    }
  }
  setanima(true);
}

const markers_normal = () =>{
  console.log("se ejecuta")
  const map = mapInstance.current;
  map.addLayer({
          id: 'route',
          type: 'line',
          source: {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {},
              geometry: {
               type: 'LineString',
               coordinates: geometry_address.map(item => [item[0], item[1]])
              }
            }
          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
           'line-color': '#21BFE9',
            'line-width': 8
          }
       });
}


const animacion = ()=>{
  setanima(false);
  const map = mapInstance.current;
  if (map) {

    if (map.getLayer('route')) {
      map.removeLayer('route');
    }
    if (map.getSource('route')) {
      map.removeSource('route');
    }
    map.addSource('route', {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
      },
    });
    map.addLayer({
      id: 'route',
      type: 'line',
      source: 'route',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#21BFE9',
        'line-width': 8,
      },
    });

    const coordinates = geometry_address.map(item => [item[0], item[1]]);
    let i = 0;
    let animationFrameId;
    const drawLine = () => {
      if (i < coordinates.length) {
        const source = map.getSource('route');
        if (!source) return; // Asegúrate de que la fuente existe antes de intentar acceder a sus datos
        const data = source._data;
        data.geometry.coordinates.push(coordinates[i]);
        source.setData(data);
        i++;
        animationFrameId = requestAnimationFrame(drawLine);
      } else {
        setanima(true);
      }
    };
    drawLine();    
  }
}

return (
  <>
  <div className="">
    <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
    <MODAL_TABLE
          open={true}
          message={menssage}
          modalGeneral={modalGeneral}
          modalT={modalT}
    ></MODAL_TABLE>
    
    <BREADCRUMBS niveles={
        [
          {label: "CONSULTAR SECTORES", path:"/consult_sectors_v2"},
          {label: `PUNTOS DE VENTA DEL SECTOR: ${sessionStorage.getItem("sectorname")}`, path: null}, 
        ]
      }
      cards_dashboard={true}
      total_rows={total_rows !== "" ? total_rows : 0}
      filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
      visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
      total={data?.length}  
    ></BREADCRUMBS>
    
    <div className="new_table_v2">  
      <MaterialReactTable
       columns={columns}
       data={data}
       enableGrouping
       enablePinning
       enableFacetedValues
       enableStickyHeader
       enableStickyFooter
       enableRowPinning
       onStateChange={handleStateChange}
       enableColumnFilterModes
       enableRowActions
       enableRowSelection
       getRowId={(row) => row?.id}
       onRowSelectionChange={setRowSelection}
       enableTooltips={false}
       localization={MRT_Localization_ES}
       state={{ rowSelection, isLoading: isLoading }}
       enableColumnResizing
       enableColumnPinning
       enableColumnOrdering
       enableExpandAll={false}
       enableColumnDragging={false}
       initialState={{ showGlobalFilter: true, showColumnFilters: true }}
       muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
       muiSkeletonProps={{ animation: 'pulse', height: 28 }}
       rowVirtualizerInstanceRef
       rowVirtualizerOptions={{ overscan: 5 }}
       columnVirtualizerOptions={{ overscan: 2 }}
       muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
       positionToolbarAlertBanner="bottom"
       paginationDisplayMode='pages'
       rowPinningDisplayMode='sticky'
       layoutMode="grid"
       muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

       muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
       muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
       displayColumnDefOptions={{
          'mrt-row-pin': {
            Header: () => (
              <div>
                <img
                title="Registrar Punto de Venta del Sector"
                src={CREATE}
                style={{"cursor":"pointer"}}
                className=""
                onClick={async () => {
                  const result = await CustomDialog(
                    <ADD_POINT_SALE_SECTOR
                        handleSubmit={handleSubmit} 
                        alertas={alertas} 
                        setmodalT={setmodalT} 
                        id={sessionStorage.getItem("id_sector")} 
                        idRuta={sessionStorage.getItem("id_ruta")}
                    />,
                    {
                      className: "custom-modal",
                      title: "Registrar Punto de Venta del Sector",
                      showCloseIcon: true,
                      isCanClose:false,
                    }
                  );
                }}
              ></img>
              </div>
            ),
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
          renderRowActionMenuItems={({ row, closeMenu }) => [
             <RENDER_ROW_ACTION_MENU_ITEMS
               closeMenu={closeMenu}
               setmodalT={setmodalT}
               setmenssage={setmenssage}
               setmodalGeneral={setmodalGeneral}
               row={row}
               handleSubmit={handleSubmit}
               setreconsult={setreconsult}
               refresh_token={refresh_token}
               alertas={alertas}
             />
           ]}
          renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table} 
              file_name={"Consultar puntos de venta sector"}
              update_identifiers={update_identifiers}
              uploadcsv={true}
              settotal_rows={settotal_rows}
              alertas={alertas}
              setmodalT={setmodalT}
              handleSubmit={handleSubmit}
            />
          )
         }
      />
      
          </div>
        </div>
        <div className="cont-map-box">
          {
            permisoconfig ?
            <div className="control-pers-mapb">
              <CustomActionMenu></CustomActionMenu>
            </div>:<></>
          }
          
          <div ref={mapRef} style={{ height: '80vh', width: '100%' }} />  
        </div>
        
    </>
  );
}

export default POINT_OF_SALE_SECTOR_V2;
