//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

//COMPONENTES

//ESTILOS

import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../../../main_components/methods_v2/export";
import { get_warehouses } from "../../../../../services/cashclosing/cashclosing";
import { calculate_total } from "../../../../../main_components/methods_v2/auxiliary_functions";
import { update_null_identifiers } from "../../../../../main_components/methods_v2/auxiliar_funtion";
import { BREADCRUMBS } from "../../../../../main_components/pagination/breadcrumbs";
function WAREHOSE_CASHCLOSING_V2(props) {
  const [total_rows, settotal_rows] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [total_products, settotal_products] = useState(0);
  const [rowSelection, setRowSelection] = useState({});
  const [data, setData] = useState([]);


  const update_identifiers =
    [
      { quantity: "number" },
      { code: "string" },
      { description: "string" },

    ];
  const handle_warehose = async () => {

    setData([]);
    setIsLoading(true);
    var dta;
    try {
      const dt = await get_warehouses(sessionStorage.getItem("selectwarehouse"), sessionStorage.getItem("datawarehose"));
      const data = dt.data.sort((a, b) => a.quantity < b.quantity ? 1 : a.quantity > b.quantity ? -1 : 0);
      dta = update_null_identifiers(data?.filter((d) => d.quantity !== 0), update_identifiers);
      settotal_products(dta?.map(obj => obj.quantity).filter(d => d !== null));
      setData(dta);

    } catch (err) {
      console.log("error", err);
      setData([]);

    } finally {
      setIsLoading(false)
    }
  };



  const columns = useMemo(
    () => [

      {
        header: "Código",
        accessorKey: "code",
        size: 200,
        muiTableBodyCellProps: {
          align: 'center',
        },

      },
      {
        header: "Nombre del producto",
        accessorKey: "description",
        size: 600,
        muiTableBodyCellProps: {
          align: 'justify',
        },
      },
      {
        header: "Cantidad",
        accessorKey: "quantity",
        size: 200,
        muiTableBodyCellProps: {
          align: 'right',
        },

        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.quantity)
          const total = data.map(obj => isNaN(obj.quantity) ? 0 : obj.quantity)

          return (
            <div >
              <div>Subtotal:{calculate_total(subtotal)}</div>
              <div>Total:{calculate_total(total)}</div>
            </div>
          )
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
    ],

    [data]
  );

  useEffect(() => {
    handle_warehose();
  }, [])


  useEffect(() => {
    if(props?.warehose_reconsult===true){
      handle_warehose();
    }
  }, [props?.warehose_reconsult])


  

  return (
    <div>
      <BREADCRUMBS niveles={
        [
          { label: "CORTE DE CAJA", path: "/cashclosing_v2" },
          { label: "INVENTARIO ASIGNADO", path: null },

        ]
      }
        cards_secundary={true}
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <MaterialReactTable
        key={"WAREHOSE_CASHCLOSING_V2"}
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}



        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              data.splice(
                hoveredRow.index,
                0,
                data.splice(draggingRow.index, 1)[0],
              );
              setData([...data]);
            }
          },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);

            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }




        renderToolbarInternalActions={({ table, idenx }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            key={idenx+"WAREHOSE_CASHCLOSING_V2"}
            share_filter={false}
            total_rows={total_rows !== "" ? total_rows : 0}
            table={table} file_name={"Inventario Asignado"}
            update_identifiers={update_identifiers}
          />
        )
        }
      />

    </div>

  );
}

export default WAREHOSE_CASHCLOSING_V2;
