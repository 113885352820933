import React, { useEffect, useState, useRef} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { Checkbox } from "semantic-ui-react";
import NotificationSystem from 'react-notification-system';
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import AlertResponse from "../../../main_components/alerts/alertResponse";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { get_businesspartners_list, get_pointsofsale_list, get_productslines, post_discount, put_discount } from "../../../services/businesspartners/businesspartners";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "./discount_crud.css";

function OPERATION_DISCOUNT(props) {

  const notificationSystemRef = useRef();  
  const [idPointSale, setidPointSale] = useState(props.operation==="EDIT"?props.row.original.namePointSale:null);
  const [idbusiness, setidbusiness]=useState(props.operation==="EDIT"?props.row.original.business_partner:null);
  const [plazo, setplazo]=useState(props.operation==="EDIT"?!(props.row.original.undefined):false);
  const [fi, setfi]=useState(props.operation==="EDIT"?props.row.original.undefined==false?(props.row.original.init_date)?.slice(0,10):null:null);
  const [ff, setff]=useState(props.operation==="EDIT"?props.row.original.undefined==false?(props.row.original.fin_date)?.slice(0,10):null:null);
  const [tag, settag] = useState(props.operation==="EDIT"?props.row.original.tag:"");
  const [discount, setdiscount] = useState(props.operation==="EDIT"?props.row.original.discount:0);
  const [discountOrder, setdiscountOrder] = useState(props.operation==="EDIT"?props.row.original.discount_order:0);
  const [enable, setenable] = useState(props.operation==="EDIT"?props.row.original.enable:false);
  const [idProductsLine, setidProductsLine] = useState(props.operation==="EDIT"?props.row.original.id_products_line_fk:null);

  const [sociod, setsociod]=useState(false);
  const dialog = useDialog();
//arreglo de consulta
  const[pointsale, setpointsale] = useState();
  const[productsline, setproductsline] = useState();
  const[bandera, setbandera]=useState(true);
  const[businesdata, setbusinesdata]=useState([])
//verificacion de valores obligatorios
  const[vpos, setvpos]=useState(false); 
  const[vfi, setvfi]=useState(false);
  const[vff, setvff]=useState(false);
  const[vsocio, setvsocio]=useState(false);

  const[vtag, setvtag]=useState(false);
  const[vdiscount, setvdiscount]=useState(false);
  const[vorddis, setvorddis]=useState(false);

useEffect(()=>{
    if(props.operation==="EDIT"){
        handlelineprod();
    }else{
        handlepointsale();
        handlelineprod();
        handlebusines();
    }
},[]);

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

//consultar todos los socios de negocios
const handlebusines = async() => {
    try {
      var rf_token=await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            var d = await get_businesspartners_list();
            setbusinesdata(d === null ? [] : d.data);
          }catch(err){
            console.log(err);
            props.alertas("Error al obtener Información de Socios de Negocios, Vuelva a intentar", false);
          }
        }, 1000);
      }else{}
    } catch (err) {}
  }
  //consultar todos los puntos de venta
  const handlepointsale = async ()=>{
    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            var d = await get_pointsofsale_list();
            setpointsale(d === null ? [] : d.data);  
          }catch(err){
            console.log(err);
            props.alertas("Error al obtener Información de Puntos de Venta, Vuelva a intentar", false);
          }   
        }, 1000);
      }else{}
    } catch (err) {}
  }
const handlelineprod = async()=>{
  try {
    var rf_token = await refresh_token();
    if(rf_token === true){
      setTimeout(async() => {
        try{
          var d = await get_productslines();
          setproductsline(d === null ? [] : d.data);
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}  
  } catch (err) {}
}
const handleChange = () => {
  if(enable===true){
    setenable(false);
  }else{
    setenable(true);
  }  
};
const handleChange2=()=>{
  if(plazo==true){
    setplazo(false);
  }else{
    setplazo(true);
  }
}
const handleChange3=()=>{
    if(sociod==true){
      setsociod(false);
    }else{
      setsociod(true);
    }
  }

//crear un nuevo descuento
function createnewdiscount(){
  
    tag===""?setvtag(true):setvtag(false);
    discount===0?setvdiscount(true):setvdiscount(false);
    discountOrder===0?setvorddis(true):setvorddis(false);
    plazo==true?(fi==null?setvfi(true):setvfi(false)):setvfi(false);
    plazo==true?(ff==null?setvff(true):setvff(false)):setvfi(false);
    sociod==true?((idbusiness==null||idbusiness==0)?setvsocio(true):setvsocio(false)):((idPointSale==null||idPointSale==0)?setvpos(true):setvpos(false));
    
    if(tag==="" || discount===0 || discountOrder===0){  
      props.alertas("Parámetros faltantes", false);
      return null;
    }
    if(plazo==true){
      if(fi==null || ff == null){
        props.alertas("Parámetros faltantes en Fecha inicial y/o Fecha Final", false);   
        return null;
      }
    }
    
    if(sociod==true){
      if(idbusiness==null || idbusiness==0){
        props.alertas("No ha seleccionado Socio de Negocios", false);
        //alert("No ha seleccionado Socio de negocios");
        return null;
      }
    }else{
      if(idPointSale==null || idPointSale==0){
        props.alertas("No ha seleccionado Punto de Venta", false);
        return null;
      }
    }
    postdiscount();
  } 
  
const postdiscount = async ()=>{
  
    var linea = idProductsLine;  
  if(linea==0){
     linea=null;
  }
  props.setmodalT(true);
  setbandera(false);
    var data = {
      "undefined": !plazo,
      "initDate": fi,
      "finDate": ff,
      "directed_to_partners": sociod,
      "idPartner": sociod==true?idbusiness:null,
      "enable":enable,
      "discount":parseInt(discount),
      "tag":tag,
      "discountOrder":parseInt(discountOrder),
      "idPointSale":sociod==true?null:idPointSale,
      "idProductsLine":linea==null?null:parseInt(linea)
    }
    try {
      var rf_token = await refresh_token();
      if(rf_token === true){
        setTimeout(async() => {
          try{
            const dt = await post_discount(data);
            console.log(dt);
            props.alertas("Descuento creado con éxito", true);
            props.setmodalT(false);
            dialog.close();
            props.handleSubmit(); 
          }catch(err){
            console.log(err);
            catchErrorModul("Descuentos", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
           
             if(err?.response?.status===500){
              props.alertas("No se pudo crear el descuento, vuelva a intentarlo", false);
               props.setmodalT(false);
               setbandera(true);
             }else{   
               props.alertas(err?.response?.data?.errorMessage, false);
               props.setmodalT(false);
               setbandera(true);
             }
            props.setmodalT(false);
            setbandera(true);
          }
        }, 1000);
      }else{}
    } catch (err) {
      console.log(err);
    }
  }

  
//Actualizar descuento
const updatediscount = async()=>{

var linea = idProductsLine;

if(linea==0){
  linea=null;
}
if(!plazo==false){
  if(fi==null || ff == null){
    alert("Parametros faltantes en fecha inicial o fecha final");
    return null
  }
}

  var data = {
      "undefined": !plazo,
      "initDate": fi,
      "finDate": ff, 
      "enable":enable,
      "discount":parseInt(discount),
      "tag":tag,
      "discountOrder":parseInt(discountOrder),
      "idProductsLine":parseInt(linea),
  }
  

props.setmodalT(true);
setbandera(false);

  try {
    var rf_token = await refresh_token();
    if(rf_token === true){
      setTimeout(async() => {
        try{
          const dt = await put_discount(data, props.row.original.idDiscount);
          console.log(dt);
          props.alertas("Descuento actualizado con éxito", true);
          props.setmodalT(false);
          dialog.close();
          props.handleSubmit();
        }catch(err){
          console.log("error post", err);
          props.alertas("No se pudo actualizar el descuento, vuelva a intertarlo",false);
          catchErrorModul("Descuentos", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response)); 
          props.setmodalT(false);
          setbandera(true);
        }     
      }, 1000);
    }else{}
  } catch (err) { 
    console.log(err);
  }
} 


const handleOnSearch = (string, results) => {
    setidPointSale(0);
  };
  const handleOnHover = (result) => {};
  const handleOnSelect5 = (item) => {
      setidPointSale(item.idPointSale);
      setvpos(false);
  };
  const handleOnFocus5 = () => {
    console.log("Focused");
  };
  const handleOnClear = () => {
    console.log("Cleared");
  };
  
  
  const handleOnSearch2 = (string, results) => {
    setidbusiness(0);
  };
  const handleOnHover2 = (result) => {};
  const handleOnSelect6 = (item) => {
      setidbusiness(item.id);
      setvsocio(false);
  };
  const handleOnFocus6 = () => {
    console.log("Focused");
  };
  const handleOnClear2 = () => {
    console.log("Cleared");
  };

return (
  <div>
    <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>

    <div className="addpointsalecss crud-discount-op">

        <Box> 

    <div className="gridds0">
    <div className="itemdis00">
      <label
          style={{"fontSize":"18px", "color":"black", "padding-top":"25px", "padding-right":"10px"}}
            >Descuento Sujeto a Plazo: 
             <Checkbox
            className="check-sp"
            checked={plazo}
            disabled={!bandera}
            onChange={handleChange2}
            inputProps={{"aria-label": "primary checkbox"}}
            style={{"padding-right":"10px", "padding-left":"20px"}}
            />     
      </label>  
    </div>
    {
        props.operation==="EDIT"?
        <></>:<div className="itemdis01">
            <label style={{"fontSize":"18px", "color":"black", "padding-top":"25px", "padding-right":"10px"}}
                >Descuento Dirigido a Socio: 
                <Checkbox
                checked={sociod}
                disabled={!bandera}
                onChange={handleChange3}
                inputProps={{"aria-label": "primary checkbox"}}
                style={{"padding-right":"10px", "padding-left":"20px"}}
                />      
            </label>  
        </div>
    }
    
  </div>
  <div className="griddsfechas" style={plazo==true?{"display":"grid"}:{"display":"none"}}>
    <div className="itemdisfi">
    <label>Fecha Inicial:&nbsp;</label> 
    <input
                  type="text"
                  className="fi" 
                  disabled={!bandera}
                  value={fi}
                  style={{"width":"50%"}}
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                placeholder="Fecha inicial"
                onChange={(event) => setfi(event.target.value)}
            ></input>
        <p className="p_alert" style={vfi==true?{"display":"block","paddingTop":"10px"}:{"display":"none"}}>Completa este campo </p>
    </div>
    <div className="itemdisff">
    <label>Fecha Final:&nbsp;&nbsp;&nbsp;</label>
    <input
                  type="text"
                  className="ff"
                  style={{"width":"50%"}}
                  value={ff}
                  disabled={!bandera}
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha Final"
                  onChange={(event) => setff(event.target.value)}
            ></input>
        <p className="p_alert" style={vff==true?{"display":"block","paddingTop":"10px"}:{"display":"none"}}>Completa este campo </p>
    </div>
  </div>
  {
    props.operation==="EDIT"?
      <></>:
      <div className="griddis1">
        <div className="itemdis1" style={sociod==false?{}:{"display":"none"}}>
        <div className="inputposdis">
            {/* <label>Punto de Venta:</label> */}
                <ReactSearchAutocomplete
                            items={pointsale?.parametersList}
                            fuseOptions={{ keys: ["code","name"]}}
                            resultStringKeyName="codeAndName"
                            onSearch={handleOnSearch}
                            onHover={handleOnHover}
                            onSelect={handleOnSelect5}
                            onFocus={handleOnFocus5}
                            onClear={handleOnClear}
                            styling={{
                                height: "2px",
                                border: "1px solid gray",
                                borderRadius: "4px",
                                backgroundColor: "white",
                                color: "black",
                                iconColor: "black",
                                lineColor: "black",
                                placeholderColor: "black",
                                zIndex: 10,
                            }}
                            showIcon={true}
                            placeholder="Buscar punto de venta"      
                            /> 
                    </div>  
                    <p className="p_alert" style={vpos==true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>

        </div>
        <div className="itemdis1" style={sociod==false?{"display":"none"}:{}}>
        <div className="inputposdis">
            {/* <label>Socio de Negocios</label> */}
                <ReactSearchAutocomplete
                            items={ businesdata?.businessPartnerList}
                            fuseOptions={{ keys: ["code","business_partner"]}}
                            resultStringKeyName="business_partner"
                            onSearch={handleOnSearch2}
                            onHover={handleOnHover2}
                            onSelect={handleOnSelect6}
                            onFocus={handleOnFocus6}
                            onClear={handleOnClear2}
                            styling={{
                                height: "2px",
                                border: "1px solid gray",
                                borderRadius: "4px",
                                backgroundColor: "white",
                                color: "black",
                                iconColor: "black",
                                lineColor: "black",
                                placeholderColor: "black",
                                zIndex: 10,
                            }}
                            showIcon={true}
                            placeholder="Buscar Socio de Negocios"      
                            /> 
                    </div> 
            <p className="p_alert" style={vsocio==true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
        </div>
        </div>
  }
  <div className="griddis4">
  <div className="itemdis1e" style={props.operation==="EDIT"?props.row.original.idPointSale==null?{"display":"none"}:{}:{"display":"none"}}> 
    <TextField
              label="Punto de Venta"
              value={idPointSale}
              required
              disabled
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => settag(event.target.value)}  
              InputLabelProps={{ shrink: true }}
    ></TextField>
  </div>
  <div className="itemdis1" style={props.operation==="EDIT"?props.row.original.idPointSale==null?{}:{"display":"none"}:{"display":"none"}}>
      <TextField
              label="Socio de Negocios"
              value={idbusiness}
              required
              disabled
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => settag(event.target.value)}  
              InputLabelProps={{ shrink: true }}
    ></TextField>
  </div>  
  </div>
  <div className="griddis4">
    <div className="itemdis2">
    <TextField
              label="Etiqueta"
              required
              value={tag}
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => settag(event.target.value)}  
              InputLabelProps={{ shrink: true }}
              style={
                vtag==true?{"border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvtag(false)}
              onBlur={
                tag==""?()=>setvtag(true):()=>setvtag(false)
              }
            ></TextField>
      <p className="p_alert" style={vtag==true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
    
    </div>
  </div>
  <div className="griddis5">
    <div className="itemdis3">
    <TextField
               label="Descuento %"
               type={"number"}
               value={discount}
               disabled={!bandera}
               required
               sx={{ m: 1.5, width: "100%" }}
               onChange={(event) => setdiscount(event.target.value)}  
               InputLabelProps={{ shrink: true }}
               style={
                 vdiscount==true?{"border":"2px solid red", "border-radius":"5px"}:{}
               }
               onSelect={()=>setvdiscount(false)}
               onBlur={
                 discount==0?()=>setvdiscount(true):()=>setvdiscount(false)
               }
            ></TextField>
    </div>
    <div className="itemdis4">
    <TextField
             label="Orden de Cálculo"
             type={"number"}
             value={discountOrder}
             disabled={!bandera}
             required
             sx={{ m: 1.5, width: "100%" }}
             onChange={(event) => setdiscountOrder(event.target.value)}  
             InputLabelProps={{ shrink: true }}
             style={
               vorddis==true?{"border":"2px solid red", "border-radius":"5px"}:{}
             }
             onSelect={()=>setvorddis(false)}
             onBlur={
               discountOrder==0?()=>setvorddis(true):()=>setvorddis(false)
             }
         ></TextField>
     <p className="p_alert" style={vorddis==true?{"display":"block"}:{"display":"none"}}>Completa este campo </p>
   
    </div>
    <div className="itemdis5">
    <label
              style={{"fontSize":"18px", "color":"black", "padding-top":"25px", "padding-right":"10px"}}
            >Disponible: 
            
             <Checkbox
            checked={enable}
            disabled={!bandera}
            onChange={handleChange}
            inputProps={{"aria-label": "primary checkbox"}}
            style={{"padding-right":"10px", "padding-left":"20px"}}
            />      </label>  
    </div>
  </div>
  <div className="griddis6">
    <div className="itemdis6">
    <TextField
              select
              label="Línea de Productos"
              disabled={!bandera}
              defaultValue={null}
              InputLabelProps={{
                shrink: true,
              }}
              value={idProductsLine}
              SelectProps={{
                native: true,
              }}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setidProductsLine(event.target.value)}  
            >
            {
              productsline?.productsLineList?.map((item) => (
                <option
                   key={item.id}
                   value={item.id}
                 >       
                  {item.name_line}
                 </option>
              ))
            } 
             <option key={0} value={0}
                 >
                  {"GENERAL"}
                 </option>
              
             </TextField>
    </div>
  
  </div> 
          </Box>
          <br></br>
          <br></br>
          <Box>
            {bandera==true?
            <Button
            variant="contained"
            style={{"background-color":"green"}}
            sx={{ m: 1.5, width: "30ch" }}
            onClick={()=>props.operation==="EDIT"?updatediscount():createnewdiscount()}
          >
            {props.operation==="EDIT"?"Actualizar Descuento":"Agregar Descuento"}
          </Button>:
          <Button
          variant="contained"
          style={{"background-color":"green", "height":"35px"}}
          sx={{ m: 1.5, width: "30ch" }}
        ><Spinner/></Button>
        }
            <Button
              style={{"background-color":"red"}}
              variant="contained"
              disabled={!bandera}
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
          
      </div>
    </div>
  );
}
export default OPERATION_DISCOUNT;
