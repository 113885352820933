import { useEffect, useMemo, useRef, useState } from 'react';
import {
    Box,
    Button,
    ListItemIcon,
    MenuItem,
} from '@mui/material';
import { Confirm } from "react-st-modal";


import VIEW from "../../../../imgComponents/lupa.png";
import DELETE from "../../../../imgComponents/borrar.png";
import LOAD from "../../../../imgComponents/actualizar.png";
import { put_cashclosing, delete_cashclosing } from '../../../../services/cashclosing/cashclosing';
import { catchErrorModul } from '../../../../main_components/catchErr/catchError';

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const handel_cashclosing = async (fi, ff, route, id, id_cashclosing) => {
        try {
            // await put_cashclosing(id_cashclosing, fi, ff, route, id);
            props.handle_success_filter();
        } catch (err) {
            // catchErrorModul("Actualizar Corte de Caja", JSON.stringify({}), JSON.stringify(err?.response === undefined ? "Token expirado" : err?.response));
            var errM = JSON.stringify(err.response.data);
            alert(errM);
        }
    }


    const handel_delete_cashclosing = async (id_cashclosing) => {
        props.handle_success_filter();

        try {
            // await delete_cashclosing(id_cashclosing);
            props.handle_success_filter();

        } catch (err) {
            // catchErrorModul("Eliminar Corte de Caja ", JSON.stringify({}), JSON.stringify(err?.response === undefined ? "Token expirado" : err?.response));
            var errM = JSON.stringify(err.response.data);
            alert(errM);
        }
    }

    return (
        <div>
            <MenuItem
                key={"a" + props.row.original.id}
                onClick={async () => {
                    props.closeMenu();
                    sessionStorage.setItem("routeSale", props.row.original.route);
                    sessionStorage.setItem("dateiSale", props.row.original.idate);
                    sessionStorage.setItem("datefSale", props.row.original.fdate);
                    sessionStorage.setItem("selectwarehouse", props.row.original.user_to_review_fk);
                    sessionStorage.setItem("datawarehose", props.row.original.id);
                    window.location = "/cashclosing_sale_v2";
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        title="Consultar corte de caja"
                        src={VIEW}

                    ></img>
                </ListItemIcon>
                Consultar Corte de Caja
            </MenuItem>

            <MenuItem
                key={"b" + props.row.original.id}
                onClick={async () => {
                    props.closeMenu();

                    if (
                        sessionStorage.getItem("ROLE_INVOICING") === "ROLE_INVOICING") {
                        const result = await Confirm(
                            "¿Esta usted seguro de actualizar este corte de caja?",
                            "Actualizar corte de caja",
                            "Si",
                            "No"
                        );
                        if (result) {
                            handel_cashclosing(props.row.original.idate, props.row.original.fdate, props.row.original.route, props.row.original.user_to_review_fk, props.row.original.id);
                        } else {
                        }
                    } else {
                        alert("No tienes permisos para actualizar un corte de caja");
                    }
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        style={{ width: "25px" }}
                        alt=""
                        title=""
                        src={LOAD}
                    ></img>
                </ListItemIcon>
                Actualizar Corte de Caja
            </MenuItem>

            <MenuItem
                key={"c" + props.row.original.id}
                style={{ display: props.row.original.close === false ? "" : "none" }}
                onClick={async () => {
                    props.closeMenu();
                    if (
                        sessionStorage.getItem("ROLE_INVOICING") === "ROLE_INVOICING") {
                        const result = await Confirm(
                            "¿Esta usted seguro de eliminar este corte de caja?",
                            "Eliminar corte de caja",
                            "Si",
                            "No"
                        );
                        if (result) {
                            handel_delete_cashclosing(props.row.original.id);
                        } else {
                        }
                    } else {
                        alert("No tienes permisos para eliminar un corte de caja");
                    }
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        style={{ width: "25px" }}
                        alt=""
                        title=""
                        src={DELETE}
                    ></img>
                </ListItemIcon>
                Eliminar Corte Caja
            </MenuItem>
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
