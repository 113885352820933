import axios from "axios";


export const get_all_notices = async () => {
    const url = `https://6grw6zjp4e.execute-api.us-east-1.amazonaws.com/prod/avisos_od2/confirm`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };



  export const post_confirm_notices = async (data) => {
    const url = `https://6grw6zjp4e.execute-api.us-east-1.amazonaws.com/prod/avisos_od2/confirm`;
    try {
      const response = await axios.post(url,data, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const put_confirm_notices = async (data) => {
    const url = `https://6grw6zjp4e.execute-api.us-east-1.amazonaws.com/prod/avisos_od2/confirm`;
    try {
      const response = await axios.put(url,data, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };


  export const put_send_email = async (data) => {
    const url = `https://6grw6zjp4e.execute-api.us-east-1.amazonaws.com/prod/avisos_od2`;
    try {
      const response = await axios.put(url, data, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  
  
export const get_deatils_notices = async (id) => {
  const url = `https://6grw6zjp4e.execute-api.us-east-1.amazonaws.com/prod/avisos_od2/read?id=${id}`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
