import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/save.png";
import Delete from "../../../imgComponents/borrar.png";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { OPERATION_COMPETITION } from "./operation";
import { delete_competition_unilever } from "../../../services/unilever/unilever";
import pointsale from "../../../imgComponents/puntoventa.png";
import { useNavigate } from "react-router-dom"; // Cambiado a useNavigate

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {
    const navigate = useNavigate(); // Cambiado a useNavigate

    const build = (item) => {
        console.log(item);
        navigate("/contests_points_of_sale", { state: item }); // Cambiado a navigate
    };

    const delete_c = async () => {
        props.setmodalT(true);
        try {
            var rf_token = await refresh_token();
            if (rf_token === true) {
                setTimeout(async () => {
                    try {
                        const d = await delete_competition_unilever(props?.row?.original?.id);
                        console.log(d);
                        props.alertas("Concurso Eliminado", true);
                        props.setmodalT(false);
                        props.handlesubmit();
                    } catch (err) {
                        console.log("error", err);
                        props.setmodalT(false);
                        props.alertas("Error, no se pudo eliminar el concurso", false);
                    }
                }, 1000);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div>
            <MenuItem
                key={0}
                onClick={async () => {
                    props.closeMenu();
                    build(props?.row?.original);
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={pointsale}
                        width={"20px"}
                        title="Puntos de Venta Participantes"
                        className=""
                    />
                </ListItemIcon>
                Puntos de Venta Participantes
            </MenuItem>
            <MenuItem
                key={1}
                onClick={async () => {
                    props.closeMenu();
                    const result = await Confirm(
                        '¿Esta usted seguro de eliminar el concurso?',
                        'Eliminar Concurso',
                        'Si',
                        'No'
                    );
                    if (result) {
                        delete_c();
                    }
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Concurso"
                        className=""
                    />
                </ListItemIcon>
                Eliminar Concurso
            </MenuItem>
            <MenuItem
                key={2}
                onClick={async () => {
                    props.closeMenu();
                    await CustomDialog(
                        <OPERATION_COMPETITION
                            operation={"EDIT"}
                            row={props?.row}
                            handlesubmit={props?.handlesubmit}
                            alertas={props?.alertas}
                            setmodalT={props.setmodalT}
                        />,
                        {
                            className: "custom-modal",
                            title: "DETALLES DEL CONCURSO",
                            showCloseIcon: true,
                        }
                    );
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Detalles del Concurso"
                        className=""
                    />
                </ListItemIcon>
                Detalles del Concurso
            </MenuItem>
        </div>
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
