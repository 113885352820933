//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, Confirm, useDialog } from "react-st-modal";
import NotificationSystem from 'react-notification-system';

//COMPONENTES
import TableContainer from "../../../../main_components/Table/TableContainer";

import MODAL_TABLE from "../../../../main_components/modal/modal_Table";
//ESTILOS

//IMAGENES/ICONOS

const PRICE_DETAIL= (props) => {
  const [modalT, setmodalT] = useState(false);
  const dialog = useDialog();
  const notificationSystemRef = useRef();
  console.log(props)
  console.log(props.row)
  const columns = useMemo(
    () => [


      {
        Header: "Lista de Precios",
        accessor: "name",
        width:100,
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: "Producto",
        accessor: "product_name",
        className:"justify_data",
        width:100,
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: "Código",
        accessor: "code",
        className:"justify_data",
        width:100,
        minWidth: 100,
        maxWidth: 100,
      },

      {
        Header: "Precio",
        accessor: "price",
        className:"justify_data",

        width:100,
        minWidth: 100,
        maxWidth: 100,
      },
      
   
    ],
    []
  );

  return (
    <div >
      <div></div>
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
      <div className="">
        <div >
          <TableContainer
            columns={columns}
            data={props.row}
            exportar={true}
            nametable={"Lista de Precios"}
            style={{ overflowx: 10 }}
           
          />
        </div>
      </div>
      <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>

    </div>
  );
};

export default PRICE_DETAIL;
