import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/lupa.png";
import DETAILS_SALES_REAL_V2 from "./details_sales_real_v2";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {
    return (
        <div>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <DETAILS_SALES_REAL_V2 row={props?.row}/>,
                    {
                        className: "custom-modal-3",
                        title: "Detalles de la Venta",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Detalles de la Venta"
                        className=""
                    ></img>
                </ListItemIcon>
                Detalles de la Venta
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
