import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import SAVE from "../../../imgComponents/save.png";
import DELETE from "../../../imgComponents/borrar.png";
import ADD from "../../../imgComponents/add.png";
import UPDATE_POLICY from "./update_policy";
import { delete_policy } from "../../../services/expenses/expenses";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    
  const deletepolicy = async (id)=>{
    
    try {
    props?.setmodalT(true);
    var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          await delete_policy(id);
          props?.setmodalT(false);
          props?.alertas("Póliza eliminada con éxito", true);
          props?.handleSubmit();
        } catch (err) {
          props?.setmodalT(false);
          props?.alertas("Error.," + err.response.data.errorMessage, true);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ 
                    async () => {
                        props.closeMenu();
                        const result = await CustomDialog(
                        <UPDATE_POLICY
                        codCocept={props?.row.original.concept}
                            codType={props?.row.original.type}
                            codId={props?.row.original.id}
                            codAction={"INJECTION"}
                            codFecha={props?.row.original.export_date}
                        ></UPDATE_POLICY>,
                        {
                            className: "modalTItle",
                            title: "Inyectar poliza",
                            showCloseIcon: true,
                            isCanClose: false,
                        }
                )}}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={ADD}
                        title="Inyectar Poliza"
                        className=""
                    ></img>
                </ListItemIcon>
                Inyectar Poliza
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () => {
                    props.closeMenu();
                        if (props?.row.original.modificable === true) {
                          const result = await CustomDialog(
                            <UPDATE_POLICY
                              codCocept={props?.row.original.concept}
                              codType={props?.row.original.type}
                              codId={props?.row.original.id}
                              codAction={"UPDATE"}
                              codFecha={props?.row.original.export_date}
                              setmodalT={props?.setmodalT}
                              handleSubmit2={props?.handleSubmit}
                              alertas={props?.alertas}
                            ></UPDATE_POLICY>,
                            {
                              className: "modalTItle",
                              title: "Editar Póliza",
                              showCloseIcon: true,
                              isCanClose: false,
                            }
                          );
                        } else {
                          props?.alertas("Esta póliza ya no se puede modificar.", false);
                        }
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={SAVE}
                        title="Editar Poliza"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Poliza
            </MenuItem>
            <MenuItem
                key={3}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar la póliza?','Eliminar Póliza','Si','No');
                    if(result){
                        deletepolicy(props?.row.original.id)
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={DELETE}
                        title="Eliminar Poliza"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Poliza
            </MenuItem>
            
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
