//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, Confirm, useDialog } from "react-st-modal";
import NotificationSystem from 'react-notification-system';

//COMPONENTES
import TableContainer from "../../../main_components/Table/TableContainer";
import { get_price_list } from "../../../services/inventory/inventory";
import { delete_price_list, get_price_list_products } from "../../../services/inventory/inventory";

import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import AlertResponse from "../../../main_components/alerts/alertResponse";
import PRICE_DETAIL from "./details/price_list_detail";
import VIEW from "../../../imgComponents/lupa.png"
//ESTILOS

//IMAGENES/ICONOS

import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import PRODUCTS from "../../products/products";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import SEARCH_PRODUCT from "../../../imgComponents/icons/product.png"

const PRICE_LIST_REPORT = () => {
  const [data, setData] = useState([]);
  const [data_product, setdata_product] = useState([]);

  const [menssage, setMenssage] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const dialog = useDialog();
  const notificationSystemRef = useRef();

  const handleSubmit = async (a, b) => {
    setmodalT(true);
    setData([]);
    setMenssage("Consultando lista de precios...");
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_price_list();
            setData(dt.data);


            if (a) {
              handle_alert(b, a)
            }

          } catch (err) {
            setData([]);
            setMenssage("");
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };


  const [productsData, setProductsData] = useState([]);
  const [currentStoreIndex, setCurrentStoreIndex] = useState(0);

  // Función para hacer la petición GET y actualizar la lista de productos
  const handle_detail_warehouse = async (id) => {
    // ... tu lógica para realizar la petición GET

    const products = await get_price_list_products(id);

    // Agregar los productos a la lista productsData
    setProductsData((prevProductsData) => [
      ...prevProductsData,
      ...products.data.map((product) => ({
        id: id,
        name: data.find((store) => store.id === id)?.name,
        ...product,
      })),
    ]);

    // Incrementar el índice de la tienda actual
    setCurrentStoreIndex((prevIndex) => prevIndex + 1);
  };

  // Llamar a la función para obtener los datos de manera recursiva
  const fetchProductsRecursively = () => {

    if (currentStoreIndex < data.length) {
      console.log(currentStoreIndex)
      handle_detail_warehouse(data[currentStoreIndex].id);
      setmodalT(true);
    } else {
      setmodalT(false);

    }
  };

  // Iniciar la obtención de datos cuando el componente se monte
  useEffect(() => {
    fetchProductsRecursively();
  }, []);

  // Llamar a la función recursiva cuando se actualice el índice
  useEffect(() => {
    fetchProductsRecursively();
  }, [currentStoreIndex, data]);



  useEffect(() => {
    setmodalT(true);

    const groupedProducts = {};

    // Iteramos a través de los productos y los agrupamos por código
    productsData.forEach((product) => {
      if (!groupedProducts[product.code]) {
        // Si no existe el grupo para este código, lo creamos
        groupedProducts[product.code] = {
          code: product.code,
          product_name: product.product_name,
          description: product.description,
          description: product.pieces,
          store: [],
        };
      }

      // Agregamos el producto a su grupo correspondiente
      groupedProducts[product.code].store.push(product);
    });

    // Convertimos el objeto en un arreglo de grupos
    const groupedProductsArray = Object.values(groupedProducts);

    console.log(groupedProductsArray);
    setdata_product(groupedProductsArray)
    setmodalT(false);


  }, [productsData]);

  console.log(productsData);



  const handle_reconsult = (result) => {
    if (result?.confirm) {
      handleSubmit(result?.confirm, result.msj);
    } else if (result?.confirm === false) {
      handle_alert(result.msj, result.confirm);
    }
  };

  const handle_alert = (msj, status) => {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(() => {
    const staticColumns = [
      {
        Header: "Código",
        accessor: "code",
        width: 100,
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: "Producto",
        accessor: "product_name",
        className: "justify_data",
        width: 250,
        minWidth: 250,
        maxWidth: 250,
      },
      {
        Header: "Descripción",
        accessor: "description",
      }
    ];

    const storeNames = new Set();
    data_product.forEach(product => {
      product.store.forEach(storeItem => {
        storeNames.add(storeItem.name);
      });
    });

    const storeColumns = Array.from(storeNames).map(storeName => ({
      Header: storeName,
      accessor: `store.${storeName}`,
      Cell: ({ row }) => {
        const storeInfo = row.original.store.find(store => store.name === storeName);
        return storeInfo ? storeInfo.price : 0;
      }
    }));

    return [...staticColumns, ...storeColumns];
  }, [data_product]);

  return (
    <div >
      <div></div>
      <BREADCRUMBS niveles={
        [
          { label: "LISTA DE PRECIOS", path: null },
        ]
      }
      ></BREADCRUMBS>
      <div className="TablePriceList">
        <div >
          <TableContainer
            columns={columns}
            data={data_product}
            exportar={true}
            load_table={modalT}
            nametable={"Lista de Precios"}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <PRICE_DETAIL row={row.original.store} subtabla={true} />
                </div>
              );
            }}
          />
        </div>
      </div>
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>

    </div>
  );
};

export default PRICE_LIST_REPORT;
