//paqueterias
import { useEffect, useMemo, useRef, useState } from 'react';
import { StickyTable } from "react-sticky-table";
import NotificationSystem from 'react-notification-system';
import FormControlLabel from "@mui/material/FormControlLabel";
//componentes
import { COLUMS_SHOW } from '../../../main_components/Table/ColumsShow';
import { refresh_token } from '../../../main_components/tokens/tokenrefresh'
import { get_invoices_payment_report } from '../../../services/invoices/invoice';
import TableContainer from '../../../main_components/Table/TableContainer';
import { BREADCRUMBS } from '../../../main_components/pagination/breadcrumbs';
import { MaterialUISwitch } from "../../../main_components/material_switch/switch";
import AlertResponse from '../../../main_components/alerts/alertResponse';
import { SelectColumnFilter } from '../../filters';
import { GENERATE_COLUMNS, SHOWDATA } from '../../../main_components/Table/generate_columns';
import MODAL_TABLE from '../../../main_components/modal/modal_Table';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//Estilos css
import "./payment_report.css";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../../main_components/methods_v2/export';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Button } from "@mui/material";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { calculate_total } from '../../../main_components/methods_v2/auxiliary_functions';
import { update_null_identifiers } from '../../../main_components/methods_v2/auxiliar_funtion';
import { SEARCH_FILTER_WEB_MOVIL } from '../../../main_components/methods_v2/search_filter_web_movil';

export const PAYMENT_REPORT_V2 = () => {

  const [fi, setfi] = useState("");
  const [ff, setff] = useState("");
  const [data, setdata] = useState([]);
  const notificationSystemRef = useRef();
  const [modalT, setmodalT] = useState(false);
  const [filtersColumsView, setfiltersColumsView] = useState(true);
  const [total_rows, settotal_rows] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);

  const [gtotal, setgtotal] = useState([]);
  const [givapago, setgivapago] = useState([]);
  const [giepspago, setgiepspago] = useState([]);
  const [gmonf, setgmonf] = useState([]);
  const [givaf, setgivaf] = useState([]);
  const [giepsf, setgiepsf] = useState([]);

  const update_identifiers =
    [
      { foliop: 'string' },
      { fecha: 'string' },
      { uuidp: 'string' },
      { socio: 'string' },
      { rfc: 'string' },
      { cancel: 'string' },
      { total: 'number' },
      { baseiva0: 'number' },
      { baseieps8: 'number' },
      { folioi: 'string' },
      { uuidi: 'string' },
      { montoi: 'number' },
      { baseiva0i: 'number' },
      { baseieps8i: 'number' }
    ];


  const handlesubmit = async () => {

    if (fi === "" || fi === " " || ff === "" || ff === " ") {
      alertas("Falta asignar Fecha", false);
      return null;
    }

    setmodalT(true);
    setdata([]);
    setopenmini(false);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_invoices_payment_report(fi.format('YYYY-MM-DD'), ff.format('YYYY-MM-DD'));

            setgtotal(d?.data?.map(obj => obj.total).filter(d => d !== null));
            setgivapago(d?.data?.map(obj => obj.baseiva0).filter(d => d !== null));
            setgiepspago(d?.data?.map(obj => obj.baseieps8).filter(d => d !== null));
            setgmonf(d?.data?.map(obj => obj.montoi).filter(d => d !== null));
            setgivaf(d?.data?.map(obj => obj.baseiva0i).filter(d => d !== null));
            setgiepsf(d?.data?.map(obj => obj.baseieps8i).filter(d => d !== null));
            var ndt = update_null_identifiers(d?.data, update_identifiers);
            setdata(ndt);
            setmodalT(false);
          } catch (err) {
            console.log(err);
            setmodalT(false);
            alertas("Error al obtener información del reporte", false);
            setdata([]);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err)
    }
  }
  const alertas = (msj, status) => {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  };


  const columns = useMemo(
    () => [
      {
        header: "Folio Pago",
        accessorKey: "foliop",
        id: "foliop",
        Footer: () => (
          <div>
            <div>Subtotal: </div>
            <div>Gran Total: </div>
          </div>
        ),
      },
      {
        header: "Fecha Pago",
        accessorKey: "fecha",
        id: "fecha"
      },
      {
        header: "UUID Pago",
        accessorKey: "uuidp",
        id: "uuidp"
      },
      {
        header: "Socio de Negocios",
        accessorKey: "socio",
        id: "socio",
      },
      {
        header: "RFC",
        accessorKey: "rfc",
        id: "rfc"
      },
      {
        header: "Cancelada",
        accessorKey: "cancel",
        accessorFn: (d) => {
          return d.cancel === false ? "NO" : "SI"
        },
        id: "cancel"
      },
      {
        header: "Total",
        id: "total",
        accessorKey: "total",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.total).filter(d => d !== "")
          console.log(subtotal)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(gtotal))}</div>
            </div>
          )
        },
      },
      {
        header: "Base IVA Pago",
        id: "baseiva0",
        accessorKey: "baseiva0",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.baseiva0).filter(d => d !== "")
          console.log(subtotal)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(givapago))}</div>
            </div>
          )
        },
      },
      {
        header: "Base IEPS Pago",
        id: "baseieps8",
        accessorKey: "baseieps8",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.baseieps8).filter(d => d !== "")
          console.log(subtotal)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(giepspago))}</div>
            </div>
          )
        },
      },
      {
        header: "Folio Factura",
        accessorKey: "folioi",
        id: "folioi"
      },
      {
        header: "UUID Factura",
        accessorKey: "uuidi",
        id: "uuidi"
      },
      {
        header: "Monto Factura",
        id: "montoi",
        accessorKey: "montoi",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.montoi).filter(d => d !== "")
          console.log(subtotal)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(gmonf))}</div>
            </div>
          )
        },
      },
      {
        header: "Base IVA Factura",
        id: "baseiva0i",
        accessorKey: "baseiva0i",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.baseiva0i).filter(d => d !== "")
          console.log(subtotal)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(givaf))}</div>
            </div>
          )
        },
      },
      {
        header: "Base IEPS Factura",
        id: "baseieps8i",
        accessorKey: "baseieps8i",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.baseieps8i).filter(d => d !== "")
          console.log(subtotal)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(giepsf))}</div>
            </div>
          )
        },
      }

    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gtotal, givapago, giepspago, gmonf, givaf, giepsf]
  );

  const handlechecked = (event) => {
    setfiltersColumsView(event.target.checked);
  };

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  const [minimenu, setminimenu] = useState(false);
  const [openmini, setopenmini] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        console.log('Screen width is less than 480px');
        setminimenu(true);
      } else {
        setminimenu(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const filters_custom = (
    <div style={{ "width": "100%" }}>
      <div className='paymentreport-container'>
        <div className='search_payment_report'>
          <div className="fil1">

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "90%" }}
                label="Fecha Inicial"
                value={fi}
                onChange={(newValue) => setfi(newValue)}
              />
            </LocalizationProvider>
          </div>
          <div className="fil2">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "90%" }}
                label="Fecha Final"
                value={ff}
                onChange={(newValue) => setff(newValue)}
              />
            </LocalizationProvider>

          </div>
          <div className='button-report'>

            <Button
              variant="contained"
              title='BUSCAR'
              sx={{ height: '35px', borderRadius: "15px", marginRight: "5px", width: "50%" }}
              onClick={(e) => {
                handlesubmit();
              }}
            >
              <ManageSearchIcon></ManageSearchIcon>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
      <BREADCRUMBS niveles={
        [
          { label: "REPORTE DE COMPLEMENTOS DE PAGO", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}

      ></BREADCRUMBS>
      <SEARCH_FILTER_WEB_MOVIL
        minimenu={minimenu}
        setminimenu={setminimenu}
        openmini={openmini}
        setopenmini={setopenmini}
        filterMovComponent={filters_custom}
        renderTopToolbarCustomActionsFilters={true}
      ></SEARCH_FILTER_WEB_MOVIL>


      <div className='new_table_v2'>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          // enableRowActions
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          onStateChange={handleStateChange}
          enableExpandAll={false}
          initialState={{ showGlobalFilter: true }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
          muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setdata([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              settotal_rows(table);
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }
          renderTopToolbarCustomActions={({ }) => {
            if (minimenu === false) {
              return (
                <>
                  {filters_custom}
                </>
              )
            }
          }
          }
          renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table}
              file_name={"Cuentas Contables"}
              update_identifiers={update_identifiers}
              settotal_rows={settotal_rows}
            />
          )
          }
        />

      </div>
      {/* <div className='table_report_payment'>
          <StickyTable>
            <TableContainer 
              Grantotal={true}
              GrantotalCabeceras={["Total","Base IVA Pago","Base IEPS Pago"]}
              paginado={"Pagos"}
              consultas={"Pagos Consultados"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              nametable={"Reporte de Complementos de Pago"}
            />
          </StickyTable>

        </div> */}
    </>

  )
}
