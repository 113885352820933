import { Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { PieChart,Cell, Bar,XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, Brush, ReferenceLine, Line, ComposedChart, Pie } from 'recharts';
import "./slider.css";

export const GRAPHIC = (props) => {

    console.log(props?.data);
    const [dataf1, setdataf1]=useState([]);
    const [dataf2, setdataf2]=useState([]);
    const [dataf3, setdataf3] = useState([]);

    const [ban, setban]=useState(1);

    useEffect(() => {
      filtroagency();
      filtrodate();
      filtroresponsable();
    }, [])
    
    const filtroagency = () =>{
        if(props?.data?.length === 0){
            console.log("data vacia");
        }else{
            const agencyCounts = props?.data?.reduce((counts, item) => {
                counts[item.agency] = (counts[item.agency] || 0) + 1;
                return counts;
            }, {});
            const result = Object.entries(agencyCounts).map(([agency, count]) => ({ agency, count }));
            setdataf1(result);
        }
    }

    const filtrodate = ()=>{
        if(props?.data?.length === 0){
            console.log("data vacia");
        }else{
            const agencyCounts = props?.data?.reduce((counts, item) => {
                counts[item.date_censo] = (counts[item.date_censo] || 0) + 1;
                return counts;
            }, {});
            const result = Object.entries(agencyCounts).map(([date_censo, count]) => ({ date_censo, count }));
            setdataf2(result);
        }
    }

    const filtroresponsable = ()=>{
        if(props?.data?.length === 0){
            console.log("data vacia");
        }else{
            const agencyCounts = props?.data?.reduce((counts, item) => {
                counts[item.usuario] = (counts[item.usuario] || 0) + 1;
                return counts;
            }, {});
            const result = Object.entries(agencyCounts).map(([usuario, count]) => ({ usuario, count }));
            setdataf3(result);
        }
    }


    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
      
        return (
          <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        );
      };
    
    return (
    <div style={{"textAlign":"center"}}>
        <h3>Censo Congeladores</h3>
    <div className='grid-graph'>
        <div className='grp1'>
            <Checkbox 
                checked={ban===1? true : false}
                onClick={()=>{
                    setban(1);
            }}></Checkbox>
            <label>Agencia</label>
        </div>
        <div className="grp2">
            <Checkbox 
                checked={ban===2? true : false}
                onClick={()=>{
                    setban(2);
            }}></Checkbox>
            <label>Fecha</label>
        </div>
        <div className="grp3">
            <Checkbox 
                checked={ban===3? true : false}
                onClick={()=>{
                    setban(3);
            }}></Checkbox>
            <label>Responsable</label>
        </div>
    </div>
    <div className=''>
    <ResponsiveContainer width="95%" maxHeight={450} minHeight={500}>
        <ComposedChart
        data={ban===1 ? dataf1 : ban===2 ? dataf2 : ban===3 ? dataf3 : []}
        margin={{
          top: 20,
          right: 50,
          left: 50,
          bottom: 10
        }}
      > 
        <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={ban===1? "agency" : ban===2 ? "date_censo" : "usuario"}> 
                <Label value={ban===1 ? "Agencia" : ban===2 ? "date_censo" : "usuario"} offset={-10} position="insideBottom"/>
            </XAxis>
            <YAxis dataKey="count"/>
                <Brush dataKey="Cantidad" height={30} stroke="#2299D0" />  
            <Tooltip/>
        <Legend verticalAlign="top" height={50}/>
        <ReferenceLine y={0} stroke="#000" />       
            <Bar dataKey="count" stackId="a" name="Cantidad" fill="#2299D0"/>:    
      </ComposedChart>
      </ResponsiveContainer> 

{/* 
      <ResponsiveContainer width="95%" maxHeight={450} minHeight={500}>
        <PieChart width={800} height={800}>
          <Pie
            data={dataf1}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="count"
          >
            {dataf1.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer> */}
        </div>
    </div>
  )
}
