import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { post_accountingA, put_accountingA } from "../../services/finances/finances";
import "./modal_acc.css";


function OPERATION_ACCOUNTING_ACCOUNT(props) {

  const dialog = useDialog();
  const [code, setcode] = useState(props.operation === "EDIT" ? props.row.original.code : null);
  const [name, setname] = useState(props.operation === "EDIT" ? props.row.original.name : null);
  const [concep, setconcep] = useState(props.operation === "EDIT" ? props.row.original.expense_concept : null);
  const [type, settype] = useState(props.operation === "EDIT" ? props.row.original.type : "CARGO");
  const [efect, setefect] = useState(props.operation === "EDIT" ? props.row.original.efect : "CARGO");
  const [vcode, setvcode] = useState(false);
  const [vname, setvname] = useState(false);
  const [bandera, setbandera] = useState(true);


  const createnewaccounting = async () => {

    if (vcode === true) {
      props?.alertas("Verifica el código de la cuenta contable", false);
      return null;
    }


    if (code === null || code === "" || name === null || name === "") {
      props.alertas("Parámetros faltantes", false);
      code === null || code === "" ? setvcode(true) : setvcode(false);
      name === null || name === "" ? setvname(true) : setvname(false);
    } else {
      props.setmodalT(true);
      setbandera(false);

      var data = {
        "code": code,
        "name": name,
        "expense_concept": concep,
        "type": type,
        "efect": efect
      }
      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try {
              const d = await post_accountingA(data);
              props.setmodalT(false);
              props.alertas("Cuenta contable creada con éxito", true);
              dialog.close();
              props.handleSubmit();
            } catch (err) {
              console.log("error post", err);
              catchErrorModul("Cuentas Contables", JSON.stringify(data), JSON.stringify(err?.response === undefined ? "Token expirado" : err?.response));

              //localStorage.setItem("error_system_od2", err?.response);

              if (err.response.status === 500) {
                props.alertas("No se pudo crear la cuenta contable, vuelva a intertarlo", false);
              } else
                if (err.response.status === 400) {
                  props.alertas("Error en parámetros enviados", false);
                } else
                  if (err.response.status === 409) {
                    props.alertas("Error 409: Ya existe una cuenta contable con el mismo código")
                  }
                  else {
                    props.alertas("Error: " + err?.response?.data?.errorMessage, false);

                  }
              props.setmodalT(false);
              setbandera(true);
            }
          }, 1000);
        } else { }
      } catch (err) {
        console.log(err);
      }
    }
  }

  const updateAccounting = async () => {

    var data = {
      "code": code,
      "name": name,
      "expense_concept": concep,
      "type": type,
      "efect": efect
    }

    props.setmodalT(true);
    setbandera(false);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await put_accountingA(props.row.original.id, data);
            props.alertas("Cuenta Contable actualizada con éxito", true);
            props.setmodalT(false);
            dialog.close();
            props.handleSubmit();
          } catch (err) {
            console.log("error post", err);
            catchErrorModul("Cuentas Contables", JSON.stringify(data), JSON.stringify(err?.response === undefined ? "Token expirado" : err?.response));
            if (err.response.status === 409) {
              props.alertas("Error 409: Ya existe una cuenta contable con el mismo código")
            } else {
              props.alertas("No se pudo actualizar la cuenta contable, vuelva a intertarlo", false);
            }
            props.setmodalT(false);
            setbandera(true);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  }

  function Spinner() {
    return (
      <div className="spinner-border spinner-border-sm text-light" role="status">
        <span className="sr-only">Cargando...</span>
      </div>
    );
  }

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%',  }}>
        <TextField
          label="Código"
          value={code}
          required
          sx={{ m: 1.5, width: "100%" }}
          style={
            vcode === true ? { "border": "2px solid red", "border-radius": "5px" } : {}
          }
          onSelect={() => setvcode(false)}
          onBlur={() => {
            if (code === null || code === "") {
              setvcode(true);
            } else {
              const exists = props?.data?.some(item => item.code === code);
              if (!exists) {
                setvcode(false);
              } else {
                props?.alertas("El código ya se encuentra registrado.", false);
                setvcode(true);
              }
            }
          }
            // (code===null||code==="")?()=>setvcode(true):()=>setvcode(false)
          }
          onChange={(event) => {
            setcode(event.target.value?.trim());
          }}
          InputLabelProps={{ shrink: true }}
        ></TextField>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%',  }}>
        <TextField
          label="Nombre"
          required
          value={name}
          sx={{ m: 1.5, width: "100%" }}
          style={
            vname === true ? { "border": "2px solid red", "border-radius": "5px" } : {}
          }
          onSelect={() => setvname(false)}
          onBlur={
            (name === null || name === "") ? () => setvname(true) : () => setvname(false)
          }
          onChange={(event) => setname(event.target.value)}
          InputLabelProps={{ shrink: true }}
        ></TextField>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%',  }}>
        <TextField
          label="Concepto"
          value={concep}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setconcep(event.target.value.toUpperCase())}
          InputLabelProps={{ shrink: true }}
        ></TextField>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%',  }}>
        <TextField
          select
          label="Tipo"
          value={type}
          defaultValue={""}
          InputLabelProps={{ shrink: true }}
          SelectProps={{ native: true }}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => settype(event.target.value)}
        >
          <option key={2} value={"CARGO"}>{"CARGO"}</option>
          <option key={3} value={"ABONO"}>{"ABONO"}</option>
          <option key={4} value={"IMPUESTO"}>{"IMPUESTO"}</option>
        </TextField>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%',  }}>

        <TextField
          select
          label="Efecto"
          value={efect}
          defaultValue={""}
          InputLabelProps={{ shrink: true }}
          SelectProps={{ native: true }}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setefect(event.target.value)}
        >
          <option key={2} value={"CARGO"}>{"CARGO"}</option>
          <option key={3} value={"ABONO"}>{"ABONO"}</option>
        </TextField>

      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%',  }}>
        {bandera === true ?
          <Button
            variant="contained"
            style={{ "backgroundColor": "green" }}
            sx={{ m: 1.5, width: "100%" }}
            onClick={() => { props.operation === "EDIT" ? updateAccounting() : createnewaccounting() }}
          >
            {props.operation === "EDIT" ? "Actualizar" : "Registrar"}
          </Button> :
          <Button
            variant="contained"
            disabled
            style={{ "backgroundColor": "green", "height": "35px" }}
            sx={{ m: 1.5, width: "100%" }}
          >
            <Spinner></Spinner>
          </Button>
        }
        <Button
          style={{ "backgroundColor": "red" }}
          variant="contained"
          disabled={!bandera}
          sx={{ m: 1.5, width: "100%" }}
          onClick={() => {
            dialog.close();
          }
          }
        >
          cancelar
        </Button>
      </Box>
    </div>
  );
}
export default OPERATION_ACCOUNTING_ACCOUNT;
