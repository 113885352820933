import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/save.png";
import Delete from "../../../imgComponents/borrar.png";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import OPERATION_DISCOUNT from "./op_discount";
import { detele_discount } from "../../../services/businesspartners/businesspartners";


const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const DeleteSector =async () =>{
        props.setmodalT(true);
        props.setreconsult(true);
        try {
            var rf_token = await refresh_token();
            if(rf_token===true){
                setTimeout(async() => {
                try{
                   const dt = await detele_discount(props?.row?.original?.idDiscount);
                   console.log(dt);
                   props.alertas("Descuento Eliminado", true);
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.handleSubmit();
                }catch(err){
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.alertas("Error, no se pudo eliminar el descuento", false);
                }
                }, 1000);
            }else{}    
        } catch (err) {
        console.log(err);
        }
    }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar el Descuento?','Eliminar Descuento','Si','No');
                    if(result){
                        DeleteSector()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Descuento"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Descuento
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <OPERATION_DISCOUNT 
                            operation={"EDIT"}  
                            row={props?.row} 
                            handleSubmit={props?.handleSubmit} 
                            alertas={props?.alertas} 
                            setmodalT={props?.setmodalT}
                        />,
                    {
                        className: "custom-modal",
                        title: "EDITAR DESCUENTO",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Descuento"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Descuento
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
