import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/save.png";
import Delete from "../../../imgComponents/borrar.png";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { delete_promotion_details } from "../../../services/businesspartners/businesspartners";
import EditDetails from "./EditDetails";


const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    console.log(props);


    const DeleteSector =async () =>{
        props.setmodalT(true);
        try {
            var rf_token = await refresh_token();
            if(rf_token===true){
                setTimeout(async() => {
                try{
                   const dt = await delete_promotion_details(props?.row.original.id_promotios_fk, props?.row.original.id);
                   console.log(dt);
                   props.alertas("Producto Relacionado a la Promoción Eliminado", true);
                   props.setmodalT(false);
                   props.handlesubmit();
                }catch(err){
                   console.log(err);
                   props.setmodalT(false);
                   props.alertas("No se pudo eliminar el producto relacionado a la promoción", false);
                  }
                }, 1000);
            }else{}    
        } catch (err) {
        console.log(err);
        }
    }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar el producto relacionado a la promoción?','Eliminar Detalles de promoción','Si','No');
                    if(result){
                        DeleteSector()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Detalles de Promoción"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Detalles de Promoción
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <EditDetails
                            handlesubmit={props?.handlesubmit} 
                            row={props?.row} 
                            setmodalT={props.setmodalT} 
                            alertas={props.alertas}
                        ></EditDetails>,
                    {
                        className: "custom-modal",
                        title: "EDITAR DETALLES DE PROMOCIÓN",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Detalles de Promoción"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Detalles de Promoción
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
