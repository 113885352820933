import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import SAVE from "../../../../imgComponents/save.png";
import DELETE from "../../../../imgComponents/borrar.png";
import ADD from "../../../../imgComponents/add.png";
// import UPDATE_POLICY from "./update_policy";
import { delete_policy, delete_policy_detail } from "../../../../services/expenses/expenses";
import UPDATE_DETAIL_POLICY from "../update_detail_policy";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    
  const deletepolicy = async ()=>{
    
    try {
    props?.setmodalT(true);
    var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          const d = delete_policy_detail(props?.id, props?.row.original.id)
          console.log(d);
          props?.alertas("Póliza eliminada con éxito", true);
          setTimeout(() => {
            props?.setmodalT(false);
            props?.handleSubmit();
          }, 1000);
          
        } catch (err) {
          props?.setmodalT(false);
          props?.alertas("Error al eliminar detalle", false);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  }
   

    return (
        <div>
            <MenuItem
                key={2}
                onClick={ async () => {
                    props?.closeMenu();
                    await CustomDialog(
                    <UPDATE_DETAIL_POLICY
                      id_policy={props?.id}
                      id_detail={props?.row.original.id}
                      codaccounts={props?.row.original.accounting_account}
                      codconcept={props?.row.original.concept}
                      codamount={props?.row.original.ammount}
                      codeffect={props?.row.original.effect}
                      codidConcept={props?.row.original.id_account}
                      setmodalT={props?.setmodalT}
                      alertas={props?.alertas}
                      handleSubmit2 = {props?.handleSubmit}
                    />,
                    {
                      title: "Editar Detalle",
                      className: "modalTItle",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                )}}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={SAVE}
                        title="Editar Detalle"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Detalle
            </MenuItem>
            <MenuItem
                key={3}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar el detalle de la póliza?','Eliminar Detalle Póliza','Si','No');
                    if(result){
                        deletepolicy()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={DELETE}
                        title="Eliminar Detalle"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Detalle
            </MenuItem>
            
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
