import React, { useEffect, useState } from 'react';
import axios from "axios";
import "./datasync.css";
import MODAL_TABLE from '../main_components/modal/modal_Table';
import RefreshIcon from '@mui/icons-material/Refresh';
import GradingIcon from '@mui/icons-material/Grading';
import { HISTORY } from './tools/history';
import { CustomDialog } from 'react-st-modal';
import { HISTORY_S3 } from './tools/history_s3';

export const DATASYNC = () => {


    useEffect(() => {
        consult_status();
        consult_status_s3();
    }, [])

    const [start, setstart] = useState(false);
    const [modalT, setmodalT] = useState(false);
    const [statusupload, setstatusupload] = useState("");

    const [starts3, setstarts3] = useState(false);
    const [statuss3, setstatuss3] = useState(false);


    const consult_status = async () => {
        const url = "https://d7vstz3f03.execute-api.us-east-1.amazonaws.com/prod/datasync"
        try {
            setmodalT(true);
            const response = await axios.get(url, {
                headers: {
                    Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                    "Content-Type": "application/json",
                },
            });
            if (response?.data?.task_status === "AVAILABLE" || response?.data?.task_status === "UNAVAILABLE") {
                setstart(false);
            } else {
                setstart(true);
                setstatusupload(response?.data?.task_status);
            }
            setmodalT(false);
        } catch (error) {
            console.log(error);
            setmodalT(false);
        }
    }

    const post_transfer_s3 = async () => {
        const url = "https://d7vstz3f03.execute-api.us-east-1.amazonaws.com/prod/downloads3";
        setmodalT(true);
        try {
            const response = await axios.post(url, {
                headers: {
                    Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                    "Content-Type": "application/json",
                },
            });
            console.log(response);
            setTimeout(() => {
                setmodalT(false);
                consult_status_s3();
                alert("Se inicio la descarga de copia de seguridad a su ordenador");
            }, 2000);
        } catch (error) {
            setmodalT(false);
            console.log(error);
        }
    }


    const post_transfer = async () => {

        const url = "https://d7vstz3f03.execute-api.us-east-1.amazonaws.com/prod/datasync";
        setmodalT(true);
        try {
            const response = await axios.post(url, {
                headers: {
                    Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                    "Content-Type": "application/json",
                },
            });
            console.log(response);
            setTimeout(() => {
                setmodalT(false);
                consult_status();
                alert("Se inicio la subida de la copia de seguridad");
            }, 2000);
        } catch (error) {
            setmodalT(false);
            console.log(error);
        }
    }

    const consult_status_s3 = async () => {

        const url = "https://d7vstz3f03.execute-api.us-east-1.amazonaws.com/prod/downloads3/status"
        try {
            setmodalT(true);
            const response = await axios.get(url, {
                headers: {
                    Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                    "Content-Type": "application/json",
                },
            });
            if (response?.data?.task_status === "AVAILABLE" || response?.data?.task_status === "UNAVAILABLE") {
                setstarts3(false);
            } else {
                setstarts3(true);
                setstatuss3(response?.data?.task_status)
            }
            setmodalT(false);
        } catch (error) {
            console.log(error);
            setmodalT(false);
        }
    }

    return (
        <div className='content-transf'>
            <MODAL_TABLE
                open={true}
                message={""}
                modalGeneral={false}
                modalT={modalT}
            ></MODAL_TABLE>
            <div className='title-trasnfer'>
                <h2>Transferencia de Archivos</h2>
            </div>
            <div className='botones-trasnfer'>
                <div className='secc1'>
                    <div className='texttransfer'>
                        <h3>Transferir archivos a nube</h3>
                        <p>Sube todos tus archivos de tú computadora a la nube</p>
                    </div>
                    <div className='buttontransf'>
                        {
                            start === true ?
                                <button className='button-disabled'
                                    disabled
                                >
                                    En Ejecución...
                                </button> :
                                <button className='button'
                                    onClick={() => post_transfer()}
                                    disabled={start}
                                >
                                    Iniciar
                                </button>
                        }
                        <span
                            title="Historial"
                            className='span-svg'
                        >
                            <GradingIcon

                                style={{ "cursor": "pointer" }}
                                onClick={async () => {
                                    await CustomDialog(
                                        <HISTORY></HISTORY>
                                        ,
                                        {
                                            className: "custom-modal-3",
                                            title: "Historial",
                                            showCloseIcon: true,
                                        });
                                }
                                }
                            ></GradingIcon>
                        </span>


                    </div>
                </div>
                {
                    start === true ? <>
                        <div className='status-progress'>
                            <RefreshIcon onClick={() => consult_status()}></RefreshIcon>
                            <p>Estado: {statusupload}</p>
                        </div>
                    </> : <></>
                }
            </div>
            <div className='botones-trasnfer'>
                <div className='secc1'>
                    <div className='texttransfer'>
                        <h3>Descargar archivos de nube</h3>
                        <p>Descargar la copia de seguridad más reciente de tus archivos en su computadora.</p>
                    </div>
                    <div className='buttontransf'>
                        {
                            starts3 === true ?
                                <button className='button-disabled'
                                    disabled
                                >
                                    En Ejecución...
                                </button> :
                                <button
                                    className='button'
                                    onClick={() => post_transfer_s3()}
                                >
                                    Descargar
                                </button>
                        }
                        <span
                            title="Historial"
                            className='span-svg'
                        >
                            <GradingIcon

                                style={{ "cursor": "pointer" }}
                                onClick={async () => {
                                    await CustomDialog(
                                        <HISTORY_S3></HISTORY_S3>
                                        ,
                                        {
                                            className: "custom-modal-3",
                                            title: "Historial",
                                            showCloseIcon: true,
                                        });
                                }
                                }
                            ></GradingIcon>
                        </span>
                    </div>
                </div>
                {
                    starts3 === true ? <>
                        <div className='status-progress'>
                            <RefreshIcon onClick={() => consult_status_s3()}></RefreshIcon>
                            <p>Estado: {statuss3}</p>
                        </div>
                    </> : <></>
                }
            </div>
            <div className='botones-trasnfer'>
                <div className='secc1'>
                    <div className='texttransfer'>
                        <h3>Ajustes de transferencia</h3>
                        <p>Programa la frecuencia en que se van a estar actualizando los datos en nube (horas, días, semanas, etc).</p>
                    </div>
                    <div className='buttontransf'>
                        <button className='button'>Configurar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
