//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { MaterialReactTable } from 'material-react-table';
import { CustomDialog, Confirm } from "react-st-modal";
import axios from "axios";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
//COMPONENTES

//ESTILOS

import { get_detail_sale_cashclosing } from "../../../services/cashclosing/cashclosing";
import { get_detail_sale_return_cashclosing } from "../../../services/cashclosing/cashclosing";


//PAQUETERIAS
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import NotificationSystem from "react-notification-system";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
//COMPONENTES

import SHEARCH_IMG from "../../../imgComponents/lupa.png";
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import SliderComponent from "./sliderComponent";


function DETAIL_SALE(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [total_rows, settotal_rows] = useState("");
  const [rowSelection, setRowSelection] = useState({});

  const consult_history_censo = async () => {

    setIsLoading(true)

    var config = {
      method: "get",
      url: "https://oe6i9zr4ok.execute-api.us-east-1.amazonaws.com/prod/history",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        id: props?.economic_number
      },
    };

    axios(config)
      .then(function (response) {
        setIsLoading(false)

        setData(response.data.filter((d)=>d.date_censo!==props?.date_censo));

      })
      .catch(function (error) {
        setData([])
        setIsLoading(false)

      });




  }



  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  useEffect(() => {
    consult_history_censo();
  }, [])



  const columns = useMemo(
    () => [
      {
        header: "Acciones",
        size: 200,
        id: "ac",
        accessorFn: (d) => {
          return (
            <div>
              {/* <img src={SHEARCH_IMG} alt='Ver Detalles' title='Ver Detalles' style={{ cursor: "pointer" }}></img> */}
              <span title='Ver Imagenes del Congelador' style={{ cursor: "pointer" }}>
                <InsertPhotoIcon style={{ color: "#f39c12" }}
                  onClick={async () => {
                    console.log(d?.cabinet?.photos)
                    try {
                      await CustomDialog(

                        <div>
                          <SliderComponent
                            imageUrls={d?.cabinet?.photos}
                          ></SliderComponent>
                        </div>
                        ,
                        {
                          className: "custom-modal-6",
                          title: `Historial de censos`,
                          showCloseIcon: true,
                        }
                      );
                    } catch (err) {

                    }
                  }}
                ></InsertPhotoIcon>
              </span>
            </div>
          )
        }
      },
      {
        header: "Responsable",
        size: 200,
        id: "usuario",
        accessorKey: "usuario"
      },
      {
        header: "Fecha",
        size: 150,
        id: "date_censo",
        accessorKey: "date_censo",
      },
      {
        header: "Folio",
        size: 150,
        id: "folio",
        accessorKey: "folio",
      },
      {
        header: "Agencia",
        size: 200,
        id: "agency",
        accessorKey: "agency",
      },

      {
        header: "Tipo de Movimiento",
        size: 250,
        id: "movement_type",
        accessorKey: "movement_type",
      },

      // DATOS DEL SOCIO DE NEGOCIOS
      {
        header: "Nombre del Propietario",
        size: 200,
        accessorKey: "business_partner_name",
        id: "business_partner_name",
        accessorFn: (d) => { return d?.business_partner?.name },
      },

      {
        header: "RFC",
        size: 200,
        id: "business_partner_rfc",
        accessorKey: "business_partner_rfc",
        accessorFn: (d) => { return d?.business_partner?.rfc },
      },
      {
        header: "Domicilio",
        size: 200,
        id: "business_partner_address",
        accessorKey: "business_partner_address",
        accessorFn: (d) => { return d?.business_partner?.address },
      },
      {
        header: "Colonia",
        size: 200,
        id: "business_partner_neighborhood",
        accessorKey: "business_partner_neighborhood",
        accessorFn: (d) => { return d?.business_partner?.neighborhood },
      },
      {
        header: "Código Postal",
        size: 200,
        id: "business_partner_code",
        accessorKey: "business_partner_code",
        accessorFn: (d) => { return d?.business_partner?.code },
      },

      {
        header: "Municipio",
        size: 200,
        id: "business_partner_town",
        accessorKey: "business_partner_town",
        accessorFn: (d) => { return d?.business_partner?.town },
      },
      {
        header: "Estado",
        size: 200,
        id: "business_partner_state",
        accessorKey: "business_partner_state",
        accessorFn: (d) => { return d?.business_partner?.state },
      },
      {
        header: "Venta Con",
        size: 200,
        id: "business_partner_sales",
        accessorKey: "business_partner_sales",
        accessorFn: (d) => { return d?.business_partner?.sales },
      },
      {
        header: "Teléfono 1 (CLIENTE)",
        size: 200,
        id: "business_partner_phone",
        accessorKey: "business_partner_phone",
        accessorFn: (d) => { return d?.business_partner?.phone },
      },
      {
        header: "Teléfono 2 (CLIENTE)",
        size: 200,
        id: "business_partner_phone2",
        accessorKey: "business_partner_phone2",
        accessorFn: (d) => { return d?.business_partner?.phone2 },
      },
      {
        header: "Referencia",
        size: 200,
        id: "business_partner_reference",
        accessorKey: "business_partner_reference",
        accessorFn: (d) => { return d?.business_partner?.reference },
      },

      // DATOS DEL PUNTO DE VENTA

      {
        header: "Nombre del Negocio",
        size: 250,
        id: "point_of_sale_name",
        accessorKey: "point_of_sale_name",
        accessorFn: (d) => { return d?.point_of_sale?.name },
      },

      {
        header: "Código SAP",
        size: 200,
        id: "point_of_sale_code_sap",
        accessorKey: "point_of_sale_code_sap",

        accessorFn: (d) => { return d?.point_of_sale?.code_sap },
      },
      {
        header: "Domicilio",
        size: 200,
        id: "point_of_sale_address",
        accessorKey: "point_of_sale_address",
        accessorFn: (d) => { return d?.point_of_sale?.address },
      },
      {
        header: "Colonia",
        size: 200,
        id: "point_of_sale_neighborhood",
        accessorKey: "point_of_sale_neighborhood",
        accessorFn: (d) => { return d?.point_of_sale?.neighborhood },
      },
      {
        header: "Código Postal",
        size: 200,
        id: "point_of_sale_zip_code",
        accessorKey: "point_of_sale_zip_code",
        accessorFn: (d) => { return d?.point_of_sale?.zip_code },
      },

      {
        header: "Municipio",
        size: 200,
        id: "point_of_sale_town",
        accessorKey: "point_of_sale_town",

        accessorFn: (d) => { return d?.point_of_sale?.town },
      },
      {
        header: "Estado",
        size: 200,
        id: "point_of_sale_state",
        accessorKey: "point_of_sale_state",
        accessorFn: (d) => { return d?.point_of_sale?.state },
      },
      {
        header: "Canal de Venta",
        size: 250,
        id: "point_of_salechannel",
        accessorKey: "point_of_salechannel",
        accessorFn: (d) => { return d?.point_of_sale?.channel },
      },
      {
        header: "Ruta",
        size: 200,
        id: "point_of_sale_route",
        accessorKey: "point_of_sale_route",
        accessorFn: (d) => { return d?.point_of_sale?.route },
      },
      {
        header: "Teléfono 1(NEGOCIO)",
        size: 200,
        id: "point_of_sale_phone",
        accessorKey: "point_of_sale_phone",
        accessorFn: (d) => { return d?.point_of_sale?.phone },
      },
      {
        header: "Teléfono 2 (NEGOCIO) ",
        size: 200,
        id: "point_of_sale_phone2",
        accessorKey: "point_of_sale_phone2",
        accessorFn: (d) => { return d?.point_of_sale?.phone2 },
      },
      {
        header: "Referencia",
        size: 200,
        id: "point_of_sale_reference",
        accessorKey: "point_of_sale_reference",
        accessorFn: (d) => { return d?.point_of_sale?.reference },
      },
      {
        header: "Esquina Más Cercana",
        size: 250,
        id: "point_of_sale_nearest_corner",
        accessorKey: "point_of_sale_nearest_corner",
        accessorFn: (d) => { return d?.point_of_sale?.nearest_corner },
      },

      {
        header: "Días de Visita",
        size: 200,
        id: "point_of_sale_visit_day",
        accessorKey: "point_of_sale_visit_day",
        accessorFn: (d) => { return d?.point_of_sale?.visit_day },
      },
      {
        header: "Periodicidad",
        size: 200,
        id: "point_of_sale_frequency",
        accessorKey: "point_of_sale_frequency",

        accessorFn: (d) => { return d?.point_of_sale?.frequency },
      },

      {
        header: "Forma de Pago",
        size: 250,
        id: "payment_method",
        accessorKey: "payment_method"
      },
      {
        header: "Días de Crédito",
        size: 250,
        id: "credit_days",
        accessorKey: "credit_days"
      },
      {
        header: "Límite de Crédito",
        size: 250,
        id: "credit_limit",
        accessorKey: "credit_limit"
      },
      {
        header: "Tipo de Descuento",
        size: 250,
        id: "discount_type",
        accessorKey: "discount_type"
      },
      {
        header: "Descuento (%)",
        size: 250,
        id: "discount_percentage",
        accessorKey: "discount_percentage"
      },

      // DATOS DEL CONGELADOR

      {
        header: "Tipo",
        size: 200,
        id: "cabinet_type_cabinet",
        accessorKey: "cabinet_type_cabinet",
        accessorFn: (d) => { return d?.cabinet?.type_cabinet },
      },
      {
        header: "Número Económico",
        size: 250,
        id: "cabinet_economic_number",
        accessorKey: "cabinet_economic_number",

        accessorFn: (d) => { return d?.cabinet?.economic_number },
      },
      {
        header: "Serie",
        size: 200,
        id: "cabinet_serie",
        accessorKey: "cabinet_serie",

        accessorFn: (d) => { return d?.cabinet?.serie },
      },
      {
        header: "Marca",
        size: 200,
        id: "cabinet_brand",
        accessorKey: "cabinet_brand",

        accessorFn: (d) => { return d?.cabinet?.brand },
      },
      {
        header: "Modelo",
        size: 200,
        id: "cabinet_model",
        accessorKey: "cabinet_model",
        accessorFn: (d) => { return d?.cabinet?.model },
      },


    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]

  );






  return (
    <div style={{ width: "100%", margin: "0 auto" }} className='new_table_v2'>

      {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}

      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        // enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        onStateChange={handleStateChange}
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important", } }}
        muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}

        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              data.splice(
                hoveredRow.index,
                0,
                data.splice(draggingRow.index, 1)[0],
              );
              setData([...data]);
            }
          },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);

            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        // renderDetailPanel={({ row }) =>
        //   row?.original?.returns?.length === undefined ? "Sin devolución" : row?.original?.returns?.length > 0 ? (
        //     <RETURNS_SALES
        //       row={row?.original?.returns}
        //       id={row?.original?.id}
        //       columns={columns}
        //       handle_success_filter={handle_success_filter}

        //     />
        //   ) : "Sin devolución"
        // }
        // renderRowActionMenuItems={({ row, closeMenu }) => [
        //   <RENDER_ROW_ACTION_MENU_ITEMS
        //     key={row.original.id}
        //     closeMenu={closeMenu}
        //     setmodalT={setmodalT}
        //     setmenssage={setmenssage}
        //     setmodalGeneral={setmodalGeneral}
        //     row={row}
        //     handle_get_sales={handle_get_sales}
        //     refresh_token={refresh_token}
        //     handle_success_filter={handle_success_filter}
        //   />
        // ]}


        renderToolbarInternalActions={({ table, idenx }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            key={idenx}
            settotal_rows={settotal_rows}
            total_rows={total_rows !== "" ? total_rows : 0}
            table={table} 
            file_name={"HISTORIAL DE CENSO"}
            update_identifiers={props?.update_identifiers}
          />
        )
        }
      />
    </div >
  );
};

export default DETAIL_SALE;
