//Paqueterias
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { DialogContent, Grid, MenuItem, Box, IconButton, Typography, Tabs, Tab, Autocomplete, tabsClasses } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Props } from './interface/interface';
import { Alert, AlertTitle, List, ListItem, ListItemText } from '@mui/material';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';

//Interfaz
import { ErrorsBySection } from './interface/interface';

//Componentes
import Comments from './comments';

//Iconos e Imagenes
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import Skeleton from '@mui/material/Skeleton';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

//Estilos 
import { StyledDialog, StyledDialogTitle, StyledDialogTitleError, StyledTextField, StyledTextFieldMulti, StyledSelect, StyledInputLabel, StyledButtonCancel, StyledButton, StyledDatePicker } from './styles/general_form_styles';

const GENERAL_FORM: React.FC<Props> = (props) => {
	const { open, onClose, isNew, title_create, title_update, data_form, FormData, original_data, size, method_delete, message_delete, identifier_data, loadingSkeleton, handleProcessedFormat, onSave, validateField, updatedFormData, } = props;
	const [formData, setFormData] = useState<typeof FormData>(original_data);
	const [sections, setSections] = useState<Record<string, { title: string; fields: any[], icon: any }>>({});
	const [sectionKeys, setSectionKeys] = useState<string[]>([]);
	const [intarfaceFormUpdate, setIntarfaceFormUpdate] = useState<typeof data_form>();
	const [errors, setErrors] = useState<Record<string, string>>({});
	const [errorsBySection, setErrorsBySection] = useState<ErrorsBySection>({});
	const [activeTab, setActiveTab] = useState(0);
	const [loading, setLoading] = useState(false);
	const [create, setCreate] = useState(false);
	const [isValidated, setIsValidated] = useState(false);
	const [isModified, setIsModified] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
	const DEBOUNCE_DELAY = 500;

	var debounceTimeout: ReturnType<typeof setTimeout>;

	function areEqual(obj1: {}, obj2: {}) { return JSON.stringify(obj1) === JSON.stringify(obj2); }

	function shouldDisplayField(field: any, isNew: boolean) { return !(field.method === "UPDATE" && isNew); }

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => { setActiveTab(newValue); };

	const handleDateChange = (e: any, accessor?: string) => {
		if (dayjs.isDayjs(e) || e === null) {
			const name = accessor as string;
			const value = e ? e.format('YYYY-MM-DD') : '';
			setFormData(updatedFormData(name, value, formData));
			setErrors((prev) => ({ ...prev, [name]: '' }));
		}
	};

	const handleCheckboxChange = (checked: boolean, accessor?: string) => {
		if (accessor) {
			setFormData(updatedFormData(accessor, checked, formData));
			setErrors((prev) => ({ ...prev, [accessor]: '' }));
			handleValid();
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setFormData(updatedFormData(name, value, formData));
		setErrors((prev) => ({ ...prev, [name]: '' }));
	};

	const handleAutocompleteChange = (value: any, accessor?: string) => {
		if (accessor) {
			setFormData(updatedFormData(accessor, value, formData));
			setErrors((prev) => ({ ...prev, [accessor]: '' }));
		}
	};

	const handleChange = (e: any, accessor?: string) => {
		if (accessor && typeof e === 'object' && !('target' in e) && e !== null) {
			handleAutocompleteChange(e.value, accessor);
		} else if (dayjs.isDayjs(e) || e === null) {
			handleDateChange(e, accessor);
		} else if (typeof e === 'boolean') {
			handleCheckboxChange(e, accessor);
		} else if (e && 'target' in e) {
			handleInputChange(e as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
		}
	};

	const handleValid = useCallback((objForm?: {}) => {
		clearTimeout(debounceTimeout);
		debounceTimeout = setTimeout(() => {
			const dataToValidate = objForm ?? formData;
			if (dataToValidate && typeof dataToValidate === "object") {
				const validationErrors = validateFormData(dataToValidate);
				if (Object.keys(validationErrors).length > 0) {
					setErrors(validationErrors);
				} else {
					setErrors({});
					setErrorsBySection({});
				}
			} else {
				console.warn("No se proporcionó un objeto válido para validar.");
			}
		}, DEBOUNCE_DELAY);
	},
		[formData]
	);

	const handleValidOnBlur = (key: string) => (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const validationErrors = validateFormDataOnBlur(formData, key);
		setErrors(prevErrors => {
			const updatedErrors = { ...prevErrors };
			if (Object.keys(validationErrors).length > 0) {
				updatedErrors[key] = validationErrors[key];
			} else {
				delete updatedErrors[key];
			}
			return updatedErrors;
		});
	};

	const validateFormDataOnBlur = (data: Record<string, any>, keyName: string) => {
		const validationErrors: Record<string, string> = {};
		if (data && typeof data === 'object') {
			try {
				const error = validateField(keyName, data[keyName], formData);
				if (error.error !== '') {
					validationErrors[keyName] = error?.error;
					try {
						setIntarfaceFormUpdate(error?.objetUpdate);
					} catch (err) {
						console.error("Error in setIntarfaceFormUpdate:", err);
					}
				}
			} catch (err) {
				console.error("Error during validation of", keyName, ":", err);
			}
		} else {
			console.warn("Data is not a valid object or is missing.");
		}
		return validationErrors;
	};

	const validateFormData = (data: Record<string, any>) => {
		var validationErrors: Record<string, string> = {};
		if (data && typeof data === 'object') {
			Object.keys(data).forEach(key => {
				const error = validateField(key, data[key], formData);
				if (error) {
					validationErrors[key] = error?.error;
					setIntarfaceFormUpdate(error?.objetUpdate)
				}
			});
		}
		return validationErrors;
	};

	const createSectionsForm = (data_form: any[]) => {
		return data_form.reduce((acc, field) => {
			if (!acc[field.section]) {
				acc[field.section] = { title: field.sectionTitle, fields: [], icon: field.icon || null };
			}
			acc[field.section].fields.push(field);
			return acc;
		}, {} as Record<string, { title: string; fields: any[] }>);
	};

	const handle_delete = () => { onSave({}, 'delete', identifier_data); };

	const handleSubmit = () => {
		const validationErrors = validateFormData(formData);
		if ((Object.entries(validationErrors).filter(([key, value]) => value !== "")).length > 0) {
			setErrors(validationErrors);
		} else {
			const processedData = handleProcessedFormat(formData);
			onSave(processedData, isNew, identifier_data);
		}
	};

	useEffect(() => {
		if (Object.keys(errorsBySection).length === 0) {
			setIsModified(!areEqual(formData, original_data));
		} else {
			setIsModified(false)
		}
	}, [formData, original_data, errorsBySection]);

	useEffect(() => {
		const sections_form = createSectionsForm(data_form);
		setSections(sections_form);
		setSectionKeys(Object.keys(sections_form));
	}, [data_form, intarfaceFormUpdate]);

	useEffect(() => {
		if (open && !loadingSkeleton) {
			setFormData(original_data);
		}
	}, [open, original_data, loadingSkeleton]);

	useEffect(() => {
		if (!isNew && open && !loadingSkeleton && Object.keys(formData)?.length > 0 && !isValidated) {
			setErrorsBySection({});
			setErrors({});
			handleValid(formData);
			setIsValidated(true);
		}
	}, [formData, open, loadingSkeleton, isNew, isValidated, original_data]);

	useEffect(() => {
		const newErrorsBySection: ErrorsBySection = {};
		Object.keys(errors).forEach(errorKey => {
			const section = Object.keys(sections).find(sectionKey => sections[sectionKey].fields.some(field => field.accessor === errorKey));
			if (section && errors[errorKey].trim() !== "") {
				const sectionTitle = sections[section].title;
				newErrorsBySection[sectionTitle] = newErrorsBySection[sectionTitle] || [];
				newErrorsBySection[sectionTitle].push(errors[errorKey]);
			}
		});
		setErrorsBySection(newErrorsBySection);
	}, [errors, sections]);

	useEffect(() => {
		if (!open) {
			setErrorsBySection({});
			setErrors({});
			setCreate(false)
			setIsValidated(false);
			console.log("cerrado")
			setFormData([])
		}
	}, [open]);


	useEffect(() => {
		if (open) {
			setCreate(true)
		}
	}, [formData]);

	useEffect(() => {
		const sections_form = createSectionsForm(data_form);
		const filteredSectionKeys = Object.keys(sections_form).filter(sectionKey => sections_form[sectionKey].fields.some((field: any) => shouldDisplayField(field, isNew)));
		setSections(sections_form);
		setSectionKeys(filteredSectionKeys);
	}, [data_form, isNew, intarfaceFormUpdate]);


	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1020);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);


	const renderedSections = useMemo(() => {
		return sectionKeys.map((sectionKey, index) => (
			<Box key={sectionKey} role="tabpanel" hidden={activeTab !== index}>
				<Grid container spacing={1} columns={30} direction={isMobile ? 'column' : 'row'}>
					{sections[sectionKey].fields.map((field) => (
						<Grid item xs={30} sm={field.sm} style={{ paddingTop: "22px" }} key={field.accessor}>
							{field.method === "UPDATE" && isNew ? <div></div> :
								field.type === "date" ?
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<StyledDatePicker
											label={field.label}
											value={formData?.[field.accessor]}
											onChange={(newValue) => {
												if (dayjs.isDayjs(newValue) || newValue === null) {
													handleChange(newValue, field.accessor);
												}
											}}
											format="DD/MM/YYYY"
											slotProps={{
												textField: {
													fullWidth: true,
													error: !!errors?.[field.accessor],
													helperText: errors?.[field.accessor],
												}
											}}
										/>
									</LocalizationProvider>
									: field.type === "select" ?
										<FormControl sx={{ width: '100%' }} error={!!errors?.[field.accessor]}>
											{loadingSkeleton ? (
												<Skeleton key={`skeleton-${field.accessor}-header`} height={40} width="100%" />
											) : (
												<StyledInputLabel id="demo-simple-select-required-label">
													{field.required ? field.name_select + "*" : field.name_select}
												</StyledInputLabel>
											)}
											{loadingSkeleton ? (
												<Skeleton key={`skeleton-${field.accessor}`} height={40} width="100%" />
											) : (
												<StyledSelect
													labelId="demo-simple-select-required-label"
													id="demo-simple-select-required"
													sx={{ height: '35px' }}
													value={formData?.[field.accessor] !== undefined && formData?.[field.accessor] !== null && formData?.[field.accessor] !== ""
														? formData?.[field.accessor]
														: field.dfl}
													onChange={(e) => handleChange(e, field.accessor)}
													name={field.accessor}
													required={field.required}
													onBlur={(e) => handleValidOnBlur(field.accessor)(e)}
													error={!!errors?.[field.accessor]}
												>
													{loading && (
														<MenuItem disabled>
															<CircularProgress size={20} thickness={4} />
														</MenuItem>
													)}
													{!loading && field.data_select.map((select_option: any, index: any) => (
														<MenuItem key={`${select_option.id}-${index}`} value={select_option.value}>
															{select_option.label}
														</MenuItem>
													))}
												</StyledSelect>
											)}
										</FormControl>
										: field.type === "autocomplete" ?
											loadingSkeleton ? (
												<Skeleton key={`skeleton-${field.accessor}`} height={40} width="100%" />
											) : (
												<Autocomplete
													id="material-autocomplete"
													options={field.data_select}
													getOptionLabel={(option) => option.label}
													value={
														formData?.[field.accessor] !== undefined &&
															formData?.[field.accessor] !== null &&
															formData?.[field.accessor] !== ""
															? field.data_select.find((opt: any) => opt.value === formData?.[field.accessor])
															: null
													}
													onChange={(e, newValue) => handleChange({ target: { name: field.accessor, value: newValue ? newValue.value : '' } })}
													onBlur={(e) => handleValidOnBlur(field.accessor)}
													// onChange={(e, newValue) => handleChange({ target: { name: field.accessor, value: newValue ? newValue.value : '' } })}
													isOptionEqualToValue={(option, value) => option.value === value.value}
													loading={loading}
													style={{ width: "100%" }}
													renderInput={(params) => (
														<StyledTextField
															{...params}
															label={field.name_select}
															error={!!errors?.[field.accessor]}
															required={field.required}
															InputLabelProps={{ shrink: true }}
															size="small"
															InputProps={{
																...params.InputProps,
																endAdornment: (
																	<>
																		{loading ? <CircularProgress size={20} /> : null}
																		{params.InputProps.endAdornment}
																	</>
																),
															}}
														/>
													)}
												/>
											)
											:
											field.type === "checkbox" ?
												loadingSkeleton ? (
													<Skeleton
														key={`skeleton-${field.accessor}`}
														height={40}
														width="100%"
													/>) : (
													<div className='checkbox-ts'>
														<FormControlLabel
															control={
																<Checkbox
																	onBlur={(e) => handleValidOnBlur(field.accessor)}
																	value={formData?.[field.accessor]}
																	onChange={(e) => handleChange(e.target.checked, field.accessor)}
																/>}
															label="Forzar Código" />
													</div>
												)
												:
												field.type === "comments" ?
													loadingSkeleton ? (
														<Skeleton
															key={`skeleton-${field.accessor}`}
															height={40}
															width="100%"
														/>) : (
														<Comments commentsText={formData?.[field.accessor]} />
													)
													:
													field.type === "multitext" ?
														loadingSkeleton ? (
															<Skeleton
																key={`skeleton-${field.accessor}`}
																height={80}
																width="100%"
															/>) : (
															<StyledTextFieldMulti
																label={field.label}
																required={field.required}
																placeholder={field.label}
																multiline
																fullWidth
																key={field.accessor}
																variant="outlined"
																maxRows={10}
																name={field.accessor}
																onBlur={(e) => handleValidOnBlur(field.accessor)(e)}
																value={formData?.[field.accessor]}
																type={"text"}
																onChange={(e) => handleChange(e, field.accessor)}
																error={!!errors?.[field.accessor]}
																inputProps={{ maxLength: field.maxLength, minLength: field.minLength }}
															></StyledTextFieldMulti>
														) :
														field.type === "int" ?
															loadingSkeleton ? (
																<Skeleton
																	key={`skeleton-${field.accessor}`}
																	height={40}
																	width="100%"
																/>) : (
																<StyledTextField
																	label={field.label}
																	required={field.required}
																	placeholder={field.label}
																	sx={{ width: "100%" }}
																	onBlur={(e) => handleValidOnBlur(field.accessor)(e)}
																	key={field.accessor}
																	variant="outlined"
																	name={field.accessor}
																	value={formData?.[field.accessor] || formData?.[field.dfl] || ""}
																	type="text"
																	onChange={(e) => handleChange(e, field.accessor)}
																	error={!!errors?.[field.accessor]}
																	onKeyDown={(e) => !field.change && e.key !== "Tab" && e.preventDefault()}
																	onInput={(e: React.FormEvent<HTMLInputElement>) => {
																		const target = e.target as HTMLInputElement;
																		target.value = target.value.replace(/[^0-9]/g, '').slice(0, field.maxLength);
																	}}
																/>
															)
															:
															loadingSkeleton ? (
																<Skeleton
																	key={`skeleton-${field.accessor}`}
																	height={40}
																	width="100%"
																/>) :
																field.type === "custom" && field.customComponent ? (
																	<Box>
																		{React.createElement(field.customComponent, {
																			key: field.accessor,
																			data: formData?.[field.accessor]
																		})}
																	</Box>
																) :
																	loadingSkeleton ? (
																		<Skeleton
																			key={`skeleton-${field.accessor}`}
																			height={40}
																			width="100%"
																		/>) : (
																		<StyledTextField
																			label={field.label}
																			required={field.required}
																			placeholder={field.label}
																			sx={{ width: "100%" }}
																			onBlur={(e) => handleValidOnBlur(field.accessor)(e)}
																			key={field.accessor}
																			variant="outlined"
																			name={field.accessor}
																			value={formData?.[field.accessor] || formData?.[field.dfl] || ""}
																			type={field.type}
																			onChange={(e) => handleChange(e, field.accessor)}
																			error={!!errors?.[field.accessor]}
																			onKeyDown={(e) => !field.change && e.key !== "Tab" && e.preventDefault()}
																			onInput={(e: React.FormEvent<HTMLInputElement>) => {
																				const target = e.target as HTMLInputElement;
																				target.value = target.value.slice(0, field.maxLength);
																			}}
																		/>
																	)
							}
						</Grid>
					))}
				</Grid>
			</Box>
		));
	}, [sectionKeys, sections, activeTab, isNew, formData, loadingSkeleton, errors, isMobile]);

	const errorsDisplay = useMemo(() => {
		if (loadingSkeleton) {
			return <Skeleton key={`skeleton-error` + open} height={80} width="100%" />;
		} else if (Object.keys(errorsBySection).length > 0) {
			return Object.keys(errorsBySection).map((section, sectionIndex) => (
				<Alert
					key={sectionIndex}
					severity="error"
					sx={{ backgroundColor: '#fff5f5', color: '#d32f2f', borderLeft: '5px solid #d32f2f', marginBottom: 2 }}
				>
					<AlertTitle>Error en la Sección {section}:</AlertTitle>
					<List sx={{ padding: 0 }}>
						{errorsBySection[section].map((error, errorIndex) => (
							<ListItem key={errorIndex} sx={{ paddingY: 0.5 }}>
								<ListItemText primary={`${errorIndex + 1}.- ${error}`} />
							</ListItem>
						))}
					</List>
				</Alert>
			));
		} else {
			return <div></div>;
		}
	}, [loadingSkeleton, errorsBySection, errors, sections, formData]);

	return (
		<StyledDialog
			open={open}
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					onClose();
				}
			}}
			fullWidth
			key={"key_form" + loadingSkeleton + create}
			maxWidth={false}
			PaperProps={{
				style: { width: size, margin: '0 auto' },
			}}
		>
			{method_delete ? (
				<StyledDialogTitleError>
					<WarningIcon></WarningIcon> <p>ELIMINAR</p>
					<IconButton
						aria-label="close"
						onClick={() => onClose()}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: 'white',
						}}
					><CloseIcon /></IconButton>
				</StyledDialogTitleError>
			) : (
				<StyledDialogTitle>
					{isNew ? title_create : title_update}
					<IconButton
						aria-label="close"
						onClick={() => onClose()}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: 'white',
						}}
					><CloseIcon /></IconButton>
				</StyledDialogTitle>
			)}
			<DialogContent sx={{ marginTop: "10px" }}>
				{method_delete ? (
					<Typography >
						{message_delete}
					</Typography>
				) : (
					<>
						{sectionKeys.length > 1 && (
							loadingSkeleton ? (
								<Skeleton
									key={`skeleton-${sectionKeys}`}
									height={40}
									width="100%"
									sx={{ margin: 1 }}
								/>
							) : (
								<Box
									sx={{
										flexGrow: 1,

									}}>
									<Tabs
										value={activeTab}
										onChange={handleTabChange}
										selectionFollowsFocus={true}
										variant={"scrollable"}
										scrollButtons
										sx={{
											[`& .${tabsClasses.scrollButtons}`]: {
												'&.Mui-disabled': { opacity: 0.3 },
											},
										}}
									>
										{sectionKeys.map((sectionKey) => {
											const section = sections[sectionKey];
											const shouldShowSection = section.fields.some(field => shouldDisplayField(field, isNew));
											return shouldShowSection ? (
												<Tab
													key={sectionKey}
													label={
														<span style={{ display: 'flex', alignItems: 'center' }} title={section.title}>
															{section.icon && <section.icon sx={{ marginRight: 1 }} />}
															{isMobile ? "" : section.title}
															{errorsBySection[section.title] && errorsBySection[section.title].length > 0 && (
																<ReportGmailerrorredIcon sx={{ color: '#d32f2f', marginLeft: 1 }} />
															)}
														</span>
													}
												/>
											) : null;
										})}
									</Tabs>
								</Box>
							)
						)}
						<br></br>
						{renderedSections}
						<br></br>
						{errorsDisplay}
					</>
				)}
				{method_delete ? (
					<Box display="flex" justifyContent="flex-end" gap={2} mt={1}>
						<StyledButtonCancel variant="outlined" onClick={() => onClose()}>
							ABORTAR
						</StyledButtonCancel>
						<StyledButton variant="contained" onClick={() => handle_delete()} sx={{ backgroundColor: '#e9a952', fontWeight: 'bold' }}>
							ELIMINAR
						</StyledButton>
					</Box>
				) : (
					<Box display="flex" justifyContent="flex-end" gap={2} mt={1}>
						{loadingSkeleton ? (
							<Skeleton key={`skeleton-cancel`} height={40} width="100%" />
						) : (
							<StyledButtonCancel variant="outlined" onClick={() => onClose()}>
								CANCELAR
							</StyledButtonCancel>
						)}

						{loadingSkeleton ? (
							<Skeleton key={`skeleton-next-create`} height={40} width="100%" />
						) : (
							isNew && activeTab < sectionKeys.length - 1 ? (
								<StyledButton variant="contained" onClick={() => setActiveTab(prev => prev + 1)} sx={{ backgroundColor: '#1976d2' }}>
									SIGUIENTE
								</StyledButton>
							) : (
								<StyledButton variant="contained" onClick={() => handleSubmit()} sx={{ backgroundColor: '#1976d2' }} disabled={!isModified}>
									{isNew ? 'CREAR' : 'ACTUALIZAR'}
								</StyledButton>
							)
						)}
					</Box>

				)}
			</DialogContent>
		</StyledDialog>

	);
};

export default GENERAL_FORM;
