//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
//COMPONENTES
import {get_products_tax} from "../../../services/inventory/inventory"
//ESTILOS
//IMAGENES/ICONOS
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import SubTableContainer from "../../../main_components/Table/SubTableContainer";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";

const TAX_V2 = (props) => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  
  const handleSubmit = async(event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Productos...");
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            const dt= await get_products_tax(props?.id);
            console.log(dt);
            setData(dt.data);
            setOpen(false);
        } catch (err) {
            setData([]);
            setMenssage("");
        }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
     
      {
        id: "tax",
        header: "Impuesto",
        accessorKey: "tax",
        size: 500
      },
      {
        id: "code",
        header: "Código",
        accessorKey: "code",
      },
       
      {
        id :"configured",
        header: "Configuración",
        accessorKey: "configured",
        accessorFn: (d) => {
          return d.configured === true ? "Si" : "NO";
        },
      },
     
    ],
    []
  );

  
  const update_identifiers =
    [
      { tax: 'string' },
      { code: 'string' },
      { configured: 'string'}
    ];

  return (
    <div >
      <div>
        <h3>CONFIGURACIÓN DE IMPUESTOS</h3>
      </div>
      <br></br>
      <div className="new_table_v2">
        <div >
        <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          // enableRowActions
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          enableExpandAll={false}
          initialState={{ showGlobalFilter: true }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
                  muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }    
          renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table} 
              file_name={"Consultar impuestos del producto"}
              update_identifiers={update_identifiers}
            />
          )}
        />
        </div>
      </div>
    </div>
  );
};

export default TAX_V2;
