import { useRef, useState } from 'react'
import "./upload_mult.css";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { ReactMic } from 'react-mic';

export const UPLOAD_IMAGE_CHAT = (props) => {
  
const handleImageUpload = (e) => {
  const selectedFile = e.target.files[0];
  if(selectedFile===undefined){
   props.setdataimg({});
  }else{

  const d = selectedFile?.name.split(".");

  if (selectedFile) {
      const reader = new FileReader();
      const imageUrl = URL.createObjectURL(selectedFile);
      
      props.setImageSrc(imageUrl);
      props.settiposend("img");  
      props.setfilesrc("");
      props.setaudiosrc("");

      reader.onload = (event) => {
          const base64Image = event.target.result;
          props.setdataimg({
            "tipo":"image",
            "ext": d[1],
            "base64": base64Image,
            "name_archivo": d[0]
          })
      };
      reader.readAsDataURL(selectedFile);
  } 
 }
};
const handleImageUpload2 = (e) => {
  const selectedFile = e.target.files[0];
  if(selectedFile===undefined){
   props.setdataimg({});
  }else{
  const d = selectedFile?.name.split(".");
  if (selectedFile) {
      const reader = new FileReader();
      props.setfilesrc(d[0]);
      props.setImageSrc("");
      props.setaudiosrc("");
      props.settiposend("archivo");   
      reader.onload = (event) => {
          const base64 = event.target.result;
          props.setdataimg({
            "tipo":"archivo",
            "ext": d[1],
            "base64": base64,
            "name_archivo": d[0]
          })
      };
      reader.readAsDataURL(selectedFile);
  } 
 }
};
const fileInputRef = useRef(null);
const fileInputRef2 = useRef(null);

const handleDivClick = () => {
  fileInputRef.current.click();
}; 
const handleDivClick2 = () =>{
  fileInputRef2.current.click();
}

  const [isRecording, setIsRecording] = useState(false);


  const onStop = (recordedBlob) => {
    console.log('recordedBlob is: ', recordedBlob);
    props.setdataaudio(recordedBlob);
    props.setImageSrc("");
    props.setaudiosrc("");
    props.settiposend("audio");
    setIsRecording(false);
  };
  
return (
  <>
  <div className='input-multimedia'>
    <div className='botton-img-clic'>
        <AddPhotoAlternateIcon onClick={handleDivClick}> 
        </AddPhotoAlternateIcon>
        <input
            type='file'
            accept='.jpg, .jpeg, .png' // Acepta archivos JPG y PNG
            onChange={(e) => handleImageUpload(e)}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
      </div>
      <div className='botton-archivos'>
        <UploadFileIcon onClick={handleDivClick2}></UploadFileIcon>
        <input
            type='file'
            onChange={(e) => handleImageUpload2(e)}
            ref={fileInputRef2}
            style={{ display: 'none' }}
          />
      </div>
      <div className='botton-audio'>
          {isRecording ? (
            <MicOffIcon onClick={() => {setIsRecording(false); props.setrecord(false)}}></MicOffIcon>
          ) : (
            <MicIcon onClick={() => {setIsRecording(true); props.setrecord(true)}}></MicIcon>
          )}
      </div>
  </div>

      <ReactMic
          record={isRecording}
          className="sound-wave"
          onStop={onStop}
          // onData={(recordedData) => console.log('chunk of real-time data is: ', recordedData)}
          strokeColor="#000000"
          backgroundColor="black"
      />
  </>
  )
}
