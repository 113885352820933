import { useState, useEffect } from "react";
import { useDialog } from "react-st-modal";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CircularProgress from '@mui/material/CircularProgress';
import esLocale from 'dayjs/locale/es';

import {
  Box,
  Button,
  MenuItem,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { formattedCurrentDate, formattedPreviousDate, formattedPreviousDate_f, formattedCurrentDate_f } from "../../../../main_components/date/day";

// Componentes
import MODAL_TABLE from "../../../../main_components/modal/modal_Table";
import { get_sale_cashclosing } from "../../../../services/cashclosing/cashclosing";
// Estilos

const SELECT_DATE_REPORT = (props) => {
  const [fi, setfi] = useState(dayjs(formattedCurrentDate));
  const [modalT,setmodalT] = useState(false);

  dayjs.locale(esLocale);

  const handleSubmit = async (event) => {
    console.log(props.range_dates)
    console.log(fi.format('YYYY-MM-DD'))

    if (props?.range_dates?.includes(fi.format('YYYY-MM-DD'))) {
      var date_cashclosing = props?.data.map((d) => { return { route: d.route, fi: d.idate, ff: d.fdate, date_register: d.date_register?.split(' ')[0] } }).filter(d => d.date_register === fi.format('YYYY-MM-DD'));

      var aggregatedData = [];
      setmodalT(true);
      const promises = date_cashclosing.map(async ({ fi, ff, route }) => {
        try {
          const response = await get_sale_cashclosing(fi, ff, route);

          aggregatedData = aggregatedData.concat(response.data);
        } catch (error) {
          console.error(`Error for parameters: ${fi}, ${ff}, ${route}`, error);
        }
      });

      await Promise.all(promises);
      setmodalT(false);

      console.log(aggregatedData);
      sessionStorage.setItem("cashclosing_sale_report_v2_data", JSON.stringify(aggregatedData));
      window.location = "/cashclosing_sale_report_v2"

    } else {
      alert("La fecha seleccionada no coincide con una fecha de corte guardada")
    }

  };




  return (
    <div>
      <MODAL_TABLE
        open={true}
        message={""}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>
      <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', margin: "10px" }}>

        <LocalizationProvider dateAdapter={AdapterDayjs} locale={esLocale}>
          <DatePicker
            sx={{ width: "70%" }}
            label="Fecha Inicial"
            value={fi}
            onChange={(event) => setfi(event)}
            format="DD/MM/YYYY"

          />
        </LocalizationProvider>

        <FormControl required sx={{ width: "30%" }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button
              variant="contained"
              title='CONSULTAR'
              sx={{ height: '35px', borderRadius: "15px", marginRight: "5px", width: "100%" }}
              onClick={(event) => handleSubmit(event)}
            >
              <ManageSearchIcon></ManageSearchIcon>
            </Button>
          </Box>
        </FormControl>
      </Box>
    </div >
  );
};

export default SELECT_DATE_REPORT;














