import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/save.png";
import Delete from "../../../imgComponents/borrar.png";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import {
    download_expense,
    delete_expense,
  } from "../../../services/expenses/expenses";
import { base64toBlob } from "../../../main_components/donwloadfuncion/download";
import PDF from "../../../imgComponents/pdf.png";
import UPDATE_EXPENSE from "./update_expense";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {


  console.log(props)
    
  const handle_download = async (id, file_name) => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        props?.setmodalT(true);
        try {
          var dta = await download_expense(id);
          const data = dta.data;
          var blob = base64toBlob(data.file);
          const blobUrl = URL.createObjectURL(blob);
          const downloadLink = document.createElement("a");
          downloadLink.href = blobUrl;
          var arr = file_name.split("/");
          downloadLink.download = arr[4] + ".pdf";
          downloadLink.click();
          props?.setmodalT(false);
        } catch (err) {
          props?.setmodalT(true);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

    const DeleteSector =async () =>{
        props.setmodalT(true);
        
        try{
             var rf_token = await refresh_token();
             if(rf_token===true){
               setTimeout(async() => {
                 try{
                   const d = await delete_expense(props?.row?.original?.id);
                   console.log(d);
                   props.alertas("Gasto eliminado correctamente", true);
                   props.setmodalT(false);
                   props.handleSubmit();
                 }catch(err){
                   console.log("error", err);
                   props.setmodalT(false);
                   props.alertas("Error, no se pudo eliminar el Gasto", false);
                 }
               }, 1000);
             }else{}
           }catch(err){
            console.log(err);
           }
    }
   

    return (
        <div>
            <MenuItem
                key={2}
                onClick={()=>{
                    props.closeMenu();
                    handle_download(props?.row.original.id, props?.row.original.file_name)
                }} 
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={PDF}
                        title="Descargar PDF"
                        className=""
                    ></img>
                </ListItemIcon>
                Descargar PDF
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () => {
                    props.closeMenu();
                    if (props?.row.original.modifiable !== "ADSA") {
                        const result = await CustomDialog(
                          <UPDATE_EXPENSE
                            codCocept={props?.row.original.concept}
                            codDescripcion={props?.row.original.description}
                            codDate={props?.row.original.date}
                            codImporte={props?.row.original.ammount}
                            codAccount={props?.row.original.id_account}
                            codIdPayment={props?.row.original.paiement_account}
                            id_expense={props?.row.original.id}
                            codidConcept={props?.row.original.id_account}
                            foreing={props?.foreing}
                            method={"UPDATE"}
                            id={props?.row.original.id}
                          ></UPDATE_EXPENSE>,
                          {
                            className: "modalTItle",
                            title: "Editar Gasto",
                            showCloseIcon: true,
                            isCanClose: false,
                          }
                        );
                        {
                          props?.handleSubmit();
                        }
                      } else {
                        alert("Este gasto ya no se puede modificar.");
                      }
                    }  
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Gasto"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Gasto
            </MenuItem>
            <MenuItem
                key={3}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar este Gasto?','Eliminar Gasto','Si','No');
                    if(result){
                        DeleteSector()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Gasto"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Gasto
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
