import React, { Component } from 'react'
import { Authenticator, SignIn, SignUp} from 'aws-amplify-react/lib/Auth';
import aws_config from './aws-exports'
import './App.css'
import Content from './Content'
import { Auth } from 'aws-amplify';
import { AuthState } from '@aws-amplify/ui-components';
import CustomSignIn from './Login/CustomSignIn'
import CustomSignUp from './Login/CustomSignUp'
import ConfirmSignUp from './Login/ConfirmSignUp';
import ForgotPassword from './Login/ForgotPassword';
import ResetPassword from './Login/ResetPassword';
import './sass/_typography.scss';
import './sass/_variables.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBox, faUsers, faTag, faPlusSquare, faMinusSquare, faBars, faUserCircle, faSpinner, faTimesCircle, faPlus, faMinus, faSearch, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

library.add(faBox, faUsers, faTag, faPlusSquare, faMinusSquare, faBars, faUserCircle, faSpinner, faTimesCircle, faPlus, faMinus, faSearch, faSignOutAlt)


export class App extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      AuthState: AuthState.SignedOut,
      User: null,
      SignUpUsername: '',
    }
    
    this.SetUserName = this.SetUserName.bind(this);
    this.SetAuthState = this.SetAuthState.bind(this);
  }

  SetUserName(Val){
    this.setState({
      SignUpUsername: Val,
    })
  }


  SetUser(UserVals){
    this.setState({
      User: UserVals,
    })
  }

   async SetAuthState(Val){
     if(Val === AuthState.SignedOut){
       Auth.signOut({ global: true });
       this.SetUser(null);
       sessionStorage.clear();
     }
    this.setState({
      AuthState: Val,
    })
    try{
      const User = await Auth.currentAuthenticatedUser();
      this.SetUser(User);
    }catch(error){
      console.log(error)
    }
    
  }

  async componentDidMount(){
    try{

    const User = await Auth.currentAuthenticatedUser();
    if(User === null){
      this.SetAuthState(AuthState.SignedOut)
    } else{
      this.SetAuthState(AuthState.SignedIn);
    }
  }catch(error){
    console.log(error)
  }
  
}

  render() {
    if(this.state.AuthState === AuthState.SignedIn){
      return(
        <Content
          AuthState = {this.state.AuthState}
          User = {this.state.User}
          SetAuthState = {this.SetAuthState}
        />
      )
    } else if(this.state.AuthState === AuthState.SignedOut){
        return(
          <Content
            AuthState = {this.state.AuthState}
            User = {null}
            SetAuthState = {this.SetAuthState}
          />
        )
    } else if(this.state.AuthState === AuthState.ConfirmSignUp){
      return(
        <ConfirmSignUp
          SetAuthState={this.SetAuthState} 
          Username={this.state.SignUpUsername}
        />
      )
    } else if(this.state.AuthState === AuthState.ForgotPassword){
      return(
        <ForgotPassword 
          SetAuthState={this.SetAuthState} 
          SetUserName={this.SetUserName}
        />
      )
    } else if(this.state.AuthState === AuthState.ResetPassword){
      return(
        <ResetPassword 
          SignUpUsername={this.state.SignUpUsername}
          SetAuthState={this.SetAuthState} 
        />
      )
    } 
    else if(this.state.AuthState===AuthState.SignIn){
      return(
        <Authenticator hide={[SignIn, SignUp]} amplifyConfig={aws_config}>
            <CustomSignIn 
                SetAuthState={this.SetAuthState}
            />
           </Authenticator>
      )
    }
    else if(this.state.AuthState===AuthState.SignUp){
      return(
        <Authenticator hide={[SignIn, SignUp]} amplifyConfig={aws_config}>
             <CustomSignUp
              AuthState = {this.state.AuthState}
              SetAuthState={this.SetAuthState}
              SetUserName={this.SetUserName}
            /> 
           </Authenticator>
      )
    }
    
    // else{
    //     return(
    //       <Authenticator hide={[SignIn, SignUp]} amplifyConfig={aws_config}>
    //         <CustomSignIn 
    //           SetAuthState={this.SetAuthState}
    //         />
    //         <CustomSignUp
    //           AuthState = {this.state.AuthState}
    //           SetAuthState={this.SetAuthState}
    //           SetUserName={this.SetUserName}
    //         />  
    //       </Authenticator>
    //     )
    // }
  }
}

export default (App);