//PAQUETERIAS
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import SALES_DEBTS from "./sales_debts";
//IMAGENES/ICONOS

const DEBTS = (props) => {

  return (
    <SALES_DEBTS status={0}></SALES_DEBTS>
  );
};

export default DEBTS;
