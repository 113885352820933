import { useEffect, useState, Fragment } from 'react';
import {
  Box,
  Button,
  MenuItem,
} from '@mui/material';
//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}
const SEARCH_FILTERS2 = (props) => {
  



  return (
    <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 135 }}
          label="Fecha Inicial"
          value={props.fi}
          onChange={(newValue) => props.setfi(newValue)}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 135 }}
          label="Fecha Final"
          value={props.ff}
          onChange={(newValue) => props.setff(newValue)}
        />
      </LocalizationProvider>
      <FormControl required sx={{ width: 60 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='CONSULTAR'
            sx={{ height: '35px', width: "100%" }}
            onClick={(e) => {
              props.handleSubmit()
            }}
          >
            <ManageSearchIcon></ManageSearchIcon>
          </Button>
        </Box>
      </FormControl>      
    </Box>
  );
};

export default SEARCH_FILTERS2;
