//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//COMPONENTES
import 'dayjs/locale/es-mx';
import {Box, Button} from '@mui/material';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";

export const RETURNS = (props) => {
    const [data, setData] = useState(props?.row?.original?.details_returns);
    const [isLoading, setIsLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [total_rows, settotal_rows] = useState("");
   
    const columns = useMemo(
        () => [
          {
            id: "type",
            header: "Tipo",
            accessorKey: "type",
            muiTableBodyCellProps:{
              align: 'center'
            },
            size: 100
          },
          {
            id: "code",
            header: "Código",
            accessorKey: "code",
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            id: "product_name",
            header: "Nombre",
            accessorKey: "product_name",
            muiTableBodyCellProps: {
              align: 'left',
            },
            size: 200
          },
          {
            id: "quantity",
            header: "Cajas",
            accessorKey: "quantity",
            muiTableBodyCellProps: {
              align: 'center',
            },
            size: 100
          },
          {
            id: "pieces",
            header: "Piezas",
            accessorKey: "pieces",
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            id: "total",
            header: "Total",
            accessorKey: "total",
            muiTableBodyCellProps: {
              align: 'right',
            },
            size: 100,
            Cell: ({ cell }) => {
              return (
              <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor: theme.palette.success.dark,
                  borderRadius: '0.25rem',
                  color: '#fff',
                  maxWidth: '12ch',
                  p: '0.25rem',
                })}
              >
                {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                  style: 'currency',
                  currency: 'MXN',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) : cell.getValue()?.toLocaleString?.('es-MX', {
                  style: 'currency',
                  currency: 'MXN',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Box>
              )
            },
          },
        ], []);
        const handleStateChange = (newState) => {
            const filteredRowsCount = newState.filteredRowModel
            settotal_rows(filteredRowsCount);
          };

        
    
  const update_identifiers =
  [
    { code: 'string' },
    { product_name: 'string' },
    { quantity: 'string' },
    { pieces: 'string' },
    { total: 'string' }
  ];


    
  
  return (
    <>
    <div className="" style={{marginTop: "20px", position: "relative", width: "98%"}}>
    <BREADCRUMBS 

      niveles={
        [
          { label: "DETALLES", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
    </div>
     
    
    <div className="new_table_v2" style={{"width":"96%", margin: "0 auto"}}>

        <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowSelection
              // enableRowActions
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                  size: 80
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }

              renderToolbarInternalActions={({ table, index }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"Devoluciones/Bonificaciones"}
                  update_identifiers={update_identifiers}
                  key={index}
                  settotal_rows={settotal_rows}
                  total_rows={total_rows !== "" ? total_rows : 0}
                />
              )
              }
            />
    </div>
    </>
  )
}
