import { useLocation } from 'react-router-dom';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../../main_components/methods_v2/export';
import { useEffect, useState, useMemo, useRef } from "react";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';
import { get_applicants } from '../../../services/humanResources/humanResources';
import { BREADCRUMBS } from '../../../main_components/pagination/breadcrumbs';
import RENDER_ROW_ACTION_MENU_ITEMS from './tools/render_row_action_menuItems';
import MODAL_TABLE from '../../../main_components/modal/modal_Table';
import { CustomDialog } from 'react-st-modal';
import NotificationSystem from 'react-notification-system';
import AlertResponse from '../../../main_components/alerts/alertResponse';
import { OPERATIONS_APPLICANTS } from './tools/operations';
import CREATE from "../../../imgComponents/create.png";

export const APPLICANTS = () => {
  
    const location = useLocation();
    const dat = location.state || {};
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [total_rows, settotal_rows] = useState("");
    const [modalT, setmodalT] = useState(false);
    const [enable, setenable] = useState(false);
    const notificationSystemRef = useRef();

    useEffect(() => {
      handlesubmit();
    }, [])
    

    const handlesubmit = async() =>{
        try{
            setData([]);
            setIsLoading(true);
            const rf_token = await refresh_token();
            if(rf_token){
                setTimeout(async() => {
                    try{
                        const d = await get_applicants(dat?.job_id);
                        const sortedData = d?.data
                            ? d.data.sort((a, b) => new Date(b.application_date) - new Date(a.application_date))
                            : [];
                        setData(sortedData);
                        setIsLoading(false);
                    }catch(err){
                        setIsLoading(false);
                        console.log(err);
                    }
                }, 1500);
            }
        }catch(err){
            setIsLoading(false);
            console.log(err);
        }
    }

    const update_identifiers =
    [
      { folio: 'string' },
      { candidate_name: 'string' },
      { candidate_email: 'string' },
      { candidate_phone: 'string' },
      { application_date: 'string' },
      { status: 'string' },
      { source: 'string' }
    ];

    const handleStateChange = (newState) => {
        const filteredRowsCount = newState.filteredRowModel
        settotal_rows(filteredRowsCount);
    };    
    
  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }
    
    
  const columns = useMemo(
    () => [
      {
        id: "folio",
        header: "Folio",
        accessorKey: "folio",
        muiTableBodyCellProps:{
            align: "center"
        }
      },
      {
        id: "candidate_name",
        header: "Nombre de Candidato",
        accessorKey: "candidate_name",
        size: 250
      },
      {
        id: "candidate_email",
        header: "Correo Electrónico",
        accessorKey: "candidate_email",
        size: 250
      },
      {
        id: "candidate_phone",
        accessorKey: "candidate_phone",
        header: "Número Teléfonico"
      },
      {
        id: "application_date",
        accessorKey: "application_date",
        header: "Fecha Postulación"
      },
      {
        id: "status",
        accessorKey: "status",
        header: "Estado",
        accessorFn:(d)=>{
            return d?.status === "Pending"?"Pendiente":
                      d?.status==="Under Review"?"EN REVISIÓN":
                        d?.status==="Interview Scheduled"?"ENTREVISTA PROGRAMADA":
                          d?.status==="Accepted" ? "ACEPTADA":
                            d?.status==="Rejected" ? "RECHAZADA" : ""
        },
        muiTableBodyCellProps:{
            align: "center"
        }
      },
      {
        id: "source",
        accessorKey: "source",
        header: "Fuente",
        muiTableBodyCellProps:{
            align:"center"
        }
      }
    ],
    []
  );

  return (
    <div className=''>

        <BREADCRUMBS niveles={
            [
                { label: "CONSULTAR VACANTES", path: "/job_opportunities" },
                { label: "SOLICITUDES", path: null}
            ]
        }
            cards_dashboard={true}
            total_rows={total_rows !== "" ? total_rows : 0}
            filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
            visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
            total={data?.length}
        ></BREADCRUMBS>
          <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
          <MODAL_TABLE
            open={true}
            message={""}
            modalGeneral={false}
            modalT={modalT}
          ></MODAL_TABLE>


        <div className='new_table_v2'>
        <   MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowActions
              enableRowSelection
              onStateChange={handleStateChange}
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  Header: () => (
                    <div>
                      <img
                        title="Registrar Aspirante"
                        src={CREATE}
                        className=""
                        onClick={async () => {
                          const result = await CustomDialog(
                            <OPERATIONS_APPLICANTS
                              operation={"CREATE"}
                              job_id = {dat?.job_id}
                              setmodalT={setmodalT}
                              handlesubmit={handlesubmit}
                              data={data}
                              alertas={alertas}
                            />,
                            {
                              className: "custom-modal-2",
                              title: "Registrar Vacante",
                              showCloseIcon: true,
                              isCanClose: false,
                            }
                          );
                        }}
                      ></img>
                    </div>
                  ),
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                  size: 80
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
            //   renderTopToolbarCustomActions={({ }) => {
            //     if(minimenu===false){
            //       return(
            //       <>
            //         {filters_custom}
            //       </>
            //       )
            //     }
            //   }}
              renderRowActionMenuItems={({ row, closeMenu }) => [
                <RENDER_ROW_ACTION_MENU_ITEMS
                  closeMenu={closeMenu}
                  setmodalT={setmodalT}
                  row={row}
                  handlesubmit={handlesubmit}
                  refresh_token={refresh_token}
                  alertas={alertas}
                />
              ]}
              renderToolbarInternalActions={({ table, index }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"Solicitude a Vacantes"}
                  update_identifiers={update_identifiers}
                  key={index}
                />
              )
              }
            />
           
        </div>
    </div>
  )
}
