//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, useDialog } from "react-st-modal";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";

//COMPONENTES
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { get_sales_tickets } from "../../services/unilever/unilever";
//ESTILOS
import "../../main_components/Table/tablecontainer.css";
import "./dms.css";

//IMAGENES


import MODAL_TABLE from "../../main_components/modal/modal_Table";

//COMPONENTES


;
const INTERFAZ_TICKETS = () => {
  const [menssage, setmenssage] = useState([]);
  const [modalGeneral, setmodalGeneral] = useState([]);

  const [tickets, settickets] = useState("");
  const [modalT, setmodalT] = useState(false);
  const dialog = useDialog();
  const handleSubmit = async (event) => {
    event?.preventDefault();

    setmodalT(true);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            await get_sales_tickets(tickets);
            setmodalGeneral(false);
            setmodalT(false);
            window.location = "/DMS_V2"

          } catch (err) {
            setmodalGeneral(false);
            setmodalT(false);
            alert(JSON.stringify(err.response.data))
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };




  return (
    <div>

      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>
      <div>


        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >

          <TextareaAutosize
            label="Descripciónn"
            variant="outlined"
            aria-label="empty textarea"
            value={tickets}
            className="textarea_dms"
            placeholder="Descripción"
            onChange={(e) => settickets(e.target.value)}
          />

        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
          <Button
            variant="contained"
            sx={{ width: "44%", marginLeft: "5%" }}
            className="ButtonModal"
            onClick={() => handleSubmit()}
          >
            Generar Interfaz
          </Button>

          <Button
            variant="contained"
            sx={{ width: "44%", marginRight: "5%" }}
            className="ButtonModal2"
            onClick={() => {
              dialog.close();
            }}
          >
            Cancelar
          </Button>
        </Box>

        <br></br>

      </div>

    </div >

  );
};

export default INTERFAZ_TICKETS;
