import { CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import vis from "../../../imgComponents/visualizar.png";
import frezz from "../../../imgComponents/fridge.png";
import ViewDetail_v2 from "../ViewDetail_Sales_v2";
import ViewDetail_frezer_v2 from "../ViewDetail_frezer_v2";

const RENDER_ROW_ACTION_SALES = (props) => {

    return (
        <div>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <ViewDetail_v2
                            id_detail={props?.row?.original?.id}
                            datei={props?.datei}
                            datef={props?.datef}
                            route={props?.route} 
                            pointSale={props?.row?.original?.name}
                            address={props?.row?.original?.address}
                            customer={props?.customer}
                            nosale={props?.nosale}
                        />,
                    {
                        className: "custom-modal-2",
                        title: "Detalles de Venta",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={vis}
                        title="Detalles de Venta"
                        className=""
                    ></img>
                </ListItemIcon>
                Detalles de Venta
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <ViewDetail_frezer_v2
                            id_detail={props?.row.original.id}
                            datei={props?.datei}
                            datef={props?.datef}
                            route={props?.route} 
                            pointSale={props?.row.original.name}
                            address={props?.row.original.address}
                            customer={props?.customer}
                        />,
                    {
                        className: "custom-modal-2",
                        title: "Detalles del congelador",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={frezz}
                        title="Detalles del congelador"
                        className=""
                    ></img>
                </ListItemIcon>
                Detalles del congelador
            </MenuItem>
        </div >
    );
};

export default RENDER_ROW_ACTION_SALES;
