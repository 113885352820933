import { useEffect, useMemo, useRef, useState } from 'react';
import "../container_v2.css"
import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
} from '@mui/material';

//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';

dayjs.locale('es-mx');

const   SEARCH_FILTERS = (props) => {

  console.log(props);

  
  return (
    <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs} locale="es-mx">
        <DatePicker
          sx={{ width: props?.mini ? "100%": 140 }}
          label="Fecha Inicial"
          value={props.fi}
          onChange={(newValue) => props.setfi(newValue)}
          locale="es"
        />
      </LocalizationProvider>
      
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: props?.mini ? "100%": 140 }}
          label="Fecha Final"
          value={props.ff}
          onChange={(newValue) => props.setff(newValue)}
        />
      </LocalizationProvider>
      <FormControl required sx={{ width: props?.mini ? "100%": 100 }} style={{ display: props?.join_invoice ? "none" : "" }}>
        <InputLabel id="demo-simple-select-required-label">PUESTO</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={props?.job}
          sx={{ height: '35px' }}
          label="PUESTO"
          onChange={(e) => props.setjob(e.target.value)}
        >
          <MenuItem value={"TODOS"}>TODOS</MenuItem>
          <MenuItem value={"PREVENTA"}>PREVENTA</MenuItem>
          <MenuItem value={"REPARTO"}>REPARTO</MenuItem>
        </Select>
      </FormControl>
      {/* <FormControl required sx={{ width: 100 }} style={{ display: props?.join_invoice ? "none" : "" }}>
        <InputLabel id="demo-simple-select-required-label">Estado</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={props.cancel===null?"all":props?.cancel}
          label="Estado *"
          sx={{ height: '35px' }}
          onChange={(e) => {props.setCancel(e.target.value);console.log(e.target.value)}}
        >
          <MenuItem value={true}>CANCELADO</MenuItem>
          <MenuItem value={"false"}>VIGENTE</MenuItem>
          <MenuItem value={"all"}>TODOS</MenuItem>
        </Select>
      </FormControl>*/}
      <FormControl required sx={{ width: props?.mini ? "100%": 140 }} style={{ display: props?.join_invoice ? "none" : "" }}>
        <TextField
          id="outlined-multiline-flexible"
          sx={{ height: '35px' }}
          label="Ticket"
          value={props.tick}
          onChange={(e) => props.settick(e.target.value)}
        />
      </FormControl>
      {/* <FormControl required sx={{ width: 125 }} style={{ display: props?.join_invoice ? "none" : "" }}>
        <TextField
          id="outlined-multiline-flexible"
          label="Socio de Negocios"
          sx={{ height: '35px' }}
          value={props.sdn}
          onChange={(e) => props.setSDN(e.target.value)}

        />
      </FormControl>  */}
      <FormControl required sx={{ width: props?.mini ? "100%": 100 }}>
        <Button variant="contained"
          sx={{ height: '35px' }}
          onClick={(e) => props.handlesubmit()}
        >
          CONSULTAR
        </Button>
      </FormControl>
      {/* <FormControl required sx={{ width: props?.mini ? "100%": 100 }}>
        <Button variant="contained"
          sx={{ height: '35px' }}
          onClick={async () => await props?.authorizeFirstAccount()}
        >
          Reporte
        </Button>
      </FormControl> */}
    </Box>
  );
};

export default SEARCH_FILTERS;
