import axios from "axios";
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import "./censo.css";
import { useEffect } from "react";
import mapboxgl from 'mapbox-gl';
import data from "./csvjson.json";
// const Map = ReactMapboxGl({
//    accessToken:'pk.eyJ1IjoiaXNyYWVscm9zZXRlIiwiYSI6ImNsdG9tbGJ3czBoc28yanBjd2NvdW85YXoifQ.phAT3KuP127OB3BvMrGjVA',
//  });

export const CENSO = () => {
  
  const consult_censo  = async() =>{

      const url = "https://oe6i9zr4ok.execute-api.us-east-1.amazonaws.com/prod/censos"

      try {
          const response = await axios.get(url, {
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "Content-Type": "application/json",
            },
          });
          console.log(response);
        } catch (error) {
          console.log(error);
        }

  }
  const consult_history_censo  = async() =>{

      const url = "https://oe6i9zr4ok.execute-api.us-east-1.amazonaws.com/prod/history"

      try {
          const response = await axios.get(url, {
            params:{
              id: "445588"
            },
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "Content-Type": "application/json",
            },
          });
          console.log(response);
        } catch (error) {
          console.log(error);
        }

  }
  const create_censo = async() =>{

      var data =  {
          date: "2024-02-26",
          user: "israel.serrano@herramientasinformaticas.com",
          folio: "1232",
          agency: "ENECE",
          movement_type: "Censo",
          business_partner:
          {
            id: null,
            code: "22121",
            name: "nombre socio",
            rfc: "fcii9908032fa",
            address: "belisario dominguez",
            neighborhood: "barrio nuevo",
            zip_code: "94300",
            town: "orizaba",
            state: "veracruz",
            sales_type: 'Factura',
            phone: "2711225465",
            phone2: "2715456588",
            reference: "cerca de iglesia"
          },
          point_of_sale:{
            id: null,
            code: "4444",
            name: "7/24",
            code_sap: "4515510",
            address: "calle 121",
            neighborhood: "barrio nuevo",
            zip_code: "94300",
            town: "orizaba",
            state: "veracruz",
            channel: "zona 1",
            route: "1",
            phone: "2711256598",
            phone2: "2721256598",
            reference: "entee calles",
            nearest_corner: "esquina 12",
            visit_day: "Lunes",
            frequency: "Quincenal"
          },
          cabinet:{
            type: "Congelador",
            economic_number: "9665584",
            serie: "1595165",
            brand: "hojeda",
            model: "51515",
            photos: [
              "s3.orurlrl.com",
              "s3.sdjsldsjdl.com"
            ]
          },
          payment_method: "Credito",
          credit_days: 0,
          credit_limit: 0,
          discount_type: null,
          discount_percentage: 0,
          note: "nota final"

      }
      try {
          const url = "https://oe6i9zr4ok.execute-api.us-east-1.amazonaws.com/prod/censos";
      
          const response = await axios.post(url, data, {
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "Content-Type": "application/json",
            },
          });
          console.log(response);
        } catch (error) {
          console.log(error);
        }
  }

  


   useEffect(() => {
     mapboxgl.accessToken = 'pk.eyJ1IjoiaXZhbmFndWlsYXIiLCJhIjoiY20zMGJoN2xuMGp0eTJwcTB4cHI5bGF6OSJ9.eCm-LTQv1QzDjsjhL3f4zA';
     const map = new mapboxgl.Map({
       container: 'map', 
      //  style: 'mapbox://styles/mapbox/standard',
       style: 'mapbox://styles/mapbox/streets-v12',
       center: [-97.07318829119332, 18.85020875064652], 
       zoom: 15
     });
     map.addControl(new mapboxgl.NavigationControl());

     data.forEach(item=>{
      new mapboxgl.Marker()
       .setLngLat([item?.Longitud, item?.Latitud])
       .setPopup(
        new mapboxgl.Popup({ offset: 25 }) // add popups
        .setHTML(
        `<p>${item.Código}</p>`
        )
        )
       .addTo(map)
     })

    //  new mapboxgl.Marker()
    //    .setLngLat([-97.07318829119332, 18.85020875064652])
    //    .setPopup(
    //     new mapboxgl.Popup({ offset: 25 }) // add popups
    //     .setHTML(
    //     `<p>Esto es una prueba</p>`
    //     )
    //     )
    //    .addTo(map)

     return () => {
       map.remove();
     };
   }, []);

  return (
    <div>
      <div className="mapa-fc"> 
        <div id="map" style={{ height: '80vh', width: '100%' }} />
      </div>
    </div>
  )
}
