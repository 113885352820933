//React
import { useEffect, useState, useRef, Fragment } from "react";
//Iconos material-ui
import {
  MenuOutlined,
  Star,
  MoreVert,
} from "@material-ui/icons";

//Herramientas material
import {
  AppBar,
  Avatar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
  Toolbar,
  useMediaQuery,
  Grid,
  Icon,
} from "@material-ui/core";
import { ListItemButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
//Importaciones del proyecto
import "./menu.css";
import SearchBar from "../../nav/SearchBar";
import fcimagen from "../../img-login/fondo.webp";
import * as icon from "./Iconos";
import * as style from './style'
import { arrStorage } from "./data";
import MODAL_TABLE from "../modal/modal_Table";
import { get_config_user, post_config_signin, post_config_user } from "../../services/dashboard/dashboard";
import { refresh_token } from "../tokens/tokenrefresh";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { getMenuSections } from "./menu";

export const MenuDrawer = (props) => {
  const theme = useTheme();
  const screenMd = useMediaQuery(theme.breakpoints.down("md"));
  const screenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);
  const [showcontrol, setShowcontrol] = useState("block");
  const [showitem, setShowItem] = useState(style.styleInhab);
  const [estilo, setEstilo] = useState(style.styleClose);

  const [accountconfig, setAccountconfig] = useState(false);
  const [openconfigure, setOpenconfigure] = useState(false);
  const navigate = useNavigate();

  const userId = props.userId

  localStorage.setItem("rolesfav", JSON.stringify(props.ROLES));
  localStorage.setItem("userIdfav", props.userId);


  const arrLocalStorage = arrStorage(props.ROLES, userId)?.filter(
    (valor) =>
      valor.forStorage !== null &&
      valor.forStorage !== undefined &&
      valor.restriccion !== "none" &&
      valor.restriccion !== "DENEGADO"
  );


  console.log(arrLocalStorage)

  const [states, setstates] = useState([
    { name: "ACTIVOS", state: false, },
    { name: "ADDENDA", state: false },
    { name: "BANCOS", state: false },
    { name: "COLABORADORES", state: false, },
    { name: "FINANZAS", state: false },
    { name: "REPORTES", state: false, },
    { name: "COMPRAS", state: false },
    { name: "FACTURAS", state: false },
    { name: "SOCIO DE NEGOCIOS", state: false, },
    { name: "OPERACIONES CON UNILEVER", state: false },
    { name: "INVENTARIOS", state: false },
    { name: "SOPORTE", state: false },
    { name: "HERRAMIENTAS CON GOOGLE", state: false }
  ]);

  const [favorite, setfavorite] = useState(false);


  useEffect(() => {
    handleconf();
  }, []);

  const handleconf = async () => {
    try {
      const d = await get_config_user();
      d.data.favorites.map(item => {
        localStorage.setItem((d?.data?.id_user + "" + item.ruta), item.name);
        setfavorite(true);
      })
    } catch (err) {
      console.log(err);
    }
  }


  const handleconfpost = async (tour) => {

    const arrLocalStorage = arrStorage(props.ROLES, userId);
    const nuevoJSON = arrLocalStorage?.map((valor) => {
      if (valor.forStorage === null || valor.forStorage === undefined || valor.restriccion === "none" || valor.restriccion === "DENEGADO"
      ) {
        return null; // O puedes devolver un objeto vacío {} si prefieres mantener el elemento en el arreglo pero sin datos.
      } else {
        return {
          ruta: valor.ruta,
          name: valor.name,
        };
      }
    }).filter(Boolean); // Filtramos para eliminar los elementos que fueron mapeados a null (o vacíos) debido a las condiciones.

    var data = {
      tour: tour,
      favorites: nuevoJSON,
    }
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await post_config_user(data);
          } catch (err) {
            console.log(err);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  }

  const handleMouseOver = () => {
    if (open || screenMd) return;
    setShowItem(style.styleHab);
    setEstilo(style.styleOpen);
  };

  const handleMouseOut = () => {
    if (open || screenMd) return;
    setShowItem(style.styleInhab);
    setEstilo(style.styleClose);
  };

  const handleStateChange = (indice) => {
    const updatedStates = [...states];

    updatedStates.map((valor, index) => {

      if (indice === index) {
        if (updatedStates[index].state === true) {
          updatedStates[index].state = false;
          return valor;
        }
        updatedStates[index].state = true;
      }

      else {
        updatedStates[index].state = false;
      }
      return valor;
    })
    setstates(updatedStates);
  }

  useEffect(() => {
    if (open) {
      setShowItem(style.styleHab);
      props.onSideBarStatic();
      setEstilo(style.styleOpen);
      setShowcontrol("block");
    } else {
      setShowItem(style.styleInhab);
      setEstilo(style.styleClose);

      if (screenMd) {
        setShowcontrol("none");
        props.onSideBarHide();
      } else {
        props.onSideBarNoStatic();
        setShowcontrol("block");
      }
    }
  }, [open]);

  const avatar = props.userEmail ? props.userEmail.charAt(0).toUpperCase() : "";

  function checkLocalStorage(itemName) {
    return localStorage.getItem(itemName) !== null ? true : false;
  }

  function handleremoveItem(itemName, itemValue) {
    if (localStorage.getItem(itemName) !== null) {
      localStorage.removeItem(itemName);
      setfavorite(false);

    } else {
      localStorage.setItem(itemName, itemValue);
      setfavorite(true)
    }

    return favorite;
  }



  window.onresize = () => {
    if (!screenMd) {
      setShowcontrol("block");
      open ? props.onSideBarStatic() : props.onSideBarNoStatic();
    } else {
      props.onSideBarHide();
      setShowcontrol("none");
    }
  };


  const [stepIndex, setStepIndex] = useState(0);



  const handleClickIcon = () => {
    setStepIndex(stepIndex + 1);
  };

  const [modal, setmodal] = useState(false);
  const handleCerrarSesion = async () => {


    setmodal(true);
    await handleconfpost("false");
    await handless();

    setTimeout(() => {
      props.closeSession();
      setmodal(false);
    }, 5000);
  };


  const handless = async () => {
    const date = Date();
    var data = {
      signup: date,
    }
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await post_config_signin(data);
          } catch (err) {
            console.log(err);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  }

  function transformModulesToMenuData(sections, userId) {
    if (!Array.isArray(sections)) {
      console.error("Expected an array for sections but received:", sections);
      return [];
    }
    let idCounter = 101101; // Contador inicial para IDs únicos
    return sections.flatMap(module =>
      Array.isArray(module.menuItems)
        ? module.menuItems.map(item => ({
          id: idCounter++, // Generar un ID único
          name: item.label, // Usar el atributo label
          ruta: item?.link?.replace("/", ""), // Quitar la barra inicial de link
          restriccion: item.rol || item.roleDisplay || "none", // Asignar el rol o roleDisplay
          icono: item.icono, // Usar el atributo icono
          forStorage: localStorage.getItem(`${userId}${item?.link?.replace("/", "")}`), // Generar el valor dinámicamente
          parent: module.title, // Usar el atributo title como parent
          module_restriccion: [item.rol || item.roleDisplay || "none"], // Colocar el rol o roleDisplay en un arreglo
        }))
        : [] // Si no hay menuItems, regresar un arreglo vacío
    );
  }

  const sections = getMenuSections(props.ROLES, openconfigure, handleCerrarSesion, setOpenconfigure, navigate, handleremoveItem);


  const favoritosRef = useRef(null);

  const menuRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);

  const checkScrollButtons = () => {
    const menu = menuRef.current;
    if (menu) {
      const { scrollLeft, scrollWidth, clientWidth } = menu;
      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const scrollLeft = () => {
    if (menuRef.current) {
      menuRef.current.scrollBy({ left: -150, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (menuRef.current) {
      menuRef.current.scrollBy({ left: 150, behavior: "smooth" });
    }
  };

  useEffect(() => {
    // Check scroll buttons on mount and whenever the window resizes
    checkScrollButtons();
    window.addEventListener("resize", checkScrollButtons);
    return () => window.removeEventListener("resize", checkScrollButtons);
  }, [arrLocalStorage]);


  useEffect(() => {
    if (favoritosRef.current) {
      const rect = favoritosRef.current.getBoundingClientRect();

    }
  }, []);

  return (
    <div>

      {
        modal === true ?
          <MODAL_TABLE
            open={true}
            message={"Cerrando Sesión"}
            modalGeneral={true}
            modalT={false}
          ></MODAL_TABLE>
          : <></>
      }
      <CssBaseline />
      <AppBar id="appbar" style={{ zIndex: 205 }} className="appbar" position="fixed">
        <Toolbar
          style={{
            display: "grid",
            gridTemplateColumns: "10% 5% 45% 30% 5%",
            alignItems: "center",
            gap: "30px",
            ...style.styleToolbar,
          }}
        >
          {/* Logo */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <a href="https://od2.fricongelados.com/">
              <img
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "135px",
                  maxHeight: "40px",
                }}
                src={fcimagen}
                alt="logo"
              />
            </a>
          </div>

          {/* Icono de menú */}
          <Icon
            id="icono-menu-od2"
            className="icono-menu-od2"
            onClick={() => {
              handleClickIcon();
              if (accountconfig) {
                setAccountconfig(false);
                setOpen(true);
                return;
              }
              setOpen(!open);
            }}
            style={{ ...style.styleIconMenu }}
          >
            <MenuOutlined className="iconMenu" />
          </Icon>

          <Grid
            style={{
              ...style.styleConfig,
              display: accountconfig ? "block" : "none",
              borderRadius: "0 0 4px 4px",
            }}
          >
            {sections.filter((d) => d.title === "CONFIG").map(item => {
              if (item.type_control === "text") {
                return (
                  <ListItemButton key={item.id}>
                    {item.label}
                  </ListItemButton>
                );
              }

              if (item.type_control === "button") {
                return (
                  <ListItemButton
                    key={item.id}
                    onClick={item.action}
                    style={{ display: item.rol !== undefined ? item.rol ? "block" : "none" : "block" }}
                  >
                    <span>{item.label}</span>
                    {item.menuItems && item.menuItems.length > 0 && (
                      <>
                        <ul
                          style={{
                            display: openconfigure ? "block" : "none",
                          }}
                        >
                          {item.menuItems.map(child => (
                            <li
                              key={child.id}
                              style={{
                                ...style.styleConfigSections,
                                display: child.rol !== undefined ? child.rol ? "block" : "none" : "block",
                              }}
                              onClick={child.action}
                            >
                              <span>{child.label}</span>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </ListItemButton>
                );
              }

              return null;
            })}
          </Grid>
          {/* Barra de búsqueda */}
          <div
            className="searchbar"
            style={{
              ...style.styleSearchBar,
              display: screenSmall ? "none" : "block",
            }}
          >
            <SearchBar
              handleClickIcon={handleClickIcon}
              placeholder="Buscar ..."
              data={transformModulesToMenuData(sections)}
            />
          </div>

          {/* Favoritos */}
          <Drawer
            className="favoritos"
            open
            variant="permanent"
            PaperProps={{
              style: {
                backgroundColor: "rgb(26, 55, 77)",
                position: "relative",
                height: "36px",
                width: "75%",

              },
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
                padding: "0 5px",
              }}
            >
              {showLeftButton && (
                <button
                  onClick={() => {
                    scrollLeft();
                    setTimeout(checkScrollButtons, 300);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    color: "#fff",
                    fontSize: "10px",
                    cursor: "pointer",
                  }}
                >
                  ◀
                </button>
              )}
              <div
                ref={menuRef}
                onScroll={checkScrollButtons}
                style={{
                  display: "flex",
                  overflowX: "hidden",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}
              >
                {arrLocalStorage.map((valor) => (
                  <a
                    key={valor.name}
                    href={valor.ruta}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      textDecoration: "none",
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                  >
                    <span style={{ fontSize: "10px" }}>{valor.icono}</span>
                    {valor.name}
                  </a>
                ))}
              </div>
              {showRightButton && (
                <button
                  onClick={() => {
                    scrollRight();
                    setTimeout(checkScrollButtons, 300);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    color: "#fff",
                    fontSize: "10px",
                    cursor: "pointer",
                  }}
                >
                  ▶
                </button>
              )}
            </div>
          </Drawer>
          <div
            onClick={() => {
              if (open) {
                setOpen(false);
                setAccountconfig(true);
                return;
              }
              setAccountconfig(!accountconfig);
            }}
            id="for-avatar"
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
          >
            <Avatar className="avatar" alt={props.userEmail}>
              {avatar}
            </Avatar>
            <MoreVert fontSize="large" className="more" />
          </div>
        </Toolbar>
      </AppBar>


      <Box className="sidebar" display={showcontrol} id="sidebar">
        <Drawer
          open
          PaperProps={{ style: estilo }}
          variant="permanent"
          onMouseOver={handleMouseOver}
          onMouseOutCapture={handleMouseOut}
        >
          <List>
            <Divider />
            {sections.filter((d) => d.title !== "CONFIG").map((section) => (
              <Fragment key={section.id}>
                <Grid
                  style={{
                    display: section.roleDisplay,
                    color: "white",
                    fontStyle: states[section.stateIndex].state ? "oblique" : "normal",
                    textShadow: states[section.stateIndex].state ? "1px 1px 1px black" : "none",
                  }}
                  container
                >
                  <ListItemButton
                    style={{ fontWeight: states[section.stateIndex].state ? "bold" : "normal" }}
                    onClick={() => handleStateChange(section.stateIndex)}
                    className="itemsDrawer"
                  >
                    {section.icon ? (
                      <section.icon
                        style={{
                          ...style.iconStyle,
                          border: states[section.stateIndex].state ? "2px solid white" : "none",
                        }}
                        sx={{ fontSize: 30 }}
                      />
                    ) : (
                      <img
                        src={section.image}
                        style={{
                          width: 23,
                          paddingLeft: 3,
                          border: states[section.stateIndex].state ? "2px solid white" : "none",
                        }}
                        alt={section.title}
                      />
                    )}
                    <span style={showitem}>
                      {section.title} {icon.desplegar(states[section.stateIndex].state)}
                    </span>
                  </ListItemButton>
                  <Divider flexItem />
                  <ul
                    className="lista"
                    style={{
                      display:
                        states[section.stateIndex].state &&
                          estilo !== style.styleClose &&
                          showitem !== style.styleInhab
                          ? "block"
                          : "none",
                    }}
                  >
                    {section.menuItems.map((item) => (
                      <li key={item.id} style={{ display: item.rol }}>
                        <Icon
                          style={style.styleIconStar}
                          onClick={() => handleremoveItem(`${props.userId}${item.id}`, item.description)}
                        >
                          <Star
                            style={
                              checkLocalStorage(`${props.userId}${item.id}`)
                                ? style.selectedStar
                                : style.unselectedStar
                            }
                          />
                        </Icon>
                        <Link to={item.link}>{item.label}</Link>
                      </li>
                    ))}
                  </ul>
                </Grid>
                <Divider />
              </Fragment>
            ))}
            <Divider />
          </List>
        </Drawer>
      </Box>
    </div>
  );
};