//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from 'react-notification-system';
import TextField from "@mui/material/TextField";
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
import "./freez.css";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { get_cabinets_warehouse, get_movement_cabinets_sales, get_movement_pos_cabinets, get_movement_warehouse_cabinets } from "../../services/assets/assets";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { get_pointsofsale_list } from "../../services/businesspartners/businesspartners";
import WARNING from "../../main_components/alerts/warning";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";
import { FILTER_MOV } from "./utilities/filter";

import { SEARCH_FILTER_WEB_MOVIL } from "../../main_components/methods_v2/search_filter_web_movil";


const MovementWarehouseCabinets_v2 = () => {

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);

  const [open, setopen] = useState(false);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [pointsale, setpointsale] = useState(null);
  const [pointsales, setpointsales] = useState([]);
  const [mov, setmov] = useState("1");

  const [id_warehouse, setid_warehouse] = useState(null);
  const [datawarehouse, setdatawarehouse] = useState([]);
  const [fi, setfi] = useState("");
  const [ff, setff] = useState("");
  const [scan, setscan] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [show, setshow] = useState(true);
  const [total_rows, settotal_rows] = useState("");

  useEffect(() => {
    handlealmacenes();
    handlepointsale();
  }, []);

  function alertas(msj, status) {
    if (status === "warning") {
      notificationSystemRef.current.addNotification({
        message: <WARNING msj={msj} view={true}></WARNING>,
        level: "success",
        position: "br",
        autoDismiss: 30,
      });
    } else if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }

  const handlealmacenes = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          var d = await get_cabinets_warehouse();
          setdatawarehouse(d === null ? [] : d?.data?.warehouses);
        }, 1000);
      } else { }
    } catch (error) {
      console.error(error);
      setdatawarehouse([]);
    }
  }
  const handlepointsale = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var d = await get_pointsofsale_list();
            setpointsales(d === null ? [] : d?.data?.parametersList);
          } catch (err) {
            console.log(err);
          }
        }, 1000);
      } else { }
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleSubmit = async () => {

    console.log("entra en submit")

    if (mov === "1") {
      setshow(true);

      if (id_warehouse === null || id_warehouse === "vacio") {
        alertas("No ha seleccionado almacén", "warning");
        return null;
      }

      try {

        setData([]);
        setopen(true);
        setmenssage("Buscando");
        setmodalT(true);

        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try {
              var d = await get_movement_warehouse_cabinets(id_warehouse, fi, ff);
              var ndt = update_null_identifiers(d?.data?.movements, update_identifiers);
              setData(ndt === null ? [] : ndt);
              if (ndt?.length === 0) {
                alertas("No se encontro información de movimientos en el rango de fechas", false);
              }
              setopen(false);
              setmodalT(false);
            } catch (err) {
              catchErrorModul("Congeladores", JSON.stringify(data), JSON.stringify(err?.response === undefined ? "Token expirado" : err?.response));
              console.log(err);
              setopen(false);
              setmodalT(false);
            }
          }, 1000);
        } else { }
      } catch (err) {
        console.log(err);
      }
    } else if (mov === "2") {

      if (pointsale === null || pointsale === "") {
        alertas("No ha seleccionado punto de venta", "warning");
        return null;
      }

      setshow(false);
      try {
        setmodalT(true);
        setopen(true);
        setmenssage("Buscando");
        setData([]);

        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try {
              var d = await get_movement_pos_cabinets(pointsale, fi, ff);
              var ndt = update_null_identifiers(d?.data?.movements, update_identifiers);
              setData(ndt === null ? [] : ndt);
              if (ndt?.length === 0) {
                alertas("No se encontro información de movimientos en el rango de fechas", "warning");
              }
              setopen(false);
              setmodalT(false);
            } catch (err) {
              // catchErrorModul("Congeladores", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
              console.log(err);
              setopen(false);
              setmodalT(false);
            }
          }, 1000);
        } else { }
      } catch (err) {
        console.log(err);
      }
    } else if (mov === "3") {

      try {
        setmodalT(true);
        setopen(true);
        setmenssage("Buscando");
        setData([]);

        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try {
              var d = await get_movement_cabinets_sales(scan?.trim() === "" ? null : scan, fi, ff);
              var ndt = update_null_identifiers(d?.data?.sales, update_identifiers);
              setData(ndt === null ? [] : ndt);
              if (ndt?.length === 0) {
                alertas("No se encontro información de movimientos en el rango de fechas", "warning");
              }
              setopen(false);
              setmodalT(false);
            } catch (err) {
              if (err?.code === "ERR_NETWORK") {
                alertas("Error al obtener la información de ventas", false);
                alertas("Vuelve e Intentar con un rango de fechas reducido", "warning");
              } else {
                alertas("Error al obtener la información de ventas", false);
              }
              setopen(false);
              setmodalT(false);
            }
          }, 1000);
        } else { }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const update_identifiers =
    [
      { economic_number: 'string' },
      { name: 'string' },
      { name_1: 'String' },
      { movement_type: 'string' },
      { date_movement: 'string' },
      { warehouse_name: 'string' },
      { note: 'string' }
    ];

  const update_identifiers2 =
    [
      { code_pos: 'string' },
      { name_pos: 'string' },
      { ticket: 'String' },
      { date: 'string' },
      { scan: 'string' },
      { cabinetname: 'string' },
      { cabinet: 'string' }
    ];

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  const columns = useMemo(
    () => [
      {
        id: "economic_number",
        header: "Número Económico",
        accessorKey: "economic_number",
      },
      {
        // Filter: SelectColumnFilter,
        id: "name",
        header: "Marca",
        accessorKey: "name"
      },
      {
        id: "name_1",
        header: "Modelo",
        accessorKey: "name_1"
      },
      {
        id: "movement_type",
        header: "Movimiento",
        accessorKey: "movement_type"
      },
      {
        id: "date_movement",
        header: "Fecha Movimiento",
        accessorKey: "date_movement"
      },
      {
        header: "Almacén",
        accessorKey: "warehouse_name",
        // show: show,
        id: "warehouse_name"
      },
      {
        id: "note",
        header: "Nota",
        accessorKey: "note"
      }
    ],
    [show]
  );

  const columns2 = useMemo(
    () => [
      {
        id: "code_pos",
        header: "Código Punto de Venta",
        accessorKey: "code_pos",
      },
      {
        id: "name_pos",
        header: "Nombre Punto de Venta",
        accessorKey: "name_pos"
      },
      {
        id: "ticket",
        header: "Ticket",
        accessorKey: "ticket"
      },
      {
        id: "date",
        header: "Fecha",
        accessorKey: "date"
      },
      {
        id: "scan",
        header: "Congelador Escaneado",
        accessorKey: "scan"
      },
      {
        id: "cabinetname",
        header: "Marca Congelador",
        accessorKey: "cabinetname",
        accessorFn: (d) => {
          return d?.cabinet ? d?.cabinet?.name : ""
        }
      },
      {
        id: "cabinet",
        header: "Modelo Congelador",
        accessorKey: "cabinet",
        accessorFn: (d) => {
          return d?.cabinet ? d?.cabinet?.name_1 : ""
        }
      }
    ],
    []
  );


  const [minimenu, setminimenu] = useState(false);
  const [openmini, setopenmini] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        console.log('Screen width is less than 480px');
        setminimenu(true);
      } else {
        setminimenu(false);
      }

    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const filterMovComponent = (
    <FILTER_MOV
      mov={mov}
      setmov={setmov}
      id_warehouse={id_warehouse}
      setid_warehouse={setid_warehouse}
      datawarehouse={datawarehouse}
      pointsale={pointsale}
      setpointsale={setpointsale}
      pointsales={pointsales}
      setpointsales={setpointsales}
      scan={scan}
      setscan={setscan}
      fi={fi}
      ff={ff}
      setfi={setfi}
      setff={setff}
      handleSubmit={handleSubmit}
    />
  );

  return (
    <>
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR CONGELADORES", path: "/consult_frezzers_v2" },
          { label: "MOVIMIENTOS DE CONGELADORES", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>

      <div className="">
        <div>
          <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
          <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
          ></MODAL_TABLE>
          <SEARCH_FILTER_WEB_MOVIL
            minimenu={minimenu}
            setminimenu={setminimenu}
            openmini={openmini}
            setopenmini={setopenmini}
            filterMovComponent={filterMovComponent}
          ></SEARCH_FILTER_WEB_MOVIL>
          <>
            <div className="new_table_v2">
              <MaterialReactTable
                columns={mov === "3" ? columns2 : columns}
                data={data}
                enableGrouping
                enablePinning
                enableFacetedValues
                enableStickyHeader
                onStateChange={handleStateChange}
                enableStickyFooter
                enableRowPinning
                enableColumnFilterModes
                enableRowActions={false}
                enableRowSelection={false}
                getRowId={(row) => row?.id}
                onRowSelectionChange={setRowSelection}
                enableTooltips={false}
                localization={MRT_Localization_ES}
                state={{ rowSelection, isLoading: isLoading }}
                enableColumnResizing
                enableColumnPinning
                enableColumnOrdering
                enableExpandAll={false}
                initialState={{ showGlobalFilter: true }}
                muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
                muiSkeletonProps={{ animation: 'pulse', height: 28 }}
                rowVirtualizerInstanceRef
                rowVirtualizerOptions={{ overscan: 5 }}
                columnVirtualizerOptions={{ overscan: 2 }}
                muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
                positionToolbarAlertBanner="bottom"
                paginationDisplayMode='pages'
                rowPinningDisplayMode='sticky'
                layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

                muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
                muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
                displayColumnDefOptions={{
                  'mrt-row-pin': {
                    enableHiding: true,
                  },
                  'mrt-row-actions': {
                    enableHiding: true,
                  },
                  'mrt-row-expand': {
                    enableHiding: true,
                  },
                  'mrt-row-select': {
                    enableHiding: true
                  }
                }}
                muiTableBodyRowDragHandleProps={({ table }) => ({
                  onDragEnd: () => {
                    const { draggingRow, hoveredRow } = table.getState();
                    if (hoveredRow && draggingRow) {
                      data.splice(
                        hoveredRow.index,
                        0,
                        data.splice(draggingRow.index, 1)[0],
                      );
                      setData([...data]);
                    }
                  },
                })}
                muiTableBodyRowProps={
                  ({ row, table }) => {
                    settotal_rows(table);
                    const { density } = table.getState();
                    return {
                      sx: {
                        height: row.getIsPinned()
                          ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                          }px`
                          : undefined,
                      },
                    };
                  }
                }
                renderToolbarInternalActions={({ table }) => (
                  <RENDER_TOOLBAR_INTERNAL_ACTIONS
                    table={table} file_name={"Consultar Movimiento de Congeladores"}
                    update_identifiers={mov === "3" ? update_identifiers : update_identifiers2}
                    setmodalT={setmodalT}
                    handleSubmit={handleSubmit}
                    alertas={alertas}
                    settotal_rows={settotal_rows}
                  />
                )
                }
              />
            </div>

          </>
        </div>
      </div>
    </>
  );
}

export default MovementWarehouseCabinets_v2;
