import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Delete from "../../imgComponents/borrar.png";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import axios from 'axios';
import { SEND_EMAIL } from "./send_email";
import email from "../../imgComponents/correoElectronico.png";
import lupa from "../../imgComponents/lupa.png";
import edit from "../../imgComponents/save.png";

import { DETAILS } from "./details";
import { OPERATIONS } from "./operations";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const DeleteSector =async () =>{
        props.setmodalT(true);
        try{
             var rf_token = await refresh_token();
             if(rf_token===true){
               setTimeout(async() => {
                 try{
                    const response = await axios.delete(`https://6grw6zjp4e.execute-api.us-east-1.amazonaws.com/prod/avisos_od2?id=${props?.row?.id}`, {
                        headers: {
                          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                          "Content-Type": "application/json",
                        },
                    });
                   console.log(response);
                   props.alertas("Aviso eliminado", true);
                   props.setmodalT(false);
                   props.handleSubmit();
                 }catch(err){
                   console.log("error", err);
                   props.setmodalT(false);
                   props.alertas("Error, no se pudo eliminar el aviso", false);
                 }
               }, 1000);
             }else{}
           }catch(err){
            console.log(err);
           }
    }
   

    return (
        <div>
            {
                props?.isDomainValid === true ? 
                <MenuItem
                key={1}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <SEND_EMAIL
                            row={props?.row}
                            alertas={props?.alertas}
                            setmodalT={props?.setmodalT}
                        ></SEND_EMAIL>
                    ,
                    {
                        className: "custom-modal",
                        title: "AVISOS FC",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={email}
                        title="Enviar Aviso por correo"
                        className=""
                    ></img>
                </ListItemIcon>
                Enviar Aviso por Correo
            </MenuItem> :<></>
            }
            
            <MenuItem
                key={1}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <DETAILS
                            row={props?.row}
                            alertas={props?.alertas}
                            setmodalT={props?.setmodalT}
                        ></DETAILS>
                    ,
                    {
                        className: "custom-modal-2",
                        title: "Detalles de Aviso",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={lupa}
                        title="Detalles de Aviso"
                        className=""
                    ></img>
                </ListItemIcon>
                Detalles de Aviso
            </MenuItem>
            
            {
                props?.isDomainValid === true ? 
                <>
                <MenuItem
                    key={1}
                    onClick={ async () =>{
                        props.closeMenu();
                        await CustomDialog(
                            <OPERATIONS
                                setmodalt={props?.setmodalT}
                                get_avisos={props?.handleSubmit}
                                alertas={props?.alertas}
                                setIsLoading={props?.setIsLoading}
                                operation={"EDIT"}
                                row={props?.row}
                            />
                        ,
                        {
                            className: "custom-modal-2",
                            title: "Editar Aviso",
                            showCloseIcon: true,
                        }
                        );
                        } 
                    }
                    sx={{ m: 0 }}
                >
                    <ListItemIcon>
                        <img
                            alt=""
                            src={edit}
                            title="Editar Aviso"
                            className=""
                        ></img>
                    </ListItemIcon>
                    Editar Aviso
                </MenuItem>  
                <MenuItem
                    key={2}
                    onClick={ async() =>{
                        props.closeMenu();
                        const result = await Confirm('¿Esta usted seguro de eliminar el Aviso?','Eliminar Aviso','Si','No');
                        if(result){
                            DeleteSector()
                            }
                        } 
                    }
                    sx={{ m: 0 }}
                >
                    <ListItemIcon>
                        <img
                            alt=""
                            src={Delete}
                            title="Eliminar Aviso"
                            className=""
                        ></img>
                    </ListItemIcon>
                    Eliminar Aviso
                </MenuItem>  
                </>:<></>
            }

              
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
