import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Delete from "../../../../imgComponents/borrar.png";
import edit from "../../../../imgComponents/save.png";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import CREATE_UPDATE from "./update_price";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    return (
        <div>
            <MenuItem
                key={1}
                onClick={async () => {
                    props.closeMenu();
                    const result = await CustomDialog(

                        <CREATE_UPDATE
                            data_price_list={props?.data}
                            code={props?.row.original.code}
                            method={"DELETE"}
                            price={null}
                            id={sessionStorage.getItem("price_list_products")}
                            handleSubmit={props?.handleSubmit}
                        ></CREATE_UPDATE>,
                        {
                            className: "custom-modal",
                            title: "EDITAR PRODUCTOS",
                            showCloseIcon: true,
                        });
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Producto"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Producto
            </MenuItem>
            <MenuItem
                key={2}
                onClick={async () => {
                    props.closeMenu();
                    const result = await CustomDialog(
                        <CREATE_UPDATE
                            data_price_list={props?.data}
                            code={props?.row.original.code}
                            method={"UPDATE"}
                            price={props?.row.original.price}
                            handleSubmit={props?.handleSubmit}
                            id={sessionStorage.getItem("price_list_products")}
                        ></CREATE_UPDATE>,
                        {
                            className: "custom-modal",
                            title: "EDITAR PRODUCTOS",
                            showCloseIcon: true,
                        });
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={edit}
                        title="Editar Producto"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Producto
            </MenuItem>

        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
