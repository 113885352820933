//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//COMPONENTES
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";

export const POINT_OF_SALE_NOT_VISIT = (props) => {
    const notificationSystemRef = useRef();
    const [open, setopen] = useState(false);
    const [data, setData] = useState(props?.row?.original?.points_not_visit);
    const [isLoading, setIsLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [modalT, setmodalT] = useState(false);
    const [menssage, setmenssage] = useState("");
    const [modalGeneral, setmodalGeneral] = useState(false);
    const [total_rows, settotal_rows] = useState("");

    const handleStateChange = (newState) => {
        const filteredRowsCount = newState.filteredRowModel
        settotal_rows(filteredRowsCount);
    };

    const update_identifiers=
    [
      { code: 'string' },
      { name: 'string' }
    ];
    
    const columns = useMemo(
        () => [
          {
            id: "code",
            header: "Código Punto de Venta",
            accessorKey: "code",
            muiTableBodyCellProps: {
              align: 'center',
            },
            size: 200
          },
          {
            id: "name",
            accessorKey: "name",
            header: "Nombre del punto de venta",
            muiTableBodyCellProps:{
                align: "left"
            },
            size: 300
          }
        ], []);
  
  return (
    <>
    <div className="" style={{marginTop: "20px", position: "relative", width: "98%"}}>
         <BREADCRUMBS
            niveles={
                [
                    { label: "PUNTOS NO VISITADOS", path: null },
                ]
            }
            style={{width: "90%", margin: "0 auto"}}
            cards_dashboard={true}
            total_rows={total_rows !== "" ? total_rows : 0}
            filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
            visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
            total={data?.length}
        ></BREADCRUMBS>
    </div>
    <div className="new_table_v2" style={{"width":"95%", margin: "0 auto"}}>
       

        <MaterialReactTable
            columns={columns}
            data={data}
            enableGrouping
            enablePinning
            enableFacetedValues
            enableStickyHeader
            onStateChange={handleStateChange}
            enableStickyFooter
            enableRowPinning
            enableColumnFilterModes
            enableRowSelection
            // enableRowActions
            getRowId={(row) => row?.id}
            onRowSelectionChange={setRowSelection}
            enableTooltips={false}
            localization={MRT_Localization_ES}
            state={{ rowSelection, isLoading: isLoading }}
            enableColumnResizing
            enableColumnPinning
            enableColumnOrdering
            enableExpandAll={false}
            enableColumnDragging={false}
            initialState={{ showGlobalFilter: true, showColumnFilters: true }}
            muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
            muiSkeletonProps={{ animation: 'pulse', height: 28 }}
            rowVirtualizerInstanceRef
            rowVirtualizerOptions={{ overscan: 5 }}
            columnVirtualizerOptions={{ overscan: 2 }}
            muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
            positionToolbarAlertBanner="bottom"
            paginationDisplayMode='pages'
            rowPinningDisplayMode='sticky'
            layoutMode="grid"
            muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

            muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
            'mrt-row-pin': {
                enableHiding: true,
            },
            'mrt-row-actions': {
                enableHiding: true,
                size: 80
            },
            'mrt-row-expand': {
                enableHiding: true,
            },
            'mrt-row-select': {
                enableHiding: true,
            }
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                data.splice(
                    hoveredRow.index,
                    0,
                    data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
                }
            },
            })}
            muiTableBodyRowProps={
            ({ row, table }) => {
                settotal_rows(table);
                const { density } = table.getState();
                return {
                sx: {
                    height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
                };
            }
            }
            renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
                closeMenu={closeMenu}
                setmodalT={setmodalT}
                setmenssage={setmenssage}
                setmodalGeneral={setmodalGeneral}
                row={row}
            />
            ]}
            renderToolbarInternalActions={({ table }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table} 
                  file_name={"Puntos de Venta No Visitados"}
                  update_identifiers={update_identifiers}
                />
              )
              }
        />
    </div>
    </>
    
  )
}
