import React, { useEffect, useRef, useState } from "react";
import datversions from "./versions_data.json";
import "./patch_notes_home.css";
import { CustomDialog } from "react-st-modal";
import { OPERATIONS } from "./tools/operations";
import MODAL_TABLE from "../main_components/modal/modal_Table";
import NotificationSystem from 'react-notification-system';
import axios from "axios";
import { useNavigate } from 'react-router-dom'; // Cambiado a useNavigate
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import TABLE_NOTES from "./tools/table";
import AlertResponse from "../main_components/alerts/alertResponse";
import WidgetsIcon from '@mui/icons-material/Widgets';
import TableChartIcon from '@mui/icons-material/TableChart';
import { get_all_notices } from "../services/supports/avisos";

export const PATCH_NOTES_HOME = () => {
  const [modalT, setmodalt] = useState(false);
  const [datacarts, setdatacarts] = useState([]);
  const [data, setdata] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); // Cambiado a useNavigate
  const [table, settable] = useState(false);
  const [dataconfirm, setdataconfirm] = useState([]);
  const notificationSystemRef = useRef();

  var permisosUsuario = sessionStorage.getItem("roles_user");
  var permisoUsuarioReplace = permisosUsuario?.replace(/ /g, "");
  var permisos = permisoUsuarioReplace?.split(",");
  var permisoconfig = permisos?.filter((x) => x === "ROLE_CONFIGURATION");
  var usercognito = sessionStorage.getItem("email_uset");
  var isDomainValid = checkEmailDomain(usercognito);

  function checkEmailDomain(email) {
    return email && email.includes("@herramientasinformaticas.com");
  }

  useEffect(() => {
    get_avisos();
    consultarall();
  }, []);

  function alertas(msj, status) {
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={msj} view={status} />,
      level: status ? 'success' : 'error',
      position: 'br',
      autoDismiss: status ? 10 : 60,
    });
  }

  const get_avisos = async () => {
    setmodalt(true);
    const url = "https://6grw6zjp4e.execute-api.us-east-1.amazonaws.com/prod/avisos_od2";
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      const sortedData = response?.data.sort((a, b) => new Date(b?.date) - new Date(a?.date));
      setdatacarts(sortedData);
      setdata(sortedData);
      setmodalt(false);
    } catch (error) {
      console.error(error);
      setmodalt(false);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value === '') {
      setdatacarts(data);
    } else {
      const filtered = datacarts.filter(item =>
        item.title.toLowerCase().includes(value.toLowerCase())
      );
      setdatacarts(filtered);
    }
  };

  const build = (item) => {
    let filt = dataconfirm?.filter(i => i?.id_aviso === item?.id) || [];
    if (filt.length === 0) {
      filt = [{ status: 'Not Confirmed', date_confirmed: '' }];
    }

    item.status = filt[0].status;
    item.date_confirmed = filt[0].date_confirmed;

    navigate("/patch_notes/description_notes", { state: item }); // Actualizado con navigate
  };

  const upload = async () => {
    await CustomDialog(
      <div>
        <OPERATIONS
          setmodalt={setmodalt}
          get_avisos={get_avisos}
          alertas={alertas}
          settable={settable}
          setIsLoading={setIsLoading}
        />
      </div>,
      {
        className: "custom-modal-2",
        title: "Registrar Aviso",
        showCloseIcon: true,
        isCanClose: false,
      }
    );
  };

  const [isLoading, setIsLoading] = useState(false);

  const consultarall = async () => {
    try {
      setmodalt(true);
      const d = await get_all_notices();
      setdataconfirm(d?.data || []);
      setmodalt(false);
    } catch (err) {
      console.error(err);
      setmodalt(false);
    }
  };

  return (
    <>
      <div className="patch-notes-container">
        <NotificationSystem ref={notificationSystemRef} />
        <MODAL_TABLE
          open={true}
          message=""
          modalGeneral={false}
          modalT={modalT}
        />
        <div className="patch-notes-content">
          <div className="navbar-patch-notes">
            <h2>Notas de Actualizaciones</h2>
          </div>
          <div className="body-patch-notes">
            <div className="acciones-button-patch">
              <input
                className="search_note"
                placeholder="Buscar ..."
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <div className="button-search-notes"><SearchIcon /></div>
              {permisoconfig.length === 1 && isDomainValid && (
                <>
                  <div className="button-create-notes" title="Registrar" onClick={upload}><AddIcon /></div>
                  <div className="button-tools-notes" onClick={() => settable(!table)}>
                    {table ? <WidgetsIcon /> : <TableChartIcon />}
                  </div>
                </>
              )}
            </div>
            {table ? (
              <TABLE_NOTES
                data={data}
                setdata={setdata}
                get_avisos={get_avisos}
                setmodalt={setmodalt}
                alertas={alertas}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                isDomainValid={isDomainValid}
              />
            ) : (
              <div className="body-carts-content">
                {datacarts.map((item, index) => (
                  <div
                    className="body-carts-version"
                    key={index}
                    onClick={() => build(item)}
                  >
                    <div className="carts-version-image">
                      <img src={item?.img} alt="image-version" />
                    </div>
                    <div className="carts-version-description">
                      <p className="date-v"><span>ACTUALIZACIONES |</span>{item?.date}</p>
                      <p className="title">Notas Documentación {item?.version}</p>
                      <p className="description">{item?.title}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
