//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import CREATE_POLICY from "./tools/create_policy";
import SubTable from "./detail/consult_datail_policy";
import UPDATE_POLICY from "./tools/update_policy";
import { get_policy, delete_policy } from "../../services/expenses/expenses";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import "../expense.css";
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import Spinner from "../../imgComponents/S.gif";
import CREATE from "../../imgComponents/create.png";
import ADD from "../../imgComponents/add.png";
import { date_current, previous_date } from "../../main_components/date/date";
import TableContainer from "../../main_components/Table/TableContainer";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import AlertResponse from "../../main_components/alerts/alertResponse";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import DETAILS_POLICY_V2 from "./detail/consult_datail_policy_v2";


const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CONSULT_POLICY_V2 = () => {

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState("");
  const [ff, setff] = useState(date_current);
  const [fi, setfi] = useState(previous_date);
  const [modalT, setmodalT] = useState(false);
  const [total_rows, settotal_rows] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setIsLoading(true);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setOpen(true);
        setMenssage("Consultando pólizas...");

        try {
          var dta = await get_policy(fi, ff);
          const data = dta?.data?.sort((a, b) =>
            a.id < b.id ? 1 : a.id > b.id ? -1 : 0
          );
          setData(data);
          setOpen(false);
          setMenssage("");
          setIsLoading(false);
        } catch (err) {
          console.log("error", err);
          setData([]);
          setOpen(false);
          setMenssage("");
          setIsLoading(false);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      // {
      //   id: "expander",
      //   header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
      //     return (
      //       <img
      //         title="Crear Póliza"
      //         src={CREATE}
      //         alt=""
      //         onClick={async () => {
      //           const result = await CustomDialog(<CREATE_POLICY></CREATE_POLICY>, {
      //             className: "modalTItle",
      //             title: "Crear Póliza",
      //             showCloseIcon: true,
      //             isCanClose: false,
      //           });
      //           {
      //             handleReconsulta(result);
      //           }
      //         }}
      //       ></img>
      //     );
      //   },
      //   Cell: ({ row }) => {
      //     function guardardato() {
      //       sessionStorage.setItem("id_policy_subtable", row.original.id);
      //     }

      //     function Denegado() {
      //       alert("No tienes el rol necesario para ver los detalles del gasto");
      //     }

      //     if (
      //       sessionStorage.getItem("PERMISOVERDETALLESGASTO") ===
      //       "ROLE_EXPENSE_ADMINISTRATIVOS"
      //     ) {
      //       return (
      //         <a
      //           title="Consultar impuestos del concepto"
      //           {...row.getToggleRowExpandedProps()}
      //         >
      //           {row?.isExpanded ? (
      //             <span title="Consultar detalles de la póliza">🔼</span>
      //           ) : (
      //             <span
      //               title="Consultar detalles de la póliza"
      //               onClick={guardardato}
      //             >
      //               🔽
      //             </span>
      //           )}
      //         </a>
      //       );
      //     } else {
      //       return (
      //         <span title="Consultar detalles del gasto" onClick={Denegado}>
      //           🔽
      //         </span>
      //       );
      //     }
      //   },
      // },

      // {
      //   header: "Acciones",
      //   id: "Acciones",

      //   Cell: ({ valor, row }) => {

      //     return (
      //       <div>
      //         <img
      //           title="Inyectar Póliza"
      //           src={ADD}
      //           alt=""
      //           onClick={async () => {
      //             const result = await CustomDialog(
      //               <UPDATE_POLICY
      //                 codCocept={row.original.concept}
      //                 codType={row.original.type}
      //                 codId={row.original.id}
      //                 codAction={"INJECTION"}
      //                 codFecha={row.original.export_date}
      //               ></UPDATE_POLICY>,
      //               {
      //                 className: "modalTItle",
      //                 title: "Editar Póliza",
      //                 showCloseIcon: true,
      //                 isCanClose: false,
      //               }
      //             );
      //             {
      //               handleReconsulta(result);
      //             }
      //           }}
      //           className="centerText, taxes"
      //         ></img>

      //         <img
      //           title="Editar Póliza"
      //           src={SAVE}
      //           alt=""
      //           onClick={async () => {
      //             if (row.original.modificable === true) {
      //               const result = await CustomDialog(
      //                 <UPDATE_POLICY
      //                   codCocept={row.original.concept}
      //                   codType={row.original.type}
      //                   codId={row.original.id}
      //                   codAction={"UPDATE"}
      //                   codFecha={row.original.export_date}
      //                 ></UPDATE_POLICY>,
      //                 {
      //                   className: "modalTItle",
      //                   title: "Editar Póliza",
      //                   showCloseIcon: true,
      //                   isCanClose: false,
      //                 }
      //               );
      //               {
      //                 handleReconsulta(result);
      //               }
      //             } else {
      //               alert("Esta póliza ya no se puede modificar.");
      //             }
      //           }}
      //           className="centerText, taxes"
      //         ></img>

      //         <img
      //           title="Eliminar Póliza"
      //           src={DELETE}
      //           alt=""
      //           className="centerText, taxes"
      //           onClick={async () => {
      //             const result = await Confirm(
      //               "¿Esta usted seguro de eliminar esta póliza?",
      //               "Eliminar Póliza",
      //               "Si",
      //               "No"
      //             );
      //             if (result) {
      //               handle_delete_policy(row.original.id);
      //             } else {
      //             }
      //           }}
      //         ></img>
      //       </div>
      //     );
      //   },
      // },
      {
        header: "No.",
        accessorKey: "id",
        id: "id"
      },
      {
        header: "Concepto",
        accessorKey: "concept",
        id: "concept"
      },
      {
        header: "Tipo",
        accessorKey: "type",
        id: "type"
      },
      {
        header: "Fecha",
        accessorKey: "export_date",
        id: "export_date"
      },
      {
        header: "Estado",
        accessorKey: "status",
        id: "status"
      },
      {
        header: "Orden de descarga",
        accessorKey: "selection_order",
        id: "selection_order"
      },
      {
        header: "Modificable",
        accessorKey: "modificable",
        id: "modificable",
        accessorFn: (d) => {
          return d.modificable === true ? "✅" : "❌";
        },
      },
    ],
    []
  );

  const update_identifiers =
    [
      { id: 'string' },
      { concept: 'string' },
      { type: 'String' },
      { export_date: 'string' },
      { status: 'string' },
      { selection_order: 'string' },
      { modificable: 'string' }
    ];

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };



  return (
    <div >
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR POLIZAS", path: null },
        ]
      }
       cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>


      <div className="new_table_v2">

        <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          enableRowActions
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          onStateChange={handleStateChange}
          enableExpandAll={false}
          enableColumnDragging={false}
          initialState={{ showGlobalFilter: true, showColumnFilters: true }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
          muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              Header: () => (
                <div>
                  <img
                    title="Registrar Poliza"
                    src={CREATE}
                    style={{ "cursor": "pointer" }}
                    className=""
                    onClick={async () => {
                      const result = await CustomDialog(
                        <CREATE_POLICY
                          method={"CREATE"}
                          alertas={alertas}
                          handleSubmit={handleSubmit}
                          setmodalT={setmodalT}
                        />,
                        {
                          className: "custom-modal",
                          title: "Registrar Poliza",
                          showCloseIcon: true,
                          isCanClose: false,
                        }
                      );
                    }}
                  ></img>
                </div>
              ),
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              settotal_rows(table);
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }
          renderDetailPanel={({ row }) => {

            if (
              sessionStorage.getItem("PERMISOVERDETALLESGASTO") ===
              "ROLE_EXPENSE_ADMINISTRATIVOS"

            ) {
              sessionStorage.setItem("id_policy_subtable", row.original.id);
              return (
                <DETAILS_POLICY_V2
                  row={row}
                  setmodalT={setmodalT}
                  alertas={alertas}
                  data={data}
                  columns={columns}
                  defaultPageSize={3}
                  showPagination={false}
                />)
            } else {
              alert("No tienes el rol necesario para ver los detalles del gasto");
            }
          }}

          renderTopToolbarCustomActions={({ }) => (
            <div class="containerExpense tools-search-policy">
              <div class="expen-1 color-1">
                <input
                  type="text"
                  className="fExpense"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha inicial"
                  onChange={(event) => setfi(event.target.value)}
                ></input>
              </div>

              <div class="expen-2 color-2">
                <input
                  type="text"
                  className="fExpense"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha Final"
                  onChange={(event) => setff(event.target.value)}
                ></input>
              </div>

              <div class="expen-3 color-5">
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Buscar
                </Button>
              </div>
            </div>

          )}

          renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
              closeMenu={closeMenu}
              setmodalT={setmodalT}
              row={row}
              handleSubmit={handleSubmit}
              setreconsult={setreconsult}
              refresh_token={refresh_token}
              alertas={alertas}
            />
          ]}
          renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table}
              file_name={"Polizas"}
              update_identifiers={update_identifiers}
              settotal_rows={settotal_rows}
            />
          )
          }
        />
      </div>
    </div>
  );
};

export default CONSULT_POLICY_V2;
