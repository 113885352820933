//PAQUETERIAS
import { useEffect, useState, useMemo } from "react";
import { useDialog } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
//COMPONENTES
import { MaterialReactTable } from 'material-react-table';
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../../components_cfdi/table.css";
import "../../components_Expenses/expense.css";

//IMAGENES
import Spinner from "../../imgComponents/S.gif";
import { dashboard1_clasificacion_ticket } from "../../services/reports/dashboard";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ViewDetail_Detail_v2({ datei,datef,route,id_detail,pointSale,address, customer}) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [equality, setEquality] = useState("");
  const [success_msg, setSuccess_msg] = useState("none");
  const [error_msg, setError_msg] = useState("none");
  const [Reconsulta, setReconsulta] = useState(false);
  const dialog = useDialog();
  
  const handleSubmit = async (event) => {

    event?.preventDefault();
    setReconsulta(true);
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await dashboard1_clasificacion_ticket(customer.replaceAll(' ', '_')?.replaceAll("/","-"), id_detail,sessionStorage.getItem("idTicket"), datei, datef, route);
            const data = d === null ? [] : d.data;
            setData(data);
            const cargos = data.filter((item) => item.efect === "CARGO");
            const abonos = data.filter((item) => item.efect === "ABONO");
    
            const sumCargos = cargos
              .map((cargoSum) => parseFloat(cargoSum.ammount))
              .reduce((previous, current) => {
                return previous + current;
              }, 0);
    
            const sumAbonos = abonos
              .map((abonoSum) => parseFloat(abonoSum.ammount))
              .reduce((previous, current) => {
                return previous + current;
              }, 0);
            console.log("==>cargos<==");
            console.log(sumCargos);
            console.log("==>abonos<==");
            console.log(sumAbonos);
            if (sumCargos - sumAbonos === 0) {
              setEquality("Los datos cuadran");
              console.log("Los datos cuadran");
              setSuccess_msg("");
              setError_msg("none");
            } else {
              setEquality("Los datos no cudran");
              console.log("Los datos no cudran");
              setError_msg("");
              setSuccess_msg("none");
            }
            setReconsulta(false);
          }catch(err){
            console.log(err);
            setData([]);
            setReconsulta(false);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };
  
  useEffect(() => {
    handleSubmit();
  }, []);

  const update_identifiers =
  [
    { line: 'string' },
    { product: 'string' },
    { quantity: 'string' },
    { discount: 'string' },
    { tax: 'string' },
    { subtotal: 'string' },
    { total: 'string' }
  ];


  const columns = useMemo(
    () => [      
      
      {
        id: "line",
        header: "Linea",
        accessorKey: "line",
        size: 100,
      },
      
      {
        id: "product",
        header: "Producto",
        accessorKey: "product",
        size: 100
      },
      
      {
        id: "quantity",
        header: "Cantidad",
        accessorKey: "quantity",
      },
      {
        id: "discount",
        header: "Descuento",
        accessorKey: "discount",
        size: 100
      },
      {
        id: "tax",
        header: "Impuesto",
        accessorKey: "tax",
        size: 100
      },
      {
        id: "subtotal",
        header: "Sub Total",
        accessorKey: "subtotal",
        size: 100
      },
     
      {
        id:"total",
        header: "total",
        accessorKey: "total",
      },      
    ],
    []
  );

  if (Reconsulta === false) {
    return (
      <div>
        <div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style2}>
              <div className="centermodaldescarga">
                <Typography
                  id="modal-modal-title"
                  
                  variant="h6"
                  component="h2"
                >
                  <img src={Spinner}></img>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  
                  sx={{ mt: 2 }}
                >
                  {"Eliminando detalle..."}
                </Typography>
              </div>
            </Box>
          </Modal>
        </div>

        <div className="">   
        <MaterialReactTable
          columns= { columns}
          data= { data }
          enableColumnActions={false}
          enableColumnFilters
          enablePagination
          enableSorting
          enableBottomToolbar={false}
          enableTopToolbar={false}
          muiTableBodyRowProps={{ hover: false }}
        />
        </div>
      </div>
    );
  } else {
    return <div className="loader"></div>;
  }
}

export default ViewDetail_Detail_v2;
