//PAQUETERIAS
import { useEffect, useState, useMemo } from "react";
import { StickyTable} from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal"
//COMPONENTES
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { delete_models, get_models } from "../../services/assets/assets";
import TableContainer from "../../main_components/Table/TableContainer";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../../components_cfdi/table.css";
//IMAGENES
import OPERATIONS_MODEL from "./utilities/op_model";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./utilities/render_row_action_menuItems";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";

function MODELS_V2 (props) {
 
  const [data, setData] = useState([]);
  const [open, setopen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);

useEffect(() => {
  handleSubmit();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);


const update_identifiers =
[
  { name: 'string' },
  { date_register: 'String' },
];

const handleSubmit = async () => {
   
  setData([]);
  setopen(true);
  setIsLoading(true);

  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_models(props.id);
          var ndt = update_null_identifiers(d?.data?.models, update_identifiers);
          setData(ndt);
          setopen(false);
          setIsLoading(false);
        }catch(err){
          setData([]);
          setopen(false);
          setIsLoading(false);
        }
      }, 1000);
    }else{}
  } catch (err) {
    console.log(err);
  }
}
  const columns = useMemo(
    () => [
      // {
      //   Header:()=>(
      //     <div>
      //       <img
      //         id="AgregarModelo"
      //         src={CREATE}
      //         onClick={() => {
      //           CustomDialog(               
      //          <div>
      //             <OPERATIONS_MODEL operation={"CREATE"} handlemodelos={handlemodelos} reconsultar={props.reconsultar} alertas={props.alertas} id={props.id}></OPERATIONS_MODEL>
      //          </div>,
      //        {
      //          className: "modalTItle",
      //          title:"Agregar Modelo",
      //          showCloseIcon: true,
      //          isCanClose:false,
      //        }
      //    );
      //  }}
      //         title="Agregar Nuevo Modelo"
      //         alt=""
      //       ></img>
      //     </div>
      //   ),
      // id:"AccionesB",
      // Cell: ({ row }) => {  

      // const deletedetails = async () => {

      //   props.reconsultar(true);
      //   setData([]);
      //   setopen(true);
      //   try {
      //     var rf_token = await refresh_token();
      //     if(rf_token===true){
      //       setTimeout(async() => {
      //         try{
      //           const dt = await delete_models(props.id, row.original.id);
      //           console.log(dt);
      //           props.alertas("Modelo Relacionado a la Marca Eliminado", true);
      //           handlemodelos();
      //           props.reconsultar(false);
      //         }catch(err){
      //           console.log("error", err);
      //           props.alertas("No se pudo eliminar el Modelo relacionado a la Marca", false);
      //           handlemodelos();
      //           props.reconsultar(false);
      //         }
      //       }, 1000);
      //     }else{}
      //   } catch (err) {
      //     console.log(err);
      //   }
      // }
      //   return (
      //     <div>
      //       <img
      //         src={Delete}
      //         onClick={async() =>{
      //             const result = await Confirm('¿Esta usted seguro de eliminar el Modelo relacionado a la Marca?','Eliminar Modelo','Si','No');
      //             if(result){
      //               deletedetails();
      //               }
      //             }            
      //           }
              
      //         title="Eliminar Modelo"
      //         alt=""
      //       ></img>
      //       <img
      //         src={Edit}
      //         onClick={() => {
      //             CustomDialog(               
      //            <div>
      //               <OPERATIONS_MODEL operation={"EDIT"} handlemodelos={handlemodelos} reconsultar={props.reconsultar} alertas={props.alertas} id={props.id} row={row}></OPERATIONS_MODEL> 
      //            </div>,
      //          {
      //            className: "modalTItle",
      //            title:"Editar Modelo",
      //            showCloseIcon: true,
      //            isCanClose:false,
      //          }
      //      ); 
      //    }
      //   }
              
      //         title="Editar Modelo"
      //         alt=""
      //       ></img>
      //     </div>
      //   );
      // }
      // },
      {
        id: "name",
        header:"Modelo",
        accessorKey: "name",
        size: 300,
      },
      {
        id: "date_register",
        header:"Fecha Registro",
        accessorKey:"date_register",
        size: 300
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="">
      <div className="">  
      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
            size: 75
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderRowActionMenuItems={({ row, closeMenu }) => [
             <RENDER_ROW_ACTION_MENU_ITEMS
               closeMenu={closeMenu}
               setmodalT={props?.setmodalT}
               row={row}
               handleSubmit={handleSubmit}
               alertas={props?.alertas}
               id={props?.id}
              />
           ]}
        renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table} 
              file_name={"Consultar Modelos"}
              update_identifiers={update_identifiers}
            />
          )
        }
      />
      </div>
    </div>
    
  );
};

export default MODELS_V2;
