import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Delete from "../../imgComponents/borrar.png";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import Package from "../../imgComponents/package.png"
import { delete_bonification_packages_sales, post_bonification_packages_report } from "../../services/bonifications/bonifications";


const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const delete_sale = async (id) => {
        
        props?.setmodalT(true);
        
        try {
          var rf_token = await refresh_token();
          if (rf_token === true) {
            setTimeout(async () => {
              try {
                const d = await delete_bonification_packages_sales(sessionStorage.getItem("id_pack_edit"), props?.row.original.id);
                if(d?.status === 201){
                  props?.alertas("Paquete Eliminado por no contener ventas", true);
                  setTimeout(() => {
                    window.location = "/consult_report_bonuses_v2";
                  }, 2000);      
                }else{
                  if(d?.status === 200){
                    props?.alertas("Venta Eliminada", true);
                    props?.setmodalT(false);
                    props?.handleSubmit();
                  }
                }
              } catch (err) {
                props?.setmodalT(false);
                console.log("AXIOS ERROR: ", err);
              }
            }, 1000);
          } else { }
        } catch (err) {
          console.log(err);
        }
      }


    return (
        <div>
            <MenuItem
                key={1}
                onClick={() =>{
                    props.closeMenu(false);
                    delete_sale()
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Venta"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Venta
            </MenuItem>
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
