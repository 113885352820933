//PAQUETERIAS
import { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import NotificationSystem from "react-notification-system";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//COMPONENTES
import { refresh_token } from '../../main_components/tokens/tokenrefresh';
import { custom_multiple_filter, calculate_total } from '../../main_components/methods_v2/auxiliary_functions';
import { get_invoices } from '../../services/invoices/invoice';
import { formattedCurrentDate, formattedPreviousDate, formattedPreviousDate_f, formattedCurrentDate_f } from "../../main_components/date/day";
import SEARCH_FILTERS from './tools/shearch_filter';
import MODAL_TABLE from '../../main_components/modal/modal_Table';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../main_components/methods_v2/export';
import DETAIL_PAID_BY from './tools/paid _by';
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import RENDER_ROW_ACTION_MENU_ITEMS from './tools/render_row_action_menuItems';
import AlertResponse from '../../main_components/alerts/alertResponse';
import { pagination } from '../../main_components/pagination/pagination';
import { dateOnlyFilterFn, update_null_identifiers } from '../../main_components/methods_v2/auxiliar_funtion';
import { SEARCH_FILTER_WEB_MOVIL } from '../../main_components/methods_v2/search_filter_web_movil';
//CSS

const SALES_DEBTS = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [ff, setff] = useState(dayjs(formattedCurrentDate));
  const [fi, setfi] = useState(dayjs(formattedPreviousDate));
  const notificationSystemRef = useRef();
  const [rfc, setRFC] = useState("");
  const [sdn, setSDN] = useState("");
  const [cancel, setCancel] = useState("all");
  const [tipodoc, setTipoDoc] = useState("null");
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [id_businesspartners, setid_businesspartners] = useState(null);
  const [id_pointsofsale, setid_pointsofsale] = useState(null);
  const [invoicing, setinvoicing] = useState(true);

  const [total_rows, settotal_rows] = useState("");
  const [query_filters, setquery_filters] = useState({});
  const [restriction_dt, setrestriction_dt] = useState("SC");
  const [id_shearch_dt, setid_shearch_dt] = useState("");
  const [red_data, setred_data] = useState([]);
  const [yello_data_data, setyello_data_data] = useState([]);
  const [green_data, setgreen_data] = useState([]);

  const [g_total, setg_total] = useState(0);
  const [g_debts, setg_debts] = useState(0);
  const [g_returns, setg_returns] = useState(0);
  const [g_paid, setg_paid] = useState(0);
  const [usStateList, setusStateList] = useState([]);

  const update_identifiers =
    [

      { debt_date: "string" },
      { due_date: "string" },
      { "route-list": "string" },
      { uuid: "string" },
      { note: "string" },
      { debt: "number" },
      { paid: "number" },
      { total_debt: "number" },
      { ticket: "string" },
      { sale_date: "string" },
      { total_sale: "number" },
      { route: "string" },
      { folio_invoice: "string" },
      { invoice_date: "string" },
      { fullusername: "string" },
      { date_register: "string" },
      { payment_method: "string" },
      { overdraft_days: "string" },
      { code_business_partner: "string" },
      { name_business_partner: 'string' },
      { code_pos: 'string' },
      { name_pos: 'string' },
      { paidout: 'string' },
      { debt_flag: 'string' },
      { last_payment_date: 'string' },
      { total_returns: 'number' },
      { cancelled: 'string' },
      { name_user_of_payment: 'string' },
    ];

  const handle_get_sales = async (query_filters_flag, query_filters_data) => {
    setIsLoading(true);
    setData([]);
    try {
      await refresh_token();
      setTimeout(async () => {
        try {
          var dt;
          if (query_filters_flag) {
            setrestriction_dt(query_filters_data.id_businesspartners != null ? "SN" : query_filters_data.id_pointsofsale != null ? "PDV" : "SC");
            setid_shearch_dt(query_filters_data.id_businesspartners != null ? query_filters_data.shearch_dt : query_filters_data.id_businesspartners != null ? query_filters_data.shearch_dt : "");
            setfi(dayjs(query_filters_data.di))
            setff(dayjs(query_filters_data.df))
            dt = await pagination(7, "debts", null, null, query_filters_data.di, query_filters_data.df, query_filters_data.id_businesspartners === "null" ? null : query_filters_data.id_businesspartners, query_filters_data.id_pointsofsale === "null" ? null : query_filters_data.id_pointsofsale, props?.status, query_filters_data.invoicing);
          } else {
            setquery_filters({
              di: fi.format('YYYY-MM-DD'),
              df: ff.format('YYYY-MM-DD'),
              id_businesspartners: id_businesspartners,
              id_pointsofsale: id_pointsofsale,
              invoicing: invoicing,
              shearch_dt: id_shearch_dt,

            });

            dt = await pagination(
              7,
              "debts",
              null,
              null,
              fi.format('YYYY-MM-DD'),
              ff.format('YYYY-MM-DD'),
              id_businesspartners,
              id_pointsofsale,
              props?.status,
              invoicing,

            );
          }
          var dta = update_null_identifiers(dt, update_identifiers)

          // console.log(dta?.map(obj => obj.total_debt).filter(d => d !== null));
          setg_total(dta?.map(obj => obj.total_debt).filter(d => d !== null));
          setg_debts(dta?.map(obj => obj.debt).filter(d => d !== null));
          setg_returns(dta?.map(obj => obj.total_returns).filter(d => d !== null));
          setg_paid(dta?.map(obj => obj.paid).filter(d => d !== null));
          setIsLoading(false);
          setData(dta);

          var green_data = dta.filter((d) => d.debt_flag === 0);
          var yello_data = dta.filter((d) => d.debt_flag === 1);
          var red_data = dta.filter((d) => d.debt_flag === 2);

          setyello_data_data(
            ((yello_data.length / dta.length) * 100).toFixed(2)
          );
          setred_data(((red_data.length / dta.length) * 100).toFixed(2));
          setgreen_data(((green_data.length / dta.length) * 100).toFixed(2));
          const list = Array.from(new Set(dta?.map(obj => obj.route)));
          setusStateList(list);
          console.log(list)
        } catch (error) {
          console.log(error);
          setIsLoading(false)
          return null;
        }
      }, 1000);
    } catch (err) {
      setData([]);
      setIsLoading(false);
      setmodalT(false);
      console.log(err);

    }

  };

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const handle_success_filter = (a, b, c) => {
    handle_get_sales(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={c} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });
  };


  const columns = useMemo(
    () => [

      {
        id: "invoice_date",
        header: "Fecha Factura",
        accessorKey: "invoice_date",
        size: 200,
        muiTableBodyCellProps: {
          align: 'center',
        },
        Footer: () => (
          <div>
            <div>Subtotal: </div>
            <div>Gran Total: </div>
          </div>
        ),
        // filterVariant: 'date',
        // filterFn: dateOnlyFilterFn,
        // enableColumnFilterModes: false
      },
      {
        header: "Factura",
        accessorKey: "folio_invoice",
        id: "folio_invoice",
        size:150,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: "Ruta",
        accessorKey: "route-list",
        accessorFn: (d) => {
          return d.route
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        id: "route-list",
        filterVariant: 'multi-select',
        filterSelectOptions: usStateList,
        filtferFn: custom_multiple_filter,
        enableColumnFilterModes: false,
        size:120,

      },
      {
        header: "Fecha Venta",
        id: "sale_date",
        accessorKey: "sale_date",
        // filterVariant: 'date',
        // filterFn: dateOnlyFilterFn,
        // enableColumnFilterModes: false

      },
      {
        header: "Venta",
        accessorKey: "ticket",
        id: "ticket",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: "Código Punto de Venta",
        accessorKey: "code_pos",
        id: "code_pos",
        size:250,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: "Punto de venta",
        accessorKey: "name_pos",
        id: "name_pos",
        size:250,
      },
      {
        header: "Código Socio de Negocios",
        accessorKey: "code_business_partner",
        id: "code_business_partner",
        size:250,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: "Cliente",
        accessorKey: "name_business_partner",
        id: "name_business_partner",
      },
      {
        header: "Fecha Deuda",
        accessorKey: "debt_date",

        id: "debt_date",
        // filterVariant: 'date',
        // filterFn: dateOnlyFilterFn,
        // enableColumnFilterModes: false
      },

      {
        header: "Vencimiento",
        accessorKey: "due_date",
        accessorFn: (d) => {
          return d?.due_date?.split(".")[0];
        },
        id: "due_date",
        // filterVariant: 'date',
        // filterFn: dateOnlyFilterFn,
        // enableColumnFilterModes: false
      },
      {
        header: "Total",
        accessorKey: "total_debt",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        id: "total_debt",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.total_debt)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_total))}</div>
            </div>
          )
        },
      },

      {
        header: "Devolución / Bonificación",
        accessorKey: "total_returns",
        size:250,
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        id: "total_returns",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.total_returns)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_returns))}</div>
            </div>
          )
        },
      },
      {
        header: "Pago",
        accessorKey: "paid",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        id: "paid",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.paid)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_paid))}</div>
            </div>
          )
        },
      },
      {
        header: "Deuda",
        accessorKey: "debt",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        id: "debt",
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.debt)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_debts))}</div>
            </div>
          )
        },
      },
      {
        header: "Pagado",
        accessorKey: "debt",
        accessorFn: (d) => {
          return d.debt === 0 ? "SI" : "NO";
        },
        id: "debt_flag_trufalse",
        filterVariant: 'select',
        enableColumnFilterModes: false,
        muiTableBodyCellProps:{
          align: "center"
        }

      },
      {
        header: "Método",
        accessorKey: "payment_method",
        id: "payment_method",

      },
      {
        header: "Semaforo",
        accessorKey: "debt_flag",
        accessorFn: (d) => {
          return d.debt_flag === 0 ? "🟢" : d.debt_flag === 1 ? "🟡" : "🔴";
        },
        Cell: ({ row }) => {
          return (
            <div
              className="semaforo-celda"
              style={{
                backgroundColor:
                  row?.original?.debt_flag === 0
                    ? "#75ff75"
                    : row?.original?.debt_flag === 1
                      ? "#FFBF00"
                      : "red",
              }}
            ></div>
          );
        },
        id: "debt_flag",
      },
      {
        header: "Dias de Atraso",
        accessorKey: "overdraft_days",
        id: "overdraft_days",
        muiTableBodyCellProps:{
          align: "center"
        }
      },

      {
        header: "Ultima Fecha de Pago",
        accessorKey: "last_payment_date",
        id: "last_payment_date",
        // filterVariant: 'date',
        // filterFn: dateOnlyFilterFn,
        // enableColumnFilterModes: false
        size:250,
      },
      {
        header: "Pagado Por",
        accessorKey: "name_user_of_payment",
        id: "name_user_of_payment",
      },

    ],
    [g_total, g_debts, g_returns, g_paid, data, usStateList],
  );

  useEffect(() => {
    handle_get_sales();
  }, []);


  
  const [minimenu, setminimenu]=useState(false);
  const [openmini, setopenmini]=useState(false);
   useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 480) {
          console.log('Screen width is less than 480px');
          setminimenu(true);
        } else {
          setminimenu(false);
        }
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    const filters_custom = (
      <SEARCH_FILTERS
      fi={fi}
      setfi={setfi}
      ff={ff}
      setff={setff}
      tipodoc={tipodoc}
      setTipoDoc={setTipoDoc}
      cancel={cancel}
      setCancel={setCancel}
      rfc={rfc}
      setRFC={setRFC}
      sdn={sdn}
      setSDN={setSDN}
      handle_get_sales={handle_get_sales}
      setid_businesspartners={setid_businesspartners}
      setid_pointsofsale={setid_pointsofsale}
      invoicing={invoicing}
      setinvoicing={setinvoicing}
      restriction_dt={restriction_dt}
      id_shearch_dt={id_shearch_dt}
      setid_shearch_dt={setid_shearch_dt}
      setrestriction_dt={setrestriction_dt}
      red_data={red_data}
      yello_data={yello_data_data}
      green_data={green_data}
    ></SEARCH_FILTERS>
    );

  return (
    <div style={{ width: "100%", margin: "0 auto" }} className='new_table_v2'>
      <BREADCRUMBS niveles={
        [
          { label: props.status === 1 ? "CONSULTAR PAGOS" : "CONSULTAR DEUDAS", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
        modul="CONSULTAR_FACTURAS_V1"
        path="/consultaCFDI"
      ></BREADCRUMBS>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <SEARCH_FILTER_WEB_MOVIL
          minimenu={minimenu} 
          setminimenu={setminimenu}
          openmini={openmini} 
          setopenmini={setopenmini}
          filterMovComponent={filters_custom}
          renderTopToolbarCustomActionsFilters={true}
        ></SEARCH_FILTER_WEB_MOVIL> 
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}

      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions={props.status === 1 ? false : true}
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        onStateChange={handleStateChange}
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              data.splice(
                hoveredRow.index,
                0,
                data.splice(draggingRow.index, 1)[0],
              );
              setData([...data]);
            }
          },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderDetailPanel={({ row }) => {
          if (row.getIsExpanded() === true) {
            sessionStorage.setItem("dataSuntablefiltro", row.original.uuid_sat);
            sessionStorage.setItem("dataSuntablefiltroID", row.original.id);
          }
          return <DETAIL_PAID_BY
            row={row.original}
            columns={columns}
            handle_success_filter={handle_success_filter}

          />
        }}
        renderRowActionMenuItems={({ row, closeMenu }) => [
          <RENDER_ROW_ACTION_MENU_ITEMS
            key={row.original.id}
            closeMenu={closeMenu}
            setmodalT={setmodalT}
            setmenssage={setmenssage}
            setmodalGeneral={setmodalGeneral}
            row={row}
            handle_get_sales={handle_get_sales}
            refresh_token={refresh_token}
            handle_success_filter={handle_success_filter}
          />
        ]}

        renderTopToolbarCustomActions={({ }) => {
          if(minimenu === false){
            return(
              <>{filters_custom}</>
            )
          }
          
        }}
        renderToolbarInternalActions={({ table, idenx }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            key={idenx}
            share_filter={true}
            query_filters={query_filters}
            settotal_rows={settotal_rows}
            handle_success_filter={handle_success_filter}
            total_rows={total_rows !== "" ? total_rows : 0}
            table={table} file_name={"FACTURAS DEL " + fi.format('YYYY-MM-DD') + " AL " + ff.format('YYYY-MM-DD')}
            update_identifiers={update_identifiers}
            general_public_report={false}
            zip={true}
          />
        )
        }
      />
      {/* </LocalizationProvider> */}
    </div >
  );
};

export default SALES_DEBTS;