import React, { useEffect, useState } from 'react';
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';
import { get_bonus_images } from '../../../services/unilever/unilever';
import "./gallery.css";
import cargando from "../../../imgComponents/circle.gif";

export const GALLERY_EVIDENCE = (props) => {  
  
  useEffect(() => {
    imagenes();
  }, [])

  const [dataimg, setdataimg]=useState([]);
  const [load, setload]=useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % dataimg.length);
  };
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + dataimg.length) % dataimg.length);
  };

  const imagenes = async()=>{
    try{
        setload(true);
        const refres_token = await refresh_token();
        if(refres_token){
            setTimeout(async() => {
                try{
                  const id = (props?.row?.original?.reference).replace("/","");
                  const d = await get_bonus_images(id);
                  setdataimg(d?.data?.urls);
                  setload(false);
                }catch(err){
                  setload(false);
                  console.log(err);
                }
            }, 1500);
        }
    }catch(err){
        console.log(err);
    }
  }

  return (
    <div>
      <div className='content-evidence'>
        {
          load===true?
          <div className='load-imgs-gif'>
            <img src={cargando}></img>
            <p>Cargando ...</p>
          </div>:
          <>
            <div className='gallery-content'>
              <img src={dataimg[currentIndex]} alt='evidencia imagen' />
            </div>
            {
              dataimg?.length > 1?<>
                <button onClick={handlePrevious} className='prev-button'>Anterior</button>
                <button onClick={handleNext} className='next-button'>Siguiente</button>  
              </>:<></>
            }
            </>
      }
      </div>
    </div>
  );
}