import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/save.png";
import Delete from "../../../imgComponents/borrar.png";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { delete_warehouse } from "../../../services/configuration/configuration";
import OPERATIONS_WAREHOUSE from "./op_warehouse";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const DeleteSector =async () =>{
        props.setmodalT(true);
        props.setreconsult(true);
        try{
             var rf_token = await refresh_token();
             if(rf_token===true){
               setTimeout(async() => {
                 try{
                   const dt = await delete_warehouse(props?.row?.original?.id);
                   console.log(dt);
                   props.alertas("Almacén Eliminada", true);
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.handleSubmit();
                 }catch(err){
                   console.log("error", err);
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.alertas("Error, no se pudo eliminar el Almacén", false);
                 }
               }, 1000);
             }else{}
           }catch(err){
            console.log(err);
           }
    }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar el almacén?','Eliminar Almacén','Si','No');
                    if(result){
                        DeleteSector()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Almacén"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Almacén
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <OPERATIONS_WAREHOUSE
                             operation={"EDIT"}  
                             row={props?.row} 
                             handleSubmit={props?.handleSubmit} 
                             alertas={props?.alertas} 
                             setmodalT={props?.setmodalT}
                          />,
                    {
                        className: "custom-modal-2",
                        title: "EDITAR ALMACÉN",
                        showCloseIcon: true,
                    }
                     );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Almacén"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Almacén
            </MenuItem>
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
