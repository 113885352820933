import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';
import { get_competition_unilever_points_of_sale } from '../../../services/unilever/unilever';
import { BREADCRUMBS } from '../../../main_components/pagination/breadcrumbs';
import NotificationSystem from 'react-notification-system';
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { CustomDialog } from "react-st-modal";
import AlertResponse from '../../../main_components/alerts/alertResponse';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../../main_components/methods_v2/export';
import RENDER_ROW_ACTION_MENU_ITEMS_DETAILS from './render_row_action_menuItems_details';
import { Box } from '@mui/material';
import { calculate_total } from '../../../main_components/methods_v2/auxiliary_functions';

export const POINTS_OF_SALE_PARTICIPANTS = () => {
  
  const location = useLocation();
  const dat = location.state || {};
  const notificationSystemRef = useRef();
  const [open, setopen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [total_rows, settotal_rows] = useState("");


  useEffect(() => {
    handlesubmit();
  }, []);

  const handlesubmit = async() =>{
    try{
        setIsLoading(true);
        setmodalT(true);
        const rf = await refresh_token();
        if(rf){
            setTimeout(async() => {
                try{
                    const d = await get_competition_unilever_points_of_sale(dat?.id);
                    setData(d?.data)
                    setmodalT(false);
                    setIsLoading(false);
                }catch(err){
                    console.log(err);
                    setmodalT(false);
                    setIsLoading(false);
                }
            }, 1500);
        }
    }catch(err){
        setmodalT(false);
        setIsLoading(false);
        console.log(err);
    }
  }

  
  const columns = useMemo(
    () => [
      {
        id: "route",
        accessorKey: "route",
        header: "Ruta",
        muiTableBodyCellProps:{
          align: "center"
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        Footer: () => (
          <div>
            <div>Subtotal: </div>
            <div>Gran Total: </div>
          </div>
        ),
      },
      {
        id: "point_sale_code",
        header: "Código de Punto de Venta",
        accessorKey: "point_sale_code",
      },
      {
        id: "point_sale_name",
        header: "Punto de Venta",
        accessorKey: "point_sale_name",
      },
      {
        id: "sale_total",
        header: "Total de Ventas",
        accessorKey: "sale_total",
        muiTableBodyCellProps:{
          align: "right"
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.sale_total) ? 0 : obj.sale_total)
          const total = data.map(obj => isNaN(obj.sale_total) ? 0 : obj.sale_total);
          console.log(total);
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(total))
              }</div>
            </div>
          )
        }
      },
      {
        id: "tickets_obtained",
        header: "Tickets Obtenidos",
        accessorKey: "tickets_obtained",
        muiTableBodyCellProps:{
          align: "center"
        }
      }
    ],
    []
  );

  
  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  
  const update_identifiers =
    [
      { route: 'string' },
      { point_sale_code: 'string' },
      { point_sale_name: 'String' },
      { sale_total: 'string' },
      { tickets_obtained: 'string' }
    ];
  

    
  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }

  

  return (
    <div>
         <BREADCRUMBS niveles={
        [
          { label: "CONCURSOS", path: "/contests"},
          { label: "PUNTOS DE VENTA", path: null}
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
       <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
        <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
        ></MODAL_TABLE>
      <div className='new_table_v2'>
        <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowActions
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                  size: 80
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
               renderRowActionMenuItems={({ row, closeMenu }) => [
                 <RENDER_ROW_ACTION_MENU_ITEMS_DETAILS
                  closeMenu={closeMenu}
                  setmodalT={setmodalT}
                  setmenssage={setmenssage}
                  setmodalGeneral={setmodalGeneral}
                  row={row}
                  id={dat?.id}
                  handlesubmit={handlesubmit}
                  alertas={alertas}
                 />
               ]}
              renderToolbarInternalActions={({ table, index }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"Puntos de Venta del Consurso"}
                  update_identifiers={update_identifiers}
                  handle_get_consult={handlesubmit}
                  key={index}
                />
              )
              }
            />
      </div>

    </div>
  )
}
