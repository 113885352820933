import React, { useMemo, useState } from 'react'
// import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../../main_components/methods_v2/export';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import axios from "axios";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  Box,
  MenuItem,
  TextField,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import SearchIcon from '@mui/icons-material/Search';
import {Button} from '@mui/material';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../../main_components/methods_v2/export';
import { update_null_identifiers } from '../../../main_components/methods_v2/auxiliar_funtion';
import { calculate_total } from '../../../main_components/methods_v2/auxiliary_functions';
import { BREADCRUMBS } from '../../../main_components/pagination/breadcrumbs';

export const SUMMARY = (props) => {
  
  const [total_rows, settotal_rows] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  console.log(props?.datasum);

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

const update_identifiers = [
  { code: 'string' },
  { name: 'string' },
  { route: 'string' },
  { Enero: 'number' },
  { Febrero: 'number' },
  { Marzo: 'number' },
  { Abril: 'number' },
  { Mayo: 'number' },
  { Junio: 'number' },
  { Julio: 'number' },
  { Agosto: 'number' },
  { Septiembre: 'number' },
  { Octubre: 'number' },
  { Noviembre: 'number' },
  { Diciembre: 'number' },
  { total: 'number'}
];


const update_identifiers_2 = [
  { code: 'string' },
  { name: 'string' },
  { route: 'string' },
  { Enero_l: 'number' },
  { Febrero_l: 'number' },
  { Marzo_l: 'number' },
  { Abril_l: 'number' },
  { Mayo_l: 'number' },
  { Junio_l: 'number' },
  { Julio_l: 'number' },
  { Agosto_l: 'number' },
  { Septiembre_l: 'number' },
  { Octubre_l: 'number' },
  { Noviembre_l: 'number' },
  { Diciembre_l: 'number' }
];


const columns = useMemo(
  () => [
    {
      id: "code",
      header: "Código",
      accessorKey: "code",
      muiTableBodyCellProps:{
        align: "center"
      },
      muiTableFooterCellProps: {
        align: 'center',
      },
      Footer: () => (
        <div>
          <div>Subtotal: </div>
          <div>Gran Total: </div>
        </div>
      ),
    },
    {
      id: "name",
      header: "Nombre",
      accessorKey: "name",
      muiTableBodyCellProps:{
        align: "left"
      }
    },
    {
      id: "route",
      header: "Ruta",
      accessorKey: "route",
      muiTableBodyCellProps:{
        align: "center"
      }
    },
    {
      id: "Enero",
      header: "Enero",
      accessorKey: "Enero",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '12ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Enero) ? 0 : obj.Enero)
        const total = props?.datasum.map(obj => isNaN(obj.Enero) ? 0 : obj.Enero);
        console.log(total);
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total))
            }</div>
          </div>
        )
      },   
    },
    {
      id: "Febrero",
      header: "Febrero",
      accessorKey: "Febrero",
      muiTableBodyCellProps:{
        align: "right"
      },
        muiTableFooterCellProps: {
        align: 'right',
      },
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '12ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Febrero) ? 0 : obj.Febrero)
        const total = props?.datasum.map(obj => isNaN(obj.Febrero) ? 0 : obj.Febrero)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total))
            }</div>
          </div>
        )
      }, 
    },
    {
      id: "Marzo",
      header: "Marzo",
      accessorKey: "Marzo",
      muiTableBodyCellProps:{
        align: "right"
      },
        muiTableFooterCellProps: {
        align: 'right',
      },
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '12ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Marzo) ? 0 : obj.Marzo)
        const total = props?.datasum.map(obj => isNaN(obj.Marzo) ? 0 : obj.Marzo)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total))
            }</div>
          </div>
        )
      }, 
    },
    {
      id: "Abril",
      header: "Abril",
      accessorKey: "Abril",
      muiTableBodyCellProps:{
        align: "right"
      },
        muiTableFooterCellProps: {
        align: 'right',
      },
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '12ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Abril) ? 0 : obj.Abril)
        const total = props?.datasum.map(obj => isNaN(obj.Abril) ? 0 : obj.Abril)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total))
            }</div>
          </div>
        )
      }, 
    },
    {
      id: "Mayo",
      header: "Mayo",
      accessorKey: "Mayo",
      muiTableBodyCellProps:{
        align: "right"
      },
        muiTableFooterCellProps: {
        align: 'right',
      },
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '12ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Mayo) ? 0 : obj.Mayo)
        const total = props?.datasum.map(obj => isNaN(obj.Mayo) ? 0 : obj.Mayo)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total))
            }</div>
          </div>
        )
      }, 
    },
    {
      id: "Junio",
      header: "Junio",
      accessorKey: "Junio",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '12ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Junio) ? 0 : obj.Junio)
        const total = props?.datasum.map(obj => isNaN(obj.Junio) ? 0 : obj.Junio)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total))
            }</div>
          </div>
        )
      },
    },
    {
      id: "Julio",
      header: "Julio",
      accessorKey: "Julio",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '12ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Julio) ? 0 : obj.Julio)
        const total = props?.datasum.map(obj => isNaN(obj.Julio) ? 0 : obj.Julio)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total))
            }</div>
          </div>
        )
      },
    },
    {
      id: "Agosto",
      header: "Agosto",
      accessorKey: "Agosto",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '12ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Agosto) ? 0 : obj.Agosto)
        const total = props?.datasum.map(obj => isNaN(obj.Agosto) ? 0 : obj.Agosto)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total))
            }</div>
          </div>
        )
      },
    },
    {
      id: "Septiembre",
      header: "Septiembre",
      accessorKey: "Septiembre",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '12ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Septiembre) ? 0 : obj.Septiembre)
        const total = props?.datasum.map(obj => isNaN(obj.Septiembre) ? 0 : obj.Septiembre)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total))
            }</div>
          </div>
        )
      },
    },
    {
      id: "Octubre",
      header: "Octubre",
      accessorKey: "Octubre",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '12ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Octubre) ? 0 : obj.Octubre)
        const total = props?.datasum.map(obj => isNaN(obj.Octubre) ? 0 : obj.Octubre)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total))
            }</div>
          </div>
        )
      },
    },
    {
      id: "Noviembre",
      header: "Noviembre",
      accessorKey: "Noviembre",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '12ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Noviembre) ? 0 : obj.Noviembre)
        const total = props?.datasum.map(obj => isNaN(obj.Noviembre) ? 0 : obj.Noviembre)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total))
            }</div>
          </div>
        )
      },
    },
    {
      id: "Diciembre",
      header: "Diciembre",
      accessorKey: "Diciembre",
        muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '12ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Diciembre) ? 0 : obj.Diciembre)
        const total = props?.datasum.map(obj => isNaN(obj.Diciembre) ? 0 : obj.Diciembre)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total))
            }</div>
          </div>
        )
      },
    },
    {
      id: "total",
      header: "Total",
      accessorKey: "total",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '12ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.total) ? 0 : obj.total)
        const total = props?.datasum.map(obj => isNaN(obj.total) ? 0 : obj.total)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(total))
            }</div>
          </div>
        )
      },
    }
  ],
  [props?.datasum]
);


const columns2 = useMemo(
  () => [
    {
      id: "code",
      header: "Código",
      accessorKey: "code",
      muiTableBodyCellProps:{
        align: "center"
      },
      muiTableFooterCellProps: {
        align: 'center',
      },
      Footer: () => (
        <div>
          <div>Sub Total: </div>
          <div>Gran Total: </div>
        </div>
      ),
    },
    {
      id: "name",
      header: "Nombre",
      accessorKey: "name",
      muiTableBodyCellProps:{
        align: "left"
      },
    },
    {
      id: "route",
      header: "Ruta",
      accessorKey: "route",
      muiTableBodyCellProps:{
        align: "center"
      },
    },
    {
      id: "Enero_l",
      header: "Litros Enero",
      accessorKey: "Enero_l",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },

      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Enero_l) ? 0 : obj.Enero_l)
        const total = props?.datasum.map(obj => isNaN(obj.Enero_l) ? 0 : obj.Enero_l)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{calculate_total(subtotal)?.toFixed(2)}</div>
            <div>{calculate_total(total)?.toFixed(2)}</div>
          </div>
        )
      },
    },
    {
      id: "Febrero_l",
      header: "Litros Febrero",
      accessorKey: "Febrero_l",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Febrero_l) ? 0 : obj.Febrero_l)
        const total = props?.datasum.map(obj => isNaN(obj.Febrero_l) ? 0 : obj.Febrero_l)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{calculate_total(subtotal)?.toFixed(2)}</div>
            <div>{calculate_total(total)?.toFixed(2)}</div>
          </div>
        )
      },
    },
    {
      id: "Marzo_l",
      header: "Litros Marzo",
      accessorKey: "Marzo_l",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Marzo_l) ? 0 : obj.Marzo_l)
        const total = props?.datasum.map(obj => isNaN(obj.Marzo_l) ? 0 : obj.Marzo_l)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{calculate_total(subtotal)?.toFixed(2)}</div>
            <div>{calculate_total(total)?.toFixed(2)}</div>
          </div>
        )
      },
    },
    {
      id: "Abril_l",
      header: "Litros Abril",
      accessorKey: "Abril_l",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Abril_l) ? 0 : obj.Abril_l)
        const total = props?.datasum.map(obj => isNaN(obj.Abril_l) ? 0 : obj.Abril_l)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{calculate_total(subtotal)?.toFixed(2)}</div>
            <div>{calculate_total(total)?.toFixed(2)}</div>
          </div>
        )
      },
    },
    {
      id: "Mayo_l",
      header: "Litros Mayo",
      accessorKey: "Mayo_l",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Mayo_l) ? 0 : obj.Mayo_l)
        const total = props?.datasum.map(obj => isNaN(obj.Mayo_l) ? 0 : obj.Mayo_l)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{calculate_total(subtotal)?.toFixed(2)}</div>
            <div>{calculate_total(total)?.toFixed(2)}</div>
          </div>
        )
      },
    },
    {
      id: "Junio_l",
      header: "Litros Junio",
      accessorKey: "Junio_l",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Junio_l) ? 0 : obj.Junio_l)
        const total = props?.datasum.map(obj => isNaN(obj.Junio_l) ? 0 : obj.Junio_l)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{calculate_total(subtotal)?.toFixed(2)}</div>
            <div>{calculate_total(total)?.toFixed(2)}</div>
          </div>
        )
      },
    },
    {
      id: "Julio_l",
      header: "Litros Julio",
      accessorKey: "Julio_l",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Julio_l) ? 0 : obj.Julio_l)
        const total = props?.datasum.map(obj => isNaN(obj.Julio_l) ? 0 : obj.Julio_l)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{calculate_total(subtotal)?.toFixed(2)}</div>
            <div>{calculate_total(total)?.toFixed(2)}</div>
          </div>
        )
      },
    },
    {
      id: "Agosto_l",
      header: "Litros Agosto",
      accessorKey: "Agosto_l",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Agosto_l) ? 0 : obj.Agosto_l)
        const total = props?.datasum.map(obj => isNaN(obj.Agosto_l) ? 0 : obj.Agosto_l)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{calculate_total(subtotal)?.toFixed(2)}</div>
            <div>{calculate_total(total)?.toFixed(2)}</div>
          </div>
        )
      },
    },
    {
      id: "Septiembre_l",
      header: "Litros Septiembre",
      accessorKey: "Septiembre_l",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Septiembre_l) ? 0 : obj.Septiembre_l)
        const total = props?.datasum.map(obj => isNaN(obj.Septiembre_l) ? 0 : obj.Septiembre_l)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{calculate_total(subtotal)?.toFixed(2)}</div>
            <div>{calculate_total(total)?.toFixed(2)}</div>
          </div>
        )
      },
    },
    {
      id: "Octubre_l",
      header: "Litros Octubre",
      accessorKey: "Octubre_l",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Octubre_l) ? 0 : obj.Octubre_l)
        const total = props?.datasum.map(obj => isNaN(obj.Octubre_l) ? 0 : obj.Octubre_l)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{calculate_total(subtotal)?.toFixed(2)}</div>
            <div>{calculate_total(total)?.toFixed(2)}</div>
          </div>
        )
      },
    },
    {
      id: "Noviembre_l",
      header: "Litros Noviembre",
      accessorKey: "Noviembre_l",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Noviembre_l) ? 0 : obj.Noviembre_l)
        const total = props?.datasum.map(obj => isNaN(obj.Noviembre_l) ? 0 : obj.Noviembre_l)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{calculate_total(subtotal)?.toFixed(2)}</div>
            <div>{calculate_total(total)?.toFixed(2)}</div>
          </div>
        )
      },
    },
    {
      id: "Diciembre_l",
      header: "Litros Diciembre",
      accessorKey: "Diciembre_l",
      muiTableBodyCellProps:{
        align: "right"
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.Diciembre_l) ? 0 : obj.Diciembre_l)
        const total = props?.datasum.map(obj => isNaN(obj.Diciembre_l) ? 0 : obj.Diciembre_l)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{calculate_total(subtotal)?.toFixed(2)}</div>
            <div>{calculate_total(total)?.toFixed(2)}</div>
          </div>
        )
      },
    }
  
  ],
  [props?.datasum]
);  

  return (
  <>

      <BREADCRUMBS niveles={
        [
          { label: "SUMARIA", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={props?.datasum?.length}
      ></BREADCRUMBS>

    <div className='new_table_v2'>
        <MaterialReactTable
            columns={props?.cash === true ? columns :columns2}
            data={props?.datasum}
            enableGrouping
            enablePinning
            enableFacetedValues
            enableStickyHeader
            onStateChange={handleStateChange}
            enableStickyFooter
            enableRowPinning
            enableColumnFilterModes
            // enableRowActions
            enableRowSelection
            getRowId={(row) => row?.id}
            onRowSelectionChange={setRowSelection}
            enableTooltips={false}
            localization={MRT_Localization_ES}
            state={{ rowSelection, isLoading: isLoading }}
            enableColumnResizing
            enableColumnPinning
            enableColumnOrdering
            enableExpandAll={false}
            enableColumnDragging={false}
            initialState={{ showGlobalFilter: true, showColumnFilters: true }}
            muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
            muiSkeletonProps={{ animation: 'pulse', height: 28 }}
            rowVirtualizerInstanceRef
            rowVirtualizerOptions={{ overscan: 5 }}
            columnVirtualizerOptions={{ overscan: 2 }}
            muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
            positionToolbarAlertBanner="bottom"
            paginationDisplayMode='pages'
            rowPinningDisplayMode='sticky'
            layoutMode="grid"
            muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

            muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
              'mrt-row-pin': {
                enableHiding: true,
              },
              'mrt-row-actions': {
                enableHiding: true,
                size: 80
              },
              'mrt-row-expand': {
                enableHiding: true,
              },
              'mrt-row-select': {
                enableHiding: true,
              }
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                    props?.datasum.splice(
                    hoveredRow.index,
                    0,
                    props?.datasum.splice(draggingRow.index, 1)[0],
                  );
                  props?.setdatasum([...props?.datasum]);
                }
              },
            })}
            muiTableBodyRowProps={
              ({ row, table }) => {
                settotal_rows(table);
                const { density } = table.getState();
                return {
                  sx: {
                    height: row.getIsPinned()
                      ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                      }px`
                      : undefined,
                  },
                };
              }
            }
            renderTopToolbarCustomActions={({ }) => {
                return(
                <>
                  {props?.filters_custom}
                </>
                )
              }
            }
            renderToolbarInternalActions={({ table, index }) => (
              <RENDER_TOOLBAR_INTERNAL_ACTIONS
                table={table}
                file_name={"Sumaria Puntos de venta"}
                key={index}
                 
                update_identifiers={props?.cash ? update_identifiers: update_identifiers_2}
              />  
            )}
          />
    </div>
    </>
  )
}
