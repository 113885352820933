import { CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/save.png";
import SHOW_EDIT_FREZZER from "./show_edit_freezer";


const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {
    return (
        <div>
            <MenuItem
               key={2}
               onClick={ async () =>{
                   props.closeMenu();
                   await CustomDialog(
                    <SHOW_EDIT_FREZZER
                        id={props?.row?.index}
                        row={props?.row}
                        file_cabinets={props?.file_cabinets}
                        setfile_cabinets={props?.setfile_cabinets}
                        file_cabinets_error={props?.file_cabinets_error}
                        setfile_cabinets_error={props?.setfile_cabinets_error}
                        databrand = {props?.databrand}
                        datamodels = {props?.datamodels}  
                        process={props?.process}
                        update_data={props?.update_data}
                        setmodalT={props?.setmodalT}
                        alertas={props?.alertas}
                        update_data_error={props?.update_data_error}
                    ></SHOW_EDIT_FREZZER>,
                   {
                       className: "custom-modal-3",
                       title: "REGISTRAR LOTE DE CONGELADORES",
                       showCloseIcon: true,
                   }
                   );
                   } 
               }
               sx={{ m: 0 }}
           >
               <ListItemIcon>
                   <img 
                      alt="" src={Edit} title="Editar Congelador" className=""
                   ></img>
               </ListItemIcon>
               Editar Congelador
           </MenuItem>
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
