import React, { useState } from 'react'
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Button, FormControlLabel, IconButton, MenuItem, Switch } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useDialog } from 'react-st-modal';
import dayjs from 'dayjs';
import "./applicants.css";
import { refresh_token } from '../../../../main_components/tokens/tokenrefresh';
import { post_application, put_applicants, upload_file } from '../../../../services/humanResources/humanResources';

export const OPERATIONS_APPLICANTS = (props) => {

  const dialog = useDialog();

  const folio = props?.row?.original?.folio;
  const date = props?.operation === "CREATE" ? new Date().toISOString().split('T')[0] : props?.row?.original?.application_date;

  const [ candidate_name, setcandidate_name] = useState(props?.operation==="CREATE"? "" : props?.row?.original?.candidate_name);
  const [ candidate_email, setcandidate_email] = useState(props?.operation==="CREATE"? "" :props?.row?.original?.candidate_email);
  const [ candidate_phone, setcandidate_phone] = useState(props?.operation==="CREATE"? "" :props?.row?.original?.candidate_phone);
  const [ resume_url, setresume_url] = useState(props?.operation==="CREATE"? "" :props?.row?.original?.resume_url);
  const [ cover_letter, setcover_letter] = useState(props?.operation==="CREATE"? "" :props?.row?.original?.cover_letter);
  const [ interview_date, setinterview_date] = useState(
          props?.operation==="CREATE"? null :
          props?.row?.original?.interview_date===""? null : dayjs(props?.row?.original?.interview_date));
  const [ notes, setnotes] = useState(props?.operation==="CREATE"? "" :props?.row?.original?.notes);
  const [ source, setsource] = useState(props?.operation==="CREATE"? "WEB" :props?.row?.original?.source);
  const [ availability, setavailability] = useState(props?.operation==="CREATE"? "" :props?.row?.original?.availability);
  const [ expected_salary, setexpected_salary] = useState(props?.operation==="CREATE"? "" :props?.row?.original?.expected_salary);
  const [ years_of_experience, setyears_of_experience] = useState(props?.operation==="CREATE"? "" :props?.row?.original?.years_of_experience);
  const [ portfolio_url, setportfolio_url] = useState(props?.operation==="CREATE"? "" :props?.row?.original?.portfolio_url);
  const [ preferred_contact_method, setpreferred_contact_method] = useState(props?.operation==="CREATE"? "AMBOS" :props?.row?.original?.preferred_contact_method===""?"AMBOS":props?.row?.original?.preferred_contact_method);
  const [ status, setstatus] = useState(props?.operation==="CREATE"? null :props?.row?.original?.status);
  const [base64, setbase64] = useState("");
   
  const [show, setshow] = useState(props?.operation==="CREATE"? false : true);

  const handlecreate = async()=>{


    if(candidate_name?.trim() ==="" || candidate_email?.trim() === "" || candidate_phone?.trim() === "" || availability?.trim() === "" || cover_letter?.trim()===""){
      alert("LLena todos los campos obligatorios");
      return null;
    }
    if(base64===""){
        alert("No ha proporcionado el archivo PDF");
        return null;
    }

    var dat = {
        job_id: props?.job_id,
        candidate_name: candidate_name,
        candidate_email: candidate_email,
        candidate_phone: candidate_phone,
        resume_url: resume_url,
        cover_letter: cover_letter,
        interview_date: interview_date === "" || interview_date === null ? "" : interview_date.format("YYYY-MM-DD"),
        notes: notes,
        source: source,
        availability: availability,
        expected_salary: expected_salary,
        years_of_experience: years_of_experience,
        portfolio_url: portfolio_url,
        preferred_contact_method: preferred_contact_method === "AMBOS" ? null : preferred_contact_method,
    }



    props?.setmodalT(true);

      try{
        const rf_token = await refresh_token();
        if(rf_token){
          setTimeout(async() => {
            try{
              const d = await post_application(dat);
              console.log(d);
              props?.alertas("Registro Actualizado Exitosamente", true);
              upload_cv(d?.data?.application_id);   
            }catch(err){
              console.log(err);
              props?.setmodalT(false);
              props?.alertas("Error al actualizar la Solicitud", false);
            }
          }, 1500);
        }
      }catch(err){
        console.log(err);
        props?.setmodalT(false);
        props?.alertas("Error al actualizar", false);

      }

  }
  const upload_cv = async(id) =>{

      var data ={
          application_id: id,
          job_id: props?.job_id,
          document_data: base64
      }
      try{
          const d = await upload_file(data); 
          setbase64("");
          props?.setmodalT(false);
          dialog.close();
          props.handlesubmit();
      }catch(err){
          props?.alertas("Error al subir el CV", false);
          props.setmodalT(false);
      }
  }

  const handleupdate = async() =>{

    console.log(interview_date)

    var dat = {
      application_id: props?.row?.original?.application_id,
      job_id: props?.row?.original?.job_id,
      candidate_name: candidate_name,
      candidate_email: candidate_email,
      candidate_phone: candidate_phone,
      resume_url: resume_url,
      cover_letter: cover_letter,
      interview_date: interview_date === "" ? "" : interview_date.format("YYYY-MM-DD"),
      notes: notes,
      source: source,
      availability: availability,
      expected_salary: expected_salary,
      years_of_experience: years_of_experience,
      portfolio_url: portfolio_url,
      preferred_contact_method: preferred_contact_method,
      status: status
      }

      props?.setmodalT(true);

      try{
        const rf_token = await refresh_token();
        if(rf_token){
          setTimeout(async() => {
            try{
              const d = await put_applicants(dat);
              console.log(d);
              props?.setmodalT(false);
              props?.alertas("Registro Actualizado Exitosamente", true);
              dialog.close();
              props.handlesubmit();   
            }catch(err){
              console.log(err);
              props?.setmodalT(false);
              props?.alertas("Error al actualizar la Solicitud", false);
            }
          }, 1500);
        }
      }catch(err){
        console.log(err);
        props?.setmodalT(false);
        props?.alertas("Error al actualizar", false);

      }
  
  } 


  const handleSwitchChangeshow = (event) => {
    setshow(!event.target.checked);
  };
  
  
  const handleFileUpload = (file, fileInput) => {
    if (file && file.type === "application/pdf") {
          const reader = new FileReader();
          reader.onloadend = () => {
              const base64String = reader.result.split(",")[1];
              console.log("Base64 String:", base64String);
              setbase64(base64String);
          };
          reader.onerror = (error) => {
              console.error("Error al leer el archivo:", error);
          };
          reader.readAsDataURL(file);
      } else {
          props?.alertas("Error: Solo se permiten archivos en formato PDF.", false);
          fileInput.value = "";
      }
  };

  return (
    <div>
        
          <div className='mod-edition-form-edit'>
            {
              props?.operation === "CREATE"? <></>:
              <div className=''>
              <FormControlLabel
                      control={
                          <Switch
                              checked={!show}
                              onChange={handleSwitchChangeshow}
                              color="primary"
                              sx={{ flexShrink: 0 }}
                          />
                      }
                      label={!show ? "Desactivar Modo Edición":"Activar Modo Edición"}
                      sx={{
                          marginRight: '8px',
                          flex: '1 1 auto',
                          minWidth: '150px'
                      }}
                  />
          </div>
            }
          
        </div>
        <div className='content-form-applicants'>
          <div className='form-applicants'>
            {
              props?.operation === "CREATE"?
              <></>:
              <div className='grid1-form-applicants'>
            
            <div className='item1-form-applicants'>
                <TextField
                    label="Folio"
                    value={folio}
                    className='text-field-disabled'
                    fullWidth
                    disabled={true}
                    sx={{ m: 1.5}}
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
              </div> 
              <div className='item2-form-applicants'>
                <TextField
                    label="Fecha Postulación"
                    value={date}
                    className='text-field-disabled'
                    fullWidth
                    disabled={true}
                    sx={{ m: 1.5}}
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
              </div>
              <div className='item3-form-applicants'>
                <TextField
                    label="Estado"
                    value={status}
                    select
                    disabled={show}
                    sx={{ m: 1.5, width: "100%", textAlign: "left" }}
                    onChange={(event) => setstatus(event.target.value)}  
                    InputLabelProps={{ shrink: true, style: { fontSize: '1.3rem', backgroundColor: "white", paddingRight: "10px" } }}
            
                >
                    {[
                        {label: "Pendiente ", value: "Pending"},
                        {label: "En revisión", value: "Under Review"},
                        {label: "Entrevista programada", value: "Interview Scheduled"},
                        {label: "Aceptado ", value: "Accepted"},
                        {label: "Rechazado", value: "Rejected"}
                    ].map((option, key) => (
                        <MenuItem key={key} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}

                </TextField>
              </div>
              
            </div>

            }
            
            <div className='grid0-form-applicants'>
              <div className='item0-form-applicants'>
              <TextField
                    label="Nombre de Candidato"
                    value={candidate_name}
                    className='text-field-disabled'
                    
                    fullWidth
                    disabled={show}
                    sx={{ m: 1.5}}
                    onChange={(event)=>{
                      setcandidate_name(event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
            
              </div>
            </div>
            <div className='grid2-form-applicants'>
              <div className='item4-form-applicants'>
                <TextField
                    label="Número de Teléfono"
                    value={candidate_phone}
                    className='text-field-disabled'
                    
                    fullWidth
                    disabled={show}
                    sx={{ m: 1.5}}
                    onChange={(event)=>{
                      setcandidate_phone(event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                </div>
                <div className='item5-form-applicants'>
                  <TextField
                    label="Correo Electrónico"
                    value={candidate_email}
                    className='text-field-disabled'
                    
                    fullWidth
                    disabled={show}
                    sx={{ m: 1.5}}
                    onChange={(event)=>{
                      setcandidate_email(event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                </div>
            </div> 
            <div className='grid4-form-applicants'>
                <div className='item8-form-applicants'>
                  <TextField
                    label="Fuente"
                    value={source}
                    className='text-field-disabled'
                    fullWidth
                    disabled={props?.operation === "CREATE" ? true : show}
                    sx={{ m: 1.5}}
                    onChange={(event)=>{
                      setsource(event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                </div>
                <div className='item9-form-applicants'>
                  <TextField
                    label="Disponibilidad"
                    value={availability}
                    className='text-field-disabled'
                    fullWidth
                    disabled={show}
                    sx={{ m: 1.5}}
                    onChange={(event)=>{
                      setavailability(event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                </div>
                <div className='item10-form-applicants'>
                  <LocalizationProvider dateAdapter={AdapterDayjs} locale="es-mx">
                    <DatePicker
                        sx={{ m: 1.5, width: "100%"}}
                        className='text-field-disabled'                       
                        disabled={show}
                        label="Fecha para Entrevista"
                        value={interview_date}
                        onChange={(newValue) => setinterview_date(newValue)}
                        locale="es"
                        format="DD/MM/YYYY" 
                    />
                    </LocalizationProvider> 
                </div>
            </div>
            <div className='grid5-form-applicants'>
              <div className='item11-form-applicants'>
                  <TextField
                    label="Salario Esperado"
                    value={expected_salary}
                    className='text-field-disabled'
                    fullWidth
                    disabled={show}
                    sx={{ m: 1.5}}
                    onChange={(event)=>{
                      setexpected_salary(event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                </div>
                <div className='item12-form-applicants'>
                  <TextField
                    label="Años de Experiencia"
                    value={years_of_experience}
                    className='text-field-disabled'
                    fullWidth
                    disabled={show}
                    sx={{ m: 1.5}}
                    onChange={(event)=>{
                      setyears_of_experience(event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                </div>
                <div className='item13-form-applicants'>
                  <TextField
                    label="Contacto de Preferencia"
                    value={preferred_contact_method}
                    select
                    disabled={show}
                    className='contact-preference-form'
                    sx={{ m: 1.5, width: "100%", textAlign: "left"}}
                    onChange={(event) => setpreferred_contact_method(event.target.value)}  
                    InputLabelProps={{ shrink: true, style: { fontSize: '1.3rem', backgroundColor: "white", paddingRight: "10px" } }}
                  >
                    {[
                        {label: "Ambos", value: "AMBOS"},
                        {label: "Correo Electrónico", value: "correo"},
                        {label: "Teléfono", value: "telefono"}
                    ].map((option, key) => (
                        <MenuItem key={key} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}

                </TextField>
                </div>
            </div>
            <div className='grid3-form-applicants'>
                <div className='item7-form-applicants'>
                  <TextField
                    label="Carta de Presentación"
                    value={cover_letter}
                    className='text-field-disabled'
                    
                    fullWidth
                    disabled={show}
                    sx={{ m: 1.5}}
                    multiline
                    rows={3}
                    onChange={(event)=>{
                      setcover_letter(event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                </div>
            </div>
            <div className='grid3-form-applicants'>
                <div className='item7-form-applicants'>
                  <TextField
                    label="Notas"
                    value={notes}
                    className='text-field-disabled'
                    fullWidth
                    disabled={show}
                    sx={{ m: 1.5}}
                    multiline
                    rows={2}
                    onChange={(event)=>{
                      setnotes(event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                </div>
            </div>
            {
              props?.operation === "CREATE"?
              <div className=''>
              <TextField
                  label="Subir CV o Solicitud Laboral (PDF)"
                  type="file"
                  sx={{ m: 1.5, width: "100%" }}
                  InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '1.3rem', backgroundColor: "white", paddingRight: "10px" }
                  }}
                  InputProps={{
                      inputProps: { accept: "application/pdf" }
                  }}
                  onChange={(event) => {
                      const file = event.target.files[0];
                      handleFileUpload(file, event.target);
                  }}
              />
            </div>:<></>
            }
           
            <div className='button-actions-applicants'>
              <Box className="button-action-job-opp">
                <Button
                  variant="contained"
                  style={{"backgroundColor":"#1976d2"}}
                  sx={{ m: 1.5, width: "25ch" }}
                  disabled={show}
                  onClick={()=>{props?.operation==="CREATE"?handlecreate():handleupdate()}}
                >{props?.operation==="CREATE"?"REGISTRAR":"ACTUALIZAR"}
                </Button>
                <Button
                  style={{"backgroundColor":"white", "color":"#1976d2"}}
                  variant="contained"
                  sx={{ m: 1.5, width: "20ch" }}
                  onClick={() => {
                      dialog.close();
                    } 
                  }
                >
                cancelar
                </Button>
              </Box>
            </div>
            


          </div>

        </div>
    </div>
  )
}
