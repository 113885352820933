import { useEffect, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { get_route_list, post_recover } from "../../../services/businesspartners/businesspartners";

function RECOVERY_POS(props) {

  console.log(props);
  const dialog = useDialog();
  const [bandera, setbandera]=useState(true);
  const [route, setroutes]=useState([]);

useEffect(()=>{
  handleroute();
},[]);


function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

//consultar todas las rutas
const handleroute = async ()=>{
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_route_list();
          setroutes(d === null ? [] : d.data.parametersList.find(item=>item.idRoute===28)?d.data.parametersList.find(item => item.idRoute === props.row.original.point_of_sale.id_route_fk).route : null);            
        }catch(err){
          console.log(err);
        } 
      }, 1000);
    }else{}
  } catch (error) {
    console.log("error", error);
  }
}
//Metodo post para recuperar socio y puntos de venta relacionado a el 
const recoverybp = async () => {
props.setmodalT(true)
setbandera(false);

var data = {
  "idPointSale": props.row.original.point_of_sale.id  //ID del punto de venta a recuperar
}
  try {
    var rf_token = await refresh_token();
    if(rf_token === true){
      setTimeout(async() => {
        try{
          const dt = await post_recover(data);
          console.log(dt);
          props.alertas("Puntos de Venta recuperado con éxito", true);
          props.setmodalT(false);
          dialog.close();
          props.handlesubmit(); 
        }catch(err){
          catchErrorModul("Recuperar Punto de Venta", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
          console.log("error post", err);
          props.alertas("No se pudo recuperar el Punto de Venta, vuelva a intertarlo", false);
          props.setmodalT(false);
          setbandera(true);
        }  
      }, 1000);
    }else{}
   } catch (err) {}
}

  return (
    <div>
      <div className="subtablepromotions" style={{"textAlign":"center"}}>
        <h2>Socio de Negocios</h2>
        <div className="gridrecovery01">
          <div className="recov1">
            <TextField
              label="Código Socios de Negocios"
              value={props.row.original.business_partner.code}
              sx={{ m: 1.5, width: "100%"}}
            ></TextField>
          </div>
          <div className="recov2">
            <TextField
              label="Nombre Socios de Negocios"
              value={props.row.original.business_partner.business_partner}
              sx={{ m: 1.5, width: "100%"}}
            ></TextField>
          </div>
        </div>
        <div className="gridrecovery02">
          <div className="recov3">
            <TextField
              label="RFC"
              value={props.row.original.business_partner.rfc}
              sx={{ m: 1.5, width: "100%"}}
            ></TextField>
          </div>
          <div className="recov4">
            <TextField
              label="Tipo Persona"
              value={props.row.original.business_partner.person_type}
              sx={{ m: 1.5, width: "100%"}}
            ></TextField>
          </div>
          <div className="recov5">
            <TextField
              label="Tipo Socio"
              value={props.row.original.business_partner.partner_type}
              sx={{ m: 1.5, width: "100%"}}
            ></TextField>
          </div>
        </div>
        <h2>Punto de Venta</h2>
          <div className="gridrecovery03">
            <div className="recov6">
              <TextField
                label="Código Punto de Venta"
                value={props.row.original.point_of_sale.code}
                sx={{ m: 1.5, width: "100%"}}
              ></TextField>
            </div>
            <div className="recov7">
              <TextField
                label="Punto de Venta"
                value={props.row.original.point_of_sale.name}
                sx={{ m: 1.5, width: "100%"}}
              ></TextField>
            </div>
          </div>
          <div className="gridrecovery04">
            <div className="recov8">
              <TextField
                label="Ruta"
                value={route}
                sx={{ m: 1.5, width: "100%"}}
              ></TextField>
            </div>
            <div className="recov9">
              <TextField
                label="Latitud"
                value={props.row.original.point_of_sale.lat===null?" ":props.row.original.point_of_sale.lat}
                sx={{ m: 1.5, width: "100%"}}
              ></TextField>
            </div>
            <div className="recov10">
              <TextField
                label="Longitud"
                value={props.row.original.point_of_sale.lng===null?" ":props.row.original.point_of_sale.lat}
                sx={{ m: 1.5, width: "100%"}}
              ></TextField>
            </div>
          </div>
          <br/>

          <Box>
            {
              bandera===true?
              
            <Button
            style={{"backgroundColor":"green"}}
            variant="contained"
            sx={{ m: 1.5, width: {xs: "100%", sm: "40ch"} }}
            onClick={recoverybp}
          >
            Recuperar Socio de Negocios
          </Button>:
          <Button
          style={{"backgroundColor":"green", "height":"35px"}}
          variant="contained"
          sx={{ m: 1.5, width: {xs: "100%", sm: "40ch"} }}
        ><Spinner/></Button>
          }
            <Button
              style={{"backgroundColor":"red"}}
              disabled={!bandera}
              variant="contained"
              sx={{ m: 1.5, width: {xs:"100%", sm: "20ch" }}}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
              cancelar
            </Button>
          </Box>
      </div>
    </div>
  );
}

export default RECOVERY_POS;
